import React, {useCallback, useEffect, useState} from 'react';
import {IListTag} from "../../types";
import {ISort, ShowAlertState} from "../../../../core/types";
import {Alert, Backdrop, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {extractErrorMessage} from "../../../../core/helper";
import {useNavigate} from "react-router-dom";
import TagsList from "../../components/TagsList/TagsList";
import {deleteTag, getTags} from "../../services";

const TagsListContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>(search);
    const [page, setPage] = useState<number>(0);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [order, setOrder] = useState<ISort[]>([]);
    const [items, setItems] = useState<IListTag[]>([])
    const navigate = useNavigate();

    const onAdd = () => navigate('/tags/create');

    const onEdit = (item: IListTag) => navigate('/tags/' + item.id);

    const onDelete = async (item: IListTag) => {
        try {
            setLoading(true);
            await deleteTag(item.id);
            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Etiqueta eliminada satisfactoriamente'
            });
            setTimeout(loadTags, 1500)
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al eliminar la etiqueta')
            });
            setLoading(false);
        }
    };


    const loadTags = useCallback(async () => {
        try {
            setLoading(true);
            const paginatedResult = (await getTags(page + 1, pageSize, order, debouncedSearchTerm)).data;
            setTotalElements(paginatedResult.total);
            setItems(paginatedResult.items);
            setLoading(false);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado de etiquetas')
            });
            setLoading(false);
        }
    }, [page, pageSize, order, debouncedSearchTerm]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(search);
        }, 500); // Retraso de 500 ms

        // Limpia el timeout si el efecto se vuelve a ejecutar antes de que el timeout finalice
        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    useEffect(() => {
        loadTags();
    }, [loadTags]);

    return (<><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            <TagsList title={'Etiquetas'}
                      items={items}
                      onAdd={onAdd}
                      onEdit={onEdit}
                      order={order}
                      setOrder={setOrder}
                      search={search}
                      setSearch={setSearch}
                      page={page}
                      setPage={setPage}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      totalElements={totalElements}
                      onDelete={onDelete}
            />
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </>
    );
}

export default TagsListContainer;
