// src/core/components/Layout/Layout.tsx
import React from 'react';
import { Box } from '@mui/material';
import AppSidebar from '../AppSideBar/AppSideBar';
import AppTopBar from '../AppTopBar/AppTopBar';
import AppBottomBar from '../AppBottomBar/AppBottomBar';
import { Outlet } from 'react-router-dom';

interface LayoutProps {
    open: boolean;
    handleDrawerToggle: () => void;
}

const Layout: React.FC<LayoutProps> = ({ open, handleDrawerToggle }) => {
    return (
        <Box sx={{ display: 'flex' }}>
            <AppSidebar open={open} />
            <AppTopBar handleDrawerToggle={handleDrawerToggle} />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    width: '100%',
                    overflowX: 'hidden',
                    p: 1,
                    mt: 8, // Espacio para la AppBar superior
                    mb: 7, // Espacio para la AppBar inferior
                }}
            >
                <Outlet />
            </Box>
            <AppBottomBar />
        </Box>
    );
};

export default Layout;
