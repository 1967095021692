import React, {useCallback, useEffect, useState} from 'react';
import {IDetailProvider, IProvider} from "../../types";
import {ShowAlertState} from "../../../../core/types";
import {Alert, Backdrop, Box, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {createProvider, getProvider, updateProvider} from "../../services";
import {extractErrorMessage, is404Error} from "../../../../core/helper";
import {useNavigate, useParams} from "react-router-dom";
import {ProviderUpsertForm} from "../../components/ProviderUpsertForm/ProviderUpsertForm";

const ProviderUpsertContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [providerDetail, setProviderDetail] = useState<IDetailProvider | null>(null);
    const {id} = useParams<{ id: string }>();
    const navigate = useNavigate();
    const validNumberId = !isNaN(Number(id))

    const loadProviderDetail = useCallback(async () => {
        try {
            if (id && id !== 'create') {
                setLoading(true);
                const result = (await getProvider(+id)).data;
                setProviderDetail(result);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el proveedor')
            });
            setLoading(false);
            if (is404Error(error)) {
                setTimeout(() => navigate('/providers'), 2500);
            }
        }
    }, [id, setLoading, setProviderDetail, setAlert]);


    useEffect(() => {
        loadProviderDetail();
    }, [loadProviderDetail]);

    const handleCreateProvider = async (value: IProvider) => {
        try {
            setLoading(true);
            await createProvider(value);
            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Proveedor creado satisfactoriamente'
            });
            setTimeout(() => navigate('/providers'), 1500)
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al crear el proveedor')
            });
            setLoading(false);
        }
    }

    const handleUpdateProvider = async (value: IProvider) => {
        try {
            setLoading(true);
            await updateProvider(Number(id), value);
            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Proveedor actualizado satisfactoriamente'
            });
            setTimeout(() => navigate('/providers'), 1500)
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al actualizar el proveedor')
            });
            setLoading(false);
        }
    }


    return (<Box sx={{width: '80%', padding: 3}}><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            {id && id === 'create' && <ProviderUpsertForm loading={loading} handleSubmitForm={handleCreateProvider}/>}
            {id && id !== 'create' && validNumberId && providerDetail &&
                <ProviderUpsertForm value={providerDetail} loading={loading} handleSubmitForm={handleUpdateProvider}/>}
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </Box>
    );
}

export default ProviderUpsertContainer;
