import React from 'react';
import {Box, Button, Card, CardContent, TextField, Typography} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {IListCorporateCardAuthorizationConcept, IUpsertCorporateCardAuthorizationConcept} from "../../types";
import {useNavigate} from "react-router-dom";
import {appRoutes} from "../../../../core/const";

export interface CorporateCardAuthorizationConceptUpsertFormProps {
    handleSubmitForm: (value: IUpsertCorporateCardAuthorizationConcept) => void;
    loading: boolean;
    value?: IListCorporateCardAuthorizationConcept
}

export const CorporateCardAuthorizationConceptUpsertForm: React.FC<CorporateCardAuthorizationConceptUpsertFormProps> = ({handleSubmitForm, loading, value}) => {

    const methods = useForm<IUpsertCorporateCardAuthorizationConcept>({
        mode: 'all',
        defaultValues: {name: value?.name || ''}
    });

    const navigate = useNavigate();
    const {
        control,
        formState: {errors, isValid},
        getValues,
    } = methods;


    return (
        <>
            <Card sx={{maxWidth: 600, width: '100%'}}>
                <CardContent>
                    <Typography variant="h5" style={{fontWeight: 'bold'}}
                                p={1}>{value ? 'Actualizar concepto' : 'Crear nuevo concepto'}</Typography>
                    <br/>
                    <Box component="form" display="flex" flexDirection="column" gap={1}>

                        <Box display="flex" flexDirection="row" justifyContent="space-around">
                            <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{
                                        required: {value: true, message: 'Este campo es requerido'},
                                        maxLength: {
                                            value: 255,
                                            message: 'El campo excede el largo máximo permitido de: 255 caracteres'
                                        }
                                    }}
                                    render={({field}) => (
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            label="Nombre"
                                            size={'small'}
                                            type="text"
                                            helperText={errors.name ? errors.name.message : ''}
                                            error={!!errors.name}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>
                        </Box>


                        <Box display="flex" width="100%" justifyContent="flex-end" gap={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => navigate(appRoutes.corporateCardAuthorizationConcept.list)}
                            >
                                Volver al listado
                            </Button>
                            <Button
                                variant="contained"
                                disabled={!isValid || loading}
                                color="primary"
                                onClick={() => handleSubmitForm(getValues())}
                            >
                                Guardar
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
        ;
};