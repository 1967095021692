import React, {Dispatch, SetStateAction, useState} from 'react';

import {
    Box,
    Button,
    FormControl,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    Popover,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Toolbar,
    Typography,
} from '@mui/material';
import {Add as AddIcon, FilterList as FilterListIcon, Search as SearchIcon} from '@mui/icons-material';
import {ISort} from "../../../../core/types";
import {IFilterSaleOrder, IListSaleOrder} from "../../types";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, {Dayjs} from "dayjs";
import {DatePicker} from "@mui/x-date-pickers";
import ScrollableTableContainer from "../../../../core/components/ScrollableTableContainer/ScrollableTableContainer";
import EmptyTable from "../../../../core/components/EmptyTable/EmptyTable";

interface SalesOrderListProps {
    title: string;
    search: string;
    setSearch: (search: string) => void;
    page: number;
    setPage: (page: number) => void;
    pageSize: number;
    setPageSize: (pageSize: number) => void;
    filters: IFilterSaleOrder;
    setFilters: (filter: IFilterSaleOrder) => void;
    order: ISort[],
    setOrder: Dispatch<SetStateAction<ISort[]>>;
    items: IListSaleOrder[];
    totalElements: number;
    onAdd: () => void;
}

const SalesOrderList: React.FC<SalesOrderListProps> = ({
                                                           title,
                                                           search,
                                                           setSearch,
                                                           page,
                                                           setPage,
                                                           pageSize,
                                                           totalElements,
                                                           setPageSize,
                                                           filters,
                                                           setFilters,
                                                           order,
                                                           setOrder,
                                                           items,
                                                           onAdd,
                                                       }) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [internalFilters, setInternalFilters] = useState<IFilterSaleOrder>({
        id: '',
        amount: '',
        amountFrom: '',
        amountTo: '',
        dealId: '',
        fileNumber: '',
        departureDateFrom: '',
        departureDateTo: '',
        toUseInSameIssue: '',
        fee: '',
        sellerName: '',
        createdAtFrom: '',
        createdAtTo: '',
    });
    const [departureDateFrom, setDepartureDateFrom] = useState<Dayjs | null>(null);
    const [departureDateTo, setDepartureDateTo] = useState<Dayjs | null>(null);

    const [createdAtFrom, setCreatedAtFrom] = useState<Dayjs | null>(null);
    const [createdAtTo, setCreatedAtTo] = useState<Dayjs | null>(null);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleFilterChange = (field: keyof typeof filters) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setInternalFilters((prev)=>({...prev, [field]: event.target.value}));
    };

    const handleFilterChangeValue = (field: keyof typeof filters, value: string | number) => {
        setInternalFilters((prev)=>({...prev, [field]: value}));
    };

    const handleChangeToUseSameIssue = (event: SelectChangeEvent<unknown>) => {
        if (event.target.value !== undefined) {
            setInternalFilters((prev)=>({...prev, toUseInSameIssue: event.target.value == 'true'}));
        } else {
            setInternalFilters((prev)=>({...prev, toUseInSameIssue: undefined}));
        }

    };

    const handleApplyFilter = () => {
        setFilters(internalFilters);
        handleCloseMenu();
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (property: keyof IListSaleOrder) => {
        const exist = order.find(o => o.field === property)
        const isAsc = order.find(o => o.field === property)?.order === 'asc';
        if (!exist) {
            setOrder(prevOrder => [
                ...prevOrder.filter(o => o.field !== property),
                {field: property, order: isAsc ? 'desc' : 'asc'},
            ]);
        } else {
            if (exist.order === 'asc') {
                setOrder(prevOrder => [
                    ...prevOrder.filter(o => o.field !== property),
                    {field: property, order: 'desc'},
                ]);
            } else {
                setOrder(prevOrder => [
                    ...prevOrder.filter(o => o.field !== property),
                ]);
            }
        }

    };

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleCancelFilters = () => {
        setInternalFilters(filters);
        handleCloseMenu();
    };

    return (
        <Box sx={{width: '100%', padding: 3}}>
            <Typography variant="h4" component="div" sx={{mb: 2}}>
                {title}
            </Typography>
            <Toolbar sx={{display: 'flex', justifyContent: 'space-between', mb: 2}} style={{paddingLeft: 0}}>
                <Button
                    variant="contained"
                    startIcon={<AddIcon/>}
                    onClick={() => onAdd()}
                >
                    Nueva orden de venta
                </Button>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <TextField
                        variant="outlined"
                        placeholder="Buscar"
                        size="small"
                        value={search}
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon/>
                                </InputAdornment>
                            ),
                        }}
                        sx={{mr: 2, width: 300}}
                    />
                    <Button variant="outlined" startIcon={<FilterListIcon/>} onClick={handleOpenMenu}>
                        Filtrar
                    </Button>
                    <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        disableRestoreFocus
                    >
                        <Box sx={{p: 2, display: 'flex', flexDirection: 'column', gap: 1}}>
                            <TextField
                                label="ID igual a"
                                size='small'
                                value={internalFilters.id}
                                onChange={handleFilterChange('id')}
                                fullWidth
                            />
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={internalFilters.amountFrom}
                                onChange={(e) => {
                                    let value = e.target.value;

                                    // Permitir solo números, un punto decimal, y hasta dos dígitos decimales
                                    value = value.replace(/[^0-9.]/g, ''); // Elimina todo lo que no sea un número o punto
                                    const parts = value.split('.');

                                    // Asegurarse de que solo haya un punto decimal y recortar a 2 decimales
                                    if (parts.length > 2) {
                                        value = `${parts[0]}.${parts[1]}`; // Eliminar puntos extra
                                    } else if (parts[1]?.length > 2) {
                                        value = `${parts[0]}.${parts[1].slice(0, 2)}`; // Limitar a 2 decimales
                                    }

                                    handleFilterChangeValue('amountFrom', value);
                                }}
                                label="Monto mayor o igual a"
                                size="small"
                                type="text"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">USD</InputAdornment>, // Añade "USD" al final del input
                                }}

                            />

                            <TextField
                                variant="outlined"
                                fullWidth
                                value={internalFilters.amountTo}
                                onChange={(e) => {
                                    let value = e.target.value;

                                    // Permitir solo números, un punto decimal, y hasta dos dígitos decimales
                                    value = value.replace(/[^0-9.]/g, ''); // Elimina todo lo que no sea un número o punto
                                    const parts = value.split('.');

                                    // Asegurarse de que solo haya un punto decimal y recortar a 2 decimales
                                    if (parts.length > 2) {
                                        value = `${parts[0]}.${parts[1]}`; // Eliminar puntos extra
                                    } else if (parts[1]?.length > 2) {
                                        value = `${parts[0]}.${parts[1].slice(0, 2)}`; // Limitar a 2 decimales
                                    }

                                    handleFilterChangeValue('amountTo', value);
                                }}
                                label="Monto menor o igual a"
                                size="small"
                                type="text"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">USD</InputAdornment>, // Añade "USD" al final del input
                                }}

                            />

                            <TextField
                                variant="outlined"
                                fullWidth
                                value={internalFilters.amount}
                                onChange={(e) => {
                                    let value = e.target.value;

                                    // Permitir solo números, un punto decimal, y hasta dos dígitos decimales
                                    value = value.replace(/[^0-9.]/g, ''); // Elimina todo lo que no sea un número o punto
                                    const parts = value.split('.');

                                    // Asegurarse de que solo haya un punto decimal y recortar a 2 decimales
                                    if (parts.length > 2) {
                                        value = `${parts[0]}.${parts[1]}`; // Eliminar puntos extra
                                    } else if (parts[1]?.length > 2) {
                                        value = `${parts[0]}.${parts[1].slice(0, 2)}`; // Limitar a 2 decimales
                                    }

                                    handleFilterChangeValue('amount', value);
                                }}
                                label="Monto igual a"
                                size="small"
                                type="text"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">USD</InputAdornment>, // Añade "USD" al final del input
                                }}

                            />
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Cuotas igual a"
                                value={internalFilters.fee}
                                placeholder="Cantidad de cuotas igual a"
                                size="small"
                                type="text"
                                onChange={(e) => {
                                    const value = e.target.value.replace(/\D/g, ''); // Elimina todo lo que no sea un número
                                    handleFilterChangeValue('fee', value);
                                }}
                            />
                            <TextField
                                label="Número de la negociación igual a"
                                size='small'
                                value={internalFilters.dealId}
                                onChange={handleFilterChange('dealId')}
                                fullWidth
                            />
                            <TextField
                                label="Número de file igual a"
                                size='small'
                                value={internalFilters.fileNumber}
                                onChange={handleFilterChange('fileNumber')}
                                fullWidth
                            />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Box sx={{display: 'flex', gap: 2, flexDirection: 'column', maxWidth: 300}}>
                                    <DatePicker
                                        label="Fecha de salida desde"
                                        format="YYYY-MM-DD"
                                        value={departureDateFrom ? dayjs(departureDateFrom) : null}
                                        onChange={(newValue) => {
                                            setDepartureDateFrom(newValue);
                                            handleFilterChangeValue('departureDateFrom', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />
                                    <DatePicker
                                        label="Fecha de salida hasta"
                                        format="YYYY-MM-DD"
                                        value={departureDateTo ? dayjs(departureDateTo) : null}
                                        onChange={(newValue) => {
                                            setDepartureDateTo(newValue);
                                            handleFilterChangeValue('departureDateTo', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        minDate={departureDateFrom ? dayjs(departureDateFrom) : undefined}  // No permite seleccionar una fecha menor a la de inicio
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />
                                </Box>

                                <Box sx={{display: 'flex', gap: 2, flexDirection: 'column', maxWidth: 300}}>
                                    <DatePicker
                                        label="Fecha de creación desde"
                                        format="YYYY-MM-DD"
                                        value={createdAtFrom ? dayjs(createdAtFrom) : null}
                                        onChange={(newValue) => {
                                            setCreatedAtFrom(newValue);
                                            handleFilterChangeValue('createdAtFrom', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />
                                    <DatePicker
                                        label="Fecha de creación hasta"
                                        format="YYYY-MM-DD"
                                        value={createdAtTo ? dayjs(createdAtTo) : null}
                                        onChange={(newValue) => {
                                            setCreatedAtTo(newValue);
                                            handleFilterChangeValue('createdAtTo', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        minDate={createdAtFrom ? dayjs(createdAtFrom) : undefined}  // No permite seleccionar una fecha menor a la de inicio
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />
                                </Box>
                            </LocalizationProvider>
                            <FormControl fullWidth>
                                <InputLabel id="to-use-same-issue-label">Para usar en la misma emisión</InputLabel>
                                <Select
                                    size='small'
                                    labelId="to-use-same-issue-select-label"
                                    id="o-use-same-issue-select-label"
                                    value={internalFilters.toUseInSameIssue}
                                    label="Para usar en la misma emisión"
                                    onChange={handleChangeToUseSameIssue}
                                >
                                    <MenuItem value={undefined} style={{minHeight: '24px'}}>
                                        <em></em>
                                    </MenuItem>
                                    <MenuItem value={'true'}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <ListItemText primary="Sí"/>
                                        </div>
                                    </MenuItem>
                                    <MenuItem value={'false'}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <ListItemText primary="No"/>
                                        </div>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                label="Vendedor contiene"
                                size='small'
                                value={internalFilters.sellerName}
                                onChange={handleFilterChange('sellerName')}
                                fullWidth
                            />

                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2}}>
                                <Button variant="contained" onClick={handleApplyFilter} sx={{mr: 2}}>
                                    Aplicar Filtros
                                </Button>
                                <Button variant="outlined" onClick={handleCancelFilters}>
                                    Cancelar
                                </Button>
                            </Box>
                        </Box>
                    </Popover>
                </Box>
            </Toolbar>
            <Typography variant="body2" sx={{mb: 1}}>
                Mostrando {items.length} resultados de {totalElements}
            </Typography>
            <TableContainer component="main" sx={{width: '100%'}}>
                <ScrollableTableContainer>
                <Table sx={{width: '100%'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                ID
                            </TableCell>
                            <TableCell sx={{width: '10%'}}>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'amount')}
                                    direction={order.find(o => o.field === 'amount')?.order || 'asc'}
                                    onClick={() => handleRequestSort('amount')}
                                >
                                    Monto
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'fee')}
                                    direction={order.find(o => o.field === 'fee')?.order || 'asc'}
                                    onClick={() => handleRequestSort('fee')}
                                >
                                    Cuotas
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'dealId')}
                                    direction={order.find(o => o.field === 'dealId')?.order || 'asc'}
                                    onClick={() => handleRequestSort('dealId')}
                                >
                                    Negociación
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'fileNumber')}
                                    direction={order.find(o => o.field === 'fileNumber')?.order || 'asc'}
                                    onClick={() => handleRequestSort('fileNumber')}
                                >
                                    File
                                </TableSortLabel>
                            </TableCell>

                            <TableCell sx={{width: '10%'}}>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'departureDate')}
                                    direction={order.find(o => o.field === 'departureDate')?.order || 'asc'}
                                    onClick={() => handleRequestSort('departureDate')}
                                >
                                    Salida
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'toUseInSameIssue')}
                                    direction={order.find(o => o.field === 'toUseInSameIssue')?.order || 'asc'}
                                    onClick={() => handleRequestSort('toUseInSameIssue')}
                                >
                                    Usar en la misma emisión
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'sellerName')}
                                    direction={order.find(o => o.field === 'sellerName')?.order || 'asc'}
                                    onClick={() => handleRequestSort('sellerName')}
                                >
                                    Vendedor
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'createdAt')}
                                    direction={order.find(o => o.field === 'createdAt')?.order || 'asc'}
                                    onClick={() => handleRequestSort('createdAt')}
                                >
                                    Fecha de creación
                                </TableSortLabel>
                            </TableCell>

                            <TableCell sx={{maxWidth:120}}>
                                Link de pago
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{item.id}</TableCell>
                                <TableCell>{item.amount} USD</TableCell>
                                <TableCell>{item.fee}</TableCell>
                                <TableCell> <a title={'Abrir en Bitrix'}
                                               href={`https://traveller.bitrix24.es/crm/deal/details/${item.dealId}/`}
                                               target="_blank"
                                               rel="noopener noreferrer"
                                >
                                    {item.dealId}
                                </a></TableCell>
                                <TableCell>{item.fileNumber}</TableCell>
                                <TableCell>{item.departureDate}</TableCell>
                                <TableCell>{item.toUseInSameIssue ? 'Si' : 'No'}</TableCell>
                                <TableCell>{item.sellerName}</TableCell>
                                <TableCell>{item.createdAt}</TableCell>
                                <TableCell>{item.paymentLink}</TableCell>
                            </TableRow>
                        ))}
                        {items.length == 0 && <EmptyTable colSpan={10} />}
                    </TableBody>
                </Table>
                </ScrollableTableContainer>
            </TableContainer>
            <TablePagination
                labelRowsPerPage={'Filas por página'}
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={totalElements}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
};

export default SalesOrderList;
