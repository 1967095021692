import React, {useCallback, useEffect, useState} from 'react';
import {ISort, ShowAlertState} from "../../../../core/types";
import {Alert, Backdrop, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {extractErrorMessage} from "../../../../core/helper";
import {useNavigate} from "react-router-dom";
import {IFilterSaleOrderBamboo, IListSaleOrderBamboo} from "../../types";
import SaleOrderBambooList, {
    defaultFilterSAleOrderBamboo
} from "../../components/SaleOrderBambooList/SalesOrderBambooList";
import {getSalesOrderBamboo, paymentLinkSend} from "../../services";
import {appRoutes} from "../../../../core/const";

const SaleOrderBambooListContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>(search);
    const [page, setPage] = useState<number>(0);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [order, setOrder] = useState<ISort[]>([{'field': 'createdAt', order: 'desc'}]);
    const [filters, setFilters] = useState<IFilterSaleOrderBamboo>(defaultFilterSAleOrderBamboo);
    const [items, setItems] = useState<IListSaleOrderBamboo[]>([])
    const navigate = useNavigate();

    const onAdd = () => navigate(`${appRoutes.bambooSaleOrder.list}/create`);


    const loadSaleOrderBamboo = useCallback(async () => {
        try {
            setLoading(true);
            const paginatedResult = (await getSalesOrderBamboo(page + 1, pageSize, order, debouncedSearchTerm, filters)).data;
            setTotalElements(paginatedResult.total);
            setItems(paginatedResult.items);
            setLoading(false);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado de órdenes de ventas de Bamboo')
            });
            setLoading(false);
        }
    }, [page, pageSize, order, debouncedSearchTerm, filters]);

    const onCheckPaymentLinkSend = async (id: string) => {
        try {
            setLoading(true);
            await paymentLinkSend(id)
            setAlert({
                show: true,
                severity: 'success',
                message: 'Orden marcada con link de pago enviado realizado satisfactoriamente'
            });
            setTimeout(()=>{
                loadSaleOrderBamboo();
            },1500);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al marcar como link de pago enviado')
            });
            setLoading(false);
        }
    }

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(search);
        }, 500); // Retraso de 500 ms

        // Limpia el timeout si el efecto se vuelve a ejecutar antes de que el timeout finalice
        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    useEffect(() => {
        loadSaleOrderBamboo();
    }, [loadSaleOrderBamboo]);

    return (<><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            <SaleOrderBambooList title={'Órdenes de venta de Bamboo'}
                                 items={items}
                                 onAdd={onAdd}
                                 onCheckPaymentLinkSend={onCheckPaymentLinkSend}
                                 filters={filters}
                                 setFilters={setFilters}
                                 order={order}
                                 setOrder={setOrder}
                                 search={search}
                                 setSearch={setSearch}
                                 page={page}
                                 setPage={setPage}
                                 pageSize={pageSize}
                                 setPageSize={setPageSize}
                                 totalElements={totalElements}
            />
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </>
    );
}

export default SaleOrderBambooListContainer;
