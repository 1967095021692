import {ICreateAirline, IDetailAirline, IFilterAirline, IUpdateAirline} from "./types";
import {ApiService} from "../../core/services";
import {AxiosResponse} from "axios";
import {IPaginatedResult, ISort} from "../../core/types";
import {cleanFilters, formatSortingFields} from "../../core/helper";

const endpoints = {
    list: 'api/airlines',
    listAll: 'api/airlines/list/all',
    get: 'api/airlines/:code',
    create: 'api/airlines',
    update: 'api/airlines/:code',
    delete: 'api/airlines/:code'
}

export const getAirlines = (page = 1, pageSize = 10, sortingFields: ISort[], search:string, filters?: IFilterAirline): Promise<AxiosResponse<IPaginatedResult<IDetailAirline>>> => {
    const sorts = formatSortingFields(sortingFields);
    return ApiService.get(endpoints.list, {
        params: {
            page,
            pageSize,
            ...(sorts.length > 0 && {sort: sorts}), ...(filters && cleanFilters(filters)), ...(search && search!=='' && {search:search})
        }
    });
};
export const getAllAirlines = (): Promise<AxiosResponse<IDetailAirline[]>> => ApiService.get(endpoints.listAll);
export const getAirline = (code:string): Promise<AxiosResponse<IDetailAirline>> => ApiService.get(endpoints.get.replace(':code',code));
export const createAirline = (data: ICreateAirline): Promise<AxiosResponse<void>> => ApiService.post(endpoints.create, data);
export const updateAirline = (code: string, data: IUpdateAirline): Promise<AxiosResponse<void>> => ApiService.put(endpoints.update.replace(':code', code), data);
export const deleteAirline = (code: string): Promise<AxiosResponse<void>> => ApiService.delete(endpoints.delete.replace(':code', code));
