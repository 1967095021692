import {ApiService} from "../../core/services";
import {AxiosResponse} from "axios";
import {IPaginatedResult, ISort} from "../../core/types";
import {cleanFilters, formatSortingFields} from "../../core/helper";
import {ICountry, IFilterCountry} from "./types";

const endpoints = {
    list: 'api/countries',
    listAll: 'api/countries/list/all',
    get: 'api/countries/:code',
    create: 'api/countries',
    update: 'api/countries/:code',
    delete: 'api/countries/:code'
}

export const getAllCountries = (): Promise<AxiosResponse<ICountry[]>> => {
    return ApiService.get(endpoints.listAll);
};
export const getCountries = (page = 1, pageSize = 10, sortingFields: ISort[], search:string, filters?: IFilterCountry): Promise<AxiosResponse<IPaginatedResult<ICountry>>> => {
    const sorts = formatSortingFields(sortingFields);
    return ApiService.get(endpoints.list, {
        params: {
            page,
            pageSize,
            ...(sorts.length > 0 && {sort: sorts}), ...(filters && cleanFilters(filters)), ...(search && search!=='' && {search:search})
        }
    });
};
export const getCountry = (code:string): Promise<AxiosResponse<ICountry>> => ApiService.get(endpoints.get.replace(':code',code));
export const createCountry = (data: ICountry): Promise<AxiosResponse<void>> => ApiService.post(endpoints.create, data);
export const updateCountry = (code: string, data: ICountry): Promise<AxiosResponse<void>> => ApiService.put(endpoints.update.replace(':code', code), data);
export const deleteCountry = (code: string): Promise<AxiosResponse<void>> => ApiService.delete(endpoints.delete.replace(':code', code));
