// src/components/StatusBadge.tsx

import React from 'react';
import { Chip } from '@mui/material';
import {CardPortfolioStatus} from "../../types";

interface StatusBadgeProps {
    status: CardPortfolioStatus;
}

interface StatusDetail {
    label: string;
    color: string;
}

export const statusDetails: Record<CardPortfolioStatus, StatusDetail> = {
    [CardPortfolioStatus.TOTAL_AUTHORIZED]: {
        label: 'Autorizado Total',
        color: '#4caf50', // Verde
    },
    [CardPortfolioStatus.PARTIAL_AUTHORIZED]: {
        label: 'Autorizado Parcial ',
        color: '#ff9800', // Naranja
    },
    [CardPortfolioStatus.PARTIAL_AUTHORIZED_WITH_REJECT]: {
        label: 'Autorizado Parcial con Rechazo',
        color: '#ff5722', // Rojo Anaranjado
    },
    [CardPortfolioStatus.PARTIAL_AUTHORIZED_WITH_PRE_AUTHORIZATION]: {
        label: 'Autorizado Parcial con Preautorización',
        color: '#d5ab01', // Azul
    },[CardPortfolioStatus.PARTIAL_AUTHORIZED_WITH_PRE_AUTHORIZATION_WITH_REJECT]: {
        label: 'Autorizado Parcial con Preautorización con rechazo',
        color: '#d33d08', //  Rojo Anaranjado
    },
    [CardPortfolioStatus.PRE_AUTHORIZED]: {
        label: 'Preautorizado',
        color: '#f4bc03', // Azul Claro
    },
    [CardPortfolioStatus.PRE_AUTHORIZED_WITH_REJECT]: {
        label: 'Preautorizado con Rechazo',
        color: '#e57373', // Rojo claro
    },
    [CardPortfolioStatus.REJECTED]: {
        label: 'Rechazado',
        color: '#d32f2f', // Rojo intenso
    },
    [CardPortfolioStatus.OPERATE]: {
        label: 'Operar',
        color: '#607d8b', // Azul Grisáceo
    },
    [CardPortfolioStatus.FINALIZED]: {
        label: 'Finalizado',
        color: '#795548', // Marrón
    },
};


const CardPortfolioStatusBadge: React.FC<StatusBadgeProps> = ({ status }) => {
    const detail = statusDetails[status];

    return (
        <Chip
            label={detail.label}
            sx={{
                backgroundColor: detail.color,
                color: '#fff',
                borderRadius: '16px',
                fontWeight: 'bold',
            }}
        />
    );
};

export default CardPortfolioStatusBadge;
