import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import IconButton from '@mui/material/IconButton';
import { Alert, Backdrop, Button, CircularProgress, InputAdornment, Paper, Snackbar, styled, TextField, useTheme, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { extractErrorMessage } from "../../../../core/helper";
import { ShowAlertState } from "../../../../core/types";
import { IChangePasswordToUser, IListUser } from "../../types";
import { changePasswordToUser } from "../../services";

type ChangePasswordModalProps = {
    open: boolean;
    handleClose: () => void;
    userData: IListUser;
};

const StyledPaper = styled(Paper)(({ theme }) => ({
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
}));

const DivTitle = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
}));

const ChangePasswordToUserModal: React.FC<ChangePasswordModalProps> = ({ open, handleClose, userData }) => {
    const theme = useTheme();
    const { handleSubmit, control, formState: { errors, isValid }, getValues } = useForm<IChangePasswordToUser>({ mode: 'all' });
    const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);

    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleClickShowPassword = (field: string) => {
        switch (field) {
            case 'newPassword':
                setShowNewPassword(!showNewPassword);
                break;
            case 'confirmPassword':
                setShowConfirmPassword(!showConfirmPassword);
                break;
        }
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const onSubmit: SubmitHandler<IChangePasswordToUser> = async (data) => {
        try {
            setLoading(true);
            await changePasswordToUser(userData.id, data);
            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Contraseña cambiada satisfactoriamente'
            });

            setTimeout(() => handleClose(), 2000)

        } catch (error: any) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cambiar la contraseña')
            });
            setLoading(false);
        }

    };

    const handleContentClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    };

    return (
        <>
            <Backdrop sx={{
                zIndex: theme.zIndex.modal + 1,
                color: '#fff',
            }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Modal open={open}>
                <StyledPaper onClick={handleContentClick}>
                    <DivTitle>
                        <Typography variant="h5">Cambiar contraseña al usuario {userData.name}</Typography>
                        <CloseButton aria-label="close" onClick={handleClose}>
                            <CloseIcon />
                        </CloseButton>
                    </DivTitle>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            name="newPassword"
                            control={control}
                            rules={{
                                required: true,
                                minLength: 5
                            }}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Contraseña nueva"
                                    type={showNewPassword ? 'text' : 'password'}
                                    size={"small"}
                                    error={Boolean(errors.newPassword)}
                                    helperText={
                                        errors.newPassword
                                            ? errors.newPassword.type === 'required'
                                                ? 'La contraseña nueva es requerida.'
                                                : errors.newPassword.type === 'minLength'
                                                    ? 'La contraseña nueva debe tener al menos 5 caracteres.'
                                                    : ''
                                            : ''}
                                    margin="normal"
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => handleClickShowPassword('newPassword')}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="confirmPassword"
                            control={control}
                            rules={{ required: true, validate: (value) => value === getValues().newPassword }}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    size={"small"}
                                    label="Confirmar contraseña"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    error={Boolean(errors.confirmPassword)}
                                    helperText={errors.confirmPassword && 'La contraseña nueva y la confirmación no coinciden.'}
                                    margin="normal"
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => handleClickShowPassword('confirmPassword')}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <Button
                            type="submit"
                            color="primary"
                            disabled={!isValid}
                            variant="contained"
                            fullWidth
                        >
                            Cambiar contraseña
                        </Button>
                    </form>
                </StyledPaper>
            </Modal>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={alert.show} autoHideDuration={1500}
                      onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </>
    );
};

export default ChangePasswordToUserModal;
