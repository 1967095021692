import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Typography,
    useTheme
} from '@mui/material';
import {IDetailICardPortfolio} from "../../types";
import CardSummaryDetail from "../CardSummaryDetail/CardSummaryDetail";
import {ShowAlertState} from "../../../../core/types";
import CardPortfolioAuthorizationsList
    from "../../modules/card-portafolio-authorizations/components/CardPortfolioAuthorizationsList/CardPortfolioAuthorizationsList";
import {enterInCardPortfolioDetail, exitInCardPortfolioDetail} from "../../services";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import {CookieService} from "../../../auth/services/CookieService";
import 'dayjs/locale/es';
import relativeTime from "dayjs/plugin/relativeTime";
import CardPortfolioStatusBadge from "../CardPortfolioStatusBadge/CardPortfolioStatusBadge";
import CardPortfolioRejectionsList
    from "../../modules/card-portfolio-rejections/components/CardPortfolioRejectionsList/CardPortfolioRejectionsList";
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
// Configurar dayjs para usar el plugin relativeTime
dayjs.extend(relativeTime);
dayjs.locale('es');

interface CardPortfolioDetailProps {
    item: IDetailICardPortfolio;
    onLoading: (loading: boolean) => void;
    loading: boolean;
    setAlert: (alert: ShowAlertState) => void;
    onChangeAuthorizationsAmount: () => void;
    onChangeRejections: () => void;
    onAcceptFinalized:()=>void;
}

const CardPortfolioDetail: React.FC<CardPortfolioDetailProps> = ({
                                                                     item,
                                                                     onLoading,
                                                                     setAlert,
                                                                     loading,
                                                                     onChangeAuthorizationsAmount,
                                                                     onChangeRejections,
                                                                     onAcceptFinalized
                                                                 }) => {
    const [showConfirmFinalized, setShowConfirmFinalized] = useState(false);
    const theme = useTheme();

    const showAlertSomeUserIsUsedCard = (name: string, connectedSinceTime: string) => {
        const connectedSince = dayjs(connectedSinceTime).fromNow();
        Swal.fire({
            text: `La tarjeta está siendo usada por ${name} (${connectedSince}). Espere a que el usuario termine de usar la tarjeta para realizar cualquier otra operación sobre esta.`,
            icon: 'warning',
            showCancelButton: false,
            showCloseButton: true,
            showConfirmButton: false,
            allowOutsideClick: false,  // Evitar que se cierre al hacer clic fuera del modal
            allowEscapeKey: false,
        });
    };

    useEffect(() => {
        if (item.views.length > 0) {
            const myUserId = CookieService.getUserExtraData().userId;
            const viewByOtherUser = item.views.find(view => view.userId != myUserId && myUserId > 0);
            if (viewByOtherUser) {
                showAlertSomeUserIsUsedCard(viewByOtherUser.name, viewByOtherUser.connectedSince);
            }
        }
    }, []);


    useEffect(() => {
        // Función para manejar la entrada
        const handleEnter = async () => {
            try {
                await enterInCardPortfolioDetail(item.id);
                console.log('Usuario ha entrado en la vista de detalle.');
            } catch (error) {
                console.error('Error al entrar en la vista de detalle:', error);
            }
        };

        // Función para manejar la salida
        const handleExit = async () => {
            try {
                await exitInCardPortfolioDetail(item.id);
                console.log('Usuario ha salido de la vista de detalle.');
            } catch (error) {
                console.error('Error al salir de la vista de detalle:', error);
            }
        };

        // Llamar a la función de entrada cuando el componente se monta
        handleEnter();

        // Retornar la función de limpieza que se ejecuta cuando el componente se desmonta
        return () => {
            handleExit();
        };
    }, [item]);

    const onAcceptFinalizedInternal = ()=>{
        setShowConfirmFinalized(false);
        onAcceptFinalized();
    }

    return (
        <Box sx={{padding: 4}}>
            <Typography variant="h4" gutterBottom>
                Detalles de la tarjeta en cartera
            </Typography>
            <Card>
                <CardContent sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography variant="h5">
                            Información general
                        </Typography>

                    </Box>
                    <Grid container spacing={2} alignItems="flex-start">
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle1" color="textSecondary">
                                ID
                            </Typography>
                            <Typography variant="h6">{item.id}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle1" color="textSecondary">
                                ID de orden de venta
                            </Typography>
                            <Typography variant="h6">{item.orderId}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle1" color="textSecondary">
                                Monto
                            </Typography>
                            <Typography variant="h6">{item.amount} USD</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle1" color="textSecondary">
                                Cuotas
                            </Typography>
                            <Typography variant="h6">{item.fee}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle1" color="textSecondary">
                                Negociación
                            </Typography>
                            <Typography variant="h6">
                                <a title="Abrir en Bitrix"
                                   href={`https://traveller.bitrix24.es/crm/deal/details/${item.dealId}/`}
                                   target="_blank" rel="noopener noreferrer">
                                    {item.dealId}
                                </a>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle1" color="textSecondary">
                                File
                            </Typography>
                            <Typography variant="h6">{item.fileNumber}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle1" color="textSecondary">
                                Fecha de salida
                            </Typography>
                            <Typography variant="h6">{item.departureDate}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle1" color="textSecondary">
                                Usar en la misma emisión
                            </Typography>
                            <Typography variant="h6">{item.toUseInSameIssue ? 'Sí' : 'No'}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle1" color="textSecondary">
                                Titular
                            </Typography>
                            <Typography variant="h6">{item.cardHolder}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle1" color="textSecondary">
                                Sello de la tarjeta
                            </Typography>
                            <Typography variant="h6">{item.cardStamp}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle1" color="textSecondary">
                                Últimos 4 dígitos
                            </Typography>
                            <Typography variant="h6">{item.lastFourCardDigit}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle1" color="textSecondary">
                                Vendedor
                            </Typography>
                            <Typography variant="h6">{item.sellerName}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle1" color="textSecondary">
                                Fecha de creación
                            </Typography>
                            <Typography variant="h6">{item.createdAt}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle1" color="textSecondary">
                               Estado
                            </Typography>
                            <CardPortfolioStatusBadge status={item.status}/>
                        </Grid>
                        {item.allowFinalized && <Grid item xs={12} sm={4}>
                            <Button
                                variant="contained"
                                title={'Poner saldo a 0 y pasar a finalizada'}
                                startIcon={<MoneyOffIcon/>}
                                onClick={()=>setShowConfirmFinalized(true)}
                            >
                                Poner saldo a 0
                            </Button>
                        </Grid>}
                    </Grid>
                </CardContent>
            </Card>

            {/* Sección de gráficos circulares */}
            <Box sx={{marginTop: 4}}>
                <Typography variant="h5" gutterBottom>
                    Resumen Financiero
                </Typography>
                <Grid container spacing={4} justifyContent="flex-start">
                    <Grid item>
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <CircularProgress
                                variant="determinate"
                                value={Number(item.amount) >0?100:0}
                                size={100}
                                thickness={5}

                            />
                            <Typography variant="subtitle1" sx={{marginTop: 1}}>
                                Monto ({item.amount} USD)
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <CircularProgress
                                variant="determinate"
                                value={ Number(item.amount) ==0?100:Number(item.authorized)/Number(item.amount) *100}
                                sx={{color: Number(item.authorized) < Number(item.amount) ? theme.palette.warning.light : theme.palette.success.light}}
                                size={100}
                                thickness={5}
                            />
                            <Typography variant="subtitle1" sx={{marginTop: 1}}>
                                Total autorizado ({item.authorized} USD)
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <CircularProgress
                                variant="determinate"
                                value={ Number(item.amount) ==0?100:Number(item.preauthorized)/Number(item.amount) *100}
                                size={100}
                                sx={{color: theme.palette.warning.light}}
                                thickness={5}
                            />
                            <Typography variant="subtitle1" sx={{marginTop: 1}}>
                                Total preautorizado ({item.preauthorized} USD)
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <CardSummaryDetail id={item.id} setLoading={onLoading}  loading={loading}/>
            <CardPortfolioAuthorizationsList cardPortfolioId={item.id} onLoading={onLoading} setAlert={setAlert}
                                             loading={loading}
                                             onChangeAuthorizationsAmount={onChangeAuthorizationsAmount}
                                             allowCreateAuthorizations={item.allowCreateAuthorizations}
                                             allowCreatePosAuthorization={item.allowCreatePosAuthorization}
                                             totalSale={item.amount}
                                             allowEditAuthorizations={item.allowEditAuthorizations}
            />
            <CardPortfolioRejectionsList cardPortfolioId={item.id}
                                         onLoading={onLoading}
                                         setAlert={setAlert}
                                         loading={loading}
                                         allowCreateOrUpdateRejections={item.allowCreateOrUpdateRejections}
                                         onChangeRejections={onChangeRejections}
            />

            {showConfirmFinalized && <Dialog open={showConfirmFinalized} onClose={()=>setShowConfirmFinalized(false)}>
                <DialogTitle> Poner saldo a 0</DialogTitle>
                <DialogContent>

                    <DialogContentText>
                        ¿Estás seguro de que deseas poner el saldo a 0 y pasar a estado finalizado esta tarjeta?. Esta acción es irreversible
                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setShowConfirmFinalized(false)}>Cancelar</Button>
                    <Button onClick={onAcceptFinalizedInternal} color="primary">Confirmar</Button>
                </DialogActions>
            </Dialog>}
        </Box>
    );
};

export default CardPortfolioDetail;
