import React, {useState} from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography,
} from '@mui/material';
import {Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon} from '@mui/icons-material';
import {IDetailInsuranceServiceTariff} from "../../types";

interface InsuranceServiceTariffListProps {
    title: string;
    items: IDetailInsuranceServiceTariff[];
    onAdd: () => void;
    onEdit: (item: IDetailInsuranceServiceTariff) => void;
    onDelete: (item: IDetailInsuranceServiceTariff) => void;
}

const InsuranceServiceTariffList: React.FC<InsuranceServiceTariffListProps> = ({
                                                 title,
                                                 items,
                                                 onAdd,
                                                 onEdit,
                                                 onDelete
                                             }) => {

    const [open, setOpen] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<null | IDetailInsuranceServiceTariff>(null);


    const handleCloseDialog = () => {
        setOpen(false);
        setSelectedItem(null);
    };


    const handleDelete = () => {
        if (selectedItem) {
            onDelete(selectedItem);
            handleCloseDialog();
        }
    };

    const handleOpenDialog = (item: IDetailInsuranceServiceTariff | null = null) => {
        setSelectedItem(item);
        setOpen(true);
    };


    return (
        <Box sx={{width: '100%', padding: 3}}>
            <Typography variant="h4" component="div" sx={{mb: 2}}>
                {title}
            </Typography>
            <Toolbar sx={{display: 'flex', justifyContent: 'space-between', mb: 2}} style={{paddingLeft: 0}}>
                <Button
                    variant="contained"
                    startIcon={<AddIcon/>}
                    onClick={() => onAdd()}
                >
                    Nueva línea de tarifa
                </Button>
            </Toolbar>
            <TableContainer component="main" sx={{width: '100%'}}>
                <Table sx={{width: '100%'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Edad mínima
                            </TableCell>
                            <TableCell>
                                Edad máxima
                            </TableCell>
                            <TableCell>
                              Precio por día por persona
                            </TableCell>

                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{item.minAge}</TableCell>
                                <TableCell>{item.maxAge}</TableCell>
                                <TableCell>{item.priceByDayByPerson}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => onEdit(item)}>
                                        <EditIcon/>
                                    </IconButton>
                                    <IconButton onClick={() => handleOpenDialog(item)}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={open} onClose={handleCloseDialog}>
                <DialogTitle>Eliminar línea de tarifa</DialogTitle>
                <DialogContent>

                    <DialogContentText>
                        ¿Estás seguro de que deseas eliminar esta línea de tarifa?
                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancelar</Button>
                    <Button onClick={handleDelete} color="primary">Eliminar</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default InsuranceServiceTariffList;
