import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';

interface TagPreviewProps {
    label: string;
    bgColor: string;
    textColor: string;
}

const TagPreview: React.FC<TagPreviewProps> = ({ label, bgColor, textColor }) => {
    return (
        <Card
            sx={{
                position: 'relative',
                width: 300,
                height: 200,
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)', // Personalización de la sombra
                border: '1px solid rgba(0, 0, 0, 0.1)', // Opcional: Borde sutil para más definición
                borderRadius: 2, // Bordes ligeramente redondeados para una apariencia más suave
                overflow: 'hidden', // Asegura que los elementos dentro de la tarjeta no se salgan de los bordes
            }}
            elevation={6} // Propiedad de Material UI para añadir sombra (depth)
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    backgroundColor: bgColor,
                    color: textColor,
                    padding: '2px 12px',
                    borderRadius: '0 0 8px 0',
                    fontWeight: 'bold',
                }}
            >
                {label}
            </Box>
            <CardContent
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%', // Ensures the CardContent fills the Card's height
                    padding: 0, // Removes default padding for better centering
                }}
            >
                <Typography variant="body2" textAlign="center">
                    Este es el contenido de la tarjeta.
                </Typography>
            </CardContent>
        </Card>
    );
};

export default TagPreview;
