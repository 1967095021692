import React from 'react';
import {Box, Button, Card, CardContent, TextField} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';


export interface ICustomFilterFormValue {
    title: string;
    backgroundColor: string;
    textColor: string;
}

export interface CustomFilterFormProps {
    handleSubmitForm: (value: ICustomFilterFormValue) => void;
    handleClose: () => void;
}

export const CustomFilterForm: React.FC<CustomFilterFormProps> = ({handleSubmitForm,handleClose}) => {

    const methods = useForm<ICustomFilterFormValue>({
        mode: 'all',
        defaultValues: {
            title: '',
            backgroundColor: '#e55010',
            textColor: '#ffffff'
        }
    });

    const {
        control,
        formState: {errors, isValid},
        getValues,
    } = methods;


    return (
        <Card sx={{maxWidth: 800, width: '100%'}}>
            <CardContent>
                <Box display="flex" flexDirection="row" justifyContent="space-between" gap={4}>
                    <Box display="flex" flexDirection="column" flex={1} gap={2}>
                        <Box>
                            <Controller
                                name="title"
                                control={control}
                                rules={{
                                    required: {value: true, message: 'Este campo es requerido'},
                                    maxLength: {
                                        value: 255,
                                        message: 'El campo excede el largo máximo permitido de: 255 caracteres'
                                    }
                                }}
                                render={({field}) => (
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="Título del filtro"
                                        size="small"
                                        type="text"
                                        helperText={errors.title ? errors.title.message : ''}
                                        error={!!errors.title}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>

                        <Box>
                            <Controller
                                name="backgroundColor"
                                control={control}
                                rules={{
                                    required: {value: true, message: 'Este campo es requerido'},
                                }}
                                render={({field}) => (
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="Color del fondo"
                                        size="small"
                                        type="color"
                                        helperText={errors.backgroundColor ? errors.backgroundColor.message : ''}
                                        error={!!errors.backgroundColor}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>

                        <Box>
                            <Controller
                                name="textColor"
                                control={control}
                                rules={{
                                    required: {value: true, message: 'Este campo es requerido'},
                                }}
                                render={({field}) => (
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="Color del texto"
                                        size="small"
                                        type="color"
                                        helperText={errors.textColor ? errors.textColor.message : ''}
                                        error={!!errors.textColor}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>

                        <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleClose}
                            >
                                Cerrar
                            </Button>
                            <Button
                                variant="contained"
                                disabled={!isValid}
                                color="primary"
                                onClick={() => handleSubmitForm(getValues())}
                            >
                                Guardar
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};
