import React, {useState} from 'react';
import {AppBar, Avatar, Box, Divider, IconButton, Menu, MenuItem, Toolbar, Typography} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logocolor from "../../../assets/images/logo_new.svg";
import {CookieService} from "../../../modules/auth/services/CookieService";
import ChangePasswordModal from "../../../modules/auth/components/ChangePasswordModal/ChangePasswordModal";

interface AppTopBarProps {
    handleDrawerToggle: () => void;
}

const AppTopBar: React.FC<AppTopBarProps> = ({ handleDrawerToggle }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        // Lógica para cerrar sesión aquí
        handleMenuClose(); // Cierra el menú al hacer clic en cerrar sesión
        CookieService.clearToken();
        CookieService.clearUserName();
        CookieService.clearUserExtraData();
        window.location.href ='/';
    };

    const handleChangePassword = () => {
        setAnchorEl(null);
        setOpenChangePasswordModal(true);
    }

    const userName = CookieService.getUserName()||'Usuario';
    const firstLetterOfUser = userName.charAt(0); // Extraer la inicial

    return (
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar>
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle} sx={{mr: 2}}>
                    <MenuIcon/>
                </IconButton>
                <img alt="Traveller viajes" style={{cursor: 'pointer',width: '150px'}} src={logocolor} ></img>

                <Typography variant="h5" noWrap component="div" sx={{flexGrow: 1, fontWeight:'bold', textAlign:'center'}}>
                    Sys-Admin
                </Typography>

                <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
                    <Typography variant="body1" sx={{ mr: 2 }}>
                        Bienvenido, {userName}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        aria-label="account"
                        onClick={handleMenuOpen}
                        sx={{ ml: 2, borderRadius: '50%', bgcolor: 'transparent' }}
                    >
                        <Avatar>{firstLetterOfUser}</Avatar>
                    </IconButton>
                </Box>

                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    PaperProps={{
                        sx: {
                            mt: 1.5,
                            minWidth: 200,
                        },
                    }}
                >
                    <MenuItem onClick={handleChangePassword}>Cambiar contraseña</MenuItem>
                    <Divider />
                    <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
                </Menu>
            </Toolbar>
            {openChangePasswordModal && <ChangePasswordModal open={openChangePasswordModal} handleClose={() => setOpenChangePasswordModal(false)} />}
        </AppBar>
    );
}

export default AppTopBar;
