import React, {useState} from 'react';
import {Alert, Backdrop, Box, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {IBitrixDealInfoToOrderBamboo, ICreateSaleOrderBamboo} from "../../types";
import {createSaleOrderBamboo, getDealInfoToSaleOrderBamboo} from "../../services";
import {ShowAlertState} from "../../../../core/types";
import {extractErrorMessage} from "../../../../core/helper";
import {
    ICreateSaleOrderFormValue,
    SaleOrderBambooCreateForm
} from "../../components/SaleOrderBambooCreateForm/SaleOrderBambooCreateForm";
import {appRoutes} from "../../../../core/const";
import {useNavigate} from "react-router-dom";

const SaleOrderBambooCreateContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};

    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [dealIdDetail, setDealIdDetail] = useState<IBitrixDealInfoToOrderBamboo | null>(null);
    const navigate = useNavigate();
    const handleDealIdDetail = async (dealId: string) => {
        try {

            setLoading(true);
            const result = (await getDealInfoToSaleOrderBamboo(dealId)).data;
            setDealIdDetail(result);
            setLoading(false);

        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el detalle de la negociacón')
            });
            setLoading(false);
            setDealIdDetail(null);
        }
    }

    const handleCreateSaleOrder = async (value: ICreateSaleOrderFormValue) => {

        try {
            setLoading(true);

            const valueToSend = {
                ...value,
                amount: Number(value.amount),
            } as ICreateSaleOrderBamboo
            await createSaleOrderBamboo(valueToSend);

            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Orden de venta de Bamboo creada satisfactoriamente'
            });
            setTimeout(() => navigate(appRoutes.bambooSaleOrder.list), 1500);

        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al  crear la orden de venta de Bamboo')
            });
            setLoading(false);

        }

    }


    return (<Box sx={{width: '80%', padding: 3}}><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>

            <SaleOrderBambooCreateForm handleSubmitForm={handleCreateSaleOrder}
                                       loading={loading}
                                       handleCheckDeal={handleDealIdDetail}
                                       dealInfo={dealIdDetail}
            />


            <Snackbar open={alert.show} autoHideDuration={3000} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </Box>
    );
}

export default SaleOrderBambooCreateContainer;
