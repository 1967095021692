import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import Cards, {CallbackArgument, Focused} from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    useMediaQuery,
    useTheme
} from "@mui/material";
import InputMask from 'react-input-mask';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import creditCardType from "credit-card-type";
import {CreditCardType} from "credit-card-type/src/types";
import {DocumentTypeEnum, documentTypeSToViewList, IOrderInfoToPay} from "../../types";
import {validate_ci} from "../../helpers";
import Link from "@mui/material/Link";
export interface PaymentLinkFormData {
    cardNumber: string;
    cardHolder: string;
    documentType: string;
    expiry: string;
    cvc: string;
    cardStamp: string;
    cedula: string;
    dni: string;
    consent: boolean;
}

export interface PaymentLinkFormProps {
    orderInfo:IOrderInfoToPay;
    handleSubmitForm: (value: PaymentLinkFormData) => void;
    loading: boolean;
}

export default function PaymentLinkForm({orderInfo,handleSubmitForm,loading}:PaymentLinkFormProps) {
    const [paymentData, setPaymentData] = useState<PaymentLinkFormData>({
        cardNumber: "",
        cardHolder: "",
        documentType: '',
        expiry: "",
        cvc: "",
        cardStamp: "",
        cedula: '',
        dni: '',
        consent:false
    });
    const [cardFocus, setCardFocus] = useState<Focused | undefined>(undefined);
    const [isValidCard, setIsValidCard] = useState<boolean>(false);
    const [cardMask, setCardMask] = useState<string>('9999 9999 9999 9999');
    const [cvcMask, setCVCMask] = useState<string>('999');
    const [cvcName, setCVCName] = useState<string>('CVC');
    const {
        handleSubmit,
        control,
        setValue,
        trigger,
        watch,
        formState: {errors, isValid}
    } = useForm<PaymentLinkFormData>({
        mode: 'all', // Esto actualiza la validez del formulario dinámicamente
    });
    const cedulaMask = '9.999.999-9';
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const onSubmit = (data: PaymentLinkFormData) => {
        handleSubmitForm(data);
    };
    const setValueToCardMaskAndCVVBase = (lengthCCV: number, nameCCV: string) => {
        setCVCMask(Array.from({length: lengthCCV}, () => '9').join(''));
        setCVCName(nameCCV);
    }

    const setValueToCardMaskAndCVV = (x: CreditCardType) => {
        setValueToCardMaskAndCVVBase(x.code.size, x.code.name)
    }


    const handleCheckIssuer = (issuer: string) => {
        switch (issuer) {
            case 'visa':
                const creditCardTypeInfoVisa = creditCardType.getTypeInfo(creditCardType.types.VISA);
                setValueToCardMaskAndCVV(creditCardTypeInfoVisa);
                break;
            case 'mastercard':
                const creditCardTypeInfoMaster = creditCardType.getTypeInfo(creditCardType.types.MASTERCARD);
                setValueToCardMaskAndCVV(creditCardTypeInfoMaster);
                break;
            case 'american-express':
                const creditCardTypeInfoAE = creditCardType.getTypeInfo(creditCardType.types.AMERICAN_EXPRESS);
                setValueToCardMaskAndCVV(creditCardTypeInfoAE);
                break;
            case 'diners-club':
                const creditCardTypeInfoDC = creditCardType.getTypeInfo(creditCardType.types.DINERS_CLUB);
                setValueToCardMaskAndCVV(creditCardTypeInfoDC);
                break;
            case 'discover':
                const creditCardTypeInfoDIS = creditCardType.getTypeInfo(creditCardType.types.DISCOVER);
                setValueToCardMaskAndCVV(creditCardTypeInfoDIS);
                break;
            case 'jcb':
                const creditCardTypeInfoJBC = creditCardType.getTypeInfo(creditCardType.types.JCB);
                setValueToCardMaskAndCVV(creditCardTypeInfoJBC);
                break;
            case 'unionpay':
                const creditCardTypeInfoUP = creditCardType.getTypeInfo(creditCardType.types.UNIONPAY);
                setValueToCardMaskAndCVV(creditCardTypeInfoUP);
                break;
            case 'maestro':
                const creditCardTypeInfoM = creditCardType.getTypeInfo(creditCardType.types.MAESTRO);
                setValueToCardMaskAndCVV(creditCardTypeInfoM);
                break;
            case 'mir':
                const creditCardTypeInfoMIR = creditCardType.getTypeInfo(creditCardType.types.MIR);
                setValueToCardMaskAndCVV(creditCardTypeInfoMIR);
                break;
            case 'elo':
                const creditCardTypeInfoELO = creditCardType.getTypeInfo(creditCardType.types.ELO);
                setValueToCardMaskAndCVV(creditCardTypeInfoELO);
                break;
            case 'hiper':
                const creditCardTypeInfoHIPER = creditCardType.getTypeInfo(creditCardType.types.HIPER);
                setValueToCardMaskAndCVV(creditCardTypeInfoHIPER);
                break;
            case 'hipercard':
                const creditCardTypeInfoHIPERCard = creditCardType.getTypeInfo(creditCardType.types.HIPERCARD);
                setValueToCardMaskAndCVV(creditCardTypeInfoHIPERCard);
                break;
            default:
                setValueToCardMaskAndCVVBase(3, 'CVC');

        }
    }

    const handleNumberInput = (event: React.ChangeEvent<HTMLInputElement>, onChange: (...event: any[]) => void) => {
        const {target: {value}} = event;
        const cardNumberClean = value.replace(/\s/g, '').replaceAll('_', '');
        onChange(cardNumberClean);
        setPaymentData((prevState) => ({...prevState, cardNumber: cardNumberClean}));
    };

    const handleCallback = (type: CallbackArgument, isValid: boolean) => {

        if (type.issuer !== 'amex' && type.issuer !== 'american-express') {
            setCardMask('9999 9999 9999 9999');
        } else {
            setCardMask('9999 999999 99999');
        }
        handleCheckIssuer(type.issuer);
        setValue('cardStamp',type.issuer);
        setIsValidCard(isValid);
    };

    const handleExpiryInput = (event: React.ChangeEvent<HTMLInputElement>, onChange: (...event: any[]) => void) => {
        const {target: {value}} = event;
        const expiryClean = value.replaceAll('_', '');
        onChange(expiryClean);
        setPaymentData({...paymentData, expiry: expiryClean});
    };

    const handleDocumentCedulaInput = (event: React.ChangeEvent<HTMLInputElement>, onChange: (...event: any[]) => void) => {
        const {target: {value}} = event;
        const cedulaClean = value.replaceAll('.', '').replaceAll('-', '').replaceAll('_', '');
        onChange(cedulaClean);
        setPaymentData({...paymentData, cedula: cedulaClean});
    };

    const handleCVCInput = (event: React.ChangeEvent<HTMLInputElement>, onChange: (...event: any[]) => void) => {
        const {target: {value}} = event;
        const cvcClean = value.replaceAll('_', '');
        onChange(cvcClean);
        setPaymentData({...paymentData, cvc: cvcClean});
    };

    useEffect(() => {
        // Cuando isValidCard cambia, forzamos la validación del campo 'cardNumber'
        trigger("cardNumber");
    }, [isValidCard, trigger]);

    useEffect(() => {
        setValue("documentType", documentTypeSToViewList[0].id);  // Establecer el primer valor por defecto
    }, [setValue]);

    const documentType = watch('documentType');

    return (
        <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{
                maxWidth: isMobile ? 320 : 400,
                margin: "auto",
                padding: 2,
                backgroundColor: "white",
                borderRadius: "8px",
                boxShadow: "0px 3px 6px rgba(0,0,0,0.1)",
                mt: 4,
            }}
        >
            <Box sx={{marginBottom: 2, textAlign: "center"}}>
                <Cards
                    cvc={paymentData.cvc}
                    expiry={paymentData.expiry}
                    focused={cardFocus}
                    name={paymentData.cardHolder}
                    number={paymentData.cardNumber}
                    callback={handleCallback}
                    placeholders={{name: 'TITULAR'}}
                />
            </Box>

            {/* Campo para el número de tarjeta */}
            <Controller
                name="cardNumber"
                control={control}
                defaultValue=""
                rules={{
                    required: 'Este campo es requerido',
                    validate: {

                        checkComplete: (value) => {
                            return cardMask.replaceAll(' ', '').length === value.length || 'Complete la tarjeta '
                        },
                        checkValidCard: (value) => {
                            return isValidCard || 'Verifique el número de la tarjeta'
                        }
                    },
                }}
                render={({field: {value, onChange}}) => (
                    <InputMask
                        mask={cardMask}
                        maskChar={''}
                        value={value}
                        onFocus={() => setCardFocus('number')}
                        onChange={(event) => handleNumberInput(event, onChange)}
                    >
                        <TextField
                            variant="outlined"
                            size="small"
                            required={true}
                            fullWidth
                            label="Número de la tarjeta"
                            error={!!errors.cardNumber}
                            helperText={errors.cardNumber ? errors.cardNumber.message : ""}
                        />
                    </InputMask>
                )}
            />

            {/* Campo para el titular de la tarjeta */}
            <Controller
                name="cardHolder"
                control={control}
                rules={{required: "Este campo es requerido",

                    maxLength: {
                    value: 255,
                    message: 'El titular  debe tener ' + 255 + ' caracteres máximos',
                },
                    minLength: {
                    value: 3,
                    message: 'El titular  debe tener al menos ' + 3 + ' caracteres',
                }}}
                render={({field}) => (
                    <TextField
                        {...field}
                        label="Titular"
                        size="small"
                        variant="outlined"
                        required={true}
                        fullWidth
                        margin="dense"
                        error={!!errors.cardHolder}
                        helperText={errors.cardHolder ? errors.cardHolder.message : ""}
                        onFocus={() => setCardFocus('name')}
                        onChange={(e) => {
                            const value = e.target.value
                                .replace(/[^a-zA-Z\s]/g, "")
                                .normalize("NFD")
                                .replace(/[\u0300-\u036f]/g, "")
                                .toUpperCase();
                            field.onChange(value);
                            setPaymentData({...paymentData, cardHolder: value});
                        }}
                    />
                )}
            />

            {/* Campo para la fecha de vencimiento */}
            <Controller
                name="expiry"
                control={control}
                rules={{
                    required: 'Este campo es requerido',
                    validate: (value) => {
                        const [month, year] = value.split('/');
                        const currentYear = new Date().getFullYear().toString().slice(-2);
                        const currentMonth = new Date().getMonth() + 1;
                        if (month == '') return 'Complete el mes'
                        if (parseInt(month) > 12 || parseInt(month) < 1) return 'Mes inválido';
                        if (year == '') return 'Complete el año'
                        if (parseInt(year) < parseInt(currentYear)) return 'Año inválido';
                        if (parseInt(year) === parseInt(currentYear) && parseInt(month) < currentMonth)
                            return 'La tarjeta ha expirado';
                        return true;
                    },
                }}
                render={({field: {value, onChange}}) => (
                    <InputMask
                        mask="99/99"
                        value={value}
                        onFocus={() => setCardFocus('expiry')}
                        onChange={(event) => handleExpiryInput(event, onChange)}
                    >
                        <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            required={true}
                            margin="dense"
                            label="Fecha de expiración"
                            error={!!errors.expiry}
                            helperText={errors.expiry ? errors.expiry.message : ""}
                        />
                    </InputMask>
                )}
            />

            {/* Campo para el CVC */}
            <Controller
                name="cvc"
                control={control}
                rules={{
                    required: 'Este campo es requerido',
                    maxLength: {
                        value: cvcMask.length,
                        message: 'El ' + cvcName + ' debe tener ' + cvcMask.length + ' dígitos',
                    },
                    minLength: {
                        value: cvcMask.length,
                        message: 'El ' + cvcName + ' debe tener ' + cvcMask.length + ' dígitos',
                    },
                }}
                render={({field: {value, onChange}}) => (
                    <InputMask
                        mask={cvcMask}
                        value={value}
                        onFocus={() => setCardFocus('cvc')}
                        onChange={(event) => handleCVCInput(event, onChange)}
                    >
                        <TextField
                            variant="outlined"
                            size="small"
                            margin="dense"
                            required={true}
                            fullWidth
                            label={cvcName}
                            error={!!errors.cvc}
                            helperText={errors.cvc ? errors.cvc.message : ""}
                        />
                    </InputMask>
                )}
            />

            <Controller
                name="documentType"
                control={control}
                rules={{
                    required: {value: true, message: 'Este campo es requerido'},
                }}
                render={({field}) => (
                    <FormControl sx={{width: '100%', mt: 1}} error={!!errors.documentType}>
                        <InputLabel id="document-type-select-label">Tipo de documento del titular*</InputLabel>
                        <Select
                            size="small"
                            margin="dense"
                            labelId="document-type-select-label"
                            id="document-type"
                            value={field.value || ""}
                            onChange={(event) => field.onChange(event.target.value)}
                            label="Tipo de documento del titular*"
                        >
                            {documentTypeSToViewList.map((documentType) => (
                                <MenuItem key={documentType.id} value={documentType.id}>
                                    {documentType.name}
                                </MenuItem>
                            ))}
                        </Select>

                        {errors.documentType && (
                            <FormHelperText>{errors.documentType.message}</FormHelperText>
                        )}
                    </FormControl>
                )}
            />


            {documentType == DocumentTypeEnum.CEDULAUY && <Controller
                name="cedula"
                control={control}
                rules={{
                    required: 'Este campo es requerido',
                    validate: {
                        checkComplete: (value) => {
                            return cedulaMask.replaceAll('.', '').replaceAll('-', '').length == value.length || 'Complete el documento'
                        },
                        checkIsValidCedula: (value) => {
                            return validate_ci(value) || 'Cédula incorrecta'
                        }
                    }
                }}
                render={({field: {value, onChange}}) => (
                    <InputMask
                        mask={cedulaMask}
                        value={value}
                        onChange={(event) => handleDocumentCedulaInput(event, onChange)}
                    >
                        <TextField
                            variant="outlined"
                            size="small"
                            required={true}
                            fullWidth
                            margin="dense"
                            label="Documento"
                            error={!!errors.cedula}
                            helperText={errors.cedula ? errors.cedula.message : ""}
                        />
                    </InputMask>
                )}
            />}

            {documentType == DocumentTypeEnum.DNI && <Controller
                name="dni"
                control={control}
                defaultValue=""
                rules={{
                    required: "Este campo es requerido",
                    maxLength: {
                        value: 18,
                        message: "El DNI no puede tener más de 18 caracteres",
                    },
                    minLength: {
                        value: 7,
                        message: "El DNI debe tener al menos 7 caracteres",
                    },
                }}
                render={({field, fieldState: {error}}) => (
                    <TextField
                        label="Documento"
                        variant="outlined"
                        size="small"
                        required={true}
                        fullWidth
                        margin="dense"
                        value={field.value}
                        onChange={field.onChange}
                        error={!!errors.dni}
                        helperText={errors.dni ? errors.dni.message : ""}
                    />
                )}
            />}

            <Controller
                name="consent"
                control={control}
                defaultValue={false}
                rules={{
                    validate: value => value === true || 'Debes confirmar el consentimiento informado', // Validar que esté marcado
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={value}
                                    onChange={onChange}
                                    color="primary"
                                />
                            }
                            label={
                                <>
                                    Confirmo que he leído y acepto el{' '}
                                    <Link
                                        href={orderInfo.urlInformConsent} // Cambia esto por tu URL
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        consentimiento informado
                                    </Link>
                                </>
                            }
                        />
                        {error && (
                            <FormHelperText error>{error.message}</FormHelperText>
                        )}
                    </>
                )}
            />


            {/* Botón de envío */}
            <Button
                style={{fontStyle: 'normal', textTransform: 'initial', fontWeight: 'bold', fontSize: '16px'}}
                variant="contained"
                color="success"  // Color verde que transmite confianza
                disabled={!isValid||loading}
                type="submit"
                fullWidth
                sx={{marginTop: 2, padding: isMobile ? "5px" : "8px"}}  // Ajusta el tamaño del botón
                endIcon={<CheckCircleIcon/>}  // Icono de check al final
            >
                Pagar {orderInfo.amount} USD en {orderInfo.fee} {orderInfo.fee > 1 ? 'cuotas' : 'cuota'}
            </Button>
        </Box>
    );
}
