import React from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    FormControl,
    FormHelperText,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {IDetailUser, IUpdateUser} from "../../types";
import {Cancel, CheckCircle} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {rolesToViewList} from "../../constants";
import {emailValidation} from "../../../../core/const";

export interface UserUpdateFormProps {
    handleSubmitForm: (value: IUpdateUser) => void;
    loading: boolean;
    value: IDetailUser
}

export const UserUpdateForm: React.FC<UserUpdateFormProps> = ({handleSubmitForm, loading, value}) => {

    const methods = useForm<IUpdateUser>({
        mode: 'all',
        defaultValues: {name: value.name, email: value.email, roles: value.roles, isEnable: value.isEnable}
    });

    const navigate = useNavigate();
    const {
        control,
        formState: {errors, isValid},
        getValues,
    } = methods;


    return (
        <>
            <Card sx={{maxWidth: 600, width: '100%'}}>
                <CardContent>
                    <Typography variant="h5" style={{fontWeight: 'bold'}} p={1}>Actualizar usuario</Typography>
                    <br/>
                    <Box component="form" display="flex" flexDirection="column" gap={1}>

                        <Box display="flex" flexDirection="row" justifyContent="space-around">
                            <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{
                                        required: {value: true, message: 'Este campo es requerido'},
                                        maxLength: {
                                            value: 255,
                                            message: 'El campo excede el largo máximo permitido de: 255 caracteres'
                                        }
                                    }}
                                    render={({field}) => (
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            label="Nombre"
                                            size={'small'}
                                            type="text"
                                            helperText={errors.name ? errors.name.message : ''}
                                            error={!!errors.name}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>
                        </Box>


                        <Box display="flex" flexDirection="row" justifyContent="space-around">
                            <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                                <Controller
                                    name="email"
                                    control={control}
                                    rules={{
                                        required: {value: true, message: 'Este campo es requerido'},
                                        pattern: emailValidation,
                                        maxLength: {
                                            value: 255,
                                            message: 'El campo excede el largo máximo permitido de: 255 caracteres'
                                        }
                                    }}
                                    render={({field}) => (
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            label="Email"
                                            size={'small'}
                                            type="text"
                                            helperText={errors.email ? errors.email.message : ''}
                                            error={!!errors.email}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>
                        </Box>


                        <Box display="flex" flexDirection="row" justifyContent="space-around">
                            <Box display="flex" alignItems="center" gap={2} flex={3} p={1}>
                                <Controller
                                    name="roles"
                                    control={control}
                                    rules={{
                                        required: {value: true, message: 'Este campo es requerido'},
                                    }}
                                    render={({field}) => (
                                        <FormControl sx={{width: '100%'}} error={!!errors.roles}>
                                            <InputLabel id="roles-select-label">Roles*</InputLabel>
                                            <Select
                                                size={'small'}
                                                labelId="roles-select-label"
                                                id="roles-select"
                                                multiple
                                                value={field.value || []}
                                                onChange={(event) => field.onChange(event.target.value)}
                                                renderValue={(selected) => selected.map(roleId => rolesToViewList.find(role => role.id === roleId)?.name).join(', ')}
                                                label="Roles*"
                                            >
                                                {rolesToViewList.map((role) => (
                                                    <MenuItem key={role.id} value={role.id}>
                                                        {role.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.roles && <FormHelperText>{errors.roles.message}</FormHelperText>}
                                        </FormControl>
                                    )}
                                />
                            </Box>
                        </Box>

                        <Box display="flex" flexDirection="row" justifyContent="space-around">
                            <Box display="flex" alignItems="center" gap={2} flex={3} p={1}>
                                <Controller
                                    name="isEnable"
                                    control={control}
                                    render={({field}) => (

                                        <FormControl fullWidth error={errors.isEnable === null}>
                                            <InputLabel id="is-active-select-label">Activo*</InputLabel>
                                            <Select
                                                size='small'
                                                labelId="is-active-select-label"
                                                id="is-active-select"
                                                value={field.value}
                                                label="Activo*"
                                                onChange={(event) => field.onChange(event.target.value == 'true')}
                                            >
                                                <MenuItem value={'true'}>
                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                        <CheckCircle style={{color: 'green', marginRight: 8}}/>
                                                        <ListItemText primary="Sí"/>
                                                    </div>
                                                </MenuItem>
                                                <MenuItem value={'false'}>
                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                        <Cancel style={{color: 'red', marginRight: 8}}/>
                                                        <ListItemText primary="No"/>
                                                    </div>
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    )}
                                />
                            </Box>
                        </Box>


                        <Box display="flex" width="100%" justifyContent="flex-end" gap={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => navigate('/users')}
                            >
                                Volver al listado
                            </Button>
                            <Button
                                variant="contained"
                                disabled={!isValid || loading}
                                color="primary"
                                onClick={() => handleSubmitForm(getValues())}
                            >
                                Guardar
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
        ;
};