import React, {useCallback, useEffect, useState} from 'react';
import {Alert, Backdrop, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {IFilterListAllCardPortfolioAuthorization, IListAllCardPortfolioAuthorization} from "../../../types";
import {getAuthorizations} from "../../../services";
import AuthorizationsList from "../../AuthorizationsList/AuthorizationsList";
import {ISort, ShowAlertState} from "../../../../../core/types";
import {extractErrorMessage} from "../../../../../core/helper";
import {getAllAirlines} from "../../../../airlines/services";
import {getAllAuthorizationOperatorsToCardPortfolio} from "../../../../authorization-operators/services";
import {IListAuthorizationOperator} from "../../../../authorization-operators/types";
import {IDetailAirline} from "../../../../airlines/types";
import {defaultListAllCardPortfolioAuthorizationsFilter} from "../../../constants";

const AuthorizationsListContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>(search);
    const [page, setPage] = useState<number>(0);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [order, setOrder] = useState<ISort[]>([{'field': 'createdAt', order: 'desc'}]);
    const [operators, setOperators] = useState<IListAuthorizationOperator[]>([]);
    const [airlines, setAirlines] = useState<IDetailAirline[]>([]);
    const [filters, setFilters] = useState<IFilterListAllCardPortfolioAuthorization>(defaultListAllCardPortfolioAuthorizationsFilter);
    const [items, setItems] = useState<IListAllCardPortfolioAuthorization[]>([])

    const loadAuthorizations = useCallback(async () => {
        try {
            setLoading(true);
            const paginatedResult = (await getAuthorizations(page + 1, pageSize, order, debouncedSearchTerm, filters)).data;
            setTotalElements(paginatedResult.total);
            setItems(paginatedResult.items);
            setLoading(false);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado de autorizaciones')
            });
            setLoading(false);
        }
    }, [page, pageSize, order, debouncedSearchTerm, filters]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(search);
        }, 500); // Retraso de 500 ms

        // Limpia el timeout si el efecto se vuelve a ejecutar antes de que el timeout finalice
        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    useEffect(() => {
        loadAuthorizations();
    }, [loadAuthorizations]);

    const loadAirlines = async () => {
        try {
            setLoading(true);
            const result = (await getAllAirlines()).data;
            setAirlines(result);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar las compañías aéreas')
            });
        } finally {
            setLoading(false);
        }
    }

    const loadOperators = async () => {
        try {
            setLoading(true);
            const result = (await getAllAuthorizationOperatorsToCardPortfolio()).data;
            setOperators(result);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar los operadores')
            });
        } finally {
            setLoading(false);
        }
    }


    useEffect(() => {
        loadAirlines();
    }, []);

    useEffect(() => {
        loadOperators();
    }, []);

    return (<><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            <AuthorizationsList title={'Autorizaciones de tarjetas en cartera'}
                                items={items}
                                filters={filters}
                                setFilters={setFilters}
                                order={order}
                                setOrder={setOrder}
                                search={search}
                                setSearch={setSearch}
                                page={page}
                                setPage={setPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                totalElements={totalElements}
                                airlines={airlines}
                                operators={operators}
            />
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </>
    );
}

export default AuthorizationsListContainer;
