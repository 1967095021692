import React, {useCallback, useEffect, useState} from 'react';
import {IListContactConfigurationToSendEmail} from "../../types";
import {ShowAlertState} from "../../../../core/types";
import {Alert, Backdrop, Box, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {getListEmailConfiguration, updateEmailConfiguration} from "../../services";
import {extractErrorMessage, is404Error} from "../../../../core/helper";
import {
    ContactEmailConfigurationUpsertForm,
    IUpsertContactEmailConfigurationFormValue
} from "../../components/ContactEmailConfigurationUpsertForm/ContactEmailConfigurationUpsertForm";

const ContactEmailConfigurationUpsertContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [configurationDetail, setConfigurationDetail] = useState<IListContactConfigurationToSendEmail | null>(null);

    const loadDetail = useCallback(async () => {
        try {

            setLoading(true);
            const result = (await getListEmailConfiguration()).data;
            setConfigurationDetail(result[0]);
            setLoading(false);

        } catch (error) {
            console.log(error);
            if (is404Error(error)) {
                setAlert({
                    show: true,
                    severity: 'error',
                    message: 'No se encontró en la base de datos la configuración de contactos para el envío de correos'
                });
            } else {
                setAlert({
                    show: true,
                    severity: 'error',
                    message: extractErrorMessage(error, 'Ocurrió un error al cargar la configuración de contactos para el envío de correos')
                });
            }

            setLoading(false);

        }
    }, [setLoading, setConfigurationDetail, setAlert]);


    useEffect(() => {
        loadDetail();
    }, [loadDetail]);

    const handleUpsert = async (value: IUpsertContactEmailConfigurationFormValue) => {

        try {
            setLoading(true);
            await updateEmailConfiguration(value);

            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Configuración actualizada satisfactoriamente'
            });
            setTimeout(loadDetail, 1500);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al actualizar la configuración')
            });
            setLoading(false);
        }

    }


    return (<Box sx={{width: '80%', padding: 3}}><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>

            {configurationDetail &&
                <ContactEmailConfigurationUpsertForm value={configurationDetail} loading={loading}
                                                     handleSubmitForm={handleUpsert}/>}
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </Box>
    );
}

export default ContactEmailConfigurationUpsertContainer;
