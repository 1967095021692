import {IPaginatedResult, ISort} from "../../core/types";
import {AxiosResponse} from "axios";
import {cleanFilters, formatSortingFields} from "../../core/helper";
import {ApiService} from "../../core/services";
import {IFilterActionLog, IListActionLog} from "./types";

const endpoints = {
    list: 'api/action-logs',
}

export const getActionLogs = (page = 1, pageSize = 10, sortingFields: ISort[], search: string, filters?: IFilterActionLog): Promise<AxiosResponse<IPaginatedResult<IListActionLog>>> => {
    const sorts = formatSortingFields(sortingFields);
    return ApiService.get(endpoints.list, {
        params: {
            page,
            pageSize,
            ...(sorts.length > 0 && {sort: sorts}), ...(filters && cleanFilters(filters)), ...(search && search !== '' && {search: search})
        }
    });
};