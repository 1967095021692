import {ILogin, ILoginResponse} from "../types";
import {AxiosResponse} from "axios";
import {ApiService} from "../../../core/services";

export class AuthService {
static postLogin = (payload: ILogin): Promise<AxiosResponse<ILoginResponse>> =>
	ApiService.post<ILoginResponse>('/api/login_check', payload);

	static resetPassword = (email: string): Promise<AxiosResponse> =>
	ApiService.post<ILoginResponse>('/api/users/reset-password', {email:email});

	static changeUserPassword = (previousPassword: string, newPassword:string): Promise<AxiosResponse> =>
	ApiService.post('/api/users/change-password', {previousPassword:previousPassword, newPassword:newPassword});
}

