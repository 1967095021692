export const defaultFilterListCorporateCardAuthorizations = {
    id: '',
    amountAuthorized: '',
    amountAuthorizedFrom: '',
    amountAuthorizedTo: '',
    operatorId: '',
    conceptId: '',
    fileNumber: '',
    fee: '',
    withAuthorizationCode: '',
    authorizationCode: '',
    createdAtFrom: '',
    createdBy: '',
    createdAtTo: '',
    search: ''
}