import React, {useCallback, useEffect, useState} from 'react';
import DestinationsList from "../../components/DestinationsList/DestinationsList";
import {IDestinationList, IDestinationListFilter} from "../../types";
import {ISort, ShowAlertState} from "../../../../core/types";
import {Alert, Backdrop, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {deleteDestination, getDestinations} from "../../services";
import {extractErrorMessage} from "../../../../core/helper";
import {useNavigate} from "react-router-dom";

const DestinationsListContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>(search);
    const [page, setPage] = useState<number>(0);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [order, setOrder] = useState<ISort[]>([]);
    const [filters, setFilters] = useState<IDestinationListFilter>({ code: '', name: '', countryName: '' });
    const [items, setItems] = useState<IDestinationList[]>([])
    const navigate = useNavigate();

    const onAdd =() => navigate('/destinations/create');

   const onEdit = (item: IDestinationList) => navigate('/destinations/'+item.code);
    const onDelete = async (item: IDestinationList) => {
        try {
            setLoading(true);
            await deleteDestination(item.code);
            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Destino eliminado satisfactoriamente'
            });
            setTimeout(loadDestinations,1500)
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al eliminar el destino')
            });
            setLoading(false);
        }
    };

    const loadDestinations = useCallback(async () => {
        try {
            setLoading(true);
            const paginatedResult = (await getDestinations(page+1, pageSize, order, debouncedSearchTerm, filters)).data;
            setTotalElements(paginatedResult.total);
            setItems(paginatedResult.items);
            setLoading(false);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado de destinos')
            });
            setLoading(false);
        }
    }, [page, pageSize, order, debouncedSearchTerm, filters]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(search);
        }, 500); // Retraso de 500 ms

        // Limpia el timeout si el efecto se vuelve a ejecutar antes de que el timeout finalice
        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    useEffect(() => {
        loadDestinations();
    }, [loadDestinations]);

    return (<><Backdrop sx={{
        zIndex: theme.zIndex.modal + 1,
        color: '#fff',
    }} open={loading}>
        <CircularProgress color="inherit" />
    </Backdrop>
        <DestinationsList title={'Destinos'}
                          items={items}
                          onAdd={onAdd}
                          onEdit={onEdit}
                          onDelete={onDelete}
                          filters={filters}
                          setFilters={setFilters}
                          order={order}
                          setOrder={setOrder}
                          search={search}
                          setSearch={setSearch}
                          page={page}
                          setPage={setPage}
                          pageSize={pageSize}
                          setPageSize={setPageSize}
                          totalElements={totalElements}
        />
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </>
    );
}

export default DestinationsListContainer;
