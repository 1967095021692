import React, {Dispatch, SetStateAction, useState} from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Toolbar,
    Typography,
} from '@mui/material';
import {Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon, Search as SearchIcon} from '@mui/icons-material';
import {ISort} from "../../../../core/types";
import {IListCorporateCardAuthorizationConcept} from "../../types";

interface CorporateCardAuthorizationConceptsListProps {
    title: string;
    search: string;
    setSearch: (search: string) => void;
    page: number;
    setPage: (page: number) => void;
    pageSize: number;
    setPageSize: (pageSize: number) => void;
    order: ISort[],
    setOrder: Dispatch<SetStateAction<ISort[]>>;
    items: IListCorporateCardAuthorizationConcept[];
    totalElements: number;
    onAdd: () => void;
    onEdit: (item: IListCorporateCardAuthorizationConcept) => void;
    onDelete: (item: IListCorporateCardAuthorizationConcept) => void;
}

const CorporateCardAuthorizationConceptsList: React.FC<CorporateCardAuthorizationConceptsListProps> = ({
                                                         title,
                                                         search,
                                                         setSearch,
                                                         page,
                                                         setPage,
                                                         pageSize,
                                                         totalElements,
                                                         setPageSize,
                                                         order,
                                                         setOrder,
                                                         items,
                                                         onAdd,
                                                         onEdit,
                                                         onDelete
                                                     }) => {

    const [open, setOpen] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<null | IListCorporateCardAuthorizationConcept>(null);
    const handleOpenDialog = (item: IListCorporateCardAuthorizationConcept | null = null) => {
        setSelectedItem(item);
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
        setSelectedItem(null);
    };


    const handleDelete = () => {
        if (selectedItem) {
            onDelete(selectedItem);
            handleCloseDialog();
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (property: keyof IListCorporateCardAuthorizationConcept) => {
        const exist = order.find(o => o.field === property)
        const isAsc = order.find(o => o.field === property)?.order === 'asc';
        if (!exist) {
            setOrder(prevOrder => [
                ...prevOrder.filter(o => o.field !== property),
                {field: property, order: isAsc ? 'desc' : 'asc'},
            ]);
        } else {
            if (exist.order === 'asc') {
                setOrder(prevOrder => [
                    ...prevOrder.filter(o => o.field !== property),
                    {field: property, order: 'desc'},
                ]);
            } else {
                setOrder(prevOrder => [
                    ...prevOrder.filter(o => o.field !== property),
                ]);
            }
        }

    };


    return (
        <Box sx={{width: '100%', padding: 3}}>
            <Typography variant="h4" component="div" sx={{mb: 2}}>
                {title}
            </Typography>
            <Toolbar sx={{display: 'flex', justifyContent: 'space-between', mb: 2}} style={{paddingLeft: 0}}>
                <Button
                    variant="contained"
                    startIcon={<AddIcon/>}
                    onClick={() => onAdd()}
                >
                    Nuevo concepto
                </Button>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <TextField
                        variant="outlined"
                        placeholder="Buscar"
                        size="small"
                        value={search}
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon/>
                                </InputAdornment>
                            ),
                        }}
                        sx={{mr: 2, width: 300}}
                    />
                </Box>
            </Toolbar>
            <Typography variant="body2" sx={{mb: 1}}>
                Mostrando {items.length} resultados de {totalElements}
            </Typography>
            <TableContainer component="main" sx={{width: '100%'}}>
                <Table sx={{width: '100%'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'name')}
                                    direction={order.find(o => o.field === 'name')?.order || 'asc'}
                                    onClick={() => handleRequestSort('name')}
                                >
                                    Nombre
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>
                                   <><IconButton onClick={() => onEdit(item)}>
                                        <EditIcon/>
                                    </IconButton>
                                    <IconButton onClick={() => handleOpenDialog(item)}>
                                        <DeleteIcon/>
                                    </IconButton></>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                labelRowsPerPage={'Filas por página'}
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={totalElements}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Dialog open={open} onClose={handleCloseDialog}>
                <DialogTitle>Eliminar Concepto</DialogTitle>
                <DialogContent>

                    <DialogContentText>
                        ¿Estás seguro de que deseas eliminar este operador?
                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancelar</Button>
                    <Button onClick={handleDelete} color="primary">Eliminar</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default CorporateCardAuthorizationConceptsList;
