import React, {useCallback, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {Backdrop, CircularProgress, CssBaseline, useTheme} from "@mui/material";
import AppTopBarToClient from "../../../../core/components/AppTopBarToClient/AppTopBarToClient";
import AppBottomBar from "../../../../core/components/AppBottomBar/AppBottomBar";
import Container from "@mui/material/Container";
import PaymentLinkForm, {PaymentLinkFormData} from "../../components/PaymentLinkForm/PaymentLinkForm";
import {DocumentTypeEnum, ICreatePaymentLinkPay, IOrderInfoToPay} from "../../types";
import {createPaymentLinkPay, getInfoToPay} from "../../services";
import {useParams} from "react-router-dom";
import {extractErrorMessage, is404Error} from "../../../../core/helper";
import Swal from 'sweetalert2';

export const PaymentLinkContainer: React.FC = () => {
    const theme = useTheme();
    const [orderInfoToPay, setOrderInfoToPay] = useState<IOrderInfoToPay | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const {id} = useParams<{ id: string }>();

    const showSuccessOperationTransaction = () => {
        Swal.fire({
            text: 'Acción realizada satisfactoriamente.',
            icon: 'success',
            confirmButtonColor: theme.palette.primary.main,
            showCancelButton: false,
            showCloseButton:false,
            showConfirmButton:false,
            allowOutsideClick: false,  // Evitar que se cierre al hacer clic fuera del modal
            allowEscapeKey: false,
        });
    };

    const showAlertOrderHasTransaction = () => {
        Swal.fire({
            text: 'La orden ya fue transaccionada.',
            icon: 'warning',
            showCancelButton: false,
            showCloseButton:false,
            showConfirmButton:false,
            allowOutsideClick: false,  // Evitar que se cierre al hacer clic fuera del modal
            allowEscapeKey: false,
        });
    };
    const showError = (error: string) => {
        Swal.fire({
            title: 'Error!',
            text: error,
            icon: 'error',
            confirmButtonText:'Aceptar',
            confirmButtonColor: theme.palette.primary.main
        });
    };
    const handleCreatePaymentLinkPay = async (x: PaymentLinkFormData) => {
        try {
            setLoading(true);
            const dataBase64Encrypt = {
                saleOrderId: id,
                cardNumber: btoa(x.cardNumber),
                cardHolder: btoa(x.cardHolder),
                expiry: btoa(x.expiry),
                cvc: btoa(x.cvc),
                cardStamp: btoa(x.cardStamp),
                documentType: btoa(x.documentType),
                documentNumber: btoa(x.documentType == DocumentTypeEnum.CEDULAUY ? x.cedula : x.dni)

            } as ICreatePaymentLinkPay
            await createPaymentLinkPay(dataBase64Encrypt);
            setOrderInfoToPay(null);
            showSuccessOperationTransaction();
        } catch (e) {
            showError(extractErrorMessage(e, 'Ocurrió un error al procesar la transacción.'));
        } finally {
            setLoading(false);
        }
    }

    const loadSaleOrderInfoToPay = useCallback(async () => {
        try {
            if (id) {
                setLoading(true);
                const result = (await getInfoToPay(id)).data;
                setOrderInfoToPay(result);
                setLoading(false);
                if (!result.allowPay) {
                    showAlertOrderHasTransaction();
                }
            } else {
                showError('El id de la orden es requerido');
            }
        } catch (error) {
            if (is404Error(error)) {
                showError('La orden de pago no existe');
            } else {
                showError(extractErrorMessage(error, 'Ocurrió un error al cargar el detalle de la orden de pago'));
            }

            setLoading(false);

        }
    }, [id, setLoading, setOrderInfoToPay]);


    useEffect(() => {
        loadSaleOrderInfoToPay();
    }, [loadSaleOrderInfoToPay]);

    return (<><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            <Box sx={{display: 'flex'}}>
                <CssBaseline/>
                <AppTopBarToClient/>
                <Container component="main" maxWidth="xl"
                           sx={{
                               flexGrow: 1,
                               width: '100%',
                               mt: 8, // Espacio para la AppBar superior
                               mb: 7 // Espacio para la AppBar inferior
                           }}
                >
                    {orderInfoToPay && orderInfoToPay.allowPay &&
                        <PaymentLinkForm orderInfo={orderInfoToPay} handleSubmitForm={handleCreatePaymentLinkPay}
                                         loading={loading}/>}
                </Container>
                <AppBottomBar/>
            </Box></>
    );
};
