import React, {useCallback, useEffect, useState} from 'react';
import {ICreateUser, IDetailUser, IUpdateUser} from "../../types";
import {ShowAlertState} from "../../../../core/types";
import {Alert, Backdrop, Box, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {createUser, getUser, updateUser} from "../../services";
import {extractErrorMessage, is404Error} from "../../../../core/helper";
import {useNavigate, useParams} from "react-router-dom";
import {UserCreateForm} from "../../components/UserCreateForm/UserCreateForm";
import {UserUpdateForm} from "../../components/UserUpdateForm/UserUpdateForm";

const UserUpsertContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [userDetail, setUserDetail] = useState<IDetailUser|null>(null);
    const {id} = useParams<{ id: string }>();
    const navigate = useNavigate();
    const validNumberId = !isNaN(Number(id))

    const loadUserDetail = useCallback(async () => {
        try {
            if (id && id!== 'create' ) {
                setLoading(true);
                const result = (await getUser(+id)).data;
                setUserDetail(result);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el usuario')
            });
            setLoading(false);
            if(is404Error(error)){
                setTimeout(()=>navigate('/users'), 2500);
            }
        }
    }, [id, setLoading, setUserDetail, setAlert]);


    useEffect(() => {
        loadUserDetail();
    }, [loadUserDetail]);

    const handleCreateUser = async (value: ICreateUser) => {
        try {
            setLoading(true);
           await createUser(value);
            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Usuario creado satisfactoriamente'
            });
            setTimeout(()=> navigate('/users'),1500)
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al crear el usuario')
            });
            setLoading(false);
        }
    }

    const handleUpdateUser = async (value: IUpdateUser) => {
        try {
            setLoading(true);
            await updateUser(Number(id),value);
            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Usuario actualizado satisfactoriamente'
            });
            setTimeout(()=> navigate('/users'),1500)
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al actualizar el usuario')
            });
            setLoading(false);
        }
    }


    return ( <Box sx={{width: '80%', padding: 3}}><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            {id && id === 'create' && <UserCreateForm loading={loading} handleSubmitForm={handleCreateUser}/>}
            {id && id !== 'create' && validNumberId && userDetail && <UserUpdateForm value={userDetail} loading={loading} handleSubmitForm={handleUpdateUser}/>}
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </Box>
    );
}

export default UserUpsertContainer;
