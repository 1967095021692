import React from 'react';
import {Box} from '@mui/material';

interface ColorSquareProps {
    color: string; // Color to be displayed
}

const ColorSquare: React.FC<ColorSquareProps> = ({color}) => {
    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Box
                sx={{
                    width: 24, // Width of the square
                    height: 24, // Height of the square
                    backgroundColor: color, // Background color of the square
                    border: '1px solid #000', // Optional border
                }}
            />
        </Box>
    );
};

export default ColorSquare;
