import React, {Dispatch, SetStateAction} from 'react';
import {Box, Typography} from '@mui/material';
import {IDetailCorporateCard} from "../../types";
import {ISort, ShowAlertState} from "../../../../core/types";
import 'dayjs/locale/es';
import {
    IFilterListCorporateCardAuthorization,
    IListCorporateCardAuthorization
} from "../../modules/corporate-card-authorization/types";
import {IListCorporateCardAuthorizationConcept} from "../../../corporate-card-authorization-concept/types";
import {IListAuthorizationOperator} from "../../../authorization-operators/types";
import CorporateCardInformationSummaryDetail
    from "../CorporateCardInformationSummaryDetail/CorporateCardInformationSummaryDetail";
import CorporateCardAuthorizationsList
    from "../../modules/corporate-card-authorization/components/CorporateCardAuthorizationsList/CorporateCardAuthorizationsList";


interface CorporateCardDetailProps {
    item: IDetailCorporateCard,
    onLoading: (loading: boolean) => void;
    loading: boolean;
    setAlert: (alert: ShowAlertState) => void;
    onChangeAuthorizations: () => void;
    search: string;
    setSearch: (search: string) => void;
    page: number;
    setPage: (page: number) => void;
    pageSize: number;
    setPageSize: (pageSize: number) => void;
    filters: IFilterListCorporateCardAuthorization;
    setFilters: (filter: IFilterListCorporateCardAuthorization) => void;
    order: ISort[],
    setOrder: Dispatch<SetStateAction<ISort[]>>;
    items: IListCorporateCardAuthorization[];
    totalElements: number;
    concepts: IListCorporateCardAuthorizationConcept[],
    operators: IListAuthorizationOperator[],
}

const CorporateCardDetail: React.FC<CorporateCardDetailProps> = ({
                                                                     item,
                                                                     onLoading,
                                                                     setAlert,
                                                                     loading,
                                                                     search,
                                                                     setSearch,
                                                                     page,
                                                                     setPage,
                                                                     pageSize,
                                                                     totalElements,
                                                                     setPageSize,
                                                                     filters,
                                                                     setFilters,
                                                                     order,
                                                                     setOrder,
                                                                     items,
                                                                     concepts,
                                                                     operators,
                                                                     onChangeAuthorizations
                                                                 }) => {
    return (
        <Box sx={{padding: 4}}>
            <Typography variant="h4" gutterBottom>
                Detalles de la tarjeta corporativa
            </Typography>

            {/* Sección de gráficos circulares */}


            <CorporateCardInformationSummaryDetail cardInfo={item} loading={loading}/>
            <CorporateCardAuthorizationsList loading={loading} onLoading={onLoading} setAlert={setAlert}
                                             onChangeAuthorizations={onChangeAuthorizations} corporateCardId={item.id}
                                             title={'Listado de autorizaciones'} search={search}
                                             setSearch={setSearch}
                                             page={page}
                                             setPage={setPage}
                                             pageSize={pageSize}
                                             totalElements={totalElements}
                                             setPageSize={setPageSize}
                                             filters={filters}
                                             setFilters={setFilters}
                                             order={order}
                                             setOrder={setOrder}
                                             items={items}
                                             concepts={concepts}
                                             operators={operators}

            />
        </Box>
    );
};

export default CorporateCardDetail;
