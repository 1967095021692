import React, {useEffect} from 'react';
import {Autocomplete, Box, Button, Card, CardContent, FormControl, TextField, Typography} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {IListCardPortfolioAuthorization} from "../../types";
import {IDetailAirline} from "../../../../../airlines/types";
import {IListAuthorizationOperator} from "../../../../../authorization-operators/types";
import {operatorIdPreAuthorized} from "../../../../../authorization-operators/constants";

export interface CardPortfolioAuthorizationUpsertFormProps {
    handleSubmitForm: (value: IUpsertCardPortfolioAuthorizationFormValue) => void;
    loading: boolean;
    airlines: IDetailAirline[],
    operators: IListAuthorizationOperator[],
    value: IListCardPortfolioAuthorization | null,
    handleClose: () => void,
    totalOfSale: number,
    hasAuthorizations:boolean
}

export interface IUpsertCardPortfolioAuthorizationFormValue {
    airlineCode: string;
    amount: string;
    operatorId: string;
    fileNumber?: string;
    authorizationCode: string;
}

const defaultValue: IUpsertCardPortfolioAuthorizationFormValue = {
    airlineCode: '',
    amount: '',
    operatorId: '',
    fileNumber: '',
    authorizationCode: ''
};
export const CardPortfolioAuthorizationUpsertForm: React.FC<CardPortfolioAuthorizationUpsertFormProps> = ({
                                                                                                              handleSubmitForm,
                                                                                                              loading,
                                                                                                              handleClose,
                                                                                                              airlines,
                                                                                                              operators,
                                                                                                              value,
                                                                                                              totalOfSale,
                                                                                                              hasAuthorizations
                                                                                                          }) => {

    const convertToValueToForm = (x: IListCardPortfolioAuthorization) => {
        return {
            airlineCode: x.airlineCode,
            amount: String(x.amount),
            operatorId: String(x.operatorId),
            fileNumber: x.fileNumber ? x.fileNumber : '',
            authorizationCode: x.authorizationCode
        }
    }


    const methods = useForm<IUpsertCardPortfolioAuthorizationFormValue>({
        mode: 'all',
        defaultValues: value ? convertToValueToForm(value) : defaultValue
    });

    const {
        control,
        watch,
        formState: {errors, isValid},
        getValues,
        setValue,
        trigger
    } = methods;

    const operatorIdValue = watch('operatorId');

    useEffect(() => {
        if (operatorIdValue == operatorIdPreAuthorized && !value && !hasAuthorizations) {
            setValue('amount', String(totalOfSale));
            trigger('amount');
        }
        if(!value && operatorIdValue){
           trigger('amount');
        }

    }, [operatorIdValue]);

    return (
        <>
            <Card sx={{maxWidth: 700, width: '100%'}}>
                <CardContent>
                    <Typography variant="h5" style={{fontWeight: 'bold'}}
                                p={1}>{value ? 'Actualizar' : 'Nueva'} autorización clásica</Typography>
                    <br/>
                    <Box component="form" display="flex" flexDirection="column" gap={1}>
                        <Box display="flex" alignItems="center" gap={2} flex={3} p={1}>
                            <Controller
                                name="airlineCode"
                                control={control}
                                rules={{
                                    required: {value: true, message: 'Este campo es requerido'}
                                }}
                                render={({field}) => (
                                    <FormControl sx={{width: '100%'}} disabled={value !== null}>
                                        <Autocomplete
                                            options={airlines}
                                            getOptionLabel={(option: IDetailAirline) => `${option.code} - ${option.name}`}
                                            value={airlines.find((option) => option.code === field.value) || null}
                                            onChange={(event, value) => {
                                                const selectedValues = (value as IDetailAirline)?.code || '';
                                                field.onChange(selectedValues);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    variant="outlined"
                                                    required
                                                    label="Seleccione la compañía aérea"
                                                    fullWidth
                                                    helperText={errors.airlineCode ? errors.airlineCode.message : ''}
                                                    error={!!errors.airlineCode}
                                                />
                                            )}
                                            disabled={value !== null}
                                        />
                                    </FormControl>
                                )}
                            />
                        </Box>

                        <Box display="flex" alignItems="center" gap={2} flex={3} p={1}>
                            <Controller
                                name="operatorId"
                                control={control}
                                rules={{
                                    required: {value: true, message: 'Este campo es requerido'}
                                }}
                                render={({field}) => (
                                    <FormControl sx={{width: '100%'}}>
                                        <Autocomplete
                                            options={operators}
                                            getOptionLabel={(option: IListAuthorizationOperator) => `${option.name}`}
                                            value={
                                                operators.find((option) => option.id === field.value) || null
                                            }
                                            onChange={(event, value) => {
                                                const selectedValues = (value as IListAuthorizationOperator)?.id || '';
                                                field.onChange(selectedValues);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size={'small'}
                                                    variant="outlined"
                                                    required
                                                    label="Seleccione el operador"
                                                    fullWidth
                                                    helperText={errors.operatorId ? errors.operatorId.message : ''}
                                                    error={!!errors.operatorId}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                )}
                            />
                        </Box>


                        <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                            <Controller
                                name="amount"
                                control={control}
                                rules={{
                                    min: {value: 1, message: 'El valor mínimo es 1'},
                                    required: {value: true, message: 'Este campo es requerido'},
                                    validate: (valueOfField) => {
                                       if(operatorIdValue == operatorIdPreAuthorized && Number(valueOfField)!=totalOfSale && !value && !hasAuthorizations){
                                           return 'El monto autorizado debe ser '+totalOfSale +' porque es una preautorización y debe ser igual al monto total de la venta';
                                       }
                                       return true;
                                    },
                                    pattern: {
                                        value: /^[0-9]*[.,]?[0-9]+$/,
                                        message: 'El campo monto debe ser un número válido'
                                    }
                                }}
                                render={({field}) => (
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="Monto autorizado"
                                        size={'small'}
                                        type="text"
                                        disabled={value != null}
                                        helperText={errors.amount ? errors.amount.message : ''}
                                        error={!!errors.amount}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>

                        <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                            <Controller
                                name="authorizationCode"
                                control={control}
                                rules={{
                                    required: {value: true, message: 'Este campo es requerido'},
                                    maxLength: {
                                        value: 15,
                                        message: 'El campo excede el largo máximo permitido de: ' + 15 + ' caracteres'
                                    }
                                }}
                                render={({field}) => (
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="Código de autorización"
                                        size={'small'}
                                        type="text"
                                        disabled={value != null}
                                        helperText={errors.authorizationCode ? errors.authorizationCode.message : ''}
                                        error={!!errors.authorizationCode}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>




                        {operatorIdValue != '' && operatorIdValue != operatorIdPreAuthorized &&
                            <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                                <Controller
                                    name="fileNumber"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Este campo es requerido'
                                        },
                                        pattern: {value: /^[0-9]*$/, message: "Solo se permiten números"} // Validación para solo números
                                    }}
                                    render={({field}) => (
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Número de file"
                                            required={true}
                                            size="small"
                                            disabled={value != null && operatorIdValue != '' && operatorIdValue == operatorIdPreAuthorized}
                                            type="text"
                                            helperText={errors.fileNumber ? errors.fileNumber.message : ''}
                                            error={!!errors.fileNumber}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>}


                        <Box display="flex" width="100%" justifyContent="flex-end" gap={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleClose}
                            >
                                Volver al listado
                            </Button>
                            <Button
                                variant="contained"
                                disabled={!isValid || loading}
                                color="primary"
                                onClick={() => handleSubmitForm(getValues())}
                            >
                                Guardar
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
        ;
};