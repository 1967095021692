import React, {useCallback, useEffect, useState} from 'react';
import {ICardPortfolioSummaryContainer} from "../../types";
import {Alert, Backdrop, Box, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {getSummaryToReport} from "../../services";
import CardPortfolioSummaryDashBoard
    from "../../components/CardPortfolioSummaryDashBoard/CardPortfolioSummaryDashBoard";
import {ShowAlertState} from "../../../../../../core/types";
import {extractErrorMessage} from "../../../../../../core/helper";

const CardPortfolioSummaryContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [cardPortfolioSummary, setCardPortfolioSummary] = useState<ICardPortfolioSummaryContainer | null>(null);

    const loadCardPortfolioDetail = useCallback(async () => {
        try {

            setLoading(true);
            const result = (await getSummaryToReport()).data;
            setCardPortfolioSummary(result);
            setLoading(false);

        } catch (error) {
            console.log(error);
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el detalle para el reporte')
            });
            setLoading(false);
        }
    }, [setLoading, setCardPortfolioSummary, setAlert]);


    useEffect(() => {
        loadCardPortfolioDetail();
    }, [loadCardPortfolioDetail]);

    return (<Box sx={{width: '100%', padding: 3}}><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            {cardPortfolioSummary &&
                <CardPortfolioSummaryDashBoard data={cardPortfolioSummary}/>}

            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </Box>
    );
}

export default CardPortfolioSummaryContainer;
