import React, {Dispatch, SetStateAction, useState} from 'react';

import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogTitle,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    Popover,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Toolbar,
    Typography,
} from '@mui/material';
import {
    Add as AddIcon,
    Edit as EditIcon,
    FilterList as FilterListIcon,
    Search as SearchIcon
} from '@mui/icons-material';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, {Dayjs} from "dayjs";
import {DatePicker} from "@mui/x-date-pickers";
import {
    ICreateCorporateCardAuthorization,
    IFilterListCorporateCardAuthorization,
    IListCorporateCardAuthorization, IUpdateCorporateCardAuthorization
} from "../../types";
import {IListCorporateCardAuthorizationConcept} from "../../../../../corporate-card-authorization-concept/types";
import {IListAuthorizationOperator} from "../../../../../authorization-operators/types";
import {ISort, ShowAlertState} from "../../../../../../core/types";
import DraggablePaperComponent from "../../../../../../core/components/DraggablePaperComponent/DraggablePaperComponent";
import CloseIcon from "@mui/icons-material/Close";
import {
    CorporateCardAuthorizationUpsertForm, IUpsertCorporateCardAuthorizationFormValue
} from "../CorporateCardAuthorizationUpsertForm/CorporateCardAuthorizationUpsertForm";
import {createCorporateCardAuthorization, updateCorporateCardAuthorization} from "../../services";
import {extractErrorMessage} from "../../../../../../core/helper";
import {defaultFilterListCorporateCardAuthorizations} from "../../constants";

interface AuthorizationsListProps {
    corporateCardId: number,
    title: string;
    search: string;
    setSearch: (search: string) => void;
    page: number;
    setPage: (page: number) => void;
    pageSize: number;
    setPageSize: (pageSize: number) => void;
    filters: IFilterListCorporateCardAuthorization;
    setFilters: (filter: IFilterListCorporateCardAuthorization) => void;
    order: ISort[],
    setOrder: Dispatch<SetStateAction<ISort[]>>;
    items: IListCorporateCardAuthorization[];
    totalElements: number;
    concepts: IListCorporateCardAuthorizationConcept[],
    operators: IListAuthorizationOperator[],
    onChangeAuthorizations: () => void;
    onLoading: (loading: boolean) => void;
    loading: boolean;
    setAlert: (alert: ShowAlertState) => void;
}

const CorporateCardAuthorizationsList: React.FC<AuthorizationsListProps> = ({
                                                                                corporateCardId,
                                                                                title,
                                                                                search,
                                                                                setSearch,
                                                                                page,
                                                                                setPage,
                                                                                pageSize,
                                                                                totalElements,
                                                                                setPageSize,
                                                                                filters,
                                                                                setFilters,
                                                                                order,
                                                                                setOrder,
                                                                                items,
                                                                                concepts,
                                                                                operators,
                                                                                onChangeAuthorizations,
                                                                                onLoading,
                                                                                loading, setAlert
                                                                            }) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedItem, setSelectedItem] = useState<null | IListCorporateCardAuthorization>(null);
    const [visibleFormCreate, setVisibleFormCreate] = useState<boolean>(false);

    const [internalFilters, setInternalFilters] = useState<IFilterListCorporateCardAuthorization>(defaultFilterListCorporateCardAuthorizations);

    const [createdAtFrom, setCreatedAtFrom] = useState<Dayjs | null>(null);
    const [createdAtTo, setCreatedAtTo] = useState<Dayjs | null>(null);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleFilterChange = (field: keyof typeof filters) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setInternalFilters((prev) => ({...prev, [field]: event.target.value}));
    };

    const handleFilterChangeValue = (field: keyof typeof filters, value: string | number) => {
        setInternalFilters((prev) => ({...prev, [field]: value}));
    };

    const handleWithAuthorizationCode = (event: SelectChangeEvent<unknown>) => {
        if (event.target.value !== undefined) {
            setInternalFilters((prev) => ({...prev, withAuthorizationCode: event.target.value == 'true'}));
        } else {
            setInternalFilters((prev) => ({...prev, withAuthorizationCode: undefined}));
        }

    };
    const handleApplyFilter = () => {
        setFilters(internalFilters);
        handleCloseMenu();
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (property: keyof IListCorporateCardAuthorization) => {
        const exist = order.find(o => o.field === property)
        const isAsc = order.find(o => o.field === property)?.order === 'asc';
        if (!exist) {
            setOrder(prevOrder => [
                ...prevOrder.filter(o => o.field !== property),
                {field: property, order: isAsc ? 'desc' : 'asc'},
            ]);
        } else {
            if (exist.order === 'asc') {
                setOrder(prevOrder => [
                    ...prevOrder.filter(o => o.field !== property),
                    {field: property, order: 'desc'},
                ]);
            } else {
                setOrder(prevOrder => [
                    ...prevOrder.filter(o => o.field !== property),
                ]);
            }
        }

    };

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleCancelFilters = () => {
        setInternalFilters(filters);
        handleCloseMenu();
    };

    const handleCreateAuthorization = async (value: IUpsertCorporateCardAuthorizationFormValue) => {
        try {
            onLoading(true);
            const valueToSend: ICreateCorporateCardAuthorization = {
                amount: Number(value.amount),
                fee: Number(value.fee),
                fileNumber: value.fileNumber,
                authorizationCode: value?.authorizationCode != undefined ? value?.authorizationCode : undefined,
                conceptId: value?.conceptId != undefined ? Number(value?.conceptId) : undefined,
                operatorId: value.operatorId
            } as ICreateCorporateCardAuthorization
            await createCorporateCardAuthorization(corporateCardId,valueToSend);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Autorización creada satisfactoriamente'
            });
            setVisibleFormCreate(false);
            setTimeout(onChangeAuthorizations,1500)

        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al crear la autorización')
            });
        } finally {
            onLoading(false);
        }
    }

    const handleUpdateAuthorization = async (value: IUpsertCorporateCardAuthorizationFormValue) => {
        try {
            onLoading(true);
            const valueToSend: IUpdateCorporateCardAuthorization = {
                fileNumber: value.fileNumber,
                operatorId: value.operatorId
            } as IUpdateCorporateCardAuthorization
            await updateCorporateCardAuthorization(corporateCardId,selectedItem?.id||-1,valueToSend);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Autorización actualizada satisfactoriamente'
            });
            setSelectedItem(null);
            setTimeout(onChangeAuthorizations,1500)

        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al actualizar la autorización')
            });
        } finally {
            onLoading(false);
        }
    }

    return (<>
            <Box sx={{width: '100%', padding: 3}}>
                <Typography variant="h5" component="div" sx={{mb: 2}}>
                    {title}
                </Typography>
                <Toolbar sx={{display: 'flex', justifyContent: 'space-between', mb: 2}} style={{paddingLeft: 0}}>
                    <Button
                        variant="contained"
                        startIcon={<AddIcon/>}
                        onClick={() => setVisibleFormCreate(true)}
                    >
                        Nueva autorización
                    </Button>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <TextField
                            variant="outlined"
                            placeholder="Buscar"
                            size="small"
                            value={search}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon/>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{mr: 2, width: 300}}
                        />
                        <Button variant="outlined" startIcon={<FilterListIcon/>} onClick={handleOpenMenu}>
                            Filtrar
                        </Button>
                        <Popover
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={handleCloseMenu}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            disableRestoreFocus
                        >
                            <Box sx={{p: 2, display: 'flex', flexDirection: 'column', gap: 1}}>
                                <TextField
                                    label="ID igual a"
                                    size='small'
                                    value={internalFilters.id}
                                    onChange={handleFilterChange('id')}
                                    fullWidth
                                />
                                <FormControl sx={{width: '100%'}}>
                                    <Autocomplete
                                        options={concepts}
                                        getOptionLabel={(option: IListCorporateCardAuthorizationConcept) => ` ${option.name}`}
                                        value={concepts.find((option) => option.id === internalFilters.conceptId) || null}
                                        onChange={(event, value) => {
                                            const selectedValue = (value as IListCorporateCardAuthorizationConcept)?.id || '';
                                            handleFilterChangeValue('conceptId', selectedValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                size="small"
                                                variant="outlined"
                                                label="Concepto igual a"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </FormControl>

                                <FormControl sx={{width: '100%'}}>
                                    <Autocomplete
                                        options={operators}
                                        getOptionLabel={(option: IListAuthorizationOperator) => `${option.name}`}
                                        value={
                                            operators.find((option) => option.id === internalFilters.operatorId) || null
                                        }
                                        onChange={(event, value) => {
                                            const selectedValue = (value as IListAuthorizationOperator)?.id || '';
                                            handleFilterChangeValue('operatorId', selectedValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                size={'small'}
                                                variant="outlined"
                                                required
                                                label="Operador igual a"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </FormControl>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    value={internalFilters.amountAuthorized}
                                    onChange={(e) => {
                                        let value = e.target.value;

                                        // Permitir solo números, un punto decimal, y hasta dos dígitos decimales
                                        value = value.replace(/[^0-9.]/g, ''); // Elimina todo lo que no sea un número o punto
                                        const parts = value.split('.');

                                        // Asegurarse de que solo haya un punto decimal y recortar a 2 decimales
                                        if (parts.length > 2) {
                                            value = `${parts[0]}.${parts[1]}`; // Eliminar puntos extra
                                        } else if (parts[1]?.length > 2) {
                                            value = `${parts[0]}.${parts[1].slice(0, 2)}`; // Limitar a 2 decimales
                                        }

                                        handleFilterChangeValue('amountAuthorized', value);
                                    }}
                                    label="Monto autorizado igual a"
                                    size="small"
                                    type="text"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">USD</InputAdornment>, // Añade "USD" al final del input
                                    }}

                                />
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    value={internalFilters.amountAuthorizedFrom}
                                    onChange={(e) => {
                                        let value = e.target.value;

                                        // Permitir solo números, un punto decimal, y hasta dos dígitos decimales
                                        value = value.replace(/[^0-9.]/g, ''); // Elimina todo lo que no sea un número o punto
                                        const parts = value.split('.');

                                        // Asegurarse de que solo haya un punto decimal y recortar a 2 decimales
                                        if (parts.length > 2) {
                                            value = `${parts[0]}.${parts[1]}`; // Eliminar puntos extra
                                        } else if (parts[1]?.length > 2) {
                                            value = `${parts[0]}.${parts[1].slice(0, 2)}`; // Limitar a 2 decimales
                                        }

                                        handleFilterChangeValue('amountAuthorizedFrom', value);
                                    }}
                                    label="Monto autorizado >= a"
                                    size="small"
                                    type="text"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">USD</InputAdornment>, // Añade "USD" al final del input
                                    }}

                                />

                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    value={internalFilters.amountAuthorizedTo}
                                    onChange={(e) => {
                                        let value = e.target.value;

                                        // Permitir solo números, un punto decimal, y hasta dos dígitos decimales
                                        value = value.replace(/[^0-9.]/g, ''); // Elimina todo lo que no sea un número o punto
                                        const parts = value.split('.');

                                        // Asegurarse de que solo haya un punto decimal y recortar a 2 decimales
                                        if (parts.length > 2) {
                                            value = `${parts[0]}.${parts[1]}`; // Eliminar puntos extra
                                        } else if (parts[1]?.length > 2) {
                                            value = `${parts[0]}.${parts[1].slice(0, 2)}`; // Limitar a 2 decimales
                                        }

                                        handleFilterChangeValue('amountAuthorizedTo', value);
                                    }}
                                    label="Monto autorizado <= a"
                                    size="small"
                                    type="text"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">USD</InputAdornment>, // Añade "USD" al final del input
                                    }}

                                />
                                <TextField
                                    label="Código de autorización igual a"
                                    size='small'
                                    value={internalFilters.authorizationCode}
                                    onChange={handleFilterChange('authorizationCode')}
                                    fullWidth
                                />

                                <TextField
                                    label="Número de file igual a"
                                    size='small'
                                    value={internalFilters.fileNumber}
                                    onChange={handleFilterChange('fileNumber')}
                                    fullWidth
                                />

                                <TextField
                                    label="Cuotas igual a"
                                    size='small'
                                    value={internalFilters.fee}
                                    onChange={handleFilterChange('fee')}
                                    fullWidth
                                />
                                <LocalizationProvider dateAdapter={AdapterDayjs}>

                                    <Box sx={{display: 'flex', gap: 2, flexDirection: 'column', maxWidth: 300}}>
                                        <DatePicker
                                            label="Fecha de autorización desde"
                                            format="YYYY-MM-DD"
                                            value={createdAtFrom ? dayjs(createdAtFrom) : null}
                                            onChange={(newValue) => {
                                                setCreatedAtFrom(newValue);
                                                handleFilterChangeValue('createdAtFrom', newValue ? newValue.format('YYYY-MM-DD') : '')
                                            }}
                                            slots={{
                                                textField: textFieldProps => <TextField {...textFieldProps}
                                                                                        variant="outlined"
                                                                                        fullWidth
                                                                                        size="small"/>
                                            }}
                                        />
                                        <DatePicker
                                            label="Fecha de autorización hasta"
                                            format="YYYY-MM-DD"
                                            value={createdAtTo ? dayjs(createdAtTo) : null}
                                            onChange={(newValue) => {
                                                setCreatedAtTo(newValue);
                                                handleFilterChangeValue('createdAtTo', newValue ? newValue.format('YYYY-MM-DD') : '')
                                            }}
                                            minDate={createdAtFrom ? dayjs(createdAtFrom) : undefined}  // No permite seleccionar una fecha menor a la de inicio
                                            slots={{
                                                textField: textFieldProps => <TextField {...textFieldProps}
                                                                                        variant="outlined"
                                                                                        fullWidth
                                                                                        size="small"/>
                                            }}
                                        />
                                    </Box>
                                </LocalizationProvider>

                                <FormControl fullWidth>
                                    <InputLabel id="with-auth-code-label">Con código de autorización</InputLabel>
                                    <Select
                                        size='small'
                                        labelId="with-auth-code-select-label"
                                        value={internalFilters.withAuthorizationCode}
                                        label="Con código de autorización"
                                        onChange={handleWithAuthorizationCode}
                                    >
                                        <MenuItem value={undefined} style={{minHeight: '24px'}}>
                                            <em></em>
                                        </MenuItem>
                                        <MenuItem value={'true'}>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <ListItemText primary="Sí"/>
                                            </div>
                                        </MenuItem>
                                        <MenuItem value={'false'}>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <ListItemText primary="No"/>
                                            </div>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    label="Autorizado por contiene"
                                    size='small'
                                    value={internalFilters.createdBy}
                                    onChange={handleFilterChange('createdBy')}
                                    fullWidth
                                />

                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2}}>
                                    <Button variant="contained" onClick={handleApplyFilter} sx={{mr: 2}}>
                                        Aplicar Filtros
                                    </Button>
                                    <Button variant="outlined" onClick={handleCancelFilters}>
                                        Cancelar
                                    </Button>
                                </Box>
                            </Box>
                        </Popover>
                    </Box>
                </Toolbar>
                <Typography variant="body2" sx={{mb: 1}}>
                    Mostrando {items.length} resultados de {totalElements}
                </Typography>
                <TableContainer component="main" sx={{width: '100%'}}>
                    <Table sx={{width: '100%'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    ID
                                </TableCell>


                                <TableCell sx={{width: '10%'}}>
                                    <TableSortLabel
                                        active={order.some(o => o.field === 'amount')}
                                        direction={order.find(o => o.field === 'amount')?.order || 'asc'}
                                        onClick={() => handleRequestSort('amount')}
                                    >
                                        Monto autorizado
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell>
                                    <TableSortLabel
                                        active={order.some(o => o.field === 'fileNumber')}
                                        direction={order.find(o => o.field === 'fileNumber')?.order || 'asc'}
                                        onClick={() => handleRequestSort('fileNumber')}
                                    >
                                        File
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell>
                                    <TableSortLabel
                                        active={order.some(o => o.field === 'fee')}
                                        direction={order.find(o => o.field === 'fee')?.order || 'asc'}
                                        onClick={() => handleRequestSort('fee')}
                                    >
                                        Cuotas
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell>
                                    <TableSortLabel
                                        active={order.some(o => o.field === 'operatorName')}
                                        direction={order.find(o => o.field === 'operatorName')?.order || 'asc'}
                                        onClick={() => handleRequestSort('operatorName')}
                                    >
                                        Operador
                                    </TableSortLabel>
                                </TableCell>


                                <TableCell>
                                    <TableSortLabel
                                        active={order.some(o => o.field === 'authorizationCode')}
                                        direction={order.find(o => o.field === 'authorizationCode')?.order || 'asc'}
                                        onClick={() => handleRequestSort('authorizationCode')}
                                    >
                                        Código de autorización
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell>
                                    <TableSortLabel
                                        active={order.some(o => o.field === 'conceptName')}
                                        direction={order.find(o => o.field === 'conceptName')?.order || 'asc'}
                                        onClick={() => handleRequestSort('conceptName')}
                                    >
                                        Concepto
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={order.some(o => o.field === 'createdAt')}
                                        direction={order.find(o => o.field === 'createdAt')?.order || 'asc'}
                                        onClick={() => handleRequestSort('createdAt')}
                                    >
                                        Fecha de autorización
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell>
                                    <TableSortLabel
                                        active={order.some(o => o.field === 'createdBy')}
                                        direction={order.find(o => o.field === 'createdBy')?.order || 'asc'}
                                        onClick={() => handleRequestSort('createdBy')}
                                    >
                                        Autorizado por
                                    </TableSortLabel>
                                </TableCell>


                                <TableCell>
                                    <TableSortLabel
                                        active={order.some(o => o.field === 'updatedAt')}
                                        direction={order.find(o => o.field === 'updatedAt')?.order || 'asc'}
                                        onClick={() => handleRequestSort('updatedAt')}
                                    >
                                        Fecha de actualización
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell>
                                    <TableSortLabel
                                        active={order.some(o => o.field === 'updatedBy')}
                                        direction={order.find(o => o.field === 'updatedBy')?.order || 'asc'}
                                        onClick={() => handleRequestSort('updatedBy')}
                                    >
                                        Actualizada por
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((item) => (
                                <TableRow
                                    key={item.id}
                                >
                                    <TableCell>{item.id}</TableCell>
                                    <TableCell>{item.amount} USD</TableCell>
                                    <TableCell>{item.fileNumber}</TableCell>
                                    <TableCell>{item.fee}</TableCell>
                                    <TableCell>{item.operatorName}</TableCell>
                                    <TableCell>{item.authorizationCode}</TableCell>
                                    <TableCell>{item.conceptName ? item.conceptName : '-'}</TableCell>
                                    <TableCell>{item.createdAt}</TableCell>
                                    <TableCell>{item.createdBy}</TableCell>
                                    <TableCell>{item.updatedAt ? item.updatedAt : '-'}</TableCell>
                                    <TableCell>{item.updatedBy ? item.updatedBy : '-'}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => setSelectedItem(item)}>
                                            <EditIcon/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    labelRowsPerPage={'Filas por página'}
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={totalElements}
                    rowsPerPage={pageSize}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
            {visibleFormCreate && <Dialog
                open={visibleFormCreate}
                PaperComponent={DraggablePaperComponent}
                aria-labelledby="draggable-dialog-title-create-form"
            >
                <DialogTitle
                    style={{cursor: 'move', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
                    id="draggable-dialog-title-create-form"
                >
                    Crear autorización de tarjeta corporativa
                    <IconButton
                        aria-label="close"
                        onClick={() => setVisibleFormCreate(false)}
                        sx={{
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <CorporateCardAuthorizationUpsertForm authorization={null} operators={operators}
                                                      handleClose={() => setVisibleFormCreate(false)}
                                                      loading={loading} concepts={concepts} handleSubmitForm={handleCreateAuthorization}/>
            </Dialog>}

            {selectedItem!==null && <Dialog
                open={true}
                PaperComponent={DraggablePaperComponent}
                aria-labelledby="draggable-dialog-title-update-form"
            >
                <DialogTitle
                    style={{cursor: 'move', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
                    id="draggable-dialog-title-update-form"
                >
                    Actualizar autorización de tarjeta corporativa
                    <IconButton
                        aria-label="close"
                        onClick={() => setVisibleFormCreate(false)}
                        sx={{
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <CorporateCardAuthorizationUpsertForm authorization={selectedItem} operators={operators}
                                                      handleClose={() => setSelectedItem(null)}
                                                      loading={loading} concepts={concepts} handleSubmitForm={handleUpdateAuthorization}/>
            </Dialog>}


        </>
    );
};

export default CorporateCardAuthorizationsList;
