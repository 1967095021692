import React, {useCallback, useEffect, useState} from 'react';
import {IFilterTransferService, IListTransferService} from "../../types";
import {ISort, ShowAlertState} from "../../../../core/types";
import {Alert, Backdrop, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {extractErrorMessage} from "../../../../core/helper";
import {useNavigate} from "react-router-dom";
import TransferServicesList from "../../components/TransferServicesList/TransferServicesList";
import {deleteTransferService, getTransferServices} from "../../services";

const TransferServiceListContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>(search);
    const [page, setPage] = useState<number>(0);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [order, setOrder] = useState<ISort[]>([]);
    const [filters, setFilters] = useState<IFilterTransferService>({name: '',departurePoint:'',
        destinationPoint:'',transferType:undefined,isPrivate:undefined,destinationName:undefined});
    const [items, setItems] = useState<IListTransferService[]>([])
    const navigate = useNavigate();

    const onAdd = () => navigate('/services/transfer-services/create');

    const onEdit = (item: IListTransferService) => navigate('/services/transfer-services/' + item.id);
    const onViewTariff = (item: IListTransferService) => navigate('/services/transfer-services/' + item.id+'/tariff');


    const loadTransferServices = useCallback(async () => {
        try {
            setLoading(true);
            const paginatedResult = (await getTransferServices(page + 1, pageSize, order, debouncedSearchTerm, filters)).data;
            setTotalElements(paginatedResult.total);
            setItems(paginatedResult.items);
            setLoading(false);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado de servicios de traslado')
            });
            setLoading(false);
        }
    }, [page, pageSize, order, debouncedSearchTerm, filters]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(search);
        }, 500); // Retraso de 500 ms

        // Limpia el timeout si el efecto se vuelve a ejecutar antes de que el timeout finalice
        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    useEffect(() => {
        loadTransferServices();
    }, [loadTransferServices]);


    const onDelete = async (item: IListTransferService) => {
        try {
            setLoading(true);
            await deleteTransferService(item.id);
            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Servicio de traslado eliminado satisfactoriamente'
            });
            setTimeout(loadTransferServices,1500)
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al eliminar el servicio de traslado')
            });
            setLoading(false);
        }
    };

    return (<><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            <TransferServicesList title={'Servicios de traslado'}
                              items={items}
                              onAdd={onAdd}
                              onEdit={onEdit}
                              onViewTariff={onViewTariff}
                              onDelete={onDelete}
                              filters={filters}
                              setFilters={setFilters}
                              order={order}
                              setOrder={setOrder}
                              search={search}
                              setSearch={setSearch}
                              page={page}
                              setPage={setPage}
                              pageSize={pageSize}
                              setPageSize={setPageSize}
                              totalElements={totalElements}
            />
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </>
    );
}

export default TransferServiceListContainer;
