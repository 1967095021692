import React, {useCallback, useEffect, useState} from 'react';
import {IDestinationCreate, IDestinationDetail, IDestinationUpdate} from "../../types";
import {ShowAlertState} from "../../../../core/types";
import {Alert, Backdrop, Box, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {createDestination, getDestination, updateDestination} from "../../services";
import {extractErrorMessage, is404Error} from "../../../../core/helper";
import {useNavigate, useParams} from "react-router-dom";
import {DestinationCreateForm} from "../../components/DestinationCreateForm/DestinationCreateForm";
import {DestinationUpdateForm} from "../../components/DestinationUpdateForm/DestinationUpdateForm";
import {getAllCountries} from "../../../countries/services";
import {ICountry} from "../../../countries/types";

const DestinationUpsertContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [countries, setCountries] = useState<ICountry[]>([]);
    const [destinationDetail, setDestinationDetail] = useState<IDestinationDetail|null>(null);
    const {code} = useParams<{ code: string }>();
    const navigate = useNavigate();

    const loadCountries = async () => {
        try {
            setLoading(true);
            const result = (await getAllCountries()).data;
            setCountries(result);
            setLoading(false);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al listar los paises')
            });
            setLoading(false);
        }
    };

    const loadDestinationDetail = useCallback(async () => {
        try {
            if (code && code !== 'create') {
                setLoading(true);
                const result = (await getDestination(code)).data;
                setDestinationDetail(result);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el destino')
            });
            setLoading(false);
            if(is404Error(error)){
                setTimeout(()=>navigate('/destinations'), 2500);
            }
        }
    }, [code, setLoading, setDestinationDetail, setAlert]);

    useEffect(() => {
        loadCountries();
    }, []);



    useEffect(() => {
        loadDestinationDetail();
    }, [loadDestinationDetail]);

    const handleCreateDestination = async (value: IDestinationCreate) => {
        try {
            setLoading(true);
           await createDestination({...value, image:value.image.split(',')[1]});
            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Destino creado satisfactoriamente'
            });
            setTimeout(()=> navigate('/destinations'),1500)
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al crear el destino')
            });
            setLoading(false);
        }
    }

    const handleUpdateDestination = async (value: IDestinationUpdate) => {
        try {
            setLoading(true);
            await updateDestination(value.code,value);
            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Destino actualizado satisfactoriamente'
            });
            setTimeout(()=> navigate('/destinations'),1500)
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al actualizar el destino')
            });
            setLoading(false);
        }
    }


    return ( <Box sx={{width: '80%', padding: 3}}><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            {code && code === 'create' && <DestinationCreateForm loading={loading} countries={countries}
                                                                 handleSubmitForm={handleCreateDestination}/>}
            {code && code !== 'create' && destinationDetail &&  <DestinationUpdateForm loading={loading} countries={countries} value={destinationDetail}
                                                                 handleSubmitForm={handleUpdateDestination}/>}
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </Box>
    );
}

export default DestinationUpsertContainer;
