import {ResolvedMethodRejectionEnum} from "./types";

export const getHumanNameResolvedMethodRejectionEnum = (x: ResolvedMethodRejectionEnum) => {
    switch (x) {
        case ResolvedMethodRejectionEnum.TRY_TO_COLLECT_AGAIN:
            return 'Intentar cobrar nuevamente';
        case ResolvedMethodRejectionEnum.PAY_WITH_OTHER_METHODS:
            return 'Paga con otros medios de pago';
        default:
            return 'Desconocido';

    }
}

export interface IResolvedMethodRejectionToView {
    id: string;
    name: string
}

export const resolveMethodRejectionToViewList: IResolvedMethodRejectionToView[] = [
    {
        id: ResolvedMethodRejectionEnum.TRY_TO_COLLECT_AGAIN,
        name: getHumanNameResolvedMethodRejectionEnum(ResolvedMethodRejectionEnum.TRY_TO_COLLECT_AGAIN)
    },
    {
        id: ResolvedMethodRejectionEnum.PAY_WITH_OTHER_METHODS,
        name: getHumanNameResolvedMethodRejectionEnum(ResolvedMethodRejectionEnum.PAY_WITH_OTHER_METHODS)
    }
]