import React, {useCallback, useEffect, useState} from 'react';
import {ISort, ShowAlertState} from "../../../../core/types";
import {Alert, Backdrop, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {extractErrorMessage} from "../../../../core/helper";
import {useNavigate} from "react-router-dom";
import {ICountry, IFilterCountry} from "../../types";
import {deleteCountry, getCountries} from "../../services";
import CountriesList from "../../components/CountriesList/CountriesList";

const CountriesListContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>(search);
    const [page, setPage] = useState<number>(0);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [order, setOrder] = useState<ISort[]>([]);
    const [filters, setFilters] = useState<IFilterCountry>({code: '', name: ''});
    const [items, setItems] = useState<ICountry[]>([])
    const navigate = useNavigate();

    const onAdd = () => navigate('/countries/create');

    const onEdit = (item: ICountry) => navigate('/countries/' + item.code);
    const onDelete = async (item: ICountry) => {
        try {
            setLoading(true);
            await deleteCountry(item.code);
            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'País eliminado satisfactoriamente'
            });
            setTimeout(loadCountries, 1500)
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al eliminar el país')
            });
            setLoading(false);
        }
    };

    const loadCountries = useCallback(async () => {
        try {
            setLoading(true);
            const paginatedResult = (await getCountries(page + 1, pageSize, order, debouncedSearchTerm, filters)).data;
            setTotalElements(paginatedResult.total);
            setItems(paginatedResult.items);
            setLoading(false);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado de paises')
            });
            setLoading(false);
        }
    }, [page, pageSize, order, debouncedSearchTerm, filters]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(search);
        }, 500); // Retraso de 500 ms

        // Limpia el timeout si el efecto se vuelve a ejecutar antes de que el timeout finalice
        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    useEffect(() => {
        loadCountries();
    }, [loadCountries]);

    return (<><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            <CountriesList title={'Paises'}
                          items={items}
                          onAdd={onAdd}
                          onEdit={onEdit}
                          onDelete={onDelete}
                          filters={filters}
                          setFilters={setFilters}
                          order={order}
                          setOrder={setOrder}
                          search={search}
                          setSearch={setSearch}
                          page={page}
                          setPage={setPage}
                          pageSize={pageSize}
                          setPageSize={setPageSize}
                          totalElements={totalElements}
            />
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </>
    );
}

export default CountriesListContainer;
