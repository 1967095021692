import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import Cards, {CallbackArgument, Focused} from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import {Box, Button, Card, CardContent, TextField, Typography, useMediaQuery, useTheme} from "@mui/material";
import InputMask from 'react-input-mask';
import creditCardType from "credit-card-type";
import {CreditCardType} from "credit-card-type/src/types";
import {IUpsertCorporateCard} from "../../types";
import {useNavigate} from "react-router-dom";
import {appRoutes} from "../../../../core/const";

export interface UpsertCorporateCardFormProps {
    handleSubmitForm: (value: IUpsertCorporateCard) => void;
    corporateCardValue?: IUpsertCorporateCard
    loading: boolean;
}

export default function CorporateCardUpsertForm({handleSubmitForm, loading, corporateCardValue}: UpsertCorporateCardFormProps) {
    const [corporateCardData, setCorporateCardData] = useState<IUpsertCorporateCard>({
        cardNumber: corporateCardValue?.cardNumber || '',
        cardHolder: corporateCardValue?.cardHolder || '',
        expiry: corporateCardValue?.expiry || '',
        cvc: corporateCardValue?.cvc || '',
        cardStamp: corporateCardValue?.cardStamp || '',
    });
    const [cardFocus, setCardFocus] = useState<Focused | undefined>(undefined);
    const [isValidCard, setIsValidCard] = useState<boolean>(false);
    const [cardMask, setCardMask] = useState<string>('9999 9999 9999 9999');
    const [cvcMask, setCVCMask] = useState<string>('999');
    const [cvcName, setCVCName] = useState<string>('CVC');
    const {
        handleSubmit,
        control,
        setValue,
        trigger,
        formState: {errors, isValid}
    } = useForm<IUpsertCorporateCard>({
        mode: 'all', // Esto actualiza la validez del formulario dinámicamente
    });
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const navigate = useNavigate() ;
    const onSubmit = (data: IUpsertCorporateCard) => {
        handleSubmitForm(data);
    };
    const setValueToCardMaskAndCVVBase = (lengthCCV: number, nameCCV: string) => {
        setCVCMask(Array.from({length: lengthCCV}, () => '9').join(''));
        setCVCName(nameCCV);
    }

    const setValueToCardMaskAndCVV = (x: CreditCardType) => {
        setValueToCardMaskAndCVVBase(x.code.size, x.code.name)
    }

    useEffect(() => {
        if(corporateCardValue){
            handleCheckIssuer(corporateCardValue.cardStamp);
            setValue('cardNumber',corporateCardValue.cardNumber);
            setValue('cardHolder',corporateCardValue.cardHolder);
            setValue('cvc',corporateCardValue.cvc);
            setValue('cardStamp',corporateCardValue.cardStamp);
            setValue('expiry',corporateCardValue.expiry);
            trigger('cardNumber');
            trigger('cardHolder');
            trigger('cvc');
            trigger('cardStamp');
            trigger('expiry');
        }

    }, [corporateCardValue]);


    const handleCheckIssuer = (issuer: string) => {
        switch (issuer) {
            case 'visa':
                const creditCardTypeInfoVisa = creditCardType.getTypeInfo(creditCardType.types.VISA);
                setValueToCardMaskAndCVV(creditCardTypeInfoVisa);
                break;
            case 'mastercard':
                const creditCardTypeInfoMaster = creditCardType.getTypeInfo(creditCardType.types.MASTERCARD);
                setValueToCardMaskAndCVV(creditCardTypeInfoMaster);
                break;
            case 'american-express':
                const creditCardTypeInfoAE = creditCardType.getTypeInfo(creditCardType.types.AMERICAN_EXPRESS);
                setValueToCardMaskAndCVV(creditCardTypeInfoAE);
                break;
            case 'diners-club':
                const creditCardTypeInfoDC = creditCardType.getTypeInfo(creditCardType.types.DINERS_CLUB);
                setValueToCardMaskAndCVV(creditCardTypeInfoDC);
                break;
            case 'discover':
                const creditCardTypeInfoDIS = creditCardType.getTypeInfo(creditCardType.types.DISCOVER);
                setValueToCardMaskAndCVV(creditCardTypeInfoDIS);
                break;
            case 'jcb':
                const creditCardTypeInfoJBC = creditCardType.getTypeInfo(creditCardType.types.JCB);
                setValueToCardMaskAndCVV(creditCardTypeInfoJBC);
                break;
            case 'unionpay':
                const creditCardTypeInfoUP = creditCardType.getTypeInfo(creditCardType.types.UNIONPAY);
                setValueToCardMaskAndCVV(creditCardTypeInfoUP);
                break;
            case 'maestro':
                const creditCardTypeInfoM = creditCardType.getTypeInfo(creditCardType.types.MAESTRO);
                setValueToCardMaskAndCVV(creditCardTypeInfoM);
                break;
            case 'mir':
                const creditCardTypeInfoMIR = creditCardType.getTypeInfo(creditCardType.types.MIR);
                setValueToCardMaskAndCVV(creditCardTypeInfoMIR);
                break;
            case 'elo':
                const creditCardTypeInfoELO = creditCardType.getTypeInfo(creditCardType.types.ELO);
                setValueToCardMaskAndCVV(creditCardTypeInfoELO);
                break;
            case 'hiper':
                const creditCardTypeInfoHIPER = creditCardType.getTypeInfo(creditCardType.types.HIPER);
                setValueToCardMaskAndCVV(creditCardTypeInfoHIPER);
                break;
            case 'hipercard':
                const creditCardTypeInfoHIPERCard = creditCardType.getTypeInfo(creditCardType.types.HIPERCARD);
                setValueToCardMaskAndCVV(creditCardTypeInfoHIPERCard);
                break;
            default:
                setValueToCardMaskAndCVVBase(3, 'CVC');

        }
    }

    const handleNumberInput = (event: React.ChangeEvent<HTMLInputElement>, onChange: (...event: any[]) => void) => {
        const {target: {value}} = event;
        const cardNumberClean = value.replace(/\s/g, '').replaceAll('_', '');
        onChange(cardNumberClean);
        setCorporateCardData((prevState) => ({...prevState, cardNumber: cardNumberClean}));
    };

    const handleCallback = (type: CallbackArgument, isValid: boolean) => {

        if (type.issuer !== 'amex' && type.issuer !== 'american-express') {
            setCardMask('9999 9999 9999 9999');
        } else {
            setCardMask('9999 999999 99999');
        }
        handleCheckIssuer(type.issuer);
        setValue('cardStamp', type.issuer);
        setIsValidCard(isValid);
    };

    const handleExpiryInput = (event: React.ChangeEvent<HTMLInputElement>, onChange: (...event: any[]) => void) => {
        const {target: {value}} = event;
        const expiryClean = value.replaceAll('_', '');
        onChange(expiryClean);
        setCorporateCardData({...corporateCardData, expiry: expiryClean});
    };


    const handleCVCInput = (event: React.ChangeEvent<HTMLInputElement>, onChange: (...event: any[]) => void) => {
        const {target: {value}} = event;
        const cvcClean = value.replaceAll('_', '');
        onChange(cvcClean);
        setCorporateCardData({...corporateCardData, cvc: cvcClean});
    };

    useEffect(() => {
        // Cuando isValidCard cambia, forzamos la validación del campo 'cardNumber'
        trigger("cardNumber");
    }, [isValidCard, trigger]);


    return (<>
            <Card>
                <CardContent>
                    <Typography variant="h5" style={{fontWeight: 'bold'}} p={1}>{corporateCardValue ? 'Actualizar' : 'Nueva'} tarjeta
                        corporativa</Typography>
                    <br/>
                    <Box
                        component="form"
                        onSubmit={handleSubmit(onSubmit)}
                        sx={{
                            maxWidth: isMobile ? 320 : 400,
                            margin: "auto",
                            padding: 2,
                            backgroundColor: "white",
                            borderRadius: "8px",
                            boxShadow: "0px 3px 6px rgba(0,0,0,0.1)",
                            mt: 4,
                        }}
                    >
                        <Box sx={{marginBottom: 2, textAlign: "center"}}>
                            <Cards
                                cvc={corporateCardData.cvc}
                                expiry={corporateCardData.expiry}
                                focused={cardFocus}
                                name={corporateCardData.cardHolder}
                                number={corporateCardData.cardNumber}
                                callback={handleCallback}
                                placeholders={{name: 'TITULAR'}}
                            />
                        </Box>

                        {/* Campo para el número de tarjeta */}
                        <Controller
                            name="cardNumber"
                            control={control}
                            defaultValue=''
                            rules={{
                                required: 'Este campo es requerido',
                                validate: {

                                    checkComplete: (value) => {
                                        return cardMask.replaceAll(' ', '').length == value.length || 'Complete la tarjeta '
                                    },
                                    checkValidCard: (value) => {
                                        return isValidCard || 'Verifique el número de la tarjeta'
                                    }
                                },
                            }}
                            render={({field: {value, onChange}}) => (
                                <InputMask
                                    mask={cardMask}
                                    disabled={!!corporateCardValue}
                                    maskChar={''}
                                    value={value}
                                    onFocus={() => setCardFocus('number')}
                                    onChange={(event) => handleNumberInput(event, onChange)}
                                >
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        required={true}
                                        fullWidth
                                        label="Número de la tarjeta"
                                        error={!!errors.cardNumber}
                                        helperText={errors.cardNumber ? errors.cardNumber.message : ''}
                                    />
                                </InputMask>
                            )}
                        />

                        {/* Campo para el titular de la tarjeta */}
                        <Controller
                            name="cardHolder"
                            control={control}
                            rules={{
                                required: "Este campo es requerido",

                                maxLength: {
                                    value: 255,
                                    message: 'El titular  debe tener ' + 255 + ' caracteres máximos',
                                },
                                minLength: {
                                    value: 3,
                                    message: 'El titular  debe tener al menos ' + 3 + ' caracteres',
                                }
                            }}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    label="Titular"
                                    size="small"
                                    variant="outlined"
                                    required={true}
                                    disabled={!!corporateCardValue}
                                    fullWidth
                                    margin="dense"
                                    error={!!errors.cardHolder}
                                    helperText={errors.cardHolder ? errors.cardHolder.message : ''}
                                    onFocus={() => setCardFocus('name')}
                                    onChange={(e) => {
                                        const value = e.target.value
                                            .replace(/[^a-zA-Z\s]/g, '')
                                            .normalize("NFD")
                                            .replace(/[\u0300-\u036f]/g, '')
                                            .toUpperCase();
                                        field.onChange(value);
                                        setCorporateCardData({...corporateCardData, cardHolder: value});
                                    }}
                                />
                            )}
                        />

                        {/* Campo para la fecha de vencimiento */}
                        <Controller
                            name="expiry"
                            control={control}
                            rules={{
                                required: 'Este campo es requerido',
                                validate: (value) => {
                                    const [month, year] = value.split('/');
                                    const currentYear = new Date().getFullYear().toString().slice(-2);
                                    const currentMonth = new Date().getMonth() + 1;
                                    if (month == '') return 'Complete el mes'
                                    if (parseInt(month) > 12 || parseInt(month) < 1) return 'Mes inválido';
                                    if (year == '') return 'Complete el año'
                                    if (parseInt(year) < parseInt(currentYear)) return 'Año inválido';
                                    if (parseInt(year) === parseInt(currentYear) && parseInt(month) < currentMonth)
                                        return 'La tarjeta ha expirado';
                                    return true;
                                },
                            }}
                            render={({field: {value, onChange}}) => (
                                <InputMask
                                    mask="99/99"
                                    value={value}
                                    onFocus={() => setCardFocus('expiry')}
                                    onChange={(event) => handleExpiryInput(event, onChange)}
                                >
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        required={true}
                                        margin="dense"
                                        label="Fecha de expiración"
                                        error={!!errors.expiry}
                                        helperText={errors.expiry ? errors.expiry.message : ''}
                                    />
                                </InputMask>
                            )}
                        />

                        {/* Campo para el CVC */}
                        <Controller
                            name="cvc"
                            control={control}
                            rules={{
                                required: 'Este campo es requerido',
                                maxLength: {
                                    value: cvcMask.length,
                                    message: 'El ' + cvcName + ' debe tener ' + cvcMask.length + ' dígitos',
                                },
                                minLength: {
                                    value: cvcMask.length,
                                    message: 'El ' + cvcName + ' debe tener ' + cvcMask.length + ' dígitos',
                                },
                            }}
                            render={({field: {value, onChange}}) => (
                                <InputMask
                                    mask={cvcMask}
                                    value={value}
                                    onFocus={() => setCardFocus('cvc')}
                                    onChange={(event) => handleCVCInput(event, onChange)}
                                >
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        margin="dense"
                                        required={true}
                                        fullWidth
                                        label={cvcName}
                                        error={!!errors.cvc}
                                        helperText={errors.cvc ? errors.cvc.message : ''}
                                    />
                                </InputMask>
                            )}
                        />

                        <Box display="flex" width="100%" justifyContent="flex-end" gap={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => navigate(appRoutes.corporateCard.list)}
                            >
                                Volver al listado
                            </Button>
                            <Button
                                variant="contained"
                                disabled={!isValid || loading}
                                type="submit"
                            >
                                Guardar
                            </Button>
                        </Box>
                    </Box> </CardContent>
            </Card>
        </>
    );
}
