import {AxiosResponse} from "axios";
import {
    IDetailTariffNotPrivateTransferService,
    IDetailTariffPrivateTransferService,
    IUpsertTariffNotPrivateTransferService,
    IUpsertTariffPrivateTransferService
} from "./types";
import {ApiService} from "../../../../core/services";

const endpoints = {
    listTariffPrivate: 'api/transfer-services/:id/tariff-private',
    createTariffPrivate: 'api/transfer-services/:id/tariff-private',
    updateTariffPrivate: 'api/transfer-services/:id/tariff-private/:tariffId',
    deleteTariffPrivate: 'api/transfer-services/:id/tariff-private/:tariffId',

    listTariffNotPrivate: 'api/transfer-services/:id/tariff-not-private',
    createTariffNotPrivate: 'api/transfer-services/:id/tariff-not-private',
    updateTariffNotPrivate: 'api/transfer-services/:id/tariff-not-private/:tariffId',
    deleteTariffNotPrivate: 'api/transfer-services/:id/tariff-not-private/:tariffId',
}

export const getTransferServicesTariffPrivate = (transferId: number): Promise<AxiosResponse<IDetailTariffPrivateTransferService[]>> => {
    return ApiService.get(endpoints.listTariffPrivate.replace(':id', transferId + ''));
};
export const createTransferServiceTariffPrivate = (transferId: number, data: IUpsertTariffPrivateTransferService): Promise<AxiosResponse<void>> =>
    ApiService.post(endpoints.createTariffPrivate.replace(':id', transferId + ''), data);
export const updateTransferServiceTariffPrivate = (transferId: number, tariffId: number, data: IUpsertTariffPrivateTransferService): Promise<AxiosResponse<void>> =>
    ApiService.put(endpoints.updateTariffPrivate.replace(':id', transferId + '')
        .replace(':tariffId', tariffId + ''), data);

export const deleteTransferServiceTariffPrivate = (transferId: number, tariffId: number): Promise<AxiosResponse<void>> =>
    ApiService.delete(endpoints.deleteTariffPrivate
        .replace(':id', transferId + '')
        .replace(':tariffId', tariffId + ''));


export const getTransferServicesTariffNotPrivate = (transferId: number): Promise<AxiosResponse<IDetailTariffNotPrivateTransferService[]>> => {
    return ApiService.get(endpoints.listTariffNotPrivate.replace(':id', transferId + ''));
};
export const createTransferServiceTariffNotPrivate = (transferId: number, data: IUpsertTariffNotPrivateTransferService): Promise<AxiosResponse<void>> =>
    ApiService.post(endpoints.createTariffNotPrivate.replace(':id', transferId + ''), data);
export const updateTransferServiceTariffNotPrivate = (transferId: number, tariffId: number, data: IUpsertTariffNotPrivateTransferService): Promise<AxiosResponse<void>> =>
    ApiService.put(endpoints.updateTariffNotPrivate.replace(':id', transferId + '')
        .replace(':tariffId', tariffId + ''), data);

export const deleteTransferServiceTariffNotPrivate = (transferId: number, tariffId: number): Promise<AxiosResponse<void>> =>
    ApiService.delete(endpoints.deleteTariffNotPrivate
        .replace(':id', transferId + '')
        .replace(':tariffId', tariffId + ''));