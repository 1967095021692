// components/ProtectedRoute.tsx
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import {CookieService} from "../../../modules/auth/services/CookieService";

const ProtectedRoute: React.FC = () => {
    const token = CookieService.getToken();

    if (!token) {
        // Si no hay token, redirige al login
        return <Navigate to="/login" replace />;
    }

    // Si hay token, renderiza el componente hijo (Outlet)
    return <Outlet />;
};

export default ProtectedRoute;
