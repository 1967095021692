import React, {useEffect, useState} from 'react';
import {
    Alert,
    Backdrop,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogContentText,
    Snackbar,
    useTheme
} from "@mui/material";
import {IDetailInsuranceServiceTariff, IUpsertInsuranceServiceTariff} from "../../types";
import {ShowAlertState} from "../../../../../../core/types";
import {useParams} from "react-router-dom";
import {IDetailInsuranceService} from "../../../../types";
import {extractErrorMessage} from "../../../../../../core/helper";
import {
    createInsuranceServiceTariff,
    deleteInsuranceServiceTariff,
    getInsuranceServiceTariff,
    updateInsuranceServiceTariff
} from "../../services";
import {getInsuranceService} from "../../../../services";
import {
    InsuranceServiceTariffUpsertForm,
    IUpsertInsuranceServiceTariffFormValue
} from "../../components/InsuranceServiceTariffUpsertForm/InsuranceServiceTariffUpsertForm";
import InsuranceServiceTariffList from "../../components/InsuranceServiceTariffList/InsuranceServiceTariffList";

const InsuranceServiceTariffListContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [tariffs, setTariffs] = useState<IDetailInsuranceServiceTariff[]>([])
    const [tariffLineToEdit, setTariffLineToEdit] = useState<IDetailInsuranceServiceTariff | null>(null)
    const [insuranceService, setInsuranceService] = useState<IDetailInsuranceService | null>(null)
    const {insuranceServiceId} = useParams<{ insuranceServiceId: string }>();
    const [showFormModalTariff, setShowFormModalTariff] = useState<boolean>(false);


    const loadTariff = async () => {
        if (insuranceService) {
            try {
                setLoading(true);
                const result = (await getInsuranceServiceTariff(insuranceService.id)).data;
                setTariffs(result);
                setLoading(false);
            } catch (error) {
                setAlert({
                    show: true,
                    severity: 'error',
                    message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado de tarifas del servicio')
                });
                setLoading(false);
            }
        }

    };

    useEffect(() => {
        loadTariff();
    }, [insuranceService]);

    const onAddTariff = () => {
        setTariffLineToEdit(null);
        setShowFormModalTariff(true);
    }

    const onEditTariff = (x: IDetailInsuranceServiceTariff) => {
        setTariffLineToEdit(x);
        setShowFormModalTariff(true);
    }

    const onDeleteTariff = async (x: IDetailInsuranceServiceTariff) => {
        if (insuranceService) {
            try {
                setLoading(true);
                await deleteInsuranceServiceTariff(insuranceService.id, x.id);
                setLoading(false);
                setAlert({
                    show: true,
                    severity: 'success',
                    message: 'Linea de tarifa eliminada satisfactoriamente'
                });
                setTimeout(() => loadTariff(), 1500)
            } catch (error) {
                setAlert({
                    show: true,
                    severity: 'error',
                    message: extractErrorMessage(error, 'Ocurrió un error al eliminar la linea de tarifa')
                });
                setLoading(false);
            }
        }

    }


    useEffect(() => {
        const loadInsuranceService = async () => {
            if (insuranceServiceId && !isNaN(Number(insuranceServiceId))) {
                try {
                    setLoading(true);
                    const result = (await getInsuranceService(Number(insuranceServiceId))).data;
                    setInsuranceService(result);
                    setLoading(false);
                } catch (error) {
                    setAlert({
                        show: true,
                        severity: 'error',
                        message: extractErrorMessage(error, 'Ocurrió un error al buscar el servicio de seguro')
                    });
                    setLoading(false);
                }
            }

        };
        loadInsuranceService();

    }, [insuranceServiceId]);

    const handleCloseModalTariff = () => {
        setShowFormModalTariff(false);
        setTariffLineToEdit(null);
    }


    const handleSubmitFormTariff = async (value: IUpsertInsuranceServiceTariffFormValue) => {
        const messageSuccess = !tariffLineToEdit ? 'creada' : 'actualizada';
        const messageError = !tariffLineToEdit ? 'crear' : 'actualizar';
        if (insuranceService) {
            try {

                const valueToSend: IUpsertInsuranceServiceTariff = {
                    insuranceServiceId: insuranceService.id,
                    benefits: value.benefits,
                    minAge: Number(value.minAge),
                    maxAge: Number(value.maxAge),
                    priceByDayByPerson: Number(value.priceByDayByPerson),
                    ...(value.observations.length > 0 && {observations: value.observations}),

                }
                setLoading(true);
                if (!tariffLineToEdit) {
                    await createInsuranceServiceTariff(insuranceService.id, valueToSend);
                } else {
                    await updateInsuranceServiceTariff(insuranceService.id, tariffLineToEdit.id, valueToSend);
                }

                setLoading(false);
                setAlert({
                    show: true,
                    severity: 'success',
                    message: 'Linea de tarifa ' + messageSuccess + ' satisfactoriamente'
                });
                setTimeout(() => {
                    handleCloseModalTariff();
                    loadTariff();
                }, 1000)
            } catch (error) {
                setAlert({
                    show: true,
                    severity: 'error',
                    message: extractErrorMessage(error, 'Ocurrió un error al ' + messageError + ' la linea de tarifa')
                });
                setLoading(false);
            }
        }
    }


    return (<><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            {insuranceService &&
                <InsuranceServiceTariffList title={'Listado de tarifas del servicio de seguro ' + insuranceService.name}
                                            items={tariffs}
                                            onAdd={onAddTariff}
                                            onEdit={onEditTariff}
                                            onDelete={onDeleteTariff}

                />}

            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
            {showFormModalTariff &&
                <Dialog fullWidth={true} open={showFormModalTariff} onClose={handleCloseModalTariff} maxWidth='lg'>
                    <DialogContent>

                        <DialogContentText>
                            <InsuranceServiceTariffUpsertForm handleClose={handleCloseModalTariff}
                                                              loading={loading}
                                                              handleSubmitForm={handleSubmitFormTariff}
                                                              value={tariffLineToEdit}/>
                        </DialogContentText>

                    </DialogContent>

                </Dialog>}
        </>
    );
}

export default InsuranceServiceTariffListContainer;
