import React, {Dispatch, SetStateAction, useState} from 'react';

import {
    Box,
    Button,
    Checkbox,
    FormControl,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    Popover,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Toolbar,
    Typography,
} from '@mui/material';
import {FilterList as FilterListIcon, Search as SearchIcon} from '@mui/icons-material';
import {ISort} from "../../../../core/types";
import {IFilterListReceipts, IListReceipts} from "../../types";
import {defaultFilterListReceipts} from "../../constants";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import EmptyTable from "../../../../core/components/EmptyTable/EmptyTable";

interface ReceiptsListProps {
    title: string;
    search: string;
    setSearch: (search: string) => void;
    page: number;
    setPage: (page: number) => void;
    pageSize: number;
    setPageSize: (pageSize: number) => void;
    filters: IFilterListReceipts;
    setFilters: (filter: IFilterListReceipts) => void;
    order: ISort[],
    setOrder: Dispatch<SetStateAction<ISort[]>>;
    items: IListReceipts[];
    onShowOrHideCardNumber : (authorizationId:number)=> void;
    onShowOrHideDocumentInformation : (authorizationId:number)=> void;
    onCheckMadeReceipt : (authorizationId:number)=> void;
    totalElements: number;
}

const ReceiptsList: React.FC<ReceiptsListProps> = ({
                                                       title,
                                                       search,
                                                       setSearch,
                                                       page,
                                                       setPage,
                                                       pageSize,
                                                       totalElements,
                                                       setPageSize,
                                                       filters,
                                                       setFilters,
                                                       order,
                                                       setOrder,
                                                       items,
                                                       onShowOrHideCardNumber,
                                                       onShowOrHideDocumentInformation,
                                                       onCheckMadeReceipt
                                                   }) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [internalFilters, setInternalFilters] = useState<IFilterListReceipts>(defaultFilterListReceipts);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleFilterChange = (field: keyof typeof filters) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setInternalFilters((prev) => ({...prev, [field]: event.target.value}));
    };

    const handleFilterChangeValue = (field: keyof typeof filters, value: string | number) => {
        setInternalFilters((prev) => ({...prev, [field]: value}));
    };

    const handleApplyFilter = () => {
        setFilters(internalFilters);
        handleCloseMenu();
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (property: keyof IListReceipts) => {
        const exist = order.find(o => o.field === property)
        const isAsc = order.find(o => o.field === property)?.order === 'asc';
        if (!exist) {
            setOrder(prevOrder => [
                ...prevOrder.filter(o => o.field !== property),
                {field: property, order: isAsc ? 'desc' : 'asc'},
            ]);
        } else {
            if (exist.order === 'asc') {
                setOrder(prevOrder => [
                    ...prevOrder.filter(o => o.field !== property),
                    {field: property, order: 'desc'},
                ]);
            } else {
                setOrder(prevOrder => [
                    ...prevOrder.filter(o => o.field !== property),
                ]);
            }
        }

    };

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleCancelFilters = () => {
        setInternalFilters(filters);
        handleCloseMenu();
    };

    const handleChangeWasReceiptMade = (event: SelectChangeEvent<unknown>) => {
        if (event.target.value !== undefined) {
            setInternalFilters((prev) => ({...prev, wasReceiptMade: event.target.value == 'true'}));
        } else {
            setInternalFilters((prev) => ({...prev, wasReceiptMade: undefined}));
        }

    };

    return (
        <Box sx={{width: '100%', padding: 3}}>
            <Typography variant="h4" component="div" sx={{mb: 2}}>
                {title}
            </Typography>
            <Toolbar sx={{display: 'flex', justifyContent: 'flex-end', mb: 2}} style={{paddingLeft: 0}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <TextField
                        variant="outlined"
                        placeholder="Buscar"
                        size="small"
                        value={search}
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon/>
                                </InputAdornment>
                            ),
                        }}
                        sx={{mr: 2, width: 300}}
                    />
                    <Button variant="outlined" startIcon={<FilterListIcon/>} onClick={handleOpenMenu}>
                        Filtrar
                    </Button>
                    <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        disableRestoreFocus
                    >
                        <Box sx={{p: 2, display: 'flex', flexDirection: 'column', gap: 1, minWidth:350}}>
                            <TextField
                                label="ID igual a"
                                size='small'
                                value={internalFilters.authorizationId}
                                onChange={handleFilterChange('authorizationId')}
                                fullWidth
                            />

                            <TextField
                                variant="outlined"
                                fullWidth
                                label="ID de tarjeta en cartera igual a"
                                value={internalFilters.cardPortfolioId}
                                placeholder="ID de tarjeta en cartera igual a"
                                size="small"
                                type="text"
                                onChange={(e) => {
                                    const value = e.target.value.replace(/\D/g, ''); // Elimina todo lo que no sea un número
                                    handleFilterChangeValue('cardPortfolioId', value);
                                }}
                            />
                            <TextField
                                label="Titular contiene"
                                size='small'
                                value={internalFilters.cardHolder}
                                onChange={handleFilterChange('cardHolder')}
                                fullWidth
                            />


                            <TextField
                                label="Últimos 4 digitos igual a "
                                size='small'
                                type={"number"}
                                value={internalFilters.lastFourCardDigit}
                                onChange={handleFilterChange('lastFourCardDigit')}
                                fullWidth
                            />

                            <TextField
                                label="Sello contiene"
                                size='small'
                                value={internalFilters.cardStamp}
                                onChange={handleFilterChange('cardStamp')}
                                fullWidth
                            />

                            <TextField
                                variant="outlined"
                                fullWidth
                                value={internalFilters.amountAuthorized}
                                onChange={(e) => {
                                    let value = e.target.value;

                                    // Permitir solo números, un punto decimal, y hasta dos dígitos decimales
                                    value = value.replace(/[^0-9.]/g, ''); // Elimina todo lo que no sea un número o punto
                                    const parts = value.split('.');

                                    // Asegurarse de que solo haya un punto decimal y recortar a 2 decimales
                                    if (parts.length > 2) {
                                        value = `${parts[0]}.${parts[1]}`; // Eliminar puntos extra
                                    } else if (parts[1]?.length > 2) {
                                        value = `${parts[0]}.${parts[1].slice(0, 2)}`; // Limitar a 2 decimales
                                    }

                                    handleFilterChangeValue('amountAuthorized', value);
                                }}
                                label="Monto autorizado igual a"
                                size="small"
                                type="text"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">USD</InputAdornment>, // Añade "USD" al final del input
                                }}

                            />
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={internalFilters.amountAuthorizedFrom}
                                onChange={(e) => {
                                    let value = e.target.value;

                                    // Permitir solo números, un punto decimal, y hasta dos dígitos decimales
                                    value = value.replace(/[^0-9.]/g, ''); // Elimina todo lo que no sea un número o punto
                                    const parts = value.split('.');

                                    // Asegurarse de que solo haya un punto decimal y recortar a 2 decimales
                                    if (parts.length > 2) {
                                        value = `${parts[0]}.${parts[1]}`; // Eliminar puntos extra
                                    } else if (parts[1]?.length > 2) {
                                        value = `${parts[0]}.${parts[1].slice(0, 2)}`; // Limitar a 2 decimales
                                    }

                                    handleFilterChangeValue('amountAuthorizedFrom', value);
                                }}
                                label="Monto autorizado mayor o igual a"
                                size="small"
                                type="text"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">USD</InputAdornment>, // Añade "USD" al final del input
                                }}

                            />

                            <TextField
                                variant="outlined"
                                fullWidth
                                value={internalFilters.amountAuthorizedTo}
                                onChange={(e) => {
                                    let value = e.target.value;

                                    // Permitir solo números, un punto decimal, y hasta dos dígitos decimales
                                    value = value.replace(/[^0-9.]/g, ''); // Elimina todo lo que no sea un número o punto
                                    const parts = value.split('.');

                                    // Asegurarse de que solo haya un punto decimal y recortar a 2 decimales
                                    if (parts.length > 2) {
                                        value = `${parts[0]}.${parts[1]}`; // Eliminar puntos extra
                                    } else if (parts[1]?.length > 2) {
                                        value = `${parts[0]}.${parts[1].slice(0, 2)}`; // Limitar a 2 decimales
                                    }

                                    handleFilterChangeValue('amountAuthorizedTo', value);
                                }}
                                label="Monto autorizado menor o igual a"
                                size="small"
                                type="text"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">USD</InputAdornment>, // Añade "USD" al final del input
                                }}

                            />

                            <TextField
                                label="Código de autorización igual a"
                                size='small'
                                value={internalFilters.authorizationCode}
                                onChange={handleFilterChange('authorizationCode')}
                                fullWidth
                            />

                            <TextField
                                label="Número de file de la venta igual a"
                                size='small'
                                value={internalFilters.saleFileNumber}
                                onChange={handleFilterChange('saleFileNumber')}
                                fullWidth
                            />

                            <FormControl fullWidth>
                                <InputLabel id="made-receipt-label">Recibo realizado</InputLabel>
                                <Select
                                    size='small'
                                    labelId="made-receipt-select-label"
                                    id="o-use-same-issue-select-label"
                                    value={internalFilters.wasReceiptMade}
                                    label="Recibo realizado"
                                    onChange={handleChangeWasReceiptMade}
                                >
                                    <MenuItem value={undefined} style={{minHeight: '24px'}}>
                                        <em></em>
                                    </MenuItem>
                                    <MenuItem value={'true'}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <ListItemText primary="Sí"/>
                                        </div>
                                    </MenuItem>
                                    <MenuItem value={'false'}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <ListItemText primary="No"/>
                                        </div>
                                    </MenuItem>
                                </Select>
                            </FormControl>

                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2}}>
                                <Button variant="contained" onClick={handleApplyFilter} sx={{mr: 2}}>
                                    Aplicar Filtros
                                </Button>
                                <Button variant="outlined" onClick={handleCancelFilters}>
                                    Cancelar
                                </Button>
                            </Box>
                        </Box>
                    </Popover>
                </Box>
            </Toolbar>
            <Typography variant="body2" sx={{mb: 1}}>
                Mostrando {items.length} resultados de {totalElements}
            </Typography>
            <TableContainer component="main" sx={{width: '100%'}}>
                <Table sx={{width: '100%'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                ID
                            </TableCell>
                            <TableCell>
                                ID de tarjeta en cartera
                            </TableCell>

                            <TableCell sx={{width: '10%'}}>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'cardHolder')}
                                    direction={order.find(o => o.field === 'cardHolder')?.order || 'asc'}
                                    onClick={() => handleRequestSort('cardHolder')}
                                >
                                    Titular
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                Tipo de documento
                            </TableCell>

                            <TableCell>
                                Documento
                            </TableCell>

                            <TableCell sx={{width: '10%'}}>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'lastFourCardDigit')}
                                    direction={order.find(o => o.field === 'lastFourCardDigit')?.order || 'asc'}
                                    onClick={() => handleRequestSort('lastFourCardDigit')}
                                >
                                    Número de tarjeta
                                </TableSortLabel>
                            </TableCell>

                            <TableCell sx={{width: '10%'}}>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'cardStamp')}
                                    direction={order.find(o => o.field === 'cardStamp')?.order || 'asc'}
                                    onClick={() => handleRequestSort('cardStamp')}
                                >
                                    Sello
                                </TableSortLabel>
                            </TableCell>

                            <TableCell sx={{width: '10%'}}>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'amountAuthorized')}
                                    direction={order.find(o => o.field === 'amountAuthorized')?.order || 'asc'}
                                    onClick={() => handleRequestSort('amountAuthorized')}
                                >
                                    Monto autorizado
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'saleFileNumber')}
                                    direction={order.find(o => o.field === 'saleFileNumber')?.order || 'asc'}
                                    onClick={() => handleRequestSort('saleFileNumber')}
                                >
                                    File de la venta
                                </TableSortLabel>
                            </TableCell>


                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'authorizationCode')}
                                    direction={order.find(o => o.field === 'authorizationCode')?.order || 'asc'}
                                    onClick={() => handleRequestSort('authorizationCode')}
                                >
                                    Código de autorización
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                            <TableSortLabel
                                active={order.some(o => o.field === 'createdAt')}
                                direction={order.find(o => o.field === 'createdAt')?.order || 'asc'}
                                onClick={() => handleRequestSort('createdAt')}
                            >
                                Fecha de autorización
                            </TableSortLabel>
                        </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'wasReceiptMade')}
                                    direction={order.find(o => o.field === 'wasReceiptMade')?.order || 'asc'}
                                    onClick={() => handleRequestSort('wasReceiptMade')}
                                >
                                   Recibo realizado
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                               Marcar como recibo realizado
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item) => (
                            <TableRow
                                key={item.authorizationId}
                            >
                                <TableCell>{item.authorizationId}</TableCell>
                                <TableCell>
                                    {item.cardPortfolioId}</TableCell>

                                <TableCell>
                                    <Box display="flex" alignItems="center" whiteSpace="nowrap" flexGrow={1} minWidth={0}>
                                        {item.cardHolder}
                                    </Box>

                                    </TableCell>
                                <TableCell>
                                    {item.showDocumentInformation
                                        ? item.documentType
                                        : 'XXXX'}
                                </TableCell>
                                <TableCell>
                                    <Box display="flex" alignItems="center" whiteSpace="nowrap" flexGrow={1} minWidth={0}>
                                        {item.showDocumentInformation
                                            ? item.documentNumber
                                            : 'XXXX'}

                                        <IconButton onClick={() => onShowOrHideDocumentInformation(item.authorizationId)} edge="end">
                                            {item.showDocumentInformation ? <VisibilityOff titleAccess={'Ocultar documento'} /> : <Visibility titleAccess={'Mostrar documento'}/>}
                                        </IconButton>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box display="flex" alignItems="center" whiteSpace="nowrap" flexGrow={1} minWidth={0}>
                                        {item.showCardNumber
                                            ? item.cardNumberHidden
                                            : ' XXXX XXXX XXXX ' + item.lastFourCardDigit}

                                        <IconButton onClick={() => onShowOrHideCardNumber(item.authorizationId)} edge="end">
                                            {item.showCardNumber ? <VisibilityOff titleAccess={'Ocultar número de la tarjeta'} /> : <Visibility titleAccess={'Mostrar número de la tarjeta'}/>}
                                        </IconButton>
                                    </Box>
                                </TableCell>
                                <TableCell>{item.cardStamp}</TableCell>
                                <TableCell>{item.amountAuthorized} USD</TableCell>
                                <TableCell>{item.saleFileNumber}</TableCell>
                                <TableCell>{item.authorizationCode}</TableCell>
                                <TableCell>{item.createdAt}</TableCell>
                                <TableCell>{item.wasReceiptMade?'Si':'No'}</TableCell>
                                <TableCell>{item.wasReceiptMade?<Checkbox  disabled checked />:
                                    <Checkbox title={'Marcar como recibo realizado'}  checked={false} onClick={()=>onCheckMadeReceipt(item.authorizationId)}/>}</TableCell>
                            </TableRow>
                        ))}
                        {items.length === 0 && <EmptyTable colSpan={13} />}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                labelRowsPerPage={'Filas por página'}
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={totalElements}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
};

export default ReceiptsList;
