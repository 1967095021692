import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    Popover,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Toolbar,
    Typography,
} from '@mui/material';
import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    FilterList as FilterListIcon,
    Search as SearchIcon
} from '@mui/icons-material';
import {ISort} from "../../../../core/types";
import {IFilterAuthorizationOperator, IListAuthorizationOperator} from "../../types";
import EmptyTable from "../../../../core/components/EmptyTable/EmptyTable";

interface AuthorizationOperatorsListProps {
    title: string;
    search: string;
    setSearch: (search: string) => void;
    page: number;
    setPage: (page: number) => void;
    pageSize: number;
    setPageSize: (pageSize: number) => void;
    order: ISort[],
    setOrder: Dispatch<SetStateAction<ISort[]>>;
    items: IListAuthorizationOperator[];
    totalElements: number;
    filters: IFilterAuthorizationOperator;
    setFilters: (filter: IFilterAuthorizationOperator) => void;
    onAdd: () => void;
    onEdit: (item: IListAuthorizationOperator) => void;
    onDelete: (item: IListAuthorizationOperator) => void;
}

const AuthorizationOperatorsList: React.FC<AuthorizationOperatorsListProps> = ({
                                                                                   title,
                                                                                   search,
                                                                                   setSearch,
                                                                                   page,
                                                                                   setPage,
                                                                                   pageSize,
                                                                                   totalElements,
                                                                                   setPageSize,
                                                                                   order,
                                                                                   setOrder,
                                                                                   items,
                                                                                   onAdd,
                                                                                   onEdit,
                                                                                   onDelete,
                                                                                   filters,
                                                                                   setFilters
                                                                               }) => {

    const [open, setOpen] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<null | IListAuthorizationOperator>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [internalFilters, setInternalFilters] = useState<IFilterAuthorizationOperator>(filters);
    const handleOpenDialog = (item: IListAuthorizationOperator | null = null) => {
        setSelectedItem(item);
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
        setSelectedItem(null);
    };


    const handleDelete = () => {
        if (selectedItem) {
            onDelete(selectedItem);
            handleCloseDialog();
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (property: keyof IListAuthorizationOperator) => {
        const exist = order.find(o => o.field === property)
        const isAsc = order.find(o => o.field === property)?.order === 'asc';
        if (!exist) {
            setOrder(prevOrder => [
                ...prevOrder.filter(o => o.field !== property),
                {field: property, order: isAsc ? 'desc' : 'asc'},
            ]);
        } else {
            if (exist.order === 'asc') {
                setOrder(prevOrder => [
                    ...prevOrder.filter(o => o.field !== property),
                    {field: property, order: 'desc'},
                ]);
            } else {
                setOrder(prevOrder => [
                    ...prevOrder.filter(o => o.field !== property),
                ]);
            }
        }

    };

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleFilterChange = (field: keyof typeof filters) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setInternalFilters((prev) => ({...prev, [field]: event.target.value}));
    };

    useEffect(() => {
        setInternalFilters(filters);
    }, [filters]);

    const handleCloseMenu = () => setAnchorEl(null);

    const handleApplyFilter = () => {
        setFilters(internalFilters);
        handleCloseMenu();
    }

    const handleCancelFilters = () => {
        setInternalFilters(filters);
        handleCloseMenu();
    };

    const handleChangeIsForUseInCardPortfolioAuthorization = (event: SelectChangeEvent<unknown>) => {
        if (event.target.value !== undefined) {
            setInternalFilters((prev) => ({
                ...prev,
                isForUseInCardPortfolioAuthorization: event.target.value == 'true'
            }));
        } else {
            setInternalFilters((prev) => ({...prev, isForUseInCardPortfolioAuthorization: undefined}));
        }

    };

    const handleChangeIsForUseInCorporateCardAuthorization = (event: SelectChangeEvent<unknown>) => {
        if (event.target.value !== undefined) {
            setInternalFilters((prev) => ({
                ...prev,
                isForUseInCorporateCardAuthorization: event.target.value == 'true'
            }));
        } else {
            setInternalFilters((prev) => ({...prev, isForUseInCorporateCardAuthorization: undefined}));
        }

    };
    return (
        <Box sx={{width: '100%', padding: 3}}>
            <Typography variant="h4" component="div" sx={{mb: 2}}>
                {title}
            </Typography>
            <Toolbar sx={{display: 'flex', justifyContent: 'space-between', mb: 2}} style={{paddingLeft: 0}}>
                <Button
                    variant="contained"
                    startIcon={<AddIcon/>}
                    onClick={() => onAdd()}
                >
                    Nuevo operador
                </Button>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <TextField
                        variant="outlined"
                        placeholder="Buscar"
                        size="small"
                        value={search}
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon/>
                                </InputAdornment>
                            ),
                        }}
                        sx={{mr: 2, width: 300}}
                    />

                    <Button variant="outlined" startIcon={<FilterListIcon/>} onClick={handleOpenMenu}>
                        Filtrar
                    </Button>
                    <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        disableRestoreFocus
                    >
                        <Box sx={{p: 2, display: 'flex', flexDirection: 'column', gap: 1,minWidth:450}}>
                            <TextField
                                label="Nombre contiene"
                                size='small'
                                value={internalFilters.name}
                                onChange={handleFilterChange('name')}
                                fullWidth
                            />

                            <FormControl fullWidth>
                                <InputLabel id="to-use-card-portfolio-label">Para usar en autorizaciones de tarjetas en
                                    cartera</InputLabel>
                                <Select
                                    size='small'
                                    labelId="to-use-card-portfolio-select-label"
                                    id="to-use-card-portfolio-select-label"
                                    value={internalFilters.isForUseInCardPortfolioAuthorization}
                                    label="Para usar en autorizaciones de tarjetas en cartera"
                                    onChange={handleChangeIsForUseInCardPortfolioAuthorization}
                                >
                                    <MenuItem value={undefined} style={{minHeight: '24px'}}>
                                        <em></em>
                                    </MenuItem>
                                    <MenuItem value={'true'}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <ListItemText primary="Sí"/>
                                        </div>
                                    </MenuItem>
                                    <MenuItem value={'false'}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <ListItemText primary="No"/>
                                        </div>
                                    </MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl fullWidth>
                                <InputLabel id="to-use-corporate-card-label">Para usar en autorizaciones de tarjetas
                                    corporativas</InputLabel>
                                <Select
                                    size='small'
                                    labelId="to-use-corporate-card-select-label"
                                    id="to-use-corporate-card-select-label"
                                    value={internalFilters.isForUseInCorporateCardAuthorization}
                                    label="Para usar en autorizaciones de tarjetas corporativas"
                                    onChange={handleChangeIsForUseInCorporateCardAuthorization}
                                >
                                    <MenuItem value={undefined} style={{minHeight: '24px'}}>
                                        <em></em>
                                    </MenuItem>
                                    <MenuItem value={'true'}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <ListItemText primary="Sí"/>
                                        </div>
                                    </MenuItem>
                                    <MenuItem value={'false'}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <ListItemText primary="No"/>
                                        </div>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2}}>
                                <Button variant="contained" onClick={handleApplyFilter} sx={{mr: 2}}>
                                    Aplicar Filtros
                                </Button>
                                <Button variant="outlined" onClick={handleCancelFilters}>
                                    Cancelar
                                </Button>
                            </Box>
                        </Box>
                    </Popover>
                </Box>
            </Toolbar>
            <Typography variant="body2" sx={{mb: 1}}>
                Mostrando {items.length} resultados de {totalElements}
            </Typography>
            <TableContainer component="main" sx={{width: '100%'}}>
                <Table sx={{width: '100%'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'name')}
                                    direction={order.find(o => o.field === 'name')?.order || 'asc'}
                                    onClick={() => handleRequestSort('name')}
                                >
                                    Nombre
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'isForUseInCardPortfolioAuthorization')}
                                    direction={order.find(o => o.field === 'isForUseInCardPortfolioAuthorization')?.order || 'asc'}
                                    onClick={() => handleRequestSort('isForUseInCardPortfolioAuthorization')}
                                >
                                    Para usar en autorizaciones de tarjetas en cartera
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'isForUseInCorporateCardAuthorization')}
                                    direction={order.find(o => o.field === 'isForUseInCorporateCardAuthorization')?.order || 'asc'}
                                    onClick={() => handleRequestSort('isForUseInCorporateCardAuthorization')}
                                >
                                    Para usar en autorizaciones de tarjetas corporativas
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.isForUseInCardPortfolioAuthorization ? 'Si' : 'No'}</TableCell>
                                <TableCell>{item.isForUseInCorporateCardAuthorization ? 'Si' : 'No'}</TableCell>
                                <TableCell>
                                    {item.allowChange && <><IconButton onClick={() => onEdit(item)}>
                                        <EditIcon/>
                                    </IconButton>
                                        <IconButton onClick={() => handleOpenDialog(item)}>
                                            <DeleteIcon/>
                                        </IconButton></>}
                                </TableCell>
                            </TableRow>
                        ))}
                        {items.length == 0 && <EmptyTable colSpan={4} />}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                labelRowsPerPage={'Filas por página'}
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={totalElements}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Dialog open={open} onClose={handleCloseDialog}>
                <DialogTitle>Eliminar Operador</DialogTitle>
                <DialogContent>

                    <DialogContentText>
                        ¿Estás seguro de que deseas eliminar este operador?
                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancelar</Button>
                    <Button onClick={handleDelete} color="primary">Eliminar</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
        ;
};

export default AuthorizationOperatorsList;
