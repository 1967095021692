import React, {useEffect, useState} from 'react';
import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    TextField
} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {IListCorporateCardAuthorization} from "../../types";
import {IListAuthorizationOperator} from "../../../../../authorization-operators/types";
import {IListCorporateCardAuthorizationConcept} from "../../../../../corporate-card-authorization-concept/types";

export interface CorporateCardAuthorizationUpsertFormProps {
    handleSubmitForm: (value: IUpsertCorporateCardAuthorizationFormValue) => void;
    loading: boolean;
    concepts: IListCorporateCardAuthorizationConcept[],
    operators: IListAuthorizationOperator[],
    authorization: IListCorporateCardAuthorization | null,
    handleClose: () => void,
}

export interface IUpsertCorporateCardAuthorizationFormValue {
    conceptId?: string;
    amount: string;
    fee: string;
    operatorId: string;
    fileNumber: string;
    authorizationCode?: string;
}

const defaultValue: IUpsertCorporateCardAuthorizationFormValue = {
    conceptId: '',
    amount: '',
    fee: '1',
    operatorId: '',
    fileNumber: '',
    authorizationCode: ''
};
export const CorporateCardAuthorizationUpsertForm: React.FC<CorporateCardAuthorizationUpsertFormProps> = ({
                                                                                                              handleSubmitForm,
                                                                                                              loading,
                                                                                                              handleClose,
                                                                                                              concepts,
                                                                                                              operators,
                                                                                                              authorization,
                                                                                                          }) => {
    const [withAuthCode, setWithAuthCode] = useState<boolean>(true);
    const convertToValueToForm = (x: IListCorporateCardAuthorization) => {
        return {
            conceptId: x.conceptId ? String(x.conceptId) : '',
            fee: String(x.fee),
            amount: String(x.amount),
            operatorId: String(x.operatorId),
            fileNumber: x.fileNumber,
            authorizationCode: x.authorizationCode
        }
    }

    const methods = useForm<IUpsertCorporateCardAuthorizationFormValue>({
        mode: 'all',
        defaultValues: authorization ? convertToValueToForm(authorization) : defaultValue
    });

    const {
        control,
        formState: {errors, isValid},
        getValues,
        setValue,
        trigger
    } = methods;

    useEffect(() => {
        if (authorization) {
            setWithAuthCode(authorization.authorizationCode != null);
        }
    }, [authorization]);

    useEffect(() => {
        if (withAuthCode) {
            setValue('conceptId', '');
            trigger('authorizationCode');
        } else {
            setValue('authorizationCode', '');
            trigger('conceptId');
        }
    }, [withAuthCode]);

    const handleSubmitWithClearFormValues = () => {
        const values = getValues();
        const valuesToSend = {
            ...values,
            authorizationCode: withAuthCode ? values.authorizationCode : undefined,
            conceptId: withAuthCode ? undefined : values.conceptId
        }
        handleSubmitForm(valuesToSend)
    }


    return (
        <>
            <Card sx={{maxWidth: 700, width: '100%'}}>
                <CardContent>
                    <Box component="form" display="flex" flexDirection="column" gap={1} pt={0}>
                        <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                            <Controller
                                name="amount"
                                control={control}
                                rules={{
                                    min: {value: 1, message: 'El valor mínimo es 1'},
                                    required: {value: true, message: 'Este campo es requerido'},
                                    pattern: {
                                        value: /^[0-9]*[.,]?[0-9]+$/,
                                        message: 'El campo monto debe ser un número válido'
                                    }
                                }}
                                render={({field}) => (
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="Monto autorizado"
                                        size={'small'}
                                        type="text"
                                        disabled={authorization != null}
                                        helperText={errors.amount ? errors.amount.message : ''}
                                        error={!!errors.amount}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>

                        <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                            <Controller
                                name="fileNumber"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Este campo es requerido'
                                    },
                                    pattern: {value: /^[0-9]*$/, message: "Solo se permiten números"} // Validación para solo números
                                }}
                                render={({field}) => (
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        label="Número de file"
                                        required={true}
                                        size="small"
                                        type="text"
                                        helperText={errors.fileNumber ? errors.fileNumber.message : ''}
                                        error={!!errors.fileNumber}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>

                        <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>

                            <Controller
                                name="fee"
                                control={control}
                                rules={{
                                    min: {value: 1, message: 'El valor mínimo es 1'},
                                    required: "Este campo es requerido", // Validación de campo requerido
                                    pattern: {value: /^[0-9]*$/, message: "Solo se permiten números"} // Validación para solo números
                                }}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        fullWidth
                                        label="Cuotas"
                                        placeholder="Inserte el número de cuotas"
                                        required
                                        disabled={authorization != null}
                                        size="small"
                                        type="text"
                                        onChange={(e) => {
                                            const value = e.target.value.replace(/\D/g, ''); // Elimina todo lo que no sea un número
                                            field.onChange(value);
                                        }}
                                        error={!!errors.fee}
                                        helperText={errors.fee ? errors.fee.message : ''}
                                    />
                                )}
                            />
                        </Box>


                        <Box display="flex" alignItems="center" gap={2} flex={3} p={1}>
                            <Controller
                                name="operatorId"
                                control={control}
                                rules={{
                                    required: {value: true, message: 'Este campo es requerido'}
                                }}
                                render={({field}) => (
                                    <FormControl sx={{width: '100%'}}>
                                        <Autocomplete
                                            options={operators}
                                            getOptionLabel={(option: IListAuthorizationOperator) => `${option.name}`}
                                            value={
                                                operators.find((option) => option.id === field.value) || null
                                            }
                                            onChange={(event, value) => {
                                                const selectedValues = (value as IListAuthorizationOperator)?.id || '';
                                                field.onChange(selectedValues);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size={'small'}
                                                    variant="outlined"
                                                    required
                                                    label="Seleccione el operador"
                                                    fullWidth
                                                    helperText={errors.operatorId ? errors.operatorId.message : ''}
                                                    error={!!errors.operatorId}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                )}
                            />
                        </Box>

                        <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={withAuthCode}
                                        disabled={authorization != null}
                                        onChange={(event) => setWithAuthCode(event.target.checked)}
                                        color="primary"
                                    />
                                }
                                label={
                                    <>
                                        Con código de autorización

                                    </>
                                }
                            />
                        </Box>
                        {withAuthCode && <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                            <Controller
                                name="authorizationCode"
                                control={control}
                                rules={{
                                    required: {value: withAuthCode, message: 'Este campo es requerido'},
                                    maxLength: {
                                        value: 15,
                                        message: 'El campo excede el largo máximo permitido de: ' + 15 + ' caracteres'
                                    }
                                }}
                                render={({field}) => (
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="Código de autorización"
                                        size={'small'}
                                        type="text"
                                        disabled={authorization != null}
                                        helperText={errors.authorizationCode ? errors.authorizationCode.message : ''}
                                        error={!!errors.authorizationCode}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>}


                        {!withAuthCode && <Box display="flex" alignItems="center" gap={2} flex={3} p={1}>
                            <Controller
                                name="conceptId"
                                control={control}
                                rules={{
                                    required: {value: !withAuthCode, message: 'Este campo es requerido'}
                                }}
                                render={({field}) => (
                                    <FormControl sx={{width: '100%'}} disabled={authorization !== null}>
                                        <Autocomplete
                                            options={concepts}
                                            getOptionLabel={(option: IListCorporateCardAuthorizationConcept) => option.name}
                                            value={concepts.find((option) => option.id + '' === field.value) || null}
                                            onChange={(event, value) => {
                                                const selectedValues = (value as IListCorporateCardAuthorizationConcept)?.id || '';
                                                field.onChange(selectedValues);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    variant="outlined"
                                                    required
                                                    label="Seleccione el concepto"
                                                    fullWidth
                                                    helperText={errors.conceptId ? errors.conceptId.message : ''}
                                                    error={!!errors.conceptId}
                                                />
                                            )}
                                            disabled={authorization !== null}
                                        />
                                    </FormControl>
                                )}
                            />
                        </Box>}


                        <Box display="flex" width="100%" justifyContent="flex-end" gap={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleClose}
                            >
                                Volver al listado
                            </Button>
                            <Button
                                variant="contained"
                                disabled={!isValid || loading}
                                color="primary"
                                onClick={handleSubmitWithClearFormValues}
                            >
                                Guardar
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
        ;
};