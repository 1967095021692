import {AxiosResponse} from "axios";
import {
    ICreateCardPortfolioAuthorization, ICreateCardPortfolioPosAuthorization,
    IListCardPortfolioAuthorization,
    IUpdateCardPortfolioAuthorization, IUpdateCardPortfolioPosAuthorization
} from "./types";
import {ApiService} from "../../../../core/services";

const endpoints = {
    list: 'api/cards-portfolio/:cardPortfolioId/authorizations',
    create: 'api/cards-portfolio/:cardPortfolioId/authorizations',
    createPos: 'api/cards-portfolio/:cardPortfolioId/authorizations-in-pos',
    update: 'api/cards-portfolio/:cardPortfolioId/authorizations/:id',
    updatePos: 'api/cards-portfolio/:cardPortfolioId/authorizations-in-pos/:id',
}

export const getAuthorizations = (cardPortfolioId: number): Promise<AxiosResponse<IListCardPortfolioAuthorization[]>> => {
    return ApiService.get(endpoints.list.replace(':cardPortfolioId', cardPortfolioId + ''));
};
export const createAuthorization = (cardPortfolioId: number, data: ICreateCardPortfolioAuthorization): Promise<AxiosResponse<void>> => ApiService.post(endpoints.create.replace(':cardPortfolioId', cardPortfolioId + ''), data);
export const createPosAuthorization = (cardPortfolioId: number, data: ICreateCardPortfolioPosAuthorization): Promise<AxiosResponse<void>> => ApiService.post(endpoints.createPos.replace(':cardPortfolioId', cardPortfolioId + ''), data);
export const updateAuthorization = (cardPortfolioId: number, id: number, data: IUpdateCardPortfolioAuthorization): Promise<AxiosResponse<void>> => ApiService.put(endpoints.update.replace(':cardPortfolioId', cardPortfolioId + '').replace(':id', id + ''), data);
export const updatePosAuthorization = (cardPortfolioId: number, id: number, data: IUpdateCardPortfolioPosAuthorization): Promise<AxiosResponse<void>> => ApiService.put(endpoints.updatePos.replace(':cardPortfolioId', cardPortfolioId + '').replace(':id', id + ''), data);
