import React from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    TextField,
    Typography
} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {IListAuthorizationOperator, IUpsertAuthorizationOperator} from "../../types";
import {useNavigate} from "react-router-dom";
import {appRoutes} from "../../../../core/const";

export interface AuthorizationOperatorUpsertFormProps {
    handleSubmitForm: (value: IUpsertAuthorizationOperator) => void;
    loading: boolean;
    value?: IListAuthorizationOperator
}

export const AuthorizationOperatorUpsertForm: React.FC<AuthorizationOperatorUpsertFormProps> = ({
                                                                                                    handleSubmitForm,
                                                                                                    loading,
                                                                                                    value
                                                                                                }) => {

    const methods = useForm<IUpsertAuthorizationOperator>({
        mode: 'all',
        defaultValues: {
            name: value?.name || '',
            isForUseInCardPortfolioAuthorization: value?.isForUseInCardPortfolioAuthorization || true,
            isForUseInCorporateCardAuthorization: value?.isForUseInCorporateCardAuthorization || false,
        }
    });

    const navigate = useNavigate();
    const {
        control,
        formState: {errors, isValid},
        getValues,
    } = methods;


    return (
        <>
            <Card sx={{maxWidth: 600, width: '100%'}}>
                <CardContent>
                    <Typography variant="h5" style={{fontWeight: 'bold'}}
                                p={1}>{value ? 'Actualizar operador' : 'Crear nuevo operador'}</Typography>
                    <br/>
                    <Box component="form" display="flex" flexDirection="column" gap={1}>

                        <Box display="flex" flexDirection="row" justifyContent="space-around">
                            <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{
                                        required: {value: true, message: 'Este campo es requerido'},
                                        maxLength: {
                                            value: 255,
                                            message: 'El campo excede el largo máximo permitido de: 255 caracteres'
                                        }
                                    }}
                                    render={({field}) => (
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            label="Nombre"
                                            size={'small'}
                                            type="text"
                                            helperText={errors.name ? errors.name.message : ''}
                                            error={!!errors.name}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>
                        </Box>
                            <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                                <Controller
                                    name="isForUseInCardPortfolioAuthorization"
                                    control={control}
                                    render={({field: {onChange, value}, fieldState: {error}}) => (
                                        <>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={value}
                                                        onChange={onChange}
                                                        color="primary"
                                                    />
                                                }
                                                label={
                                                    <>
                                                        Para usar en autorizaciones de tarjetas en cartera

                                                    </>
                                                }
                                            />
                                            {error && (
                                                <FormHelperText error>{error.message}</FormHelperText>
                                            )}
                                        </>
                                    )}
                                />
                            </Box>


                        <Box display="flex" gap={2} flex={1} p={1}>
                            <Controller
                                name="isForUseInCorporateCardAuthorization"
                                control={control}
                                render={({field: {onChange, value}, fieldState: {error}}) => (
                                    <>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={value}
                                                    onChange={onChange}
                                                    color="primary"
                                                />
                                            }
                                            label={
                                                <>
                                                    Para usar en autorizaciones de tarjetas corporativas

                                                </>
                                            }
                                        />
                                        {error && (
                                            <FormHelperText error>{error.message}</FormHelperText>
                                        )}
                                    </>
                                )}
                            />
                        </Box>


                        <Box display="flex" width="100%" justifyContent="flex-end" gap={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => navigate(appRoutes.authorizationOperator.list)}
                            >
                                Volver al listado
                            </Button>
                            <Button
                                variant="contained"
                                disabled={!isValid || loading}
                                color="primary"
                                onClick={() => handleSubmitForm(getValues())}
                            >
                                Guardar
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
        ;
};