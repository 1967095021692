import React, {useCallback, useEffect, useState} from 'react';
import {IListCorporateCardAuthorizationConcept, IUpsertCorporateCardAuthorizationConcept} from "../../types";
import {ShowAlertState} from "../../../../core/types";
import {Alert, Backdrop, Box, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {createCorporateCardAuthorizationConcept, getCorporateCardAuthorizationConcept, updateCorporateCardAuthorizationConcept} from "../../services";
import {extractErrorMessage, is404Error} from "../../../../core/helper";
import {useNavigate, useParams} from "react-router-dom";
import {appRoutes} from "../../../../core/const";
import {
    CorporateCardAuthorizationConceptUpsertForm
} from "../../components/CorporateCardAuthorizationConceptUpsertForm/CorporateCardAuthorizationConceptUpsertForm";

const CorporateCardAuthorizationConceptUpsertContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [corporateCardAuthorizationConceptDetail, setCorporateCardAuthorizationConceptDetail] = useState<IListCorporateCardAuthorizationConcept | null>(null);
    const {id} = useParams<{ id: string }>();
    const navigate = useNavigate();
    const validNumberId = !isNaN(Number(id))

    const loadCorporateCardAuthorizationConceptDetail = useCallback(async () => {
        try {
            if (id && id !== 'create' && validNumberId) {
                setLoading(true);
                const result = (await getCorporateCardAuthorizationConcept(Number(id))).data;
                setCorporateCardAuthorizationConceptDetail(result);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el concepto')
            });
            setLoading(false);
            if (is404Error(error)) {
                setTimeout(() => navigate(appRoutes.corporateCardAuthorizationConcept.list), 2500);
            }
        }
    }, [id, setLoading, setCorporateCardAuthorizationConceptDetail, setAlert]);


    useEffect(() => {
        loadCorporateCardAuthorizationConceptDetail();
    }, [loadCorporateCardAuthorizationConceptDetail]);

    const handleCreateCorporateCardAuthorizationConcept = async (value: IUpsertCorporateCardAuthorizationConcept) => {
        try {
            setLoading(true);
            await createCorporateCardAuthorizationConcept(value);
            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Concepto creado satisfactoriamente'
            });
            setTimeout(() => navigate(appRoutes.corporateCardAuthorizationConcept.list), 1500)
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al crear el concepto')
            });
            setLoading(false);
        }
    }

    const handleUpdateCorporateCardAuthorizationConcept = async (value: IUpsertCorporateCardAuthorizationConcept) => {
        try {
            setLoading(true);
            await updateCorporateCardAuthorizationConcept(Number(id), value);
            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Concepto actualizado satisfactoriamente'
            });
            setTimeout(() => navigate(appRoutes.corporateCardAuthorizationConcept.list), 1500)
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al actualizar el concepto')
            });
            setLoading(false);
        }
    }


    return (<Box sx={{width: '80%', padding: 3}}><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            {id && id === 'create' &&
                <CorporateCardAuthorizationConceptUpsertForm loading={loading}
                                                 handleSubmitForm={handleCreateCorporateCardAuthorizationConcept}/>}
            {id && id !== 'create' && validNumberId && corporateCardAuthorizationConceptDetail &&
                <CorporateCardAuthorizationConceptUpsertForm value={corporateCardAuthorizationConceptDetail} loading={loading}
                                                 handleSubmitForm={handleUpdateCorporateCardAuthorizationConcept}/>}
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </Box>
    );
}

export default CorporateCardAuthorizationConceptUpsertContainer;
