import {IFilterListCorporateCard} from "./types";

export const defaultFilterListCorporateCards: IFilterListCorporateCard = {
    id: '',
    createdAt: '',
    createdAtFrom: '',
    createdAtTo: '',
    cardHolder: '',
    cardStamp: '',
    expiry: '',
    lastFourCardDigit: '',
    createdBy: '',
    updatedBy: '',
    updatedAt: '',
    updatedAtFrom: '',
    updatedAtTo: '',
};