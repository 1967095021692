import React, {Dispatch, SetStateAction, useState} from 'react';

import {
    Box,
    Button,
    InputAdornment,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Toolbar,
    Typography,
} from '@mui/material';
import {FilterList as FilterListIcon, Search as SearchIcon} from '@mui/icons-material';
import {ISort} from "../../../../core/types";
import {IFilterActionLog, IListActionLog} from "../../types";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, {Dayjs} from "dayjs";
import {DatePicker} from "@mui/x-date-pickers";
import EmptyTable from "../../../../core/components/EmptyTable/EmptyTable";

interface ActionLogListProps {
    title: string;
    search: string;
    setSearch: (search: string) => void;
    page: number;
    setPage: (page: number) => void;
    pageSize: number;
    setPageSize: (pageSize: number) => void;
    filters: IFilterActionLog;
    setFilters: (filter: IFilterActionLog) => void;
    order: ISort[],
    setOrder: Dispatch<SetStateAction<ISort[]>>;
    items: IListActionLog[];
    totalElements: number;
}

const ActionLogsList: React.FC<ActionLogListProps> = ({
                                                          title,
                                                          search,
                                                          setSearch,
                                                          page,
                                                          setPage,
                                                          pageSize,
                                                          totalElements,
                                                          setPageSize,
                                                          filters,
                                                          setFilters,
                                                          order,
                                                          setOrder,
                                                          items,
                                                      }) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [internalFilters, setInternalFilters] = useState<IFilterActionLog>({
        module: '',
        action: '',
        description: '',
        executedAtFrom: '',
        executedAtTo: '',
        executedByName: '',
        executedAt: ''
    });
    const [executedAt, setExecutedAt] = useState<Dayjs | null>(null);

    const [executedAtFrom, setCreatedAtFrom] = useState<Dayjs | null>(null);
    const [executedAtTo, setCreatedAtTo] = useState<Dayjs | null>(null);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleFilterChange = (field: keyof typeof filters) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setInternalFilters((prev) => ({...prev, [field]: event.target.value}));
    };

    const handleFilterChangeValue = (field: keyof typeof filters, value: string | number) => {
        setInternalFilters((prev) => ({...prev, [field]: value}));
    };

    const handleApplyFilter = () => {
        setFilters(internalFilters);
        handleCloseMenu();
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (property: keyof IListActionLog) => {
        const exist = order.find(o => o.field === property)
        const isAsc = order.find(o => o.field === property)?.order === 'asc';
        if (!exist) {
            setOrder(prevOrder => [
                ...prevOrder.filter(o => o.field !== property),
                {field: property, order: isAsc ? 'desc' : 'asc'},
            ]);
        } else {
            if (exist.order === 'asc') {
                setOrder(prevOrder => [
                    ...prevOrder.filter(o => o.field !== property),
                    {field: property, order: 'desc'},
                ]);
            } else {
                setOrder(prevOrder => [
                    ...prevOrder.filter(o => o.field !== property),
                ]);
            }
        }

    };

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleCancelFilters = () => {
        setInternalFilters(filters);
        handleCloseMenu();
    };

    return (
        <Box sx={{width: '100%', padding: 3}}>
            <Typography variant="h4" component="div" sx={{mb: 2}}>
                {title}
            </Typography>
            <Toolbar sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }} style={{ paddingLeft: 0 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        variant="outlined"
                        placeholder="Buscar"
                        size="small"
                        value={search}
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon/>
                                </InputAdornment>
                            ),
                        }}
                        sx={{mr: 2, width: 300}}
                    />
                    <Button variant="outlined" startIcon={<FilterListIcon/>} onClick={handleOpenMenu}>
                        Filtrar
                    </Button>
                    <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        disableRestoreFocus
                    >
                        <Box sx={{p: 2, display: 'flex', flexDirection: 'column', gap: 1}}>
                            <TextField
                                label="Módulo contiene"
                                size='small'
                                value={internalFilters.module}
                                onChange={handleFilterChange('module')}
                                fullWidth
                            />
                            <TextField
                                label="Acción contiene"
                                size='small'
                                value={internalFilters.action}
                                onChange={handleFilterChange('action')}
                                fullWidth
                            />

                            <TextField
                                label="Descripción contiene"
                                size='small'
                                value={internalFilters.description}
                                onChange={handleFilterChange('description')}
                                fullWidth
                            />


                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Box sx={{display: 'flex', gap: 2, flexDirection: 'column', maxWidth: 300}}>
                                    <DatePicker
                                        label="Fecha de ejecución igual a"
                                        format="YYYY-MM-DD"
                                        value={executedAt ? dayjs(executedAt) : null}
                                        onChange={(newValue) => {
                                            setExecutedAt(newValue);
                                            handleFilterChangeValue('executedAt', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />

                                </Box>

                                <Box sx={{display: 'flex', gap: 2, flexDirection: 'column', maxWidth: 300}}>
                                    <DatePicker
                                        label="Fecha de ejecución desde"
                                        format="YYYY-MM-DD"
                                        value={executedAtFrom ? dayjs(executedAtFrom) : null}
                                        onChange={(newValue) => {
                                            setCreatedAtFrom(newValue);
                                            handleFilterChangeValue('executedAtFrom', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />
                                    <DatePicker
                                        label="Fecha de ejecución hasta"
                                        format="YYYY-MM-DD"
                                        value={executedAtTo ? dayjs(executedAtTo) : null}
                                        onChange={(newValue) => {
                                            setCreatedAtTo(newValue);
                                            handleFilterChangeValue('executedAtTo', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        minDate={executedAtFrom ? dayjs(executedAtFrom) : undefined}  // No permite seleccionar una fecha menor a la de inicio
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />
                                </Box>
                            </LocalizationProvider>

                            <TextField
                                label="Ejecutado por contiene"
                                size='small'
                                value={internalFilters.executedByName}
                                onChange={handleFilterChange('executedByName')}
                                fullWidth
                            />

                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2}}>
                                <Button variant="contained" onClick={handleApplyFilter} sx={{mr: 2}}>
                                    Aplicar Filtros
                                </Button>
                                <Button variant="outlined" onClick={handleCancelFilters}>
                                    Cancelar
                                </Button>
                            </Box>
                        </Box>
                    </Popover>
                </Box>
            </Toolbar>
            <Typography variant="body2" sx={{mb: 1}}>
                Mostrando {items.length} resultados de {totalElements}
            </Typography>
            <TableContainer component="main" sx={{width: '100%'}}>
                <Table sx={{width: '100%'}}>
                    <TableHead>
                        <TableRow>

                            <TableCell sx={{width: '10%'}}>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'module')}
                                    direction={order.find(o => o.field === 'module')?.order || 'asc'}
                                    onClick={() => handleRequestSort('module')}
                                >
                                    Módulo
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'action')}
                                    direction={order.find(o => o.field === 'action')?.order || 'asc'}
                                    onClick={() => handleRequestSort('action')}
                                >
                                    Acción
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'description')}
                                    direction={order.find(o => o.field === 'description')?.order || 'asc'}
                                    onClick={() => handleRequestSort('description')}
                                >
                                    Descripción
                                </TableSortLabel>
                            </TableCell>


                            <TableCell sx={{width: '10%'}}>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'executedAt')}
                                    direction={order.find(o => o.field === 'executedAt')?.order || 'asc'}
                                    onClick={() => handleRequestSort('executedAt')}
                                >
                                    Fecha de ejecución
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'executedByName')}
                                    direction={order.find(o => o.field === 'executedByName')?.order || 'asc'}
                                    onClick={() => handleRequestSort('executedByName')}
                                >
                                    Ejecutado por
                                </TableSortLabel>
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{item.module}</TableCell>
                                <TableCell>{item.action}</TableCell>
                                <TableCell>{item.description}</TableCell>

                                <TableCell>{item.executedAt}</TableCell>
                                <TableCell>{item.executedByName ? item.executedByName : '-'}</TableCell>

                            </TableRow>
                        ))}
                        {items.length == 0 && <EmptyTable colSpan={5} />}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                labelRowsPerPage={'Filas por página'}
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={totalElements}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
};

export default ActionLogsList;
