import React, {useCallback, useEffect, useState} from 'react';
import {ICountry} from "../../types";
import {ShowAlertState} from "../../../../core/types";
import {Alert, Backdrop, Box, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {createCountry, getCountry, updateCountry} from "../../services";
import {extractErrorMessage, is404Error} from "../../../../core/helper";
import {useNavigate, useParams} from "react-router-dom";
import {CountryUpsertForm} from "../../components/CountryUpsertForm/CountryUpsertForm";

const CountriesUpsertContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [airportDetail, setCountryDetail] = useState<ICountry | null>(null);
    const {code} = useParams<{ code: string }>();
    const navigate = useNavigate();
    const isCreate = code === 'create'

    const loadCountryDetail = useCallback(async () => {
        try {
            if (code && code !== 'create') {
                setLoading(true);
                const result = (await getCountry(code)).data;
                setCountryDetail(result);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el país')
            });
            setLoading(false);
            if (is404Error(error)) {
                setTimeout(() => navigate('/countries'), 2500);
            }
        }
    }, [code, setLoading, setCountryDetail, setAlert]);


    useEffect(() => {
        loadCountryDetail();
    }, [loadCountryDetail]);

    const handleUpsertCountry = async (value: ICountry) => {
        const messageSuccess = isCreate ? 'creado' : 'actualizado';
        const messageError = isCreate ? 'crear' : 'actualizar';
        if (code) {
            try {
                setLoading(true);
                if (isCreate) {
                    await createCountry(value);
                } else {
                    await updateCountry(code, value);
                }

                setLoading(false);
                setAlert({
                    show: true,
                    severity: 'success',
                    message: 'País ' + messageSuccess + ' satisfactoriamente'
                });
                setTimeout(() => navigate('/countries'), 1500)
            } catch (error) {
                setAlert({
                    show: true,
                    severity: 'error',
                    message: extractErrorMessage(error, 'Ocurrió un error al ' + messageError + ' el país')
                });
                setLoading(false);
            }
        }

    }


    return (<Box sx={{width: '80%', padding: 3}}><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            {code && code === 'create' &&
                <CountryUpsertForm loading={loading} handleSubmitForm={handleUpsertCountry}/>}
            {code && code !== 'create' && airportDetail &&
                <CountryUpsertForm value={airportDetail} loading={loading}
                                   handleSubmitForm={handleUpsertCountry}/>}
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </Box>
    );
}

export default CountriesUpsertContainer;
