import React, {useCallback, useEffect, useState} from 'react';
import {IDetailICardPortfolio} from "../../types";
import {ShowAlertState} from "../../../../core/types";
import {Alert, Backdrop, Box, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {changeToFinalizedCardPortfolio, getCardPortfolioDetail} from "../../services";
import {extractErrorMessage, is404Error} from "../../../../core/helper";
import {useNavigate, useParams} from "react-router-dom";
import {appRoutes} from "../../../../core/const";
import CardPortfolioDetail from "../../components/CardPortfolioDetail/CardPortfolioDetail";

const CardPortfolioDetailContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [cardPortfolioDetail, setCardPortfolioDetail] = useState<IDetailICardPortfolio | null>(null);
    const {id} = useParams<{ id: string }>();
    const navigate = useNavigate();
    const validNumberId = !isNaN(Number(id))

    const loadCardPortfolioDetail = useCallback(async () => {
        try {
            if (id && validNumberId) {
                setLoading(true);
                const result = (await getCardPortfolioDetail(+id)).data;
                setCardPortfolioDetail(result);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el detalle de la tarjeta en cartera')
            });
            setLoading(false);
            if (is404Error(error)) {
                setTimeout(() => navigate(appRoutes.cardPortfolio.list), 2500);
            }
        }
    }, [id, setLoading, setCardPortfolioDetail, setAlert]);

    const onAcceptFinalized = async()=>{
        try {
            if (id && validNumberId) {
                setLoading(true);
                 await changeToFinalizedCardPortfolio(+id);
                setLoading(false);
                setAlert({
                    show: true,
                    severity: 'success',
                    message: 'Acción realizada satisfactoriamente'
                });
                setTimeout(loadCardPortfolioDetail, 2500);
            }
        } catch (error) {
            console.log(error);
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al poner a cero el salod de la tarjeta')
            });
            setLoading(false);
            if (is404Error(error)) {
                setTimeout(() => navigate(appRoutes.cardPortfolio.list), 2500);
            }
        }
    }


    useEffect(() => {
        loadCardPortfolioDetail();
    }, [loadCardPortfolioDetail]);

    return (<Box sx={{width: '100%', padding: 3}}><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            {id && validNumberId && cardPortfolioDetail && <CardPortfolioDetail onChangeAuthorizationsAmount={loadCardPortfolioDetail}
                                                                                onChangeRejections={loadCardPortfolioDetail}
                                                                                item={cardPortfolioDetail}
                                                                                setAlert={setAlert}
                                                                                onLoading={setLoading}
                                                                                onAcceptFinalized={onAcceptFinalized}
                                                                                loading={loading}/>}

            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </Box>
    );
}

export default CardPortfolioDetailContainer;
