import {AxiosResponse} from "axios";
import {ApiService} from "../../../../core/services";
import {IDetailInsuranceServiceTariff, IUpsertInsuranceServiceTariff} from "./types";

const endpoints = {
    list: 'api/insurance-services/:id/tariff',
    create: 'api/insurance-services/:id/tariff',
    update: 'api/insurance-services/:id/tariff/:tariffId',
    delete: 'api/insurance-services/:id/tariff/:tariffId',
}

export const getInsuranceServiceTariff = (insuranceId: number): Promise<AxiosResponse<IDetailInsuranceServiceTariff[]>> => {
    return ApiService.get(endpoints.list.replace(':id', insuranceId + ''));
};
export const createInsuranceServiceTariff = (insuranceId: number, data: IUpsertInsuranceServiceTariff): Promise<AxiosResponse<void>> =>
    ApiService.post(endpoints.create.replace(':id', insuranceId + ''), data);
export const updateInsuranceServiceTariff = (insuranceId: number, tariffId: number, data: IUpsertInsuranceServiceTariff): Promise<AxiosResponse<void>> =>
    ApiService.put(endpoints.update.replace(':id', insuranceId + '')
        .replace(':tariffId', tariffId + ''), data);

export const deleteInsuranceServiceTariff = (insuranceId: number, tariffId: number): Promise<AxiosResponse<void>> =>
    ApiService.delete(endpoints.delete
        .replace(':id', insuranceId + '')
        .replace(':tariffId', tariffId + ''));