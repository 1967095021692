import React, {useCallback, useEffect, useState} from 'react';
import {Alert, Backdrop, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {ISort, ShowAlertState} from "../../../../core/types";
import {IFilterListReceipts, IListReceipts} from "../../types";
import {defaultFilterListReceipts} from "../../constants";
import {getCardNumber, getDocumentInformation, getReceipts, madeReceipt} from "../../services";
import {extractErrorMessage, formatCardNumber, formatDocumentNumber} from "../../../../core/helper";
import ReceiptsList from "../../components/ReceiptsList/ReceiptsList";
import {documentTypeSToViewList} from "../../../payments/types";


const ReceiptsListContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>(search);
    const [page, setPage] = useState<number>(0);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [order, setOrder] = useState<ISort[]>([{'field': 'createdAt', order: 'desc'}]);
    const [filters, setFilters] = useState<IFilterListReceipts>(defaultFilterListReceipts);
    const [items, setItems] = useState<IListReceipts[]>([])

    const loadReceipts = useCallback(async () => {
        try {
            setLoading(true);
            const paginatedResult = (await getReceipts(page + 1, pageSize, order, debouncedSearchTerm, filters)).data;
            setTotalElements(paginatedResult.total);
            setItems(paginatedResult.items);
            setLoading(false);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado de recibos')
            });
            setLoading(false);
        }
    }, [page, pageSize, order, debouncedSearchTerm, filters]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(search);
        }, 500); // Retraso de 500 ms

        // Limpia el timeout si el efecto se vuelve a ejecutar antes de que el timeout finalice
        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    useEffect(() => {
        loadReceipts();
    }, [loadReceipts]);

    const handleShowOrHideCardNumber = async (authorizationId: number) => {
        const index = items.findIndex((x: IListReceipts) => x.authorizationId === authorizationId);
        if (index !== -1) {
            const item = items[index];

            const isHideAction = item.showCardNumber;
            if (isHideAction) {
                const newItems = [...items];
                newItems[index].showCardNumber = false;
                setItems(newItems);
            } else if (item.cardNumberHidden.indexOf('X') === -1) {
                const newItems = [...items];
                newItems[index].showCardNumber = true;
                setItems(newItems);
            } else {
                //si tiene x nunca se decifro lo decifro y lo asigno si no solo lo oculto
                try {
                    setLoading(true);
                    const result = await getCardNumber(item.cardPortfolioId);
                    const newItems = [...items];
                    newItems[index].cardNumberHidden = formatCardNumber(result.data.cardNumber);
                    newItems[index].showCardNumber = true;
                    setItems(newItems);
                    setLoading(false);

                } catch (error) {
                    setAlert({
                        show: true,
                        severity: 'error',
                        message: extractErrorMessage(error, 'Ocurrió un error al desencriptar el número de la tarjeta')
                    });
                    setLoading(false);
                }
            }
        }
    }


    const handleShowOrHideDocumentInformation = async (authorizationId: number) => {
        const index = items.findIndex((x: IListReceipts) => x.authorizationId === authorizationId);
        if (index !== -1) {
            const item = items[index];

            const isHideAction = item.showDocumentInformation;
            if (isHideAction) {
                const newItems = [...items];
                newItems[index].showDocumentInformation = false;
                setItems(newItems);
            } else if (item.documentNumber.indexOf('X') === -1) {
                const newItems = [...items];
                newItems[index].showDocumentInformation = true;
                setItems(newItems);
            } else {
                //si tiene x nunca se decifro lo decifro y lo asigno si no solo lo oculto
                try {
                    setLoading(true);
                    const result = await getDocumentInformation(item.cardPortfolioId);
                    const newItems = [...items];
                    newItems[index].documentNumber = formatDocumentNumber(result.data.documentType,result.data.documentNumber);
                    newItems[index].documentType = documentTypeSToViewList.find(x=>x.id ==result.data.documentType)?.name || '';
                    newItems[index].showDocumentInformation = true;
                    setItems(newItems);
                    setLoading(false);

                } catch (error) {
                    setAlert({
                        show: true,
                        severity: 'error',
                        message: extractErrorMessage(error, 'Ocurrió un error al desencriptar el número y tipo de documento')
                    });
                    setLoading(false);
                }
            }
        }
    }

    const handleCheckMadeReceipt = async (authorizationId: number) => {
        try {
            setLoading(true);
            await madeReceipt(authorizationId)
            setAlert({
                show: true,
                severity: 'success',
                message: 'Recibo marcado como realizado satisfactoriamente'
            });
            setTimeout(()=>{
                loadReceipts();
            },1500);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al marcar como recibo realizado')
            });
            setLoading(false);
        }
    }


    return (<><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            <ReceiptsList title={'Recibos'}
                          items={items}
                          filters={filters}
                          setFilters={setFilters}
                          order={order}
                          setOrder={setOrder}
                          search={search}
                          setSearch={setSearch}
                          page={page}
                          setPage={setPage}
                          pageSize={pageSize}
                          setPageSize={setPageSize}
                          totalElements={totalElements}
                          onShowOrHideCardNumber={handleShowOrHideCardNumber}
                          onShowOrHideDocumentInformation={handleShowOrHideDocumentInformation}
                          onCheckMadeReceipt={handleCheckMadeReceipt}
            />
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </>
    );
}

export default ReceiptsListContainer;
