import React from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import Paper, { PaperProps } from '@mui/material/Paper';


const DraggablePaperComponent: React.FC<PaperProps> = (props) => {
    /**
     * Evento que se dispara al iniciar el arrastre.
     * @param e Evento del ratón.
     * @param data Datos del arrastre.
     */
    const handleStart = (e: DraggableEvent, data: DraggableData) => {
        // Puedes agregar lógica adicional aquí si es necesario.
    };

    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
            onStart={handleStart}
        >
            <Paper {...props} />
        </Draggable>
    );
};

export default DraggablePaperComponent;
