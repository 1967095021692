import React, {useCallback, useEffect, useState} from 'react';
import {IFilterListCorporateCard, IListCorporateCard} from "../../types";
import {ISort, ShowAlertState} from "../../../../core/types";
import {Alert, Backdrop, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {extractErrorMessage} from "../../../../core/helper";
import {useNavigate} from "react-router-dom";
import {defaultFilterListCorporateCards} from "../../constants";
import CorporateCardsList from "../../components/CorporateCardsList/CorporateCardsList";
import {getCorporateCards} from "../../services";

const CorporateCardListContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>(search);
    const [page, setPage] = useState<number>(0);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [order, setOrder] = useState<ISort[]>([{'field': 'createdAt', order: 'desc'}]);
    const [filters, setFilters] = useState<IFilterListCorporateCard>(defaultFilterListCorporateCards);
    const [items, setItems] = useState<IListCorporateCard[]>([])
    const navigate = useNavigate();

    const onAdd = () => navigate('/corporate-cards/create');

    const onEdit = (item: IListCorporateCard) => navigate('/corporate-cards/' + item.id);
    const onDetail = (item: IListCorporateCard) => navigate('/corporate-cards/detail/' + item.id);


    const loadCorporateCard = useCallback(async () => {
        try {
            setLoading(true);
            const paginatedResult = (await getCorporateCards(page + 1, pageSize, order, debouncedSearchTerm,filters)).data;
            setTotalElements(paginatedResult.total);
            setItems(paginatedResult.items);
            setLoading(false);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado de tarjetas corporativas')
            });
            setLoading(false);
        }
    }, [page, pageSize, order, debouncedSearchTerm, filters]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(search);
        }, 500); // Retraso de 500 ms

        // Limpia el timeout si el efecto se vuelve a ejecutar antes de que el timeout finalice
        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    useEffect(() => {
        loadCorporateCard();
    }, [loadCorporateCard]);

    return (<><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            <CorporateCardsList title={'Tarjetas corporativas'}
                                items={items}
                                onAdd={onAdd}
                                filters={filters}
                                setFilters={setFilters}
                                onEdit={onEdit}
                                onDetail={onDetail}
                                order={order}
                                setOrder={setOrder}
                                search={search}
                                setSearch={setSearch}
                                page={page}
                                setPage={setPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                totalElements={totalElements}
            />
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </>
    );
}

export default CorporateCardListContainer;
