import React, {useEffect} from 'react';
import {Box, Button, Card, CardContent, Checkbox, FormControlLabel, TextField, Typography} from '@mui/material';
import {Controller, useForm, useWatch} from 'react-hook-form';
import {IDetailInsuranceService, IUpsertInsuranceService} from "../../types";
import {useNavigate} from "react-router-dom";
import {cleanStringEmpty} from "../../../../core/helper";

export interface InsuranceServiceUpsertFormProps {
    handleSubmitForm: (value: IUpsertInsuranceService) => void;
    loading: boolean;
    value?: IDetailInsuranceService,
}

export interface IUpsertInsuranceServiceFormValue{
    name:string;
    applyMinDays:boolean;
    minDays?:number|string;
    applyMaxDays:boolean;
    maxDays?:number|string;
    downloadUrl:string;
}



const defaultValue: IUpsertInsuranceServiceFormValue = {
    name: '',
    applyMinDays: false,
    minDays: undefined,
    maxDays: undefined,
    applyMaxDays: false,
    downloadUrl: ''
};


export const InsuranceServiceUpsertForm: React.FC<InsuranceServiceUpsertFormProps> = ({
                                                                                          handleSubmitForm,
                                                                                          loading,
                                                                                          value,
                                                                                      }) => {

    const methods = useForm<IUpsertInsuranceServiceFormValue>({
        mode: 'all',
        defaultValues: value? (value as IUpsertInsuranceServiceFormValue)  : defaultValue
    });

    const navigate = useNavigate();
    const {
        control,
        setValue,
        trigger,
        formState: {errors, isValid},
        getValues
    } = methods;

    const applyMinDays = useWatch({control, name: 'applyMinDays'});
    const applyMaxDays = useWatch({control, name: 'applyMaxDays'});

    useEffect(() => {
        if (!applyMinDays) {
            setValue('minDays', '');
        }
        trigger('minDays');
        trigger('maxDays');
    }, [applyMinDays]);

    useEffect(() => {
        if (!applyMaxDays) {
            setValue('maxDays', '');
        }
        trigger('maxDays');
        trigger('minDays');
    }, [applyMaxDays]);
    return (
        <>
            <Card sx={{maxWidth: 700, width: '100%'}}>
                <CardContent>
                    <Typography variant="h5" style={{fontWeight: 'bold'}}
                                p={1}>{value ? 'Actualizar' : 'Nuevo'} Servicio de seguro</Typography>
                    <br/>
                    <Box component="form" display="flex" flexDirection="column" gap={1}>

                        <Box display="flex" flexDirection="row" justifyContent="space-around">
                            <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{
                                        required: {value: true, message: 'Este campo es requerido'},
                                        maxLength: {
                                            value: 500,
                                            message: 'El campo excede el largo máximo permitido de: 500 caracteres'
                                        }
                                    }}
                                    render={({field}) => (
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            label="Nombre"
                                            size={'small'}
                                            type="text"
                                            helperText={errors.name ? errors.name.message : ''}
                                            error={!!errors.name}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>
                        </Box>

                        <Box display="flex" flexDirection={'row'} alignItems={'flex-start'} gap={0} >
                        <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                            <Controller
                                name="applyMinDays"
                                control={control}
                                render={({field}) => (
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value}/>}
                                        label="Aplica mínimo de días"
                                    />
                                )}
                            />
                        </Box>
                        {applyMinDays && (
                            <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                                <Controller
                                    name="minDays"
                                    control={control}
                                    rules={{
                                        min: {value: 0, message: 'El valor mínimo es 0'},
                                        required: {value: applyMinDays, message: 'Este campo es requerido'},
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: 'Mínimo de días ser un número válido'
                                        },
                                    }}
                                    render={({field}) => (
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Mínimo de días"
                                            size="small"
                                            type="number"
                                            helperText={errors.minDays ? errors.minDays.message : ''}
                                            error={!!errors.minDays}
                                            {...field}
                                            onChange={(e) => field.onChange(e.target.value==''?'':Number(e.target.value))}
                                        />
                                    )}
                                />
                            </Box>
                        )}
                        </Box>
                        <Box display="flex" flexDirection={'row'} alignItems={'flex-start'}  gap={0}>
                        <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                            <Controller
                                name="applyMaxDays"
                                control={control}
                                render={({field}) => (
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value}/>}
                                        label="Aplica máximo de días"
                                    />
                                )}
                            />
                        </Box>
                        {applyMaxDays && (
                            <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                                <Controller
                                    name="maxDays"
                                    control={control}
                                    rules={{
                                        validate: (value) => {
                                            const minDaysValue = getValues('minDays');
                                            return minDaysValue!=undefined && applyMaxDays && value !== undefined && value < minDaysValue
                                                ? 'El valor máximo debe ser mayor o igual que Mínimo de días'
                                                : true;
                                        },
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: 'Máximo de Días ser un número válido'
                                        },
                                        required: {value: applyMaxDays, message: 'Este campo es requerido'},
                                    }}
                                    render={({field}) => (
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Máximo de Días"
                                            size="small"
                                            type="text"
                                            helperText={errors.maxDays ? errors.maxDays.message : ''}
                                            error={!!errors.maxDays}
                                            {...field}
                                            onChange={(e) => field.onChange(e.target.value==''?'':Number(e.target.value))}
                                        />
                                    )}
                                />
                            </Box>
                        )}
                        </Box>
                        <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                            <Controller
                                name="downloadUrl"
                                control={control}
                                rules={{
                                    required: {value: true, message: 'Este campo es requerido'},
                                    pattern: {
                                        value: /^(https?):\/\/[^\s$.?#].[^\s]*$/gm,
                                        message: 'URL inválida. Debe comenzar con http o https',
                                    }
                                }}
                                render={({field}) => (
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="URL con más información"
                                        size="small"
                                        type="text"
                                        helperText={errors.downloadUrl ? errors.downloadUrl.message : ''}
                                        error={!!errors.downloadUrl}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>
                        <Box display="flex" width="100%" justifyContent="flex-end" gap={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => navigate('/services/insurance-services')}
                            >
                                Volver al listado
                            </Button>
                            <Button
                                variant="contained"
                                disabled={!isValid || loading}
                                color="primary"
                                onClick={() => handleSubmitForm(cleanStringEmpty(getValues())  as unknown as IUpsertInsuranceService)}
                            >
                                Guardar
                            </Button>
                        </Box>
                    </Box>


                </CardContent>
            </Card>
        </>
    )
        ;
};