import React, { useState, useEffect } from 'react';
import {Box, Grid, Typography, IconButton, Card, CardContent, CircularProgress, Alert, Snackbar} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Cards, { Focused } from 'react-credit-cards-2';
import "react-credit-cards-2/dist/es/styles-compiled.css";
import {IClearInformationCardPortfolioDTO} from "../../types";
import {getCardClearInformation} from "../../services";
import {ShowAlertState} from "../../../../core/types";
import {extractErrorMessage, formatCardNumber, formatDocumentNumber} from "../../../../core/helper";
import {documentTypeSToViewList} from "../../../payments/types";


interface CardSummaryDetailProps {
    id: number;
    setLoading: (loading: boolean) => void;
    loading: boolean;
}

const CardSummaryDetail: React.FC<CardSummaryDetailProps> = ({ id,setLoading ,loading}) => {
    const [showBody, setShowBody] = useState(false);
    // const [loading, setLoading] = useState(false);
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [cardInfo, setCardInfo] = useState<IClearInformationCardPortfolioDTO | null>(null);
    const [showNumber, setShowNumber] = useState(true);
    const [showExpiration, setShowExpiration] = useState(true);
    const [showCVV, setShowCVV] = useState(true);
    const [showDocumentType, setShowDocumentType] = useState(true);
    const [showDocumentNumber, setShowDocumentNumber] = useState(true);
    const [cardFocus, setCardFocus] = useState<Focused | undefined>('name');

    // Función para cargar la información de la tarjeta
    const loadCardInfo = async () => {
        try{
            setLoading(true);
            const response = await getCardClearInformation(id);
            setCardInfo(response.data);
        } catch (e) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(e, 'Ocurrió un error al cargar la información de la tarjeta')
            });
        } finally {
            setLoading(false);
        }


    };

    // Controla la visibilidad del body y carga los datos si no están cargados
    const handleToggleView = () => {
        if (!showBody && !cardInfo) {
            loadCardInfo();
        }
        setShowBody(!showBody);
    };

    useEffect(() => {
        // Priorizar el último campo mostrado: CVV > Expiración > Número > Nombre
         if (showNumber) {
            setCardFocus('number');
        }  else if (showExpiration) {
            setCardFocus('expiry');
        }  else if (showCVV) {
             setCardFocus('cvc');
        } else setCardFocus('name');
    }, [showNumber, showExpiration, showCVV, cardInfo]);

    return (<>
        <Card sx={{ margin: '0', padding: 1, mt: 1 }}>
            <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h5">
                        Información completa de la tarjeta
                    </Typography>
                    <IconButton onClick={handleToggleView}>
                        {showBody ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                </Box>

                {showBody && (
                    <>
                        {loading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 1 }}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            cardInfo && (
                                <Grid container spacing={2} sx={{ marginTop: 1 }}>
                                    {/* Primera columna con campos */}
                                    <Grid item xs={12} sm={6}>
                                        {/* Número de la tarjeta */}
                                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                                            <Typography variant="subtitle1" sx={{ marginRight: 1 }}>
                                                Número:
                                            </Typography>
                                            <Typography variant="h6">
                                                {showNumber ? formatCardNumber(cardInfo.number) : '**** **** **** ****'}
                                            </Typography>
                                            <IconButton onClick={() => setShowNumber(!showNumber)}>
                                                {showNumber ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                            </IconButton>
                                        </Box>

                                        {/* Titular */}
                                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                                            <Typography variant="subtitle1" sx={{ marginRight: 1 }}>
                                                Titular:
                                            </Typography>
                                            <Typography variant="h6">{cardInfo.holder}</Typography>
                                        </Box>

                                        {/* Fecha de expiración */}
                                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                                            <Typography variant="subtitle1" sx={{ marginRight: 1 }}>
                                                Fecha de expiración:
                                            </Typography>
                                            <Typography variant="h6">
                                                {showExpiration ? cardInfo.expiration : '****'}
                                            </Typography>
                                            <IconButton onClick={() => setShowExpiration(!showExpiration)}>
                                                {showExpiration ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                            </IconButton>
                                        </Box>

                                        {/* CVV */}
                                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                                            <Typography variant="subtitle1" sx={{ marginRight: 1 }}>
                                                CVV:
                                            </Typography>
                                            <Typography variant="h6">{showCVV ? cardInfo.cvv : '***'}</Typography>
                                            <IconButton onClick={() => setShowCVV(!showCVV)}>
                                                {showCVV ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                            </IconButton>
                                        </Box>

                                        {/* Tipo de Documento */}
                                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                                            <Typography variant="subtitle1" sx={{ marginRight: 1 }}>
                                                Tipo de Documento:
                                            </Typography>
                                            <Typography variant="h6">{showDocumentType ? documentTypeSToViewList.find(x=>x.id ==cardInfo.documentType)?.name || '' : '****'}</Typography>
                                            <IconButton onClick={() => setShowDocumentType(!showDocumentType)}>
                                                {showDocumentType ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                            </IconButton>
                                        </Box>

                                        {/* Número de Documento */}
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography variant="subtitle1" sx={{ marginRight: 1 }}>
                                                Número de Documento:
                                            </Typography>
                                            <Typography variant="h6">{showDocumentNumber ? formatDocumentNumber(cardInfo.documentType,cardInfo.documentNumber) : '********'}</Typography>
                                            <IconButton onClick={() => setShowDocumentNumber(!showDocumentNumber)}>
                                                {showDocumentNumber ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                            </IconButton>
                                        </Box>

                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography variant="subtitle1" sx={{ marginRight: 1 }}>
                                                Cuotas:
                                            </Typography>
                                            <Typography variant="h6">{cardInfo.fee}</Typography>

                                        </Box>
                                    </Grid>

                                    {/* Segunda columna con componente react-credit-cards-2 */}
                                    <Grid item xs={12} sm={6}>
                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Cards
                                                focused={cardFocus}
                                                cvc={showCVV ? cardInfo.cvv : '***'}
                                                expiry={showExpiration ? cardInfo.expiration : '**/**'}
                                                name={cardInfo.holder}
                                                number={showNumber ? cardInfo.number : '**** **** **** ****'}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            )
                        )}
                    </>
                )}
            </CardContent>
        </Card>
    <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
        <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
    </Snackbar></>
    );
};

export default CardSummaryDetail;
