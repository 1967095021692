import React, {Dispatch, SetStateAction, useState} from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    InputAdornment,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Toolbar,
    Typography,
} from '@mui/material';
import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    FilterList as FilterListIcon,
    Search as SearchIcon
} from '@mui/icons-material';
import {IDestinationList, IDestinationListFilter} from '../../types';
import {ISort} from "../../../../core/types";
import EmptyTable from "../../../../core/components/EmptyTable/EmptyTable";

interface DestinationsListProps {
    title: string;
    search: string;
    setSearch: (search: string) => void;
    page: number;
    setPage: (page: number) => void;
    pageSize: number;
    setPageSize: (pageSize: number) => void;
    filters: IDestinationListFilter;
    setFilters: (filter: IDestinationListFilter) => void;
    order: ISort[],
    setOrder: Dispatch<SetStateAction<ISort[]>>;
    items: IDestinationList[];
    totalElements: number;
    onAdd: () => void;
    onEdit: (item: IDestinationList) => void;
    onDelete: (item: IDestinationList) => void;
}

const DestinationsList: React.FC<DestinationsListProps> = ({
                                                               title,
                                                               search,
                                                               setSearch,
                                                               page,
                                                               setPage,
                                                               pageSize,
                                                               totalElements,
                                                               setPageSize,
                                                               filters,
                                                               setFilters,
                                                               order,
                                                               setOrder,
                                                               items,
                                                               onAdd,
                                                               onEdit,
                                                               onDelete
                                                           }) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<null | IDestinationList>(null);
    const [internalFilters, setInternalFilters] = useState<IDestinationListFilter>({
        code: '',
        name: '',
        countryName: ''
    });

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleFilterChange = (field: keyof typeof filters) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setInternalFilters({...filters, [field]: event.target.value});
    };

    const handleApplyFilter = () => {
        setFilters(internalFilters);
        handleCloseMenu();
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (property: keyof IDestinationList) => {
        const exist = order.find(o => o.field === property)
        const isAsc = order.find(o => o.field === property)?.order === 'asc';
        if (!exist) {
            setOrder(prevOrder => [
                ...prevOrder.filter(o => o.field !== property),
                {field: property, order: isAsc ? 'desc' : 'asc'},
            ]);
        } else {
            if (exist.order === 'asc') {
                setOrder(prevOrder => [
                    ...prevOrder.filter(o => o.field !== property),
                    {field: property, order: 'desc'},
                ]);
            } else {
                setOrder(prevOrder => [
                    ...prevOrder.filter(o => o.field !== property),
                ]);
            }
        }

    };

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleOpenDialog = (item: IDestinationList | null = null) => {
        setSelectedItem(item);
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
        setSelectedItem(null);
    };


    const handleDelete = () => {
        if (selectedItem) {
            onDelete(selectedItem);
            handleCloseDialog();
        }
    };

    const handleCancelFilters = () => {
        setInternalFilters(filters);
        handleCloseMenu();
    };


    return (
        <Box sx={{width: '100%', padding: 3}}>
            <Typography variant="h4" component="div" sx={{mb: 2}}>
                {title}
            </Typography>
            <Toolbar sx={{display: 'flex', justifyContent: 'space-between', mb: 2}} style={{paddingLeft: 0}}>
                <Button
                    variant="contained"
                    startIcon={<AddIcon/>}
                    onClick={() => onAdd()}
                >
                    Nuevo destino
                </Button>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <TextField
                        variant="outlined"
                        placeholder="Buscar"
                        size="small"
                        value={search}
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon/>
                                </InputAdornment>
                            ),
                        }}
                        sx={{mr: 2, width: 300}}
                    />
                    <Button variant="outlined" startIcon={<FilterListIcon/>} onClick={handleOpenMenu}>
                        Filtrar
                    </Button>
                    <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        disableRestoreFocus
                    >
                        <Box sx={{p: 2, display: 'flex', flexDirection: 'column', gap: 1}}>
                            <TextField
                                label="Código contiene"
                                size='small'
                                value={internalFilters.code}
                                onChange={handleFilterChange('code')}
                                fullWidth
                            />
                            <TextField
                                label="Nombre contiene"
                                size='small'
                                value={internalFilters.name}
                                onChange={handleFilterChange('name')}
                                fullWidth
                            />
                            <TextField
                                label="País contiene"
                                size='small'
                                value={internalFilters.countryName}
                                onChange={handleFilterChange('countryName')}
                                fullWidth
                            />
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2}}>
                                <Button variant="contained" onClick={handleApplyFilter} sx={{mr: 2}}>
                                    Aplicar Filtros
                                </Button>
                                <Button variant="outlined" onClick={handleCancelFilters}>
                                    Cancelar
                                </Button>
                            </Box>
                        </Box>
                    </Popover>
                </Box>
            </Toolbar>
            <Typography variant="body2" sx={{mb: 1}}>
                Mostrando {items.length} resultados de {totalElements}
            </Typography>
            <TableContainer component="main" sx={{width: '100%'}}>
                <Table sx={{width: '100%'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'code')}
                                    direction={order.find(o => o.field === 'code')?.order || 'asc'}
                                    onClick={() => handleRequestSort('code')}
                                >
                                    Código
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'name')}
                                    direction={order.find(o => o.field === 'name')?.order || 'asc'}
                                    onClick={() => handleRequestSort('name')}
                                >
                                    Nombre
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'countryName')}
                                    direction={order.find(o => o.field === 'countryName')?.order || 'asc'}
                                    onClick={() => handleRequestSort('countryName')}
                                >
                                    País
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item) => (
                            <TableRow key={item.code}>
                                <TableCell>{item.code}</TableCell>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.countryName}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => onEdit(item)}>
                                        <EditIcon/>
                                    </IconButton>
                                    <IconButton onClick={() => handleOpenDialog(item)}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        {items.length == 0 && <EmptyTable colSpan={4} />}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                labelRowsPerPage={'Filas por página'}
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={totalElements}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Dialog open={open} onClose={handleCloseDialog}>
                <DialogTitle>Eliminar Destino</DialogTitle>
                <DialogContent>

                    <DialogContentText>
                        ¿Estás seguro de que deseas eliminar este destino?
                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancelar</Button>
                    <Button onClick={handleDelete} color="primary">Eliminar</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default DestinationsList;
