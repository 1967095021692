import React from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {IListCardPortfolioRejection} from "../../types";
import {resolveMethodRejectionToViewList} from "../../helper";

export interface CardPortfolioRejectionUpsertFormProps {
    handleSubmitForm: (value: IResolveCardPortfolioRejectionFormValue) => void;
    loading: boolean;
    value: IListCardPortfolioRejection,
    handleClose: () => void
}

export interface IResolveCardPortfolioRejectionFormValue {
    observations: string;
    method: string;
}

const defaultValue: IResolveCardPortfolioRejectionFormValue = {
    observations: '',
    method: ''
};
export const CardPortfolioRejectionResolveForm: React.FC<CardPortfolioRejectionUpsertFormProps> = ({
                                                                                                      handleSubmitForm,
                                                                                                      loading,
                                                                                                      handleClose,
                                                                                                      value
                                                                                                  }) => {


    const methods = useForm<IResolveCardPortfolioRejectionFormValue>({
        mode: 'all',
        defaultValues:  defaultValue
    });

    const {
        control,
        formState: {errors, isValid},
        getValues
    } = methods;

    return (
        <>
            <Card sx={{width: '100%'}}>
                <CardContent>
                    <Typography variant="h5" style={{fontWeight: 'bold'}}
                                p={1}>Resolver rechazo</Typography>
                    <br/>
                    <Box component="form" display="flex" flexDirection="column" gap={1}>

                            <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                                    <Controller
                                        name="method"
                                        control={control}
                                        rules={{
                                            required: {value: true, message: 'Este campo es requerido'},
                                        }}
                                        render={({field}) => (
                                            <FormControl sx={{width: '100%'}} error={!!errors.method}>
                                                <InputLabel id="roles-select-label">Método de resolución*</InputLabel>
                                                <Select
                                                    size={'small'}
                                                    labelId="roles-select-label"
                                                    id="roles-select"
                                                    value={field.value}
                                                    onChange={(event) => field.onChange(event.target.value)}
                                                    label="Método de resolución*"
                                                >
                                                    {resolveMethodRejectionToViewList.map((methodItem) => (
                                                        <MenuItem key={methodItem.id} value={methodItem.id}>
                                                            {methodItem.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {errors.method && <FormHelperText>{errors.method.message}</FormHelperText>}
                                            </FormControl>
                                        )}
                                    />
                                </Box>
                            <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                                <Controller
                                    name="observations"
                                    control={control}
                                    rules={{
                                        required: {value: true, message: 'Este campo es requerido'}
                                    }}
                                    render={({field}) => (
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Observaciones"
                                            multiline
                                            rows={4}
                                            size={'small'}
                                            type="text"
                                            helperText={errors.observations ? errors.observations.message : ''}
                                            error={!!errors.observations}
                                            {...field}
                                        />
                                    )}
                                />
                        </Box>


                        <Box display="flex" width="100%" justifyContent="flex-end" gap={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleClose}
                            >
                                Volver al listado
                            </Button>
                            <Button
                                variant="contained"
                                disabled={!isValid || loading}
                                color="primary"
                                onClick={() => handleSubmitForm({...getValues()})}
                            >
                                Guardar
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
        ;
};