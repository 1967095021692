import React, {forwardRef, useEffect, useImperativeHandle} from 'react';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {Box, Button, IconButton, TextField, Typography} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {IBenefit} from "../../types";

interface IFormInput {
    benefits: IBenefit[];
}

interface BenefitsFormProps {
    initialData?: IBenefit[];
}

const BenefitsForm = forwardRef(({ initialData }: BenefitsFormProps, ref) => {
    const { control, setValue, getValues,trigger, formState: { errors,isValid } } = useForm<IFormInput>({
        defaultValues: {
            benefits: initialData || [{ description: '', valueBenefit: '' }],
        },
        mode: 'onChange',
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'benefits',
    });

    useImperativeHandle(ref, () => ({
        getFormValues: () => getValues(),
        isValidForm: () => isValid,
        triggerValidation: () => trigger(),
    }));

    useEffect(() => {
        if (initialData) {
            setValue('benefits', initialData);
        }
    }, [initialData, setValue]);

    return (
        <Box component="form" display="flex" flexDirection="column" gap={2}>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
               La cobertura incluye
            </Typography>
            {fields.map((item, index) => (
                <Box key={item.id} display="flex" alignItems="flex-start" gap={2}>
                    <Controller
                        name={`benefits.${index}.description`}
                        control={control}
                        rules={{ required: {value: true, message: 'Este campo es requerido'}}}
                        render={({ field }) => (
                            <TextField

                                error={!!errors?.benefits?.[index]?.description}
                                helperText={errors?.benefits?.[index]?.description?.message}
                                {...field}
                                label="Descripción"
                                variant="outlined"
                                size="small"
                                multiline={true}
                                rows={3}
                                required
                                fullWidth
                            />
                        )}
                    />
                    <Controller
                        name={`benefits.${index}.valueBenefit`}
                        control={control}
                        rules={{ required: {value: true, message: 'Este campo es requerido'}}}
                        render={({ field,fieldState }) => (
                            <TextField
                                error={!!errors?.benefits?.[index]?.valueBenefit}
                                helperText={errors?.benefits?.[index]?.valueBenefit?.message}
                                {...field}
                                label="Valor"
                                variant="outlined"
                                size="small"
                                required
                                fullWidth

                            />
                        )}
                    />

                    {index > 0 && <IconButton color="secondary" onClick={() => remove(index)}>
                        <DeleteIcon />
                    </IconButton>}
                </Box>
            ))}
            <Box display="flex" justifyContent="flex-start" gap={2}>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => append({ description: '', valueBenefit: '' })}
                >
                    Agregar nuevo beneficio
                </Button>
            </Box>
        </Box>
    );
});

export default BenefitsForm;
