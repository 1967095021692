// ScrollableTableContainer.tsx
import React, { useRef, ReactNode, useEffect } from 'react';
import { IconButton, Box } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface ScrollableTableContainerProps {
    children: ReactNode;
}

const ScrollableTableContainer: React.FC<ScrollableTableContainerProps> = ({ children }) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const scrollIntervalLeft = useRef<NodeJS.Timeout | null>(null);
    const scrollIntervalRight = useRef<NodeJS.Timeout | null>(null);

    // Función para iniciar el desplazamiento hacia la izquierda
    const startScrollingLeft = () => {
        if (scrollIntervalLeft.current) return; // Evita múltiples intervalos
        scrollIntervalLeft.current = setInterval(() => {
            if (containerRef.current) {
                containerRef.current.scrollBy({ left: -10, behavior: 'auto' });
            }
        }, 20); // Ajusta la velocidad aquí (ms)
    };

    // Función para detener el desplazamiento hacia la izquierda
    const stopScrollingLeft = () => {
        if (scrollIntervalLeft.current) {
            clearInterval(scrollIntervalLeft.current);
            scrollIntervalLeft.current = null;
        }
    };

    // Función para iniciar el desplazamiento hacia la derecha
    const startScrollingRight = () => {
        if (scrollIntervalRight.current) return; // Evita múltiples intervalos
        scrollIntervalRight.current = setInterval(() => {
            if (containerRef.current) {
                containerRef.current.scrollBy({ left: 10, behavior: 'auto' });
            }
        }, 20); // Ajusta la velocidad aquí (ms)
    };

    // Función para detener el desplazamiento hacia la derecha
    const stopScrollingRight = () => {
        if (scrollIntervalRight.current) {
            clearInterval(scrollIntervalRight.current);
            scrollIntervalRight.current = null;
        }
    };

    // Limpia los intervalos al desmontar el componente
    useEffect(() => {
        return () => {
            stopScrollingLeft();
            stopScrollingRight();
        };
    }, []);

    return (
        <Box position="relative" width="100%" overflow="hidden">
            {/* Botón de desplazamiento hacia la izquierda */}
            <IconButton
                onMouseEnter={startScrollingLeft}
                onMouseLeave={stopScrollingLeft}
                sx={{
                    position: 'absolute',
                    left: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 1,
                    backgroundColor: 'rgba(255,255,255,0.7)',
                    '&:hover': { backgroundColor: 'rgba(255,255,255,1)' },
                }}
            >
                <ArrowBackIosIcon />
            </IconButton>

            {/* Botón de desplazamiento hacia la derecha */}
            <IconButton
                onMouseEnter={startScrollingRight}
                onMouseLeave={stopScrollingRight}
                sx={{
                    position: 'absolute',
                    right: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 1,
                    backgroundColor: 'rgba(255,255,255,0.7)',
                    '&:hover': { backgroundColor: 'rgba(255,255,255,1)' },
                }}
            >
                <ArrowForwardIosIcon />
            </IconButton>

            {/* Contenedor desplazable */}
            <Box
                ref={containerRef}
                sx={{
                    overflowX: 'auto',
                    whiteSpace: 'nowrap',
                    padding: '0 40px', // Espacio para los botones
                }}
            >
                {children}
            </Box>
        </Box>
    );
};

export default ScrollableTableContainer;
