

export const appRoutes = {
    destinations:{
        list:'/destinations',
        createOrUpdate:'/destinations/:code',
    },
    countries:{
        list:'/countries',
        createOrUpdate:'/countries/:code',
    },
    airlines:{
        list:'/airlines',
        createOrUpdate:'/airlines/:code',
    },
    airports:{
        list:'/airports',
        createOrUpdate:'/airports/:code',
    },
    tags:{
        list: '/tags',
        createOrUpdate: '/tags/:id'
    },
    users:{
        list:'/users',
        createOrUpdate:'/users/:id'
    },
    providers:{
        list:'/providers',
        createOrUpdate:'/providers/:id'
    },
    transferServices:{
        list:'/services/transfer-services',
        createOrUpdate:'/services/transfer-services/:id',
        tariff:'/services/transfer-services/:transferServiceId/tariff'
    },
    insuranceServices:{
        list:'/services/insurance-services',
        createOrUpdate:'/services/insurance-services/:id',
        tariff:'/services/insurance-services/:insuranceServiceId/tariff'
    },
    saleOrder: {
        list:'/sales-order',
        createOrUpdate:'/sales-order/:id',
    },
    bambooSaleOrder: {
        list:'/bamboo-sales-order',
        create:'/bamboo-sales-order/:id',
    },

    security:{
        login:'/login',
        forgotPassword:'/forgotpassword',
    },
    clientPay:{
        pay:'/payment-link/:id/pay'
    },
    paymentInformedConsent:{
        upsert:'/payment-informed-consent/upsert',
        list:'/payment-informed-consent'
    },
    cardPortfolio:{
        list:'/cards-portfolio',
        detail:'/cards-portfolio/:id',
        summary:'/summaries-cards-portfolios'
    },

    authorizationOperator:{
        list:'/authorization-operators',
        upsert:'/authorization-operators/:id'
    },

    authorizations:{
        list:'/authorizations',
    },
    receipts:{
        list:'/receipts',
    },
    actionLog:{
        list:'/actions-log'
    },
    corporateCardAuthorizationConcept:{
        list:'/corporate-card-authorization-concepts',
        upsert:'/corporate-card-authorization-concepts/:id'
    },
    corporateCard:{
        list:'/corporate-cards',
        upsert:'/corporate-cards/:id',
        detail:'/corporate-cards/detail/:id'
    },
    contactEmailConfiguration:{
        update:'/contact-email-configurations'
    },
}


export const yesNoValues = [
    {
        id: 1,
        name: 'Si'
    },

    {
        id: 0,
        name: 'No'
    }
];

export const emailValidation = {
    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        message: 'El formato del email no es válido'
}

export function checkShowErrorMessage(show: boolean, message: string | undefined): string {
    if(show && message) {
        return message;
    }
    return '';
}