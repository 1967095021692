import React from 'react';
import { TableCell, TableRow, Typography, Box } from '@mui/material';
import emptyResults from "../../../assets/images/search-no-results-found.jpg";
interface EmptyTableProps {
    colSpan: number;// Número de columnas que debe ocupar el mensaje
    message?:string;
}

const EmptyTable: React.FC<EmptyTableProps> = ({
                                                   colSpan,
                                                   message = 'No se encontraron resultados'
                                               }) => {
    return (
        <TableRow>
            <TableCell colSpan={colSpan} align="center">
                <Box display="flex" flexDirection="column" alignItems="center" py={4}>
                    <img
                        src={emptyResults}
                        alt="No Results"
                        style={{ maxWidth: '150px', marginBottom: '16px' }}
                    />
                    <Typography variant="h6" color="textSecondary">
                        {message}
                    </Typography>
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default EmptyTable;
