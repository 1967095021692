export interface IListCardPortfolioRejection {
    readonly id: number;
    readonly isEnable: boolean;
    readonly observations?: string;
    readonly createdAt: string;
    readonly createdBy: string;
    readonly updatedAt?: string;
    readonly updatedBy?: string;
    readonly resolveObservations?: string;
    readonly resolveMethod?: string;
    readonly resolvedAt?: string;
    readonly resolvedBy?: string;
}

export enum ResolvedMethodRejectionEnum {
    TRY_TO_COLLECT_AGAIN='TRY_TO_COLLECT_AGAIN',
    PAY_WITH_OTHER_METHODS='PAY_WITH_OTHER_METHODS'
}

export interface ICreateCardPortfolioRejectionResolved {
    method:ResolvedMethodRejectionEnum;
    observations:string;
}

export interface IUpsertCardPortfolioRejection {
    observations?:string;
}