import React, {useCallback, useEffect, useState} from 'react';
import {ShowAlertState} from "../../../../core/types";
import {Alert, Backdrop, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {extractErrorMessage} from "../../../../core/helper";
import {useNavigate} from "react-router-dom";
import {getPaymentInformedConsent} from "../../services";
import {IListPaymentInformedConsent} from "../../types";
import PaymentInformedConsentList from "../../components/PaymentInformedConsentList/PaymentInformedConsentList";
import {appRoutes} from "../../../../core/const";

const PaymentInformedConsentListContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [items, setItems] = useState<IListPaymentInformedConsent[]>([])
    const navigate = useNavigate();

    const onUpsert = () => navigate(appRoutes.paymentInformedConsent.upsert);


    const loadPaymentInformedConsent = useCallback(async () => {
        try {
            setLoading(true);
            const result = (await getPaymentInformedConsent()).data;
            setItems(result);
            setLoading(false);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al recibir la inormación del consentimiento informado')
            });
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        loadPaymentInformedConsent();
    }, [loadPaymentInformedConsent]);

    return (<><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            <PaymentInformedConsentList title={'Consentimiento informado'}
                                        items={items}
                                        onUpsert={onUpsert}
            />
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </>
    );
}

export default PaymentInformedConsentListContainer;
