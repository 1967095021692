import React, {useCallback, useEffect, useState} from 'react';
import {IDetailTransferService, IUpsertTransferService} from "../../types";
import {ShowAlertState} from "../../../../core/types";
import {Alert, Backdrop, Box, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {createTransferService, getTransferService, updateTransferService} from "../../services";
import {extractErrorMessage, is404Error} from "../../../../core/helper";
import {useNavigate, useParams} from "react-router-dom";
import {TransferServiceUpsertForm} from "../../components/TransferServiceUpsertForm/TransferServiceUpsertForm";
import {IDestinationList} from "../../../destinations/types";
import {getAllDestinations} from "../../../destinations/services";

const TransferServiceUpsertContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [transferServiceDetail, setTransferServiceDetail] = useState<IDetailTransferService | null>(null);
    const [destinations, setDestinations] = useState<IDestinationList[]>([]);
    const {id} = useParams<{ id: string }>();
    const navigate = useNavigate();
    const validNumberId = !isNaN(Number(id))
    const isCreate = id === 'create'

    const loadTransferServiceDetail = useCallback(async () => {
        try {
            if (id && id !== 'create') {
                setLoading(true);
                const result = (await getTransferService(+id)).data;
                setTransferServiceDetail(result);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el servicio de traslado')
            });
            setLoading(false);
            if (is404Error(error)) {
                setTimeout(() => navigate('/services/transfer-services'), 2500);
            }
        }
    }, [id, setLoading, setTransferServiceDetail, setAlert]);


    const loadDestinations =  async () => {
        try {

        setLoading(true);
        const result = (await getAllDestinations()).data;
        setDestinations(result);
        setLoading(false);
} catch (error) {
        console.log(error);
        setAlert({
            show: true,
            severity: 'error',
            message: extractErrorMessage(error, 'Ocurrió un error al cargar los destinos')
        });
        setLoading(false);
    }
}


     useEffect(()=>{
         loadDestinations()
    },[])


    useEffect(() => {
        loadTransferServiceDetail();
    }, [loadTransferServiceDetail]);

    const handleUpsertTransferService = async (value: IUpsertTransferService) => {
        const messageSuccess = isCreate?'creado':'actualizado';
        const messageError = isCreate?'crear':'actualizar';
        if(id){
            try {
                setLoading(true);
                if(isCreate){
                    await createTransferService(value);
                } else {
                    await updateTransferService(+id,value);
                }

                setLoading(false);
                setAlert({
                    show: true,
                    severity: 'success',
                    message: 'Servicio de traslado '+messageSuccess+' satisfactoriamente'
                });
                setTimeout(() => navigate('/services/transfer-services'), 1500)
            } catch (error) {
                setAlert({
                    show: true,
                    severity: 'error',
                    message: extractErrorMessage(error, 'Ocurrió un error al '+messageError+' el servicio de traslado')
                });
                setLoading(false);
            }
        }

    }


    return (<Box sx={{width: '80%', padding: 3}}><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            {id && id === 'create' &&
                <TransferServiceUpsertForm loading={loading} handleSubmitForm={handleUpsertTransferService} destinations={destinations}/> }
            {id && id !== 'create' && validNumberId && transferServiceDetail &&
                <TransferServiceUpsertForm value={transferServiceDetail} loading={loading} destinations={destinations}
                                           handleSubmitForm={handleUpsertTransferService}/>}
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </Box>
    );
}

export default TransferServiceUpsertContainer;
