// src/components/SellerItem.tsx
import React from 'react';
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import {ICardPortfolioSummaryTopSeller} from "../../types";

interface SellerItemProps {
    seller: ICardPortfolioSummaryTopSeller;
}

const getInitials = (name: string): string => {
    const nameParts = name.trim().split(' ');
    if (nameParts.length === 1) {
        return nameParts[0][0].toUpperCase();
    } else {
        return (nameParts[0][0] + nameParts[nameParts.length - 1][0]).toUpperCase();
    }
};

const SellerItem: React.FC<SellerItemProps> = ({ seller }) => {
    const initials = getInitials(seller.salespersonName);

    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar sx={{ bgcolor: deepPurple[500] }}>{initials}</Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={seller.salespersonName}
                secondary={
                    <Typography variant="body2" color="textSecondary">
                        Ventas: ${parseFloat(seller.totalSales).toLocaleString('en-US', { minimumFractionDigits: 2 })} USD
                    </Typography>
                }
            />
        </ListItem>
    );
};

export default SellerItem;
