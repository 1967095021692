import axios, {AxiosResponse} from "axios";
import {CookieService} from "../modules/auth/services/CookieService";
import {IAppFilterList, IAppFilterToSave} from "./types";

export const ApiService = axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
    baseURL: process.env.REACT_APP_API_URL,
});

ApiService.interceptors.request.use(config => {
    const token = CookieService.getToken();
    if (token) { // @ts-ignore
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
});

ApiService.interceptors.response.use((response) => response, async (error) => {
    if (error.response.status === 401 && window.location.pathname.lastIndexOf('login')===-1) {
        CookieService.clearToken();
        window.location.href ='/login';
    } else throw error;
});

export const saveCustomFilter = (appFilter: IAppFilterToSave): Promise<AxiosResponse<{id:number}>> => {
    return ApiService.post<{id:number}>('api/app-filters', appFilter);
};

export const listCustomFilters = <T>(viewId:string ): Promise<AxiosResponse<IAppFilterList<T>[]>> => {
    return ApiService.get<IAppFilterList<T>[]>('api/app-filters/by-view/'+viewId);
};

export const deleteCustomFilter= (id:number ): Promise<AxiosResponse<void>> => {
    return ApiService.delete<void>('api/app-filters/'+id+'');
};