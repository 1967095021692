import {IUpsertCorporateCard} from "./types";

export const toBase64CorporateCardUpsert = (x: IUpsertCorporateCard): IUpsertCorporateCard => {
    return {
        cardStamp: btoa(x.cardStamp),
        cardNumber: btoa(x.cardNumber),
        cvc: btoa(x.cvc),
        expiry: btoa(x.expiry),
        cardHolder: btoa(x.cardHolder)
    } as IUpsertCorporateCard
}