
import {ApiService} from "../../core/services";
import {AxiosResponse} from "axios";
import {IPaginatedResult, ISort} from "../../core/types";
import {cleanFilters, formatSortingFields} from "../../core/helper";
import {IChangePasswordToUser, ICreateUser, IDetailUser, IFilterUser, IListUser, IUpdateUser} from "./types";

const endpoints = {
    list: 'api/users',
    get: 'api/users/:id',
    create: 'api/users',
    update: 'api/users/:id',
    changePasswordToUser: 'api/users/change-password-to-user/:id',
}

export const getUsers = (page = 1, pageSize = 10, sortingFields: ISort[], search:string, filters?: IFilterUser): Promise<AxiosResponse<IPaginatedResult<IListUser>>> => {
    const sorts = formatSortingFields(sortingFields);
    return ApiService.get(endpoints.list, {
        params: {
            page,
            pageSize,
            ...(sorts.length > 0 && {sort: sorts}), ...(filters && cleanFilters(filters)), ...(search && search!=='' && {search:search})
        }
    });
};
export const getUser = (id:number): Promise<AxiosResponse<IDetailUser>> => ApiService.get(endpoints.get.replace(':id',id+''));
export const createUser = (data: ICreateUser): Promise<AxiosResponse<void>> => ApiService.post(endpoints.create, data);
export const updateUser = (id: number, data: IUpdateUser): Promise<AxiosResponse<void>> => ApiService.put(endpoints.update.replace(':id', id+''), data);
export const changePasswordToUser = (id: number, data: IChangePasswordToUser): Promise<AxiosResponse<void>> => ApiService.put(endpoints.changePasswordToUser.replace(':id', id+''), data);
