import React, {useCallback, useEffect, useState} from 'react';
import {IDetailInsuranceService, IUpsertInsuranceService} from "../../types";
import {ShowAlertState} from "../../../../core/types";
import {Alert, Backdrop, Box, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {createInsuranceService, getInsuranceService, updateInsuranceService} from "../../services";
import {extractErrorMessage, is404Error} from "../../../../core/helper";
import {useNavigate, useParams} from "react-router-dom";
import {InsuranceServiceUpsertForm} from "../../components/InsuranceServiceUpsertForm/InsuranceServiceUpsertForm";

const InsuranceServiceUpsertContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [insuranceServiceDetail, setInsuranceServiceDetail] = useState<IDetailInsuranceService | null>(null);
    const {id} = useParams<{ id: string }>();
    const navigate = useNavigate();
    const validNumberId = !isNaN(Number(id))
    const isCreate = id === 'create'

    const loadInsuranceServiceDetail = useCallback(async () => {
        try {
            if (id && id !== 'create') {
                setLoading(true);
                const result = (await getInsuranceService(+id)).data;
                setInsuranceServiceDetail(result);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el servicio de seguro')
            });
            setLoading(false);
            if (is404Error(error)) {
                setTimeout(() => navigate('/services/insurance-services'), 2500);
            }
        }
    }, [id, setLoading, setInsuranceServiceDetail, setAlert]);


    useEffect(() => {
        loadInsuranceServiceDetail();
    }, [loadInsuranceServiceDetail]);

    const handleUpsertInsuranceService = async (value: IUpsertInsuranceService) => {
        const messageSuccess = isCreate ? 'creado' : 'actualizado';
        const messageError = isCreate ? 'crear' : 'actualizar';
        if (id) {
            try {
                setLoading(true);
                if (isCreate) {
                    await createInsuranceService(value);
                } else {
                    await updateInsuranceService(+id, value);
                }

                setLoading(false);
                setAlert({
                    show: true,
                    severity: 'success',
                    message: 'Servicio de seguro ' + messageSuccess + ' satisfactoriamente'
                });
                setTimeout(() => navigate('/services/insurance-services'), 1500)
            } catch (error) {
                setAlert({
                    show: true,
                    severity: 'error',
                    message: extractErrorMessage(error, 'Ocurrió un error al ' + messageError + ' el servicio de seguro')
                });
                setLoading(false);
            }
        }

    }


    return (<Box sx={{width: '80%', padding: 3}}><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            {id && id === 'create' &&
                <InsuranceServiceUpsertForm loading={loading} handleSubmitForm={handleUpsertInsuranceService}/>}
            {id && id !== 'create' && validNumberId && insuranceServiceDetail &&
                <InsuranceServiceUpsertForm value={insuranceServiceDetail} loading={loading}
                                            handleSubmitForm={handleUpsertInsuranceService}/>}
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </Box>
    );
}

export default InsuranceServiceUpsertContainer;
