import {ApiService} from "../../core/services";
import {AxiosResponse} from "axios";
import {ICreatePaymentLinkPay, IOrderInfoToPay} from "./types";

const endpoints = {
    create: 'api/payment-link/pay',
    getSaleOrderInfoToPay:'api/payment-link/:id/info-to-pay'
}

export const createPaymentLinkPay = (data: ICreatePaymentLinkPay): Promise<AxiosResponse<void>> => ApiService.post(endpoints.create, data);
export const getInfoToPay = (saleOrderId: string): Promise<AxiosResponse<IOrderInfoToPay>> => ApiService.get(endpoints.getSaleOrderInfoToPay.replace(':id',saleOrderId));


