// src/hooks/useMercure.ts
import {useEffect} from 'react';
import {IListCardPortfolio} from "../types";
import {getMercureToken} from "../services";

interface MercureUpdate {
    action: string;
    elementId: number;
    viewId: string;
    connected: IListCardPortfolio['views'];
}

type HandleUpdate = (elementId: number, connectedUsers: IListCardPortfolio['views']) => void;

/**
 * Hook personalizado para suscribirse a Mercure y manejar actualizaciones generales.
 *
 * @param handleUpdate - Callback que maneja la actualización de los elementos.
 */
// src/hooks/useMercure.ts


const useMercure = (handleUpdate: HandleUpdate): void => {
    useEffect(() => {
        let eventSource: EventSource | null = null;

        const subscribe = async () => {
            try {
                const hubUrl = process.env.REACT_APP_MERCURE_PUBLIC_URL;
                const hubTopic = process.env.REACT_APP_MERCURE_TOPIC;

                if (!hubUrl) {
                    console.error('REACT_APP_MERCURE_PUBLIC_URL no está definida en las variables de entorno.');
                    return;
                }

                if (!hubTopic) {
                    console.error('REACT_APP_MERCURE_TOPIC no está definida en las variables de entorno.');
                    return;
                }
                let token =''
                // Paso 1: Obtener el token desde el backend
                try {
                    const response = await  getMercureToken();
                    token = response.data.token;
                } catch (e) {
                    throw new Error('No se pudo obtener el token de Mercure.');
                }


                // Paso 2: Configurar la URL del hub de Mercure con el token y el topic
                const url = new URL(hubUrl);
                url.searchParams.append('topic', hubTopic);
                url.searchParams.append('authorization', token);

                // Paso 3: Crear una instancia de EventSource
                eventSource = new EventSource(url.toString());

                // Paso 4: Escuchar los eventos
                eventSource.onmessage = (event: MessageEvent) => {
                    try {
                        const data: MercureUpdate = JSON.parse(event.data);
                        if (
                            data.action === 'update' &&
                            data.elementId &&
                            data.viewId === 'card-portfolio-detail' &&
                            Array.isArray(data.connected)
                        ) {
                            handleUpdate(data.elementId, data.connected);
                        }
                    } catch (error) {
                        console.error('Error al procesar la actualización de Mercure:', error);
                    }
                };

                // Manejar errores en la conexión
                eventSource.onerror = (error) => {
                    console.error('Error en la conexión con Mercure:', error);
                    eventSource?.close();
                };
            } catch (error) {
                console.error('Error al suscribirse a Mercure:', error);
            }
        };

        subscribe();

        // Limpiar la suscripción al desmontar el componente
        return () => {
            if (eventSource) {
                eventSource.close();
            }
        };
    }, [handleUpdate]);
};

export default useMercure;
