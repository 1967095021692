import {ApiService} from "../../core/services";
import {AxiosResponse} from "axios";
import {IPaginatedResult, ISort} from "../../core/types";
import {formatSortingFields} from "../../core/helper";
import {IListCorporateCardAuthorizationConcept, IUpsertCorporateCardAuthorizationConcept} from "./types";

const endpoints = {
    list: 'api/corporate-card-authorization-concepts',
    listAll: 'api/corporate-card-authorization-concepts/list/all',
    get: 'api/corporate-card-authorization-concepts/:id',
    create: 'api/corporate-card-authorization-concepts',
    update: 'api/corporate-card-authorization-concepts/:id',
    delete: 'api/corporate-card-authorization-concepts/:id',
}

export const getCorporateCardAuthorizationConcepts = (page = 1, pageSize = 10, sortingFields: ISort[], search: string): Promise<AxiosResponse<IPaginatedResult<IListCorporateCardAuthorizationConcept>>> => {
    const sorts = formatSortingFields(sortingFields);
    return ApiService.get(endpoints.list, {
        params: {
            page,
            pageSize,
            ...(sorts.length > 0 && {sort: sorts}), ...(search && search !== '' && {search: search})
        }
    });
};
export const getAllCorporateCardAuthorizationConcepts = (): Promise<AxiosResponse<IListCorporateCardAuthorizationConcept[]>> => ApiService.get(endpoints.listAll);
export const getCorporateCardAuthorizationConcept = (id: number): Promise<AxiosResponse<IListCorporateCardAuthorizationConcept>> => ApiService.get(endpoints.get.replace(':id', id+'' ));
export const createCorporateCardAuthorizationConcept = (data: IUpsertCorporateCardAuthorizationConcept): Promise<AxiosResponse<void>> => ApiService.post(endpoints.create, data);
export const updateCorporateCardAuthorizationConcept = (id: number, data: IUpsertCorporateCardAuthorizationConcept): Promise<AxiosResponse<void>> => ApiService.put(endpoints.update.replace(':id', id +''), data);
export const deleteCorporateCardAuthorizationConcept = (id: number): Promise<AxiosResponse<void>> => ApiService.delete(endpoints.delete.replace(':id', id+''));
