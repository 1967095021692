import React, {useEffect, useState} from 'react';
import {
    Alert,
    Backdrop,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogContentText,
    Snackbar,
    useTheme
} from "@mui/material";
import {
    IDetailTariffNotPrivateTransferService,
    IDetailTariffPrivateTransferService,
    IUpsertTariffNotPrivateTransferService,
    IUpsertTariffPrivateTransferService
} from "../../types";
import {ShowAlertState} from "../../../../../../core/types";
import {useParams} from "react-router-dom";
import {IDetailTransferService} from "../../../../types";
import {
    createTransferServiceTariffNotPrivate,
    createTransferServiceTariffPrivate,
    deleteTransferServiceTariffNotPrivate,
    deleteTransferServiceTariffPrivate,
    getTransferServicesTariffNotPrivate,
    getTransferServicesTariffPrivate,
    updateTransferServiceTariffNotPrivate,
    updateTransferServiceTariffPrivate
} from "../../services";
import {extractErrorMessage} from "../../../../../../core/helper";
import {getTransferService} from "../../../../services";
import TariffTransferServicePrivateList
    from "../../components/TariffTransferServicePrivateList/TariffTransferServicePrivateList";
import {
    IUpsertTariffPrivateTransferServiceFormValue,
    TariffTransferServicePrivateUpsertForm
} from "../../components/TariffTransferServicePrivateUpsertForm/TariffTransferServicePrivateUpsertForm";
import {IListProvider} from "../../../../../providers/types";
import {getAllProviders} from "../../../../../providers/services";
import TariffTransferServiceNotPrivateList
    from "../../components/TariffTransferServiceNotPrivateList/TariffTransferServiceNotPrivateList";
import {
    IUpsertTariffNotPrivateTransferServiceFormValue,
    TariffTransferServiceNotPrivateUpsertForm
} from "../../components/TariffTransferServiceNotPrivateUpsertForm/TariffTransferServiceNotPrivateUpsertForm";

const TariffTransferServiceListContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [tariffPrivate, setTariffPrivate] = useState<IDetailTariffPrivateTransferService[]>([])
    const [tariffPrivateLineToEdit, setTariffPrivateLineToEdit] = useState<IDetailTariffPrivateTransferService | null>(null)
    const [tariffNotPrivateLineToEdit, setTariffNotPrivateLineToEdit] = useState<IDetailTariffNotPrivateTransferService | null>(null)
    const [tariffNotPrivate, setTariffNotPrivate] = useState<IDetailTariffNotPrivateTransferService[]>([])
    const [providers, setProviders] = useState<IListProvider[]>([])
    const [transferService, setTransferService] = useState<IDetailTransferService | null>(null)
    const {transferServiceId} = useParams<{ transferServiceId: string }>();
    const [showFormModalTariffPrivate, setShowFormModalTariffPrivate] = useState<boolean>(false);
    const [showFormModalTariffNotPrivate, setShowFormModalTariffNotPrivate] = useState<boolean>(false);

    const loadTariff = async () => {
        if (transferService) {
            try {
                setLoading(true);
                if (transferService.isPrivate) {
                    const result = (await getTransferServicesTariffPrivate(transferService.id)).data;
                    setTariffPrivate(result);
                    setTariffNotPrivate([]);
                } else {
                    const result = (await getTransferServicesTariffNotPrivate(transferService.id)).data;
                    setTariffNotPrivate(result);
                    setTariffPrivate([]);
                }

                setLoading(false);
            } catch (error) {
                setAlert({
                    show: true,
                    severity: 'error',
                    message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado de tarifas del servicio')
                });
                setLoading(false);
            }
        }

    };

    useEffect(() => {
        loadTariff();
    }, [transferService]);

    const onAddTariffPrivate = () => {
        setTariffPrivateLineToEdit(null);
        setShowFormModalTariffPrivate(true);
    }

    const onEditTariffPrivate = (x: IDetailTariffPrivateTransferService) => {
        setTariffPrivateLineToEdit(x);
        setShowFormModalTariffPrivate(true);
    }

    const onDeleteTariffPrivate = async (x: IDetailTariffPrivateTransferService) => {
        if (transferService) {
            try {
                setLoading(true);
                await deleteTransferServiceTariffPrivate(transferService.id, x.id);
                setLoading(false);
                setAlert({
                    show: true,
                    severity: 'success',
                    message: 'Linea de tarifa eliminada satisfactoriamente'
                });
                setTimeout(() => loadTariff(), 1500)
            } catch (error) {
                setAlert({
                    show: true,
                    severity: 'error',
                    message: extractErrorMessage(error, 'Ocurrió un error al eliminar la linea de tarifa')
                });
                setLoading(false);
            }
        }

    }


    useEffect(() => {
        const loadTransferService = async () => {
            if (transferServiceId && !isNaN(Number(transferServiceId))) {
                try {
                    setLoading(true);
                    const result = (await getTransferService(Number(transferServiceId))).data;
                    setTransferService(result);
                    setLoading(false);
                } catch (error) {
                    setAlert({
                        show: true,
                        severity: 'error',
                        message: extractErrorMessage(error, 'Ocurrió un error al buscar el servicio de traslado')
                    });
                    setLoading(false);
                }
            }

        };
        loadTransferService();

    }, [transferServiceId]);


    useEffect(() => {
        const loadProviders = async () => {
            if (transferService) {
                try {
                    setLoading(true);
                    const result = (await getAllProviders()).data;
                    setProviders(result);
                    setLoading(false);
                } catch (error) {
                    setAlert({
                        show: true,
                        severity: 'error',
                        message: extractErrorMessage(error, 'Ocurrió un error al obtener el listado de proveedores')
                    });
                    setLoading(false);
                }
            }

        };
        loadProviders();

    }, [transferService]);

    const handleCloseModalTariffPrivate = () => {
        setShowFormModalTariffPrivate(false);
        setTariffPrivateLineToEdit(null);
    }


    const handleSubmitFormTariffPrivate = async (value: IUpsertTariffPrivateTransferServiceFormValue) => {
        const messageSuccess = !tariffPrivateLineToEdit ? 'creada' : 'actualizada';
        const messageError = !tariffPrivateLineToEdit ? 'crear' : 'actualizar';
        if (transferService) {
            try {

                const valueToSend: IUpsertTariffPrivateTransferService = {
                    transferServiceId: transferService.id,
                    providerId: Number(value.providerId),
                    totalPrice: Number(value.totalPrice),
                    dateFrom: value.dateFrom,
                    timeFrom: value.timeFrom,
                    dateTo: value.dateTo,
                    timeTo: value.timeTo
                }
                setLoading(true);
                if (!tariffPrivateLineToEdit) {
                    await createTransferServiceTariffPrivate(transferService.id, valueToSend);
                } else {
                    await updateTransferServiceTariffPrivate(transferService.id, tariffPrivateLineToEdit.id, valueToSend);
                }

                setLoading(false);
                setAlert({
                    show: true,
                    severity: 'success',
                    message: 'Linea de tarifa ' + messageSuccess + ' satisfactoriamente'
                });
                setTimeout(() => {
                    handleCloseModalTariffPrivate();
                    loadTariff();
                }, 1000)
            } catch (error) {
                setAlert({
                    show: true,
                    severity: 'error',
                    message: extractErrorMessage(error, 'Ocurrió un error al ' + messageError + ' la linea de tarifa')
                });
                setLoading(false);
            }
        }
    }

    // tariff not private function

    const onAddTariffNotPrivate = () => {
        setTariffNotPrivateLineToEdit(null);
        setShowFormModalTariffNotPrivate(true);
    }

    const onEditTariffNotPrivate = (x: IDetailTariffNotPrivateTransferService) => {
        setTariffNotPrivateLineToEdit(x);
        setShowFormModalTariffNotPrivate(true);
    }

    const onDeleteTariffNotPrivate = async (x: IDetailTariffNotPrivateTransferService) => {
        if (transferService) {
            try {
                setLoading(true);
                await deleteTransferServiceTariffNotPrivate(transferService.id, x.id);
                setLoading(false);
                setAlert({
                    show: true,
                    severity: 'success',
                    message: 'Línea de tarifa eliminada satisfactoriamente'
                });
                setTimeout(() => loadTariff(), 1500)
            } catch (error) {
                setAlert({
                    show: true,
                    severity: 'error',
                    message: extractErrorMessage(error, 'Ocurrió un error al eliminar la línea de tarifa')
                });
                setLoading(false);
            }
        }

    }

    const handleCloseModalTariffNotPrivate = () => {
        setShowFormModalTariffNotPrivate(false);
        setTariffNotPrivateLineToEdit(null);
    }

    const handleSubmitFormTariffNotPrivate = async (value: IUpsertTariffNotPrivateTransferServiceFormValue) => {
        const messageSuccess = !tariffNotPrivateLineToEdit ? 'creada' : 'actualizada';
        const messageError = !tariffNotPrivateLineToEdit ? 'crear' : 'actualizar';
        if (transferService) {
            try {

                const valueToSend: IUpsertTariffNotPrivateTransferService = {
                    transferServiceId: transferService.id,
                    providerId: Number(value.providerId),
                    adultPrice: Number(value.adultPrice),
                    childPrice: Number(value.childPrice),
                    dateFrom: value.dateFrom,
                    timeFrom: value.timeFrom,
                    dateTo: value.dateTo,
                    timeTo: value.timeTo
                }
                setLoading(true);
                if (!tariffNotPrivateLineToEdit) {
                    await createTransferServiceTariffNotPrivate(transferService.id, valueToSend);
                } else {
                    await updateTransferServiceTariffNotPrivate(transferService.id, tariffNotPrivateLineToEdit.id, valueToSend);
                }

                setLoading(false);
                setAlert({
                    show: true,
                    severity: 'success',
                    message: 'Linea de tarifa ' + messageSuccess + ' satisfactoriamente'
                });
                setTimeout(() => {
                    handleCloseModalTariffNotPrivate();
                    loadTariff();
                }, 1000)
            } catch (error) {
                setAlert({
                    show: true,
                    severity: 'error',
                    message: extractErrorMessage(error, 'Ocurrió un error al ' + messageError + ' la linea de tarifa')
                });
                setLoading(false);
            }
        }
    }


    return (<><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            {transferService && transferService?.isPrivate &&
                <TariffTransferServicePrivateList title={'Listado de tarifas del servicio de traslado ' + transferService.name}
                                                  items={tariffPrivate}
                                                  onAdd={onAddTariffPrivate}
                                                  onEdit={onEditTariffPrivate}
                                                  onDelete={onDeleteTariffPrivate}

                />}
            {transferService && !transferService.isPrivate &&
                <TariffTransferServiceNotPrivateList title={'Listado de tarifas del servicio ' + transferService.name}
                                                     items={tariffNotPrivate}
                                                     onAdd={onAddTariffNotPrivate}
                                                     onEdit={onEditTariffNotPrivate}
                                                     onDelete={onDeleteTariffNotPrivate}

                />}
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
            {showFormModalTariffPrivate &&
                <Dialog fullWidth={true} open={showFormModalTariffPrivate} onClose={handleCloseModalTariffPrivate}>
                    <DialogContent>

                        <DialogContentText>
                            <TariffTransferServicePrivateUpsertForm handleClose={handleCloseModalTariffPrivate}
                                                                    loading={loading}
                                                                    handleSubmitForm={handleSubmitFormTariffPrivate}
                                                                    value={tariffPrivateLineToEdit}
                                                                    providers={providers}/>
                        </DialogContentText>

                    </DialogContent>

                </Dialog>}

            {showFormModalTariffNotPrivate && <Dialog fullWidth={true} open={showFormModalTariffNotPrivate}
                                                      onClose={handleCloseModalTariffNotPrivate}>
                <DialogContent>
                    <DialogContentText>
                        <TariffTransferServiceNotPrivateUpsertForm handleClose={handleCloseModalTariffNotPrivate}
                                                                   loading={loading}
                                                                   handleSubmitForm={handleSubmitFormTariffNotPrivate}
                                                                   value={tariffNotPrivateLineToEdit}
                                                                   providers={providers}/>
                    </DialogContentText>

                </DialogContent>

            </Dialog>}
        </>
    );
}

export default TariffTransferServiceListContainer;
