import React from 'react';
import {Autocomplete, Box, Button, Card, CardContent, FormControl, TextField, Typography} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {IDetailAirport, IUpsertAirport} from "../../types";
import {useNavigate} from "react-router-dom";
import {IDestinationList} from "../../../destinations/types";

export interface AirportUpsertFormProps {
    handleSubmitForm: (value: IUpsertAirport) => void;
    loading: boolean;
    destinations: IDestinationList[]
    value?: IDetailAirport,
}

const defaultValue: IUpsertAirport = {
    code: '',
    name: '',
    destinationCode: '',
};
export const AirportUpsertForm: React.FC<AirportUpsertFormProps> = ({
                                                                                        handleSubmitForm,
                                                                                        loading,
                                                                                        value,
                                                                                        destinations
                                                                                    }) => {

    const methods = useForm<IUpsertAirport>({
        mode: 'all',
        defaultValues: value || defaultValue
    });

    const navigate = useNavigate();
    const {
        control,
        formState: {errors, isValid},
        getValues
    } = methods;


    return (
        <>
            <Card sx={{maxWidth: 800, width: '100%'}}>
                <CardContent>
                    <Typography variant="h5" style={{fontWeight: 'bold'}}
                                p={1}>{value ? 'Actualizar' : 'Nuevo'} Aeropuerto</Typography>
                    <br/>
                    <Box component="form" display="flex" flexDirection="column" gap={1}>

                        <Box display="flex" alignItems="center" gap={2} flex={1}  p={1}>
                            <Controller
                                name="code"
                                control={control}
                                rules={{
                                    required: { value: true, message: 'Este campo es requerido' },
                                    minLength: {
                                        value: 3,
                                        message: 'El campo excede el largo mínimo permitido de: 3 caracteres'
                                    },
                                    maxLength: {
                                        value: 3,
                                        message: 'El campo excede el largo máximo permitido de: 3 caracteres'
                                    }
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="Código"
                                        size={'small'}
                                        type="text"
                                        helperText={errors.code ? errors.code.message : ''}
                                        error={!!errors.code}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>

                        <Box display="flex" flexDirection="row" justifyContent="space-around">
                            <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{
                                        required: {value: true, message: 'Este campo es requerido'},
                                        maxLength: {
                                            value: 500,
                                            message: 'El campo excede el largo máximo permitido de: 500 caracteres'
                                        }
                                    }}
                                    render={({field}) => (
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            label="Nombre"
                                            size={'small'}
                                            type="text"
                                            helperText={errors.name ? errors.name.message : ''}
                                            error={!!errors.name}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>
                        </Box>
                        

                        <Box display="flex" alignItems="center" gap={2} flex={3} p={1}>
                            <Controller
                                name="destinationCode"
                                control={control}
                                rules={{
                                    required: {value: true, message: 'Este campo es requerido'}
                                }}
                                render={({field}) => (
                                    <FormControl sx={{width: '100%'}}>
                                        <Autocomplete
                                            options={destinations}
                                            getOptionLabel={(option: IDestinationList) => `${option.code}-${option.name}, ${option.countryName}`}
                                            value={
                                                destinations.find((option) => option.code === field.value) || null
                                            }
                                            onChange={(event, value) => {
                                                const selectedValues = (value as IDestinationList)?.code || '';
                                                field.onChange(selectedValues);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size={'small'}
                                                    variant="outlined"
                                                    required
                                                    label="Seleccione el destino"
                                                    fullWidth
                                                    helperText={errors.destinationCode ? errors.destinationCode.message : ''}
                                                    error={!!errors.destinationCode}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                )}
                            />
                        </Box>

                        <Box display="flex" width="100%" justifyContent="flex-end" gap={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => navigate('/airports')}
                            >
                                Volver al listado
                            </Button>
                            <Button
                                variant="contained"
                                disabled={!isValid || loading}
                                color="primary"
                                onClick={() => handleSubmitForm(getValues())}
                            >
                                Guardar
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
        ;
};