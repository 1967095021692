import React from 'react';
import { Box, Button, Card, CardContent, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { IListTag, ITag } from "../../types";
import TagPreview from "../TagPreview/TagPreview";

export interface TagUpsertFormProps {
    handleSubmitForm: (value: ITag) => void;
    loading: boolean;
    value?: IListTag;
}

export const TagUpsertForm: React.FC<TagUpsertFormProps> = ({ handleSubmitForm, loading, value }) => {

    const methods = useForm<ITag>({
        mode: 'all',
        defaultValues: { name: value?.name || 'Nombre de la etiqueta', backgroundColor: value?.backgroundColor || '#e51010', textColor: value?.textColor || '#e1dfe7' }
    });

    const navigate = useNavigate();
    const {
        control,
        formState: { errors, isValid },
        getValues,
        watch,
    } = methods;

    const watchFields = watch(["name", "backgroundColor", "textColor"]);

    return (
        <Card sx={{ maxWidth: 800, width: '100%' }}>
            <CardContent>
                <Typography variant="h5" style={{ fontWeight: 'bold' }} p={1}>
                    {value ? 'Actualizar etiqueta' : 'Crear nueva etiqueta'}
                </Typography>
                <br />
                <Box display="flex" flexDirection="row" justifyContent="space-between" gap={4}>
                    <Box display="flex" flexDirection="column" flex={1} gap={2}>
                        <Box>
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: { value: true, message: 'Este campo es requerido' },
                                    maxLength: {
                                        value: 50,
                                        message: 'El campo excede el largo máximo permitido de: 50 caracteres'
                                    }
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="Nombre"
                                        size="small"
                                        type="text"
                                        helperText={errors.name ? errors.name.message : ''}
                                        error={!!errors.name}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>

                        <Box>
                            <Controller
                                name="backgroundColor"
                                control={control}
                                rules={{
                                    required: { value: true, message: 'Este campo es requerido' },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="Color del fondo"
                                        size="small"
                                        type="color"
                                        helperText={errors.backgroundColor ? errors.backgroundColor.message : ''}
                                        error={!!errors.backgroundColor}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>

                        <Box>
                            <Controller
                                name="textColor"
                                control={control}
                                rules={{
                                    required: { value: true, message: 'Este campo es requerido' },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="Color del texto"
                                        size="small"
                                        type="color"
                                        helperText={errors.textColor ? errors.textColor.message : ''}
                                        error={!!errors.textColor}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>

                        <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => navigate('/tags')}
                            >
                                Volver al listado
                            </Button>
                            <Button
                                variant="contained"
                                disabled={!isValid || loading}
                                color="primary"
                                onClick={() => handleSubmitForm(getValues())}
                            >
                                Guardar
                            </Button>
                        </Box>
                    </Box>

                    <Box display="flex" alignItems="center" justifyContent="center" flex={1}>
                        <TagPreview
                            label={watchFields[0]  || "Preview"}
                            bgColor={watchFields[1] || "#FFFFFF"}
                            textColor={watchFields[2] || "#000000"}
                        />
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};
