import {ApiService} from "../../core/services";
import {AxiosResponse} from "axios";
import {IPaginatedResult, ISort} from "../../core/types";
import {cleanFilters, formatSortingFields} from "../../core/helper";
import {IFilterAuthorizationOperator, IListAuthorizationOperator, IUpsertAuthorizationOperator} from "./types";

const endpoints = {
    list: 'api/authorization-operators',
    listAll: 'api/authorization-operators/list/all',
    listAllCardPortfolioAuth: 'api/authorization-operators/list/all-for-use-card-portfolio-authorization',
    listAllCorporateCardAuth: 'api/authorization-operators/list/all-for-use-corporate-card-authorization',
    get: 'api/authorization-operators/:id',
    create: 'api/authorization-operators',
    update: 'api/authorization-operators/:id',
    delete: 'api/authorization-operators/:id',
}

export const getAuthorizationOperators = (page = 1, pageSize = 10, sortingFields: ISort[], search: string,filters?: IFilterAuthorizationOperator): Promise<AxiosResponse<IPaginatedResult<IListAuthorizationOperator>>> => {
    const sorts = formatSortingFields(sortingFields);
    return ApiService.get(endpoints.list, {
        params: {
            page,
            pageSize,
            ...(sorts.length > 0 && {sort: sorts}),...(filters && cleanFilters(filters)), ...(search && search !== '' && {search: search})
        }
    });
};
//export const getAllAuthorizationOperators = (): Promise<AxiosResponse<IListAuthorizationOperator[]>> => ApiService.get(endpoints.listAll);
export const getAllAuthorizationOperatorsToCardPortfolio = (): Promise<AxiosResponse<IListAuthorizationOperator[]>> => ApiService.get(endpoints.listAllCardPortfolioAuth);
export const getAllAuthorizationOperatorsToCorporateCard = (): Promise<AxiosResponse<IListAuthorizationOperator[]>> => ApiService.get(endpoints.listAllCorporateCardAuth);
export const getAuthorizationOperator = (id: string): Promise<AxiosResponse<IListAuthorizationOperator>> => ApiService.get(endpoints.get.replace(':id', id ));
export const createAuthorizationOperator = (data: IUpsertAuthorizationOperator): Promise<AxiosResponse<void>> => ApiService.post(endpoints.create, data);
export const updateAuthorizationOperator = (id: string, data: IUpsertAuthorizationOperator): Promise<AxiosResponse<void>> => ApiService.put(endpoints.update.replace(':id', id ), data);
export const deleteAuthorizationOperator = (id: string): Promise<AxiosResponse<void>> => ApiService.delete(endpoints.delete.replace(':id', id));
