import React, {Dispatch, SetStateAction, useState} from 'react';
import {
    Box,
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    Popover,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Toolbar,
    Typography,
} from '@mui/material';
import {
    Add as AddIcon,
    Cancel,
    CheckCircle,
    Edit as EditIcon,
    FilterList as FilterListIcon,
    Search as SearchIcon
} from '@mui/icons-material';
import {ISort} from "../../../../core/types";
import {IFilterUser, IListUser} from "../../types";
import {getNamesOfRoles} from "../../helpers";
import PasswordIcon from '@mui/icons-material/Password';
import EmptyTable from "../../../../core/components/EmptyTable/EmptyTable";
interface UsersListProps {
    title: string;
    search: string;
    setSearch: (search: string) => void;
    page: number;
    setPage: (page: number) => void;
    pageSize: number;
    setPageSize: (pageSize: number) => void;
    filters: IFilterUser;
    setFilters: (filter: IFilterUser) => void;
    order: ISort[],
    setOrder: Dispatch<SetStateAction<ISort[]>>;
    items: IListUser[];
    totalElements: number;
    onAdd: () => void;
    onEdit: (item: IListUser) => void;
    onChangePassword: (item: IListUser) => void;
}

const UsersList: React.FC<UsersListProps> = ({
                                                 title,
                                                 search,
                                                 setSearch,
                                                 page,
                                                 setPage,
                                                 pageSize,
                                                 totalElements,
                                                 setPageSize,
                                                 filters,
                                                 setFilters,
                                                 order,
                                                 setOrder,
                                                 items,
                                                 onAdd,
                                                 onEdit,
                                                 onChangePassword
                                             }) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [internalFilters, setInternalFilters] = useState<IFilterUser>({name: '', email: '', isEnable: undefined});
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleFilterChange = (field: keyof typeof filters) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setInternalFilters({...filters, [field]: event.target.value});
    };

    const handleChangeIsEnableFilter = (event: SelectChangeEvent<unknown>) => {
        if(event.target.value!==undefined){
            setInternalFilters({...filters, isEnable: event.target.value =='true'});
        } else {
            setInternalFilters({...filters, isEnable: undefined});
        }

    };

    const handleApplyFilter = () => {
        setFilters(internalFilters);
        handleCloseMenu();
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (property: keyof IListUser) => {
        const exist = order.find(o => o.field === property)
        const isAsc = order.find(o => o.field === property)?.order === 'asc';
        if (!exist) {
            setOrder(prevOrder => [
                ...prevOrder.filter(o => o.field !== property),
                {field: property, order: isAsc ? 'desc' : 'asc'},
            ]);
        } else {
            if (exist.order === 'asc') {
                setOrder(prevOrder => [
                    ...prevOrder.filter(o => o.field !== property),
                    {field: property, order: 'desc'},
                ]);
            } else {
                setOrder(prevOrder => [
                    ...prevOrder.filter(o => o.field !== property),
                ]);
            }
        }

    };

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);


    const handleCloseMenu = () => setAnchorEl(null);


    const handleCancelFilters = () => {
        setInternalFilters(filters);
        handleCloseMenu();
    };


    return (
        <Box sx={{width: '100%', padding: 3}}>
            <Typography variant="h4" component="div" sx={{mb: 2}}>
                {title}
            </Typography>
            <Toolbar sx={{display: 'flex', justifyContent: 'space-between', mb: 2}} style={{paddingLeft: 0}}>
                <Button
                    variant="contained"
                    startIcon={<AddIcon/>}
                    onClick={() => onAdd()}
                >
                    Nuevo usuario
                </Button>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <TextField
                        variant="outlined"
                        placeholder="Buscar"
                        size="small"
                        value={search}
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon/>
                                </InputAdornment>
                            ),
                        }}
                        sx={{mr: 2, width: 300}}
                    />
                    <Button variant="outlined" startIcon={<FilterListIcon/>} onClick={handleOpenMenu}>
                        Filtrar
                    </Button>
                    <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        disableRestoreFocus
                    >
                        <Box sx={{p: 2, display: 'flex', flexDirection: 'column', gap: 1}}>
                            <TextField
                                label="Nombre contiene"
                                size='small'
                                value={internalFilters.name}
                                onChange={handleFilterChange('name')}
                                fullWidth
                            />
                            <TextField
                                label="Email contiene"
                                size='small'
                                value={internalFilters.email}
                                onChange={handleFilterChange('email')}
                                fullWidth
                            />
                            <FormControl fullWidth >
                                <InputLabel id="is-active-select-label">Activo</InputLabel>
                                <Select
                                    size='small'
                                    labelId="is-active-select-label"
                                    id="is-active-select"
                                    value={internalFilters.isEnable}
                                    label="Activo"
                                    onChange={handleChangeIsEnableFilter}
                                >
                                    <MenuItem value={undefined} style={{minHeight:'24px'}}>
                                        <em></em>
                                    </MenuItem>
                                    <MenuItem value={'true'}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <CheckCircle style={{color: 'green', marginRight: 8}}/>
                                            <ListItemText primary="Sí"/>
                                        </div>
                                    </MenuItem>
                                    <MenuItem value={'false'}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <Cancel style={{color: 'red', marginRight: 8}}/>
                                            <ListItemText primary="No"/>
                                        </div>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2}}>
                                <Button variant="contained" onClick={handleApplyFilter} sx={{mr: 2}}>
                                    Aplicar Filtros
                                </Button>
                                <Button variant="outlined" onClick={handleCancelFilters}>
                                    Cancelar
                                </Button>
                            </Box>
                        </Box>
                    </Popover>
                </Box>
            </Toolbar>
            <Typography variant="body2" sx={{mb: 1}}>
                Mostrando {items.length} resultados de {totalElements}
            </Typography>
            <TableContainer component="main" sx={{width: '100%'}}>
                <Table sx={{width: '100%'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'name')}
                                    direction={order.find(o => o.field === 'name')?.order || 'asc'}
                                    onClick={() => handleRequestSort('name')}
                                >
                                    Nombre
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'email')}
                                    direction={order.find(o => o.field === 'email')?.order || 'asc'}
                                    onClick={() => handleRequestSort('email')}
                                >
                                    Email
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'roles')}
                                    direction={order.find(o => o.field === 'roles')?.order || 'asc'}
                                    onClick={() => handleRequestSort('roles')}
                                >
                                    Roles
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'isEnable')}
                                    direction={order.find(o => o.field === 'isEnable')?.order || 'asc'}
                                    onClick={() => handleRequestSort('isEnable')}
                                >
                                    Activo
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.email}</TableCell>
                                <TableCell>{getNamesOfRoles(item.roles)}</TableCell>
                                <TableCell>
                                    {item.isEnable ? (
                                        <CheckCircle style={{color: 'green'}}/>
                                    ) : (
                                        <Cancel style={{color: 'red'}}/>
                                    )}
                                </TableCell>
                                <TableCell>
                                    <IconButton onClick={() => onEdit(item)}>
                                        <EditIcon/>
                                    </IconButton>
                                    <IconButton title={'Cambiar contraseña'} onClick={() => onChangePassword(item)}>
                                        <PasswordIcon/>
                                    </IconButton>
                                </TableCell>

                            </TableRow>
                        ))}
                        {items.length === 0 && <EmptyTable colSpan={5} />}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                labelRowsPerPage={'Filas por página'}
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={totalElements}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
};

export default UsersList;
