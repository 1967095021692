import React, {useEffect, useState} from 'react';
import {Box, Card, CardContent, CircularProgress, Grid, IconButton, Typography} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Cards, {Focused} from 'react-credit-cards-2';
import "react-credit-cards-2/dist/es/styles-compiled.css";
import {IDetailCorporateCard} from "../../types";


interface CorporateCardInformationSummaryDetailProps {
    cardInfo: IDetailCorporateCard;
    loading: boolean;
}

const CorporateCardInformationSummaryDetail: React.FC<CorporateCardInformationSummaryDetailProps> = ({
                                                                                                         cardInfo,
                                                                                                         loading
                                                                                                     }) => {
    const [showBody, setShowBody] = useState(false);
    const [showNumber, setShowNumber] = useState(true);
    const [showExpiration, setShowExpiration] = useState(true);
    const [showCVV, setShowCVV] = useState(true);
    const [cardFocus, setCardFocus] = useState<Focused | undefined>('name');


    // Controla la visibilidad del body y carga los datos si no están cargados
    const handleToggleView = () => {
        setShowBody(!showBody);
    };

    useEffect(() => {
        // Priorizar el último campo mostrado: CVV > Expiración > Número > Nombre
        if (showNumber) {
            setCardFocus('number');
        } else if (showExpiration) {
            setCardFocus('expiry');
        } else if (showCVV) {
            setCardFocus('cvc');
        } else setCardFocus('name');
    }, [showNumber, showExpiration, showCVV, cardInfo]);

    return (<>
            <Card sx={{margin: '0', padding: 1, mt: 1}}>
                <CardContent>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography variant="h5">
                            Información completa de la tarjeta
                        </Typography>
                        <IconButton onClick={handleToggleView}>
                            {showBody ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                        </IconButton>
                    </Box>

                    {showBody && (
                        <>
                            {loading ? (
                                <Box sx={{display: 'flex', justifyContent: 'center', marginTop: 1}}>
                                    <CircularProgress/>
                                </Box>
                            ) : (
                                cardInfo && (
                                    <Grid container spacing={2} sx={{marginTop: 1}}>
                                        {/* Primera columna con campos */}
                                        <Grid item xs={12} sm={6}>
                                            {/* Número de la tarjeta */}
                                            <Box sx={{display: 'flex', alignItems: 'center', marginBottom: 2}}>
                                                <Typography variant="subtitle1" sx={{marginRight: 1}}>
                                                    Número:
                                                </Typography>
                                                <Typography variant="h6">
                                                    {showNumber ? cardInfo.cardNumber : '**** **** **** ****'}
                                                </Typography>
                                                <IconButton onClick={() => setShowNumber(!showNumber)}>
                                                    {showNumber ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                                                </IconButton>
                                            </Box>

                                            {/* Titular */}
                                            <Box sx={{display: 'flex', alignItems: 'center', marginBottom: 2}}>
                                                <Typography variant="subtitle1" sx={{marginRight: 1}}>
                                                    Titular:
                                                </Typography>
                                                <Typography variant="h6">{cardInfo.cardHolder}</Typography>
                                            </Box>

                                            {/* Fecha de expiración */}
                                            <Box sx={{display: 'flex', alignItems: 'center', marginBottom: 2}}>
                                                <Typography variant="subtitle1" sx={{marginRight: 1}}>
                                                    Fecha de expiración:
                                                </Typography>
                                                <Typography variant="h6">
                                                    {showExpiration ? cardInfo.expiry : '****'}
                                                </Typography>
                                                <IconButton onClick={() => setShowExpiration(!showExpiration)}>
                                                    {showExpiration ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                                                </IconButton>
                                            </Box>

                                            {/* CVV */}
                                            <Box sx={{display: 'flex', alignItems: 'center', marginBottom: 2}}>
                                                <Typography variant="subtitle1" sx={{marginRight: 1}}>
                                                    CVV:
                                                </Typography>
                                                <Typography variant="h6">{showCVV ? cardInfo.cvc : '***'}</Typography>
                                                <IconButton onClick={() => setShowCVV(!showCVV)}>
                                                    {showCVV ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                                                </IconButton>
                                            </Box>


                                        </Grid>

                                        {/* Segunda columna con componente react-credit-cards-2 */}
                                        <Grid item xs={12} sm={6}>
                                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                                <Cards
                                                    focused={cardFocus}
                                                    cvc={showCVV ? cardInfo.cvc : '***'}
                                                    expiry={showExpiration ? cardInfo.expiry : '**/**'}
                                                    name={cardInfo.cardHolder}
                                                    number={showNumber ? cardInfo.cardNumber : '**** **** **** ****'}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )
                            )}
                        </>
                    )}
                </CardContent>
            </Card>
        </>
    );
};

export default CorporateCardInformationSummaryDetail;
