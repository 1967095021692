import {IPaginatedResult, ISort} from "../../core/types";
import {AxiosResponse} from "axios";
import {cleanFilters, formatSortingFields} from "../../core/helper";
import {ApiService} from "../../core/services";
import {
    IClearInformationCardPortfolioDTO,
    IDetailICardPortfolio,
    IFilterCardPortfolio,
    IListCardPortfolio,
    IMercureToken
} from "./types";

const endpoints = {
    getMercureToken: 'api/mercure/token',
    list: 'api/cards-portfolio',
    get: 'api/cards-portfolio/:id',
    cardClearInformation: 'api/cards-portfolio/:id/clear-information',
    enterInViewDetail:'api/cards-portfolio/:id/enter',
    exitInViewDetail:'api/cards-portfolio/:id/exit',
    changeFinalized:'api/cards-portfolio/:id/change-to-finalized',
}

export const getCardsPortfolio = (page = 1, pageSize = 10, sortingFields: ISort[], search: string, filters?: IFilterCardPortfolio): Promise<AxiosResponse<IPaginatedResult<IListCardPortfolio>>> => {
    const sorts = formatSortingFields(sortingFields);
    return ApiService.get(endpoints.list, {
        params: {
            page,
            pageSize,
            ...(sorts.length > 0 && {sort: sorts}), ...(filters && cleanFilters(filters)), ...(search && search !== '' && {search: search})
        }
    });
};

export const getMercureToken = (): Promise<AxiosResponse<IMercureToken>> => ApiService.get(endpoints.getMercureToken);
export const getCardPortfolioDetail = (id: number): Promise<AxiosResponse<IDetailICardPortfolio>> => ApiService.get(endpoints.get.replace(':id', id + ''));
export const getCardClearInformation = (id: number): Promise<AxiosResponse<IClearInformationCardPortfolioDTO>> => ApiService.get(endpoints.cardClearInformation.replace(':id', id + ''));
export const enterInCardPortfolioDetail = (id: number): Promise<AxiosResponse<void>> => ApiService.post(endpoints.enterInViewDetail.replace(':id', id + ''),{});
export const exitInCardPortfolioDetail = (id: number): Promise<AxiosResponse<void>> => ApiService.post(endpoints.exitInViewDetail.replace(':id', id + ''),{});
export const changeToFinalizedCardPortfolio = (id: number): Promise<AxiosResponse<void>> => ApiService.put(endpoints.changeFinalized.replace(':id', id + ''),{});