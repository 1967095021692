import {ApiService} from "../../core/services";
import {AxiosResponse} from "axios";
import {
    IBitrixDealInfoToOrder,
    ICreateSaleOrder,
    ICreateSaleOrderResult,
    IFilterSaleOrder,
    IListSaleOrder
} from "./types";
import {IPaginatedResult, ISort} from "../../core/types";
import {cleanFilters, formatSortingFields} from "../../core/helper";

const endpoints = {
    list: 'api/sales-order',
    create: 'api/sales-order',
    getDealDetail: 'api/bitrix/:dealId/info-to-sale-order',
}

export const getSalesOrder = (page = 1, pageSize = 10, sortingFields: ISort[], search: string, filters?: IFilterSaleOrder): Promise<AxiosResponse<IPaginatedResult<IListSaleOrder>>> => {
    const sorts = formatSortingFields(sortingFields);
    return ApiService.get(endpoints.list, {
        params: {
            page,
            pageSize,
            ...(sorts.length > 0 && {sort: sorts}), ...(filters && cleanFilters(filters)), ...(search && search !== '' && {search: search})
        }
    });
};
export const createSaleOrder = (data: ICreateSaleOrder): Promise<AxiosResponse<ICreateSaleOrderResult>> => ApiService.post(endpoints.create, data);

export const getDealInfoToSaleOrder = (dealId: string): Promise<AxiosResponse<IBitrixDealInfoToOrder>> => ApiService.get(endpoints.getDealDetail.replace(':dealId', dealId));