import React from 'react';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';

const AppBottomBar: React.FC = () => {
    return (
        <AppBar
            position="fixed"
            sx={{
                top: 'auto',
                bottom: 0,
                zIndex: (theme) => theme.zIndex.drawer + 1, // Asegura que esté por encima del Sidebar
                bgcolor: 'primary.main',
                height:50
            }}
        >
            <Toolbar >
                <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                    <Typography variant="body2" color="inherit">
                        Todos los derechos reservados © {new Date().getFullYear()}
                    </Typography>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default AppBottomBar;
