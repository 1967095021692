import {ApiService} from "../../core/services";
import {AxiosResponse} from "axios";
import {IPaginatedResult, ISort} from "../../core/types";
import {cleanFilters, formatSortingFields} from "../../core/helper";
import {IDetailTransferService, IFilterTransferService, IListTransferService, IUpsertTransferService} from "./types";

const endpoints = {
    list: 'api/transfer-services',
    get: 'api/transfer-services/:id',
    create: 'api/transfer-services',
    update: 'api/transfer-services/:id',
    delete: 'api/transfer-services/:id',
}

export const getTransferServices = (page = 1, pageSize = 10, sortingFields: ISort[], search:string, filters?: IFilterTransferService): Promise<AxiosResponse<IPaginatedResult<IListTransferService>>> => {
    const sorts = formatSortingFields(sortingFields);
    return ApiService.get(endpoints.list, {
        params: {
            page,
            pageSize,
            ...(sorts.length > 0 && {sort: sorts}), ...(filters && cleanFilters(filters)), ...(search && search!=='' && {search:search})
        }
    });
};
export const getTransferService = (id:number): Promise<AxiosResponse<IDetailTransferService>> => ApiService.get(endpoints.get.replace(':id',id+''));
export const createTransferService = (data: IUpsertTransferService): Promise<AxiosResponse<void>> => ApiService.post(endpoints.create, data);
export const updateTransferService = (id: number, data: IUpsertTransferService): Promise<AxiosResponse<void>> => ApiService.put(endpoints.update.replace(':id', id+''), data);

export const deleteTransferService = (id: number): Promise<AxiosResponse<void>> => ApiService.delete(endpoints.delete.replace(':id', id+''));