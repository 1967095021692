import {AxiosResponse} from "axios";
import {
    ICreateCorporateCardAuthorization,
    IFilterListCorporateCardAuthorization,
    IListCorporateCardAuthorization,
    IUpdateCorporateCardAuthorization
} from "./types";
import {ApiService} from "../../../../core/services";
import {IPaginatedResult, ISort} from "../../../../core/types";
import {cleanFilters, formatSortingFields} from "../../../../core/helper";

const endpoints = {
    list: 'api/corporate-cards/:corporateCardId/authorizations',
    create: 'api/corporate-cards/:corporateCardId/authorizations',
    update: 'api/corporate-cards/:corporateCardId/authorizations/:id',
}

export const getCorporateCardAuthorizations = (corporateCardId: number, page = 1, pageSize = 10, sortingFields: ISort[], search: string, filters?: IFilterListCorporateCardAuthorization): Promise<AxiosResponse<IPaginatedResult<IListCorporateCardAuthorization>>> => {
    const sorts = formatSortingFields(sortingFields);
    return ApiService.get(endpoints.list.replace(':corporateCardId', corporateCardId + ''), {
        params: {
            page,
            pageSize,
            ...(sorts.length > 0 && {sort: sorts}), ...(filters && cleanFilters(filters)), ...(search && search !== '' && {search: search})
        }
    });
};

export const createCorporateCardAuthorization = (corporateCardId: number, data: ICreateCorporateCardAuthorization): Promise<AxiosResponse<void>> => ApiService.post(endpoints.create.replace(':corporateCardId', corporateCardId + ''), data);
export const updateCorporateCardAuthorization = (corporateCardId: number, id: number, data: IUpdateCorporateCardAuthorization): Promise<AxiosResponse<void>> => ApiService.put(endpoints.update.replace(':corporateCardId', corporateCardId + '').replace(':id', id + ''), data);
