import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography,
} from '@mui/material';
import {Add as AddIcon} from '@mui/icons-material';
import {
    ICreateCardPortfolioRejectionResolved,
    IListCardPortfolioRejection,
    IUpsertCardPortfolioRejection, ResolvedMethodRejectionEnum,
} from '../../types';
import {ShowAlertState} from "../../../../../../core/types";
import {createRejection, getRejections, resolveRejection, updateRejection} from "../../services";
import {extractErrorMessage} from "../../../../../../core/helper";
import EditIcon from "@mui/icons-material/Edit";
import {getHumanNameResolvedMethodRejectionEnum} from "../../helper";
import {
    CardPortfolioRejectionUpsertForm,
    IUpsertCardPortfolioRejectionFormValue
} from "../CardPortfolioRejectionUpsertForm/CardPortfolioRejectionUpsertForm";
import {
    CardPortfolioRejectionResolveForm, IResolveCardPortfolioRejectionFormValue
} from "../CardPortfolioRejectionResolveForm/CardPortfolioRejectionResolveForm";
import VerifiedIcon from '@mui/icons-material/Verified';
import EmptyTable from "../../../../../../core/components/EmptyTable/EmptyTable";

interface CardPortfolioRejectionsListProps {
    cardPortfolioId: number;
    onLoading: (loading: boolean) => void;
    loading: boolean;
    setAlert: (alert: ShowAlertState) => void;
    onChangeRejections: () => void;
    allowCreateOrUpdateRejections: boolean
}

const CardPortfolioRejectionsList: React.FC<CardPortfolioRejectionsListProps> = ({
                                                                                     cardPortfolioId,
                                                                                     onLoading,
                                                                                     loading,
                                                                                     setAlert,
                                                                                     onChangeRejections,
                                                                                     allowCreateOrUpdateRejections
                                                                                 }) => {
    const [rejections, setRejections] = useState<IListCardPortfolioRejection[]>([]);
    const [showCreateRejectionForm, setShowCreateRejectionForm] = useState<boolean>(false);
    const [showUpdateRejectionForm, setShowUpdateRejectionForm] = useState<boolean>(false);
    const [showResolveRejectionForm, setShowResolveRejectionForm] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<null | IListCardPortfolioRejection>(null);
    const [existEnableReject, setExistEnableReject] = useState<boolean>(false);

    const loadRejections = async () => {
        try {
            onLoading(true);
            const result = (await getRejections(cardPortfolioId)).data;
            setExistEnableReject(result.some(x => x.isEnable))
            setRejections(result);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar los rechazos de la tarjeta en cartera')
            });
        } finally {
            onLoading(false);
        }
    }


    useEffect(() => {
        loadRejections();
    }, [cardPortfolioId]);

    const handleSubmitFormCreateRejection = async (value: IUpsertCardPortfolioRejectionFormValue) => {
        try {
            const valueToSend: IUpsertCardPortfolioRejection = {
                observations: value.observations
            }
            onLoading(true);
            await createRejection(cardPortfolioId, valueToSend);
            setShowCreateRejectionForm(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Rechazo creado satisfactoriamente'
            });
            setTimeout(() => {
                onChangeRejections();
                loadRejections();
            }, 2000);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al crear el rechazo')
            });
        } finally {
            onLoading(false);
        }
    }


    const handleSubmitFormUpdateRejection = async (value: IUpsertCardPortfolioRejectionFormValue) => {
        try {
            onLoading(true);
            const valueToSend: IUpsertCardPortfolioRejection = {
                observations: value.observations
            }
            await updateRejection(cardPortfolioId, selectedItem?.id || -1, valueToSend);
            handleCloseUpdateDialog();
            setAlert({
                show: true,
                severity: 'success',
                message: 'Rechazo actualizado satisfactoriamente'
            });
            setTimeout(() => {
                onChangeRejections();
                loadRejections();
            }, 2000);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al actualizar el rechazo')
            });
        } finally {
            onLoading(false);
        }
    }

    const handleSubmitFormResolveRejection = async (value: IResolveCardPortfolioRejectionFormValue) => {
        try {
            onLoading(true);
            const valueToSend: ICreateCardPortfolioRejectionResolved = {
                observations: value.observations,
                method:value.method as ResolvedMethodRejectionEnum
            }
            await resolveRejection(cardPortfolioId, selectedItem?.id || -1, valueToSend);
            handleCloseUpdateDialog();
            setAlert({
                show: true,
                severity: 'success',
                message: 'Rechazo resuelto satisfactoriamente'
            });
            setTimeout(() => {
                onChangeRejections();
                loadRejections();
            }, 2000);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al resolver el rechazo')
            });
        } finally {
            onLoading(false);
        }
    }


    const handleClickEdit = (item: IListCardPortfolioRejection) => {
        setSelectedItem(item);
        setShowUpdateRejectionForm(true);
    }
    const handleClickResolveRejection = (item: IListCardPortfolioRejection) => {
        setSelectedItem(item);
        setShowResolveRejectionForm(true);
    }

    const handleCloseUpdateDialog = () => {
        setSelectedItem(null);
        setShowUpdateRejectionForm(false);
    }


    const handleCreateRejection = () => {
        setShowCreateRejectionForm(true);
    };

    const handleCloseResolveRejectionDialog = () => {
        setSelectedItem(null);
        setShowResolveRejectionForm(false);
    }

    return (
        <Box sx={{width: '100%', padding: 3}}>
            <Typography variant="h5" component="div" sx={{mb: 2}}>
                Rechazos
            </Typography>
            {allowCreateOrUpdateRejections &&   !existEnableReject &&
                <Toolbar sx={{display: 'flex', justifyContent: 'space-between', mb: 2}} style={{paddingLeft: 0}}>

                    <Button
                        variant="contained"
                        startIcon={<AddIcon/>}
                        onClick={() => handleCreateRejection()}
                    >
                        Nuevo rechazo
                    </Button>
                </Toolbar>}
            <TableContainer component="main" sx={{width: '100%'}}>
                <Table sx={{width: '100%'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                ID
                            </TableCell>
                            <TableCell>
                                Activo (Sin resolver)
                            </TableCell>

                            <TableCell>
                                Observación de rechazo
                            </TableCell>

                            <TableCell>
                                Fecha de creación
                            </TableCell>
                            <TableCell>
                                Creado por
                            </TableCell>

                            <TableCell>
                                Fecha de modificación
                            </TableCell>
                            <TableCell>
                                Modificado por
                            </TableCell>
                            <TableCell>
                                Fecha de resolución
                            </TableCell>
                            <TableCell>
                                Resuelto por
                            </TableCell>

                            <TableCell>
                                Observaciones de la resolución
                            </TableCell>
                            <TableCell>
                                Método de resolución
                            </TableCell>

                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rejections.map((item) => (
                            <TableRow
                                key={item.id}
                                sx={{

                                    backgroundColor: item.isEnable
                                        ? 'rgba(255,97,58,0.98)'  // Fondo rojo claro si está próximo a vencer y no está eliminada
                                        : 'inherit',
                                    backgroundSize: '16px 16px',
                                    position: 'relative',
                                }}
                                title={item.isEnable ? 'Rechazo activo (Sin resolver)' : ''}
                            >
                                <TableCell>{item.id}</TableCell>
                                <TableCell>{item.isEnable ? 'Si' : 'No'}</TableCell>
                                <TableCell>{item.observations ? item.observations : ''}</TableCell>
                                <TableCell>{item.createdAt}</TableCell>
                                <TableCell>{item.createdBy}</TableCell>
                                <TableCell>{item.updatedAt ? item.updatedAt : '-'}</TableCell>
                                <TableCell>{item.updatedBy ? item.updatedBy : '-'}</TableCell>
                                <TableCell>{item.resolvedAt ? item.resolvedAt : '-'}</TableCell>
                                <TableCell>{item.resolvedBy ? item.resolvedBy : '-'}</TableCell>
                                <TableCell>{item.resolveObservations ? item.resolveObservations : '-'}</TableCell>
                                <TableCell>{item.resolveMethod ? getHumanNameResolvedMethodRejectionEnum(item.resolveMethod as ResolvedMethodRejectionEnum) : '-'}</TableCell>
                                <TableCell sx={{width: 150}}>
                                    {allowCreateOrUpdateRejections &&  <IconButton title={'Modificar observación'} onClick={() => handleClickEdit(item)}>
                                        <EditIcon/>
                                    </IconButton>}

                                {item.isEnable &&
                                    <IconButton title={'Resolver rechazo'}
                                                onClick={() => handleClickResolveRejection(item)}>
                                        <VerifiedIcon/>
                                    </IconButton>}
                                </TableCell>
                            </TableRow>
                        ))}
                        {rejections.length == 0 && <EmptyTable colSpan={12}  message={'Sin rechazos para mostrar'} />}
                    </TableBody>
                </Table>
            </TableContainer>

            {showCreateRejectionForm &&
                <Dialog fullWidth={true} open={showCreateRejectionForm}
                        onClose={() => setShowCreateRejectionForm(false)}>
                    <DialogContent>

                        <DialogContentText>
                            <CardPortfolioRejectionUpsertForm loading={loading}
                                                              handleClose={() => setShowCreateRejectionForm(false)}
                                                              handleSubmitForm={handleSubmitFormCreateRejection}
                                                              value={null}
                            />
                        </DialogContentText>

                    </DialogContent>

                </Dialog>}


            {showUpdateRejectionForm && selectedItem != null &&
                <Dialog fullWidth={true} open={true}
                        onClose={() => handleCloseUpdateDialog()}>
                    <DialogContent>

                        <DialogContentText>
                            <CardPortfolioRejectionUpsertForm loading={loading}
                                                              handleClose={handleCloseUpdateDialog}
                                                              handleSubmitForm={handleSubmitFormUpdateRejection}
                                                              value={selectedItem}
                            />
                        </DialogContentText>

                    </DialogContent>

                </Dialog>}
            {showResolveRejectionForm && selectedItem != null &&
                <Dialog fullWidth={true} open={true}
                        onClose={handleCloseResolveRejectionDialog}>
                    <DialogContent>

                        <DialogContentText>
                            <CardPortfolioRejectionResolveForm loading={loading}
                                                               handleClose={handleCloseResolveRejectionDialog}
                                                               handleSubmitForm={handleSubmitFormResolveRejection}
                                                               value={selectedItem}
                            />
                        </DialogContentText>

                    </DialogContent>

                </Dialog>}

        </Box>
    );
};

export default CardPortfolioRejectionsList;
