import React from 'react';
import { Card, CardContent, Typography, List, ListItem, ListItemText, Divider } from '@mui/material';
import {ICardPortfolioSummary} from "../../types";

interface SaleSummaryProps {
    summary: ICardPortfolioSummary;
}

const CardPortfolioSummary: React.FC<SaleSummaryProps> = ({ summary }) => {
    const items = [
        { label: 'Total en Tarjetas recibidas en el año', value: summary.totalSalesCurrentYear },
        { label: 'Total Pendiente de Autorización', value: summary.totalPendingAuthorizationCurrentYear },
        { label: 'Total Pre-Autorizado', value: summary.totalPreAuthorizedCurrentYear },
        { label: 'Total Autorizado', value: summary.totalAuthorizedCurrentYear },
        { label: 'Total Pendiente de Autorización (Cerca de 10 Días a Fecha de Salida)', value: summary.totalPendingAuthorizationNear10DaysToDepartureDateCurrentYear },
    ];

    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Resumen de Ventas ({summary.currentYear})
                </Typography>
                <List>
                    {items.map((item, index) => (
                        <React.Fragment key={index}>
                            <ListItem>
                                <ListItemText
                                    primary={item.label}
                                    secondary={`${parseFloat(item.value).toLocaleString('en-US', { minimumFractionDigits: 2 })} USD`}
                                />
                            </ListItem>
                            {index < items.length - 1 && <Divider component="li" />}
                        </React.Fragment>
                    ))}
                </List>
            </CardContent>
        </Card>
    );
};

export default CardPortfolioSummary;
