export interface ILogin {
    username: string;
    password: string
}


export enum RoleEnum {
    ROLE_SYS_ADMIN = 'ROLE_SYS_ADMIN',
    ROLE_ISSUER = 'ROLE_ISSUER',
    ROLE_SELLER = 'ROLE_SELLER',
    ROLE_DPTO_ADMIN = 'ROLE_DPTO_ADMIN',
    ROLE_ISSUER_CORPORATE_CARD = 'ROLE_ISSUER_CORPORATE_CARD'
}

export interface IUserLoginExtraData {
    name: string;
    roles: RoleEnum[];
    username: string;
    userId: number;
}


export interface ILoginResponse {
    token: string
    data: IUserLoginExtraData
}

export type AuthStatus = 'idle' | 'loading' | 'succeeded' | 'failed' | 'initializing';

export interface AuthState {
    status: AuthStatus;
    needsPasswordChange: boolean;
    errorMessage?: string;
}

export interface ApiError {
    code: number;
    message: string;
}
