import React from 'react';
import {Card, CardContent, List, Typography} from '@mui/material';
import SellerItem from "../SellerItem/SellerItem";
import {ICardPortfolioSummaryTopSeller} from "../../types";

interface TopSellersListProps {
    title: string;
    sellers: ICardPortfolioSummaryTopSeller[];
}

const TopSellersList: React.FC<TopSellersListProps> = ({title, sellers}) => {
    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    {title}
                </Typography>
                {sellers.length === 0 ? (
                    <Typography variant="body2" color="textSecondary">
                        No hay vendedores destacados para este mes.
                    </Typography>
                ) : (
                    <List>
                        {sellers.map((seller) => (
                            <SellerItem key={seller.salespersonId} seller={seller}/>
                        ))}
                    </List>
                )}
            </CardContent>
        </Card>
    );
};

export default TopSellersList;
