import {AxiosResponse} from "axios";
import {IListContactConfigurationToSendEmail, IUpdateContactConfigurationToSendEmail} from "./types";
import {ApiService} from "../../core/services";

const endpoints = {
    list: 'api/contact-email-configurations',
    update: 'api/contact-email-configurations',
}

export const getListEmailConfiguration = (): Promise<AxiosResponse<IListContactConfigurationToSendEmail[]>> => {
    return ApiService.get(endpoints.list);
};

export const updateEmailConfiguration = (data: IUpdateContactConfigurationToSendEmail): Promise<AxiosResponse<void>> =>
    ApiService.put(endpoints.update, data);
