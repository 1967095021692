import {IFilterListReceipts} from "./types";

export const defaultFilterListReceipts:IFilterListReceipts = {
    authorizationId: '',
    cardPortfolioId: '',
    cardHolder: '',
    cardStamp: '',
    lastFourCardDigit: '',
    amountAuthorized: '',
    amountAuthorizedFrom: '',
    amountAuthorizedTo: '',
    saleFileNumber: '',
    authorizationCode: '',
    wasReceiptMade: false
}