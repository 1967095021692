import {CookieService} from './services/CookieService';
import {ApiError, AuthState, ILogin, ILoginResponse} from './types';
import {AuthService} from "./services/AuthService";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

const initialState: AuthState = {
    status: 'initializing',
    needsPasswordChange: false,
};

export const doLogin = createAsyncThunk<ILoginResponse,
    ILogin,
    { rejectValue: ApiError }>('auth/login', async (payload, thunkApi) => {
    try {
        const response = await AuthService.postLogin(payload);
       // console.log(JSON.stringify(response.data, null, 2));
            CookieService.setToken(response.data.token,24);
            CookieService.setUserName(response.data.data.name);
            CookieService.setUserExtraData(response.data.data);

               return response.data;
    } catch (error) {
         // @ts-ignore
        if(error?.response?.data?.code === 401) {
            // @ts-ignore
            let message = error?.response?.data?.message || 'Usuario o contraseña incorrecta'
            if(message =='Invalid credentials.') message =  'Usuario o contraseña incorrecta'
            return thunkApi.rejectWithValue({code:401, message : message});
        }
         // @ts-ignore
        return thunkApi.rejectWithValue({code:error?.response?.data?.code||500, message :'Ocurrió un error en el servidor'});
       
        
    }
});


const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: () => {
          //  AuthService.postLogout();
            return {...initialState, status: 'idle'} as AuthState;
        },
        setIdle: (state:AuthState) => {
            state.status = 'idle';
        },
    },
    extraReducers: (builder) => {
        builder.addCase(doLogin.pending, (state:AuthState) => {
            state.status = 'loading';
        });
        builder.addCase(doLogin.rejected, (state:AuthState, {payload}) => {
            state.status = 'failed';
            state.errorMessage = payload?.message|| '';
        });
        builder.addCase(doLogin.fulfilled, (state:AuthState, {payload}) => {
            state.status = 'succeeded';
            window.location.href ='/';
        });
    },
});

export const {reducer , actions} = authSlice;
