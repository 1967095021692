import React, {useCallback, useEffect, useState} from 'react';
import {ShowAlertState} from "../../../../core/types";
import {Alert, Backdrop, Box, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {extractErrorMessage, is404Error} from "../../../../core/helper";
import {useNavigate, useParams} from "react-router-dom";
import {IDetailCorporateCard, IUpsertCorporateCard} from "../../types";
import {createCorporateCard, getCorporateCardDetail, updateCorporateCard} from "../../services";
import {appRoutes} from "../../../../core/const";
import CorporateCardUpsertForm from "../../components/CorporateCardUpsertForm/CorporateCardUpsertForm";
import {toBase64CorporateCardUpsert} from "../../helper";

const CorporateUpsertContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [corporateCardDetail, setCorporateCardDetail] = useState<IDetailCorporateCard | null>(null);
    const {id} = useParams<{ id: string }>();
    const navigate = useNavigate();
    const validNumberId = !isNaN(Number(id))

    const loadCorporateCardDetail = useCallback(async () => {
        try {
            if (id && id !== 'create') {
                setLoading(true);
                const result = (await getCorporateCardDetail(+id)).data;
                setCorporateCardDetail(result);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el detalle de la tarjeta corporativa')
            });
            setLoading(false);
            if (is404Error(error)) {
                setTimeout(() => navigate(appRoutes.corporateCard.list), 2500);
            }
        }
    }, [id, setLoading, setCorporateCardDetail, setAlert]);


    useEffect(() => {
        loadCorporateCardDetail();
    }, [loadCorporateCardDetail]);

    const handleCreateCorporateCard = async (value: IUpsertCorporateCard) => {
        try {
            setLoading(true);
            await createCorporateCard(toBase64CorporateCardUpsert(value));
            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Tarjeta corporativa creada satisfactoriamente'
            });
            setTimeout(() => navigate(appRoutes.corporateCard.list), 1500);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al crear la tarjeta corporativa')
            });
            setLoading(false);
        }
    }

    const handleUpdateCorporateCard = async (value: IUpsertCorporateCard) => {
        try {
            setLoading(true);
            await updateCorporateCard(Number(id), toBase64CorporateCardUpsert(value));
            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Tarjeta corporativa actualizada satisfactoriamente'
            });
            setTimeout(() => navigate(appRoutes.corporateCard.list), 1500)
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al actualizar la tarjeta corporativa')
            });
            setLoading(false);
        }
    }


    return (<Box sx={{width: '80%', padding: 3}}><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            {id && id === 'create' &&
                <CorporateCardUpsertForm loading={loading}  handleSubmitForm={handleCreateCorporateCard}/>}
            {id && id !== 'create' && validNumberId && corporateCardDetail &&
                <CorporateCardUpsertForm corporateCardValue={corporateCardDetail} loading={loading}
                                         handleSubmitForm={handleUpdateCorporateCard}/>}
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </Box>
    );
}

export default CorporateUpsertContainer;
