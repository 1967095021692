import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Box, Button, Card, CardContent, TextField, Typography} from '@mui/material';
import {useNavigate} from "react-router-dom";
import {appRoutes} from "../../../../core/const";

interface PaymentInformedConsentFileUploadFormProps {
    handleSubmitForm: (data: FormData) => void
    loading: boolean
}

interface IFormInput {
    file: FileList; // file will be an instance of FileList
}

const PaymentInformedConsentFileUploadForm = ({
                                                  handleSubmitForm,
                                                  loading
                                              }: PaymentInformedConsentFileUploadFormProps) => {
    const {control, handleSubmit, setError, formState: { isValid}, clearErrors,} = useForm<IFormInput>();
    const navigate = useNavigate();
    const onSubmit = async (data: IFormInput) => {
        const file = data.file[0];

        if (!file || file.type !== 'application/pdf') {
            setError('file', {type: 'manual', message: 'Por favor seleccione un fichero de tipo PDF.'});
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        handleSubmitForm(formData);
    };

    const handleFileChange = () => {
        clearErrors('file');
    };

    return (<Card sx={{maxWidth: 600, width: '100%'}}>
            <CardContent>
                <Typography variant="h5" style={{fontWeight: 'bold'}}
                            p={1}>{'Consentimiento informado'}</Typography>
                <br/>
                <Box
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                    sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2}}
                >
                    <Controller
                        name="file"
                        control={control}
                        rules={{required: 'Seleccione el fichero PDF del consentimiento informado.'}}
                        render={({field, fieldState: {error}}) => (
                            <>
                                <TextField
                                    type="file"
                                    onChange={(e) => {
                                        handleFileChange();

                                        // Cast e.target to HTMLInputElement
                                        field.onChange((e.target as HTMLInputElement).files);
                                    }}
                                    inputProps={{accept: 'application/pdf'}}
                                    error={!!error}
                                    helperText={error ? error.message : ''}
                                    required
                                />
                            </>
                        )}
                    />
                    <Box display="flex" width="100%" justifyContent="flex-end" gap={2}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => navigate(appRoutes.paymentInformedConsent.list)}
                        >
                            Volver al listado
                        </Button>
                        <Button type="submit" variant="contained" color="primary" disabled={!isValid || loading}>
                            Subir archivo PDF
                        </Button>
                    </Box>

                </Box>
            </CardContent>
        </Card>
    );
};

export default PaymentInformedConsentFileUploadForm;
