import React from 'react';
import {Box, Button, Card, CardContent, TextField, Typography} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {IListCardPortfolioAuthorization} from "../../types";
import {travellerPosName} from "../../constants";

export interface CardPortfolioAuthorizationPosUpsertFormProps {
    handleSubmitForm: (value: IUpsertCardPortfolioAuthorizationPosFormValue) => void;
    loading: boolean;
    value: IListCardPortfolioAuthorization | null,
    handleClose: () => void,
    totalOfSale:number
}

export interface IUpsertCardPortfolioAuthorizationPosFormValue {
    amount: string;
    tariff: string;
    authorizationCode: string;
}


export const CardPortfolioAuthorizationPosUpsertForm: React.FC<CardPortfolioAuthorizationPosUpsertFormProps> = ({
                                                                                                                 totalOfSale,
                                                                                                              handleSubmitForm,
                                                                                                              loading,
                                                                                                              handleClose,
                                                                                                              value
                                                                                                          }) => {
    const defaultValue: IUpsertCardPortfolioAuthorizationPosFormValue = {
        amount: String(totalOfSale),
        tariff: '',
        authorizationCode: ''
    };
    const convertToValueToForm = (x: IListCardPortfolioAuthorization) => {
        return {
            amount: String(x.amount),
            authorizationCode: x.authorizationCode,
            tariff:String(x.tariff)
        }
    }

    const methods = useForm<IUpsertCardPortfolioAuthorizationPosFormValue>({
        mode: 'all',
        defaultValues: value ? convertToValueToForm(value) : defaultValue
    });

    const {
        control,
        formState: {errors, isValid},
        getValues
    } = methods;


    return (
        <>
            <Card sx={{maxWidth: 700, width: '100%'}}>
                <CardContent>
                    <Typography variant="h5" style={{fontWeight: 'bold'}}
                                p={1}>{value ? 'Actualizar' : 'Nueva'} autorización por Pos</Typography>
                    <br/>
                    <Box component="form" display="flex" flexDirection="column" gap={1}>
                        <Box display="flex" alignItems="center" gap={2} flex={3} p={1}>
                            <TextField
                                size="small"
                                variant="outlined"
                                required
                                label="Compañía"
                                fullWidth
                                disabled={true}
                                value={travellerPosName}
                            />
                        </Box>


                        <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                            <Controller
                                name="amount"
                                control={control}
                                rules={{
                                    min: {value: 1, message: 'El valor mínimo es 1'},
                                    required: {value: true, message: 'Este campo es requerido'},
                                    pattern: {
                                        value: /^[0-9]*[.,]?[0-9]+$/,
                                        message: 'El campo monto debe ser un número válido'
                                    }
                                }}
                                render={({field}) => (
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="Monto autorizado"
                                        size={'small'}
                                        type="text"
                                        disabled={true}
                                        helperText={errors.amount ? errors.amount.message : ''}
                                        error={!!errors.amount}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>

                        <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                            <Controller
                                name="authorizationCode"
                                control={control}
                                rules={{
                                    required: {value: true, message: 'Este campo es requerido'},
                                    maxLength: {
                                        value: 15,
                                        message: 'El campo excede el largo máximo permitido de: ' + 15 + ' caracteres'
                                    }
                                }}
                                render={({field}) => (
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="Código de autorización"
                                        size={'small'}
                                        type="text"
                                        disabled={value!=null}
                                        helperText={errors.authorizationCode ? errors.authorizationCode.message : ''}
                                        error={!!errors.authorizationCode}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>

                        <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                            <Controller
                                name="tariff"
                                control={control}
                                rules={{
                                    min: {value: 1, message: 'El valor mínimo es 1'},
                                    required: {value: true, message: 'Este campo es requerido'},
                                    pattern: {
                                        value: /^[0-9]*[.,]?[0-9]+$/,
                                        message: 'El campo monto debe ser un número válido'
                                    }
                                }}
                                render={({field}) => (
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="Arancel"
                                        size={'small'}
                                        type="text"
                                        helperText={errors.tariff ? errors.tariff.message : ''}
                                        error={!!errors.tariff}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>


                        <Box display="flex" alignItems="center" gap={2} flex={3} p={1}>
                            <Box display="flex" alignItems="center" gap={2} flex={3} p={1}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    required
                                    label="Operador"
                                    fullWidth
                                    disabled={true}
                                    value={travellerPosName}
                                />
                            </Box>
                        </Box>


                        <Box display="flex" width="100%" justifyContent="flex-end" gap={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleClose}
                            >
                                Volver al listado
                            </Button>
                            <Button
                                variant="contained"
                                disabled={!isValid || loading}
                                color="primary"
                                onClick={() => handleSubmitForm(getValues())}
                            >
                                Guardar
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
        ;
};