// src/App.tsx
import React, {useState} from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import {createTheme, StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import {CssBaseline} from '@mui/material';
import Home from "./modules/home/containers/Home/Home";
import DestinationsListContainer
    from "./modules/destinations/containers/DestinationListContainer/DestinationListContainer";
import DestinationUpsertContainer
    from "./modules/destinations/containers/DestinationUpsertContainer/DestinationUpsertContainer";
import UserListContainer from "./modules/users/containers/UserListContainer/UserListContainer";
import UserUpsertContainer from "./modules/users/containers/UserUpsertContainer/UserUpsertContainer";
import ProviderListContainer from "./modules/providers/containers/ProviderListContainer/ProviderListContainer";
import ProviderUpsertContainer from "./modules/providers/containers/ProviderUpsertContainer/ProviderUpsertContainer";
import TransferServiceUpsertContainer
    from "./modules/transfer-services/containers/TransferServiceUpsertContainer/TransferServiceUpsertContainer";
import TransferServicesListContainer
    from "./modules/transfer-services/containers/TransferServicesListContainer/TransferServicesListContainer";
import TariffTransferServiceListContainer
    from "./modules/transfer-services/modules/tariff-transfer-services/containers/TariffTransferServiceListContainer/TariffTransferServicesListContainer";
import InsuranceServiceListContainer
    from "./modules/insurance-services/containers/InsuranceServicesListContainer/InsuranceServicesListContainer";
import InsuranceServiceUpsertContainer
    from "./modules/insurance-services/containers/InsuranceServiceUpsertContainer/InsuranceServiceUpsertContainer";
import InsuranceServiceTariffListContainer
    from "./modules/insurance-services/modules/tariff-insurances-services/containers/InsuranceServiceTariffListContainer/InsuranceServiceTariffListContainer";
import AirportsListContainer from "./modules/airports/containers/AirportListContainer/AirportListContainer";
import AirportUpsertContainer from "./modules/airports/containers/AirportUpsertContainer/AirportUpsertContainer";
import AirlinesListContainer from "./modules/airlines/containers/AirlinesListContainer/AirlinesListContainer";
import AirlinesUpsertContainer from "./modules/airlines/containers/AirlinesUpsertContainer/AirlinesUpsertContainer";
import TagsListContainer from "./modules/tags/containers/TagsListContainer/TagsListContainer";
import TagsUpsertContainer from "./modules/tags/containers/TagsUpsertContainer/TagsUpsertContainer";
import CountriesUpsertContainer from "./modules/countries/containers/CountriesUpsertContainer/CountriesUpsertContainer";
import CountriesListContainer from "./modules/countries/containers/CountriesListContainer/CountriesListContainer";
import {Provider} from "react-redux";
import store from "./store";
import {appRoutes} from "./core/const";
import Login from "./modules/auth/components/Login/Login";
import SaleOrderUpsertContainer
    from "./modules/sale-orders/containers/SaleOrderUpsertContainer/SaleOrderUpsertContainer";
import SalesOrderListContainer from "./modules/sale-orders/containers/SalesOrderListContainer/SalesOrderListContainer";
import {PaymentLinkContainer} from "./modules/payments/containers/PaymentLinkContainer/PaymentLinkContainer";
import PaymentInformedConsentUpsertContainer
    from "./modules/payment-informed-consents/containers/PaymentInformedConsentUpsertContainer/PaymentInformedConsentUpsertContainer";
import PaymentInformedConsentListContainer
    from "./modules/payment-informed-consents/containers/PaymentInformedConsentListContainer/PaymentInformedConsentListContainer";
import CardsPortfolioListContainer
    from "./modules/cards-portfolio/containers/CardsPortfolioListContainer/CardsPortfolioListContainer";
import CardPortfolioDetailContainer
    from "./modules/cards-portfolio/containers/CardPortfolioDetailContainer/CardPortfolioDetailContainer";
import AuthorizationOperatorUpsertContainer
    from "./modules/authorization-operators/containers/AuthorizationOperatorUpsertContainer/AuthorizationOperatorUpsertContainer";
import AuthorizationOperatorListContainer
    from "./modules/authorization-operators/containers/AuthorizationOperatorListContainer/AuthorizationOperatorListContainer";
import AuthorizationsListContainer
    from "./modules/authorizations/components/containers/AuthorizationsListContainer/AuthorizationsListContainer";
import ActionLogsListContainer from "./modules/action-logs/containers/ActionLogsListContainer/ActionLogsListContainer";
import ReceiptsListContainer from "./modules/receipts/containers/ReceiptsListContainer/ReceiptsListContainer";
import CorporateCardAuthorizationConceptListContainer
    from "./modules/corporate-card-authorization-concept/containers/CorporateCardAuthorizationConceptListContainer/CorporateCardAuthorizationConceptListContainer";
import CorporateCardAuthorizationConceptUpsertContainer
    from "./modules/corporate-card-authorization-concept/containers/CorporateCardAuthorizationConceptUpsertContainer/CorporateCardAuthorizationConceptUpsertContainer";
import CorporateUpsertContainer
    from "./modules/corporate-card/containers/CorporateUpsertContainer/CorporateUpsertContainer";
import CorporateCardListContainer
    from "./modules/corporate-card/containers/CorporateCardListContainer/CorporateCardListContainer";
import CorporateCardDetailContainer
    from "./modules/corporate-card/containers/CorporateCardDetailContainer/CorporateCardDetailContainer";
import ContactEmailConfigurationUpsertContainer
    from "./modules/contact-email-configurations/containers/ContactEmailConfigurationUpsertContainer/ContactEmailConfigurationUpsertContainer";
import CardPortfolioSummaryContainer
    from "./modules/cards-portfolio/modules/card-portfolio-summaries/containers/CardPortfolioSummaryContainer/CardPortfolioSummaryContainer";
import ProtectedRoute from "./core/components/ProtectedRoute/ProtectedRoute";
import Layout from "./core/components/Layout/Layout";
import SaleOrderBambooCreateContainer
    from "./modules/sale-order-bamboo/containers/SaleOrderBambooCreateContainer/SaleOrderBambooCreateContainer";
import SaleOrderBambooListContainer
    from "./modules/sale-order-bamboo/containers/SaleOrderBamboListContainer/SalesOrderBambooListContainer"; // Asegúrate de crear este componente
// Asegúrate de crear el componente Layout según se muestra en el siguiente paso

const palette = {
    primary: {
        main: '#0047BB',
    }
};

const typography = {
    fontFamily: '"Montserrat", sans-serif'
};

const myTheme = createTheme({ palette, typography });

const App: React.FC = () => {
    const [open, setOpen] = useState(true);
    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    return (
        <Provider store={store}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={myTheme}>
                    <CssBaseline />
                    <Router>
                        <Routes>
                            {/* Rutas Públicas */}
                            <Route path="/login" element={<Login />} />
                            <Route path={appRoutes.clientPay.pay} element={<PaymentLinkContainer />} />

                            {/* Rutas Protegidas */}
                            <Route element={<ProtectedRoute />}>
                                <Route element={<Layout open={open} handleDrawerToggle={handleDrawerToggle} />}>
                                    <Route path="/" element={<Home />} />
                                    <Route path={appRoutes.destinations.list} element={<DestinationsListContainer />} />
                                    <Route path={appRoutes.destinations.createOrUpdate} element={<DestinationUpsertContainer />} />
                                    <Route path={appRoutes.countries.list} element={<CountriesListContainer />} />
                                    <Route path={appRoutes.countries.createOrUpdate} element={<CountriesUpsertContainer />} />
                                    <Route path={appRoutes.airlines.list} element={<AirlinesListContainer />} />
                                    <Route path={appRoutes.airlines.createOrUpdate} element={<AirlinesUpsertContainer />} />
                                    <Route path={appRoutes.airports.list} element={<AirportsListContainer />} />
                                    <Route path={appRoutes.airports.createOrUpdate} element={<AirportUpsertContainer />} />
                                    <Route path={appRoutes.tags.list} element={<TagsListContainer />} />
                                    <Route path={appRoutes.tags.createOrUpdate} element={<TagsUpsertContainer />} />
                                    <Route path={appRoutes.users.list} element={<UserListContainer />} />
                                    <Route path={appRoutes.users.createOrUpdate} element={<UserUpsertContainer />} />
                                    <Route path={appRoutes.providers.list} element={<ProviderListContainer />} />
                                    <Route path={appRoutes.providers.createOrUpdate} element={<ProviderUpsertContainer />} />
                                    <Route path={appRoutes.transferServices.list} element={<TransferServicesListContainer />} />
                                    <Route path={appRoutes.transferServices.createOrUpdate} element={<TransferServiceUpsertContainer />} />
                                    <Route path={appRoutes.transferServices.tariff} element={<TariffTransferServiceListContainer />} />
                                    <Route path={appRoutes.insuranceServices.list} element={<InsuranceServiceListContainer />} />
                                    <Route path={appRoutes.insuranceServices.createOrUpdate} element={<InsuranceServiceUpsertContainer />} />
                                    <Route path={appRoutes.insuranceServices.tariff} element={<InsuranceServiceTariffListContainer />} />
                                    <Route path={appRoutes.saleOrder.list} element={<SalesOrderListContainer />} />
                                    <Route path={appRoutes.saleOrder.createOrUpdate} element={<SaleOrderUpsertContainer />} />
                                    <Route path={appRoutes.bambooSaleOrder.list} element={<SaleOrderBambooListContainer />} />
                                    <Route path={appRoutes.bambooSaleOrder.create} element={<SaleOrderBambooCreateContainer />} />
                                    <Route path={appRoutes.paymentInformedConsent.list} element={<PaymentInformedConsentListContainer />} />
                                    <Route path={appRoutes.paymentInformedConsent.upsert} element={<PaymentInformedConsentUpsertContainer />} />
                                    <Route path={appRoutes.cardPortfolio.list} element={<CardsPortfolioListContainer />} />
                                    <Route path={appRoutes.cardPortfolio.detail} element={<CardPortfolioDetailContainer />} />
                                    <Route path={appRoutes.cardPortfolio.summary} element={<CardPortfolioSummaryContainer />} />
                                    <Route path={appRoutes.authorizationOperator.list} element={<AuthorizationOperatorListContainer />} />
                                    <Route path={appRoutes.authorizationOperator.upsert} element={<AuthorizationOperatorUpsertContainer />} />
                                    <Route path={appRoutes.authorizations.list} element={<AuthorizationsListContainer />} />
                                    <Route path={appRoutes.receipts.list} element={<ReceiptsListContainer />} />
                                    <Route path={appRoutes.actionLog.list} element={<ActionLogsListContainer />} />
                                    <Route path={appRoutes.corporateCardAuthorizationConcept.list} element={<CorporateCardAuthorizationConceptListContainer />} />
                                    <Route path={appRoutes.corporateCardAuthorizationConcept.upsert} element={<CorporateCardAuthorizationConceptUpsertContainer />} />
                                    <Route path={appRoutes.corporateCard.list} element={<CorporateCardListContainer />} />
                                    <Route path={appRoutes.corporateCard.upsert} element={<CorporateUpsertContainer />} />
                                    <Route path={appRoutes.corporateCard.detail} element={<CorporateCardDetailContainer />} />
                                    <Route path={appRoutes.contactEmailConfiguration.update} element={<ContactEmailConfigurationUpsertContainer />} />
                                </Route>
                            </Route>

                            {/* Ruta por defecto: Redirige a /login si no coincide con ninguna ruta */}
                            <Route path="*" element={<Navigate to="/login" replace />} />
                        </Routes>
                    </Router>
                </ThemeProvider>
            </StyledEngineProvider>
        </Provider>
    );
};

export default App;
