import {AxiosResponse} from "axios";
import {ApiService} from "../../../../core/services";
import {ICardPortfolioSummaryContainer} from "./types";

const endpoints = {
    summary: 'api/cards-portfolio/summary',
}

export const getSummaryToReport = (): Promise<AxiosResponse<ICardPortfolioSummaryContainer>> => {
    return ApiService.get(endpoints.summary);
};