import React, {useCallback, useEffect, useState} from 'react';
import {ShowAlertState} from "../../../../core/types";
import {Alert, Backdrop, Box, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {createAirline, getAirline, updateAirline} from "../../services";
import {extractErrorMessage, is404Error} from "../../../../core/helper";
import {useNavigate, useParams} from "react-router-dom";
import {AirlineCreateForm} from "../../components/AirlineCreateForm/AirlineCreateForm";
import {AirlineUpdateForm} from "../../components/AirlineUpdateForm/AirlineUpdateForm";
import {ICreateAirline, IDetailAirline, IUpdateAirline} from "../../types";

const AirlinesUpsertContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [airlineDetail, setAirlineDetail] = useState<IDetailAirline|null>(null);
    const {code} = useParams<{ code: string }>();
    const navigate = useNavigate();

    const loadAirlineDetail = useCallback(async () => {
        try {
            if (code && code !== 'create') {
                setLoading(true);
                const result = (await getAirline(code)).data;
                setAirlineDetail(result);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar la aerolínea')
            });
            setLoading(false);
            if(is404Error(error)){
                setTimeout(()=>navigate('/airlines'), 2500);
            }
        }
    }, [code, setLoading, setAirlineDetail, setAlert]);



    useEffect(() => {
        loadAirlineDetail();
    }, [loadAirlineDetail]);

    const handleCreateAirline = async (value: ICreateAirline) => {
        try {
            setLoading(true);
           await createAirline({...value, image:value.image.split(',')[1]});
            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Aerolínea creada satisfactoriamente'
            });
            setTimeout(()=> navigate('/airlines'),1500)
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al crear la aerolínea')
            });
            setLoading(false);
        }
    }

    const handleUpdateAirline = async (value: IUpdateAirline) => {
        try {
            setLoading(true);
            await updateAirline(value.code,value);
            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Aerolínea actualizada satisfactoriamente'
            });
            setTimeout(()=> navigate('/airlines'),1500)
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al actualizar la aerolínea')
            });
            setLoading(false);
        }
    }


    return ( <Box sx={{width: '80%', padding: 3}}><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            {code && code === 'create' && <AirlineCreateForm loading={loading}
                                                                 handleSubmitForm={handleCreateAirline}/>}
            {code && code !== 'create' && airlineDetail &&  <AirlineUpdateForm loading={loading} value={airlineDetail}
                                                                 handleSubmitForm={handleUpdateAirline}/>}
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </Box>
    );
}

export default AirlinesUpsertContainer;
