import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, Card, CardContent, TextField, Typography} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {IBenefit, IDetailInsuranceServiceTariff} from "../../types";
import BenefitsForm from "../BenefitsForm/BenefitForms";

export interface TariffTransferServiceUpsertFormProps {
    handleSubmitForm: (value: IUpsertInsuranceServiceTariffFormValue) => void;
    loading: boolean;
    value: IDetailInsuranceServiceTariff | null,
    handleClose: () => void
}

export interface IUpsertInsuranceServiceTariffFormValue {
    minAge: string;
    maxAge: string;
    priceByDayByPerson: string;
    insuranceServiceId: string;
    observations: string;
    benefits: IBenefit[];
}

const defaultValue: IUpsertInsuranceServiceTariffFormValue = {
    minAge: '',
    maxAge: '',
    priceByDayByPerson: '',
    insuranceServiceId: '',
    observations: '',
    benefits:[]
};
export const InsuranceServiceTariffUpsertForm: React.FC<TariffTransferServiceUpsertFormProps> = ({
                                                                                                           handleSubmitForm,
                                                                                                           loading,
                                                                                                           handleClose,
                                                                                                           value
                                                                                                       }) => {
    const formRef = useRef<any>(null);
    const [isBenefitFormValid, setIsBenefitFormValid] = useState(false);

    useEffect(() => {
        if (formRef.current) {
            formRef.current.triggerValidation();
            setIsBenefitFormValid(formRef.current.isValidForm());
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (formRef.current) {
                setIsBenefitFormValid(formRef.current.isValidForm());
            }
        }, 500);
        return () => clearInterval(interval);
    }, []);
    const getBenefitsValues = () => {
        if (formRef.current) {
            const formValues = formRef.current.getFormValues();
            console.log(formValues);
           return formValues.benefits;
        }
        return [];
    };

    const convertToValueToForm = (x: IDetailInsuranceServiceTariff):IUpsertInsuranceServiceTariffFormValue => {
        return {
            minAge: String(x.minAge),
            maxAge: String(x.maxAge),
            priceByDayByPerson: String(x.priceByDayByPerson),
            insuranceServiceId: String(x.insuranceServiceId),
            observations:x.observations||'',
            benefits: x.benefits
        }
    }

    const methods = useForm<IUpsertInsuranceServiceTariffFormValue>({
        mode: 'all',
        defaultValues: value ? convertToValueToForm(value) : defaultValue
    });

    const {
        control,
        formState: {errors, isValid},
        getValues
    } = methods;

    return (
        <>
            <Card sx={{ width: '100%'}}>
                <CardContent>
                    <Typography variant="h5" style={{fontWeight: 'bold'}}
                                p={1}>{value ? 'Actualizar' : 'Nueva'} línea de tarifa</Typography>
                    <br/>
                    <Box component="form" display="flex" flexDirection="column" gap={1}>
                            <Box display={'flex'}>
                        <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                            <Controller
                                name="minAge"
                                control={control}
                                rules={{
                                    min: {value: 0, message: 'El valor mínimo es 0'},
                                    required: {value: true, message: 'Este campo es requerido'},
                                    pattern: {
                                        value: /^[0-9]+$/,
                                        message: 'Mínimo de edad ser un número válido'
                                    },
                                }}
                                render={({field}) => (
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        label="Edad mínima"
                                        required={true}
                                        size="small"
                                        type="number"
                                        helperText={errors.minAge ? errors.minAge.message : ''}
                                        error={!!errors.minAge}
                                        {...field}
                                        onChange={(e) => field.onChange(e.target.value==''?'':Number(e.target.value))}
                                    />
                                )}
                            />
                        </Box>


                        <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                            <Controller
                                name="maxAge"
                                control={control}
                                rules={{
                                    validate: (value) => {
                                        const minAgeValue = getValues('minAge');
                                        return minAgeValue!=undefined  && value !== undefined && value < minAgeValue
                                            ? 'El valor máximo debe ser mayor o igual que Edad mínima'
                                            : true;
                                    },
                                    pattern: {
                                        value: /^[0-9]+$/,
                                        message: 'La edad máxima ser un número válido'
                                    },
                                    required: {value: true, message: 'Este campo es requerido'},
                                }}
                                render={({field}) => (
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        label="Edad máxima"
                                        size="small"
                                        required={true}
                                        type="text"
                                        helperText={errors.maxAge ? errors.maxAge.message : ''}
                                        error={!!errors.maxAge}
                                        {...field}
                                        onChange={(e) => field.onChange(e.target.value==''?'':Number(e.target.value))}
                                    />
                                )}
                            />
                        </Box>



                        <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                            <Controller
                                name="priceByDayByPerson"
                                control={control}
                                rules={{
                                    required: {value: true, message: 'Este campo es requerido'},
                                    pattern: {
                                        value: /^[0-9]*[.,]?[0-9]+$/,
                                        message: 'El campo priceByDayByPerson debe ser un número válido'
                                    }
                                }}
                                render={({field}) => (
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="Precio por día por persona"
                                        size={'small'}
                                        type="text"
                                        helperText={errors.priceByDayByPerson ? errors.priceByDayByPerson.message : ''}
                                        error={!!errors.priceByDayByPerson}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>

                            </Box>

                        <Box display="flex" flexDirection="row" justifyContent="space-around">
                            <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                                <Controller
                                    name="observations"
                                    control={control}
                                    render={({field}) => (
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Observaciones"
                                            multiline
                                            rows={4}
                                            size={'small'}
                                            type="text"
                                            helperText={errors.observations ? errors.observations.message : ''}
                                            error={!!errors.observations}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>
                        </Box>

                        <BenefitsForm ref={formRef} initialData={value?value.benefits:undefined} />



                        <Box display="flex" width="100%" justifyContent="flex-end" gap={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleClose}
                            >
                                Volver al listado
                            </Button>
                            <Button
                                variant="contained"
                                disabled={!isValid || !isBenefitFormValid|| loading}
                                color="primary"
                                onClick={() => handleSubmitForm({...getValues(),...{benefits:getBenefitsValues()}})}
                            >
                                Guardar
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
        ;
};