import React, {useState} from 'react';
import {ShowAlertState} from "../../../../core/types";
import {Alert, Backdrop, Box, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {upsertPaymentInformedConsent} from "../../services";
import {extractErrorMessage} from "../../../../core/helper";
import {useNavigate} from "react-router-dom";
import PaymentInformedConsentFileUploadForm
    from "../../components/PaymentInformedConsentFileUploadForm/PaymentInformedConsentFileUploadForm";

const PaymentInformedConsentUpsertContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleUpsertPaymentInformedConsent = async (value: FormData) => {
        try {
            setLoading(true);
            await upsertPaymentInformedConsent(value);
            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Archivo subido satisfactoriamente'
            });
            setTimeout(() => navigate('/payment-informed-consent'), 1500)
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al subir el archivo')
            });
            setLoading(false);
        }
    }


    return (<Box sx={{width: '80%', padding: 3}}><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
             <PaymentInformedConsentFileUploadForm handleSubmitForm={handleUpsertPaymentInformedConsent} loading={loading}/>
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </Box>
    );
}

export default PaymentInformedConsentUpsertContainer;
