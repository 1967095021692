import React from 'react';
import {Box, Button, Card, CardContent, TextField, Typography} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {IListCardPortfolioRejection} from "../../types";

export interface CardPortfolioRejectionUpsertFormProps {
    handleSubmitForm: (value: IUpsertCardPortfolioRejectionFormValue) => void;
    loading: boolean;
    value: IListCardPortfolioRejection | null,
    handleClose: () => void
}

export interface IUpsertCardPortfolioRejectionFormValue {
    observations: string;
}

const defaultValue: IUpsertCardPortfolioRejectionFormValue = {
    observations: ''
};
export const CardPortfolioRejectionUpsertForm: React.FC<CardPortfolioRejectionUpsertFormProps> = ({
                                                                                                      handleSubmitForm,
                                                                                                      loading,
                                                                                                      handleClose,
                                                                                                      value
                                                                                                  }) => {

    const convertToValueToForm = (x: IListCardPortfolioRejection): IUpsertCardPortfolioRejectionFormValue => {
        return {
            observations: x.observations || '',
        }
    }

    const methods = useForm<IUpsertCardPortfolioRejectionFormValue>({
        mode: 'all',
        defaultValues: value ? convertToValueToForm(value) : defaultValue
    });

    const {
        control,
        formState: {errors, isValid},
        getValues
    } = methods;

    return (
        <>
            <Card sx={{width: '100%'}}>
                <CardContent>
                    <Typography variant="h5" style={{fontWeight: 'bold'}}
                                p={1}>{value ? 'Actualizar' : 'Nuevo'} rechazo</Typography>
                    <br/>
                    <Box component="form" display="flex" flexDirection="column" gap={1}>

                        <Box display="flex" flexDirection="row" justifyContent="space-around">
                            <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                                <Controller
                                    name="observations"
                                    control={control}
                                    render={({field}) => (
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Observaciones"
                                            multiline
                                            rows={4}
                                            size={'small'}
                                            type="text"
                                            helperText={errors.observations ? errors.observations.message : ''}
                                            error={!!errors.observations}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>
                        </Box>


                        <Box display="flex" width="100%" justifyContent="flex-end" gap={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleClose}
                            >
                                Volver al listado
                            </Button>
                            <Button
                                variant="contained"
                                disabled={!isValid || loading}
                                color="primary"
                                onClick={() => handleSubmitForm({...getValues()})}
                            >
                                Guardar
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
        ;
};