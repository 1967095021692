import React, {useCallback, useEffect, useState} from 'react';
import {IFilterAuthorizationOperator, IListAuthorizationOperator} from "../../types";
import {ISort, ShowAlertState} from "../../../../core/types";
import {Alert, Backdrop, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {extractErrorMessage} from "../../../../core/helper";
import {useNavigate} from "react-router-dom";
import AuthorizationOperatorsList from "../../components/AuthorizationOperatorsList/AuthorizationOperatorsList";
import {deleteAuthorizationOperator, getAuthorizationOperators} from "../../services";
import {defaultListFilter} from "../../constants";

const AuthorizationOperatorListContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>(search);
    const [page, setPage] = useState<number>(0);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [order, setOrder] = useState<ISort[]>([]);
    const [items, setItems] = useState<IListAuthorizationOperator[]>([])
    const navigate = useNavigate();
    const [filters, setFilters] = useState<IFilterAuthorizationOperator>(defaultListFilter)

    const onAdd = () => navigate('/authorization-operators/create');

    const onEdit = (item: IListAuthorizationOperator) => navigate('/authorization-operators/' + item.id);

    const onDelete = async (item: IListAuthorizationOperator) => {
        try {
            setLoading(true);
            await deleteAuthorizationOperator(item.id);
            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Operador eliminado satisfactoriamente'
            });
            setTimeout(loadAuthorizationOperators, 1500)
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al eliminar el operador')
            });
            setLoading(false);
        }
    };


    const loadAuthorizationOperators = useCallback(async () => {
        try {
            setLoading(true);
            const paginatedResult = (await getAuthorizationOperators(page + 1, pageSize, order, debouncedSearchTerm,filters)).data;
            setTotalElements(paginatedResult.total);
            setItems(paginatedResult.items);
            setLoading(false);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado de operadores')
            });
            setLoading(false);
        }
    }, [page, pageSize, order,filters, debouncedSearchTerm]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(search);
        }, 500); // Retraso de 500 ms

        // Limpia el timeout si el efecto se vuelve a ejecutar antes de que el timeout finalice
        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    useEffect(() => {
        loadAuthorizationOperators();
    }, [loadAuthorizationOperators]);

    return (<><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            <AuthorizationOperatorsList title={'Operadores'}
                                        items={items}
                                        onAdd={onAdd}
                                        onEdit={onEdit}
                                        filters={filters}
                                        setFilters={setFilters}
                                        order={order}
                                        setOrder={setOrder}
                                        search={search}
                                        setSearch={setSearch}
                                        page={page}
                                        setPage={setPage}
                                        pageSize={pageSize}
                                        setPageSize={setPageSize}
                                        totalElements={totalElements}
                                        onDelete={onDelete}
            />
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </>
    );
}

export default AuthorizationOperatorListContainer;
