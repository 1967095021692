import {ApiService} from "../../core/services";
import {AxiosResponse} from "axios";
import {
    IBitrixDealInfoToOrderBamboo,
    ICreateSaleOrderBamboo,
    IFilterSaleOrderBamboo,
    IListSaleOrderBamboo
} from "./types";
import {IPaginatedResult, ISort} from "../../core/types";
import {cleanFilters, formatSortingFields} from "../../core/helper";

const endpoints = {
    list: 'api/sales-order-bamboo',
    create: 'api/sales-order-bamboo',
    paymentLinkSend: 'api/sales-order-bamboo/:id/payment-link-send',
    getDealDetail: 'api/bitrix/:dealId/info-to-sale-order-bamboo',
}

export const getSalesOrderBamboo = (page = 1, pageSize = 10, sortingFields: ISort[], search: string, filters?: IFilterSaleOrderBamboo): Promise<AxiosResponse<IPaginatedResult<IListSaleOrderBamboo>>> => {
    const sorts = formatSortingFields(sortingFields);
    return ApiService.get(endpoints.list, {
        params: {
            page,
            pageSize,
            ...(sorts.length > 0 && {sort: sorts}), ...(filters && cleanFilters(filters)), ...(search && search !== '' && {search: search})
        }
    });
};
export const createSaleOrderBamboo = (data: ICreateSaleOrderBamboo): Promise<AxiosResponse<void>> => ApiService.post(endpoints.create, data);

export const paymentLinkSend = (id:string):Promise<AxiosResponse<void>> =>{
    return ApiService.put(endpoints.paymentLinkSend.replace(':id',id))
}
export const getDealInfoToSaleOrderBamboo = (dealId: string): Promise<AxiosResponse<IBitrixDealInfoToOrderBamboo>> => ApiService.get(endpoints.getDealDetail.replace(':dealId', dealId));