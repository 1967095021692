import {ApiService} from "../../core/services";
import {AxiosResponse} from "axios";

import {IPaginatedResult, ISort} from "../../core/types";
import {cleanFilters, formatSortingFields} from "../../core/helper";
import {IFilterListAllCardPortfolioAuthorization, IListAllCardPortfolioAuthorization} from "./types";

const endpoints = {
    list: 'api/authorizations',
}

export const getAuthorizations = (page = 1, pageSize = 10, sortingFields: ISort[], search: string, filters?: IFilterListAllCardPortfolioAuthorization): Promise<AxiosResponse<IPaginatedResult<IListAllCardPortfolioAuthorization>>> => {
    const sorts = formatSortingFields(sortingFields);
    return ApiService.get(endpoints.list, {
        params: {
            page,
            pageSize,
            ...(sorts.length > 0 && {sort: sorts}), ...(filters && cleanFilters(filters)), ...(search && search !== '' && {search: search})
        }
    });
};