import React from 'react';
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography,
} from '@mui/material';
import {
    Add as AddIcon,
    Edit as EditIcon,
} from '@mui/icons-material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {IListPaymentInformedConsent} from "../../types"; // Ícono para abrir en nueva pestaña (opcional)

interface PaymentInformedConsentListProps {
    title: string;
    items: IListPaymentInformedConsent[];
    onUpsert: ()=>void
}

const PaymentInformedConsentList: React.FC<PaymentInformedConsentListProps> = ({
                                                                                   title,
                                                                                   items,
                                                                                   onUpsert
                                                                               }) => {


    return (
        <Box sx={{width: '100%', padding: 3}}>
            <Typography variant="h4" component="div" sx={{mb: 2}}>
                {title}
            </Typography>
            <Toolbar sx={{display: 'flex', justifyContent: 'space-between', mb: 2}} style={{paddingLeft: 0}}>
                <Button
                    variant="contained"
                    startIcon={items.length === 0 ?<AddIcon/>: <EditIcon/> }
                    onClick={() => onUpsert()}
                >
                    {items.length == 0 ? 'Subir' :'Modificar '} archivo
                </Button>
            </Toolbar>
            <Typography variant="body2" sx={{mb: 1}}>
                Mostrando {items.length} resultados
            </Typography>
            <TableContainer component="main" sx={{width: '100%'}}>
                <Table sx={{width: '100%'}}>
                    <TableHead>
                        <TableRow>


                            <TableCell>
                                Fichero
                            </TableCell>

                            <TableCell>
                                Fecha de creación
                            </TableCell>

                            <TableCell>
                                Creado por
                            </TableCell>

                            <TableCell>
                                Fecha de modificación
                            </TableCell>

                            <TableCell>
                                Modificado por
                            </TableCell>


                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>
                                    <a href={item.fileURL} target="_blank" rel="noopener noreferrer">
                                        {item.fileURL}
                                        <OpenInNewIcon style={{marginLeft: 8}}/> {/* Ícono opcional */}
                                    </a>
                                </TableCell>
                                <TableCell>{item.createdAt}</TableCell>
                                <TableCell>{item.createdBy}</TableCell>
                                <TableCell>{item.updatedAt ? item.updatedAt : '-'}</TableCell>
                                <TableCell>{item.updatedBy ? item.updatedBy : '-'}</TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default PaymentInformedConsentList;
