import React from "react";
import { AppBar, Toolbar, Typography, useTheme, useMediaQuery } from "@mui/material";
import logocolor from "../../../assets/images/logo_new.svg";
export default function ResponsiveAppBar() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar>
                <img
                    alt="Traveller viajes"
                    style={{ cursor: "pointer", width: isMobile ? "150px" : "200px" }}
                    src={logocolor}
                />

                <Typography
                    variant={isMobile ? "h6" : "h5"}
                    noWrap
                    component="p"
                    sx={{
                        flexGrow: 1,
                        fontWeight: "bold",
                        textAlign: "center",
                        fontSize: isMobile ? "0.8rem" : "1.5rem",
                    }}
                >
                    Pago de orden de venta
                </Typography>
            </Toolbar>
        </AppBar>
    );
}
