import React from 'react';
import {Autocomplete, Box, Button, Card, CardContent, FormControl, TextField, Typography} from '@mui/material';
import {Controller, useForm, useWatch} from 'react-hook-form';
import {IDetailTariffPrivateTransferService, IUpsertTariffPrivateTransferService} from "../../types";
import {IListProvider} from "../../../../../providers/types";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {MobileDatePicker, MobileTimePicker} from "@mui/x-date-pickers";

export interface TariffTransferServiceUpsertFormProps {
    handleSubmitForm: (value: IUpsertTariffPrivateTransferServiceFormValue) => void;
    loading: boolean;
    providers: IListProvider[],
    value: IDetailTariffPrivateTransferService | null,
    handleClose: () => void
}

export interface IUpsertTariffPrivateTransferServiceFormValue {
    dateFrom: string;
    timeFrom: string;
    dateTo: string;
    timeTo: string;
    totalPrice: string;
    providerId: string;
}

const defaultValue: IUpsertTariffPrivateTransferServiceFormValue = {
    dateFrom: '',
    timeFrom: '',
    dateTo: '',
    timeTo: '',
    totalPrice: '',
    providerId: '',
};
export const TariffTransferServicePrivateUpsertForm: React.FC<TariffTransferServiceUpsertFormProps> = ({
                                                                                                           handleSubmitForm,
                                                                                                           loading,
                                                                                                           handleClose,
                                                                                                           providers,
                                                                                                           value
                                                                                                       }) => {

    const convertToValueToForm = (x: IUpsertTariffPrivateTransferService) => {
        return {
            dateFrom: x.dateFrom,
            timeFrom: x.timeTo,
            dateTo: x.dateTo,
            timeTo: x.timeTo,
            totalPrice: String(x.totalPrice),
            providerId: String(x.providerId)
        }
    }

    const methods = useForm<IUpsertTariffPrivateTransferServiceFormValue>({
        mode: 'all',
        defaultValues: value ? convertToValueToForm(value) : defaultValue
    });

    const {
        control,
        formState: {errors, isValid},
        getValues
    } = methods;

    const dateFrom = useWatch({ control, name: 'dateFrom' }); // Observar el valor de dateFrom

    return (
        <>
            <Card sx={{maxWidth: 700, width: '100%'}}>
                <CardContent>
                    <Typography variant="h5" style={{fontWeight: 'bold'}}
                                p={1}>{value ? 'Actualizar' : 'Nueva'} línea de tarifa</Typography>
                    <br/>
                    <Box component="form" display="flex" flexDirection="column" gap={1}>
                        <Box display="flex" alignItems="center" gap={2} flex={3} p={1}>
                            <Controller
                                name="providerId"
                                control={control}
                                rules={{
                                    required: {value: true, message: 'Este campo es requerido'}
                                }}
                                render={({field}) => (
                                    <FormControl sx={{width: '100%'}}>
                                        <Autocomplete
                                            options={providers}
                                            getOptionLabel={(option: IListProvider) => `${option.name}`}
                                            value={
                                                providers.find((option) => option.id === +field.value) || null
                                            }
                                            onChange={(event, value) => {
                                                const selectedValues = (value as IListProvider)?.id || '';
                                                field.onChange(selectedValues);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size={'small'}
                                                    variant="outlined"
                                                    required
                                                    label="Seleccione el proveedor de la tarifa"
                                                    fullWidth
                                                    helperText={errors.providerId ? errors.providerId.message : ''}
                                                    error={!!errors.providerId}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                )}
                            />
                        </Box>
                        <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Controller
                                    name="dateFrom"
                                    control={control}
                                    rules={{
                                        required: {value: true, message: 'Este campo es requerido'},
                                        pattern: {
                                            value: /^\d{4}-\d{2}-\d{2}$/,
                                            message: 'El formato debe ser YYYY-MM-DD'
                                        }
                                    }}
                                    render={({field}) => (
                                        <MobileDatePicker
                                            label="Fecha Desde"
                                            format="YYYY-MM-DD"
                                            value={field.value ? dayjs(field.value) : null}
                                            onChange={(date) => field.onChange(date ? date.format('YYYY-MM-DD') : null)}
                                            slots={{
                                                textField: textFieldProps => <TextField {...textFieldProps}
                                                                                        variant="outlined"
                                                                                        required
                                                                                        fullWidth
                                                                                        size="small"
                                                                                        helperText={errors.dateFrom ? errors.dateFrom.message : ''}
                                                                                        error={!!errors.dateFrom}/>
                                            }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Box>

                        <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Controller
                                    name="timeFrom"
                                    control={control}
                                    rules={{
                                        required: {value: true, message: 'Este campo es requerido'},
                                    }}
                                    render={({field}) => (
                                        <MobileTimePicker
                                            ampm={false}
                                            minutesStep={1}
                                            label="Hora desde"
                                            views={['hours', 'minutes']}
                                            value={field.value ? dayjs(field.value, 'HH:mm') : null}
                                            onChange={(time) => field.onChange(time ? time.format('HH:mm') : null)}
                                            slots={{
                                                textField: textFieldProps => <TextField {...textFieldProps}
                                                                                        variant="outlined"
                                                                                        required
                                                                                        fullWidth
                                                                                        size="small"
                                                                                        helperText={errors.timeFrom ? errors.timeFrom.message : ''}
                                                                                        error={!!errors.timeFrom}/>
                                            }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Box>

                        <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Controller
                                    name="dateTo"
                                    control={control}
                                    rules={{
                                        required: {value: true, message: 'Este campo es requerido'},
                                        pattern: {
                                            value: /^\d{4}-\d{2}-\d{2}$/,
                                            message: 'El formato debe ser YYYY-MM-DD'
                                        },
                                        validate: value => {
                                            if (!value || !dateFrom) {
                                                return true;
                                            }
                                            return dayjs(value).isAfter(dayjs(dateFrom)) || dayjs(value).isSame(dayjs(dateFrom))
                                                ? true
                                                : 'La Fecha Hasta debe ser mayor o igual a la Fecha Desde';
                                        }
                                    }}
                                    render={({field}) => (
                                        <MobileDatePicker
                                            label="Fecha Hasta"
                                            format="YYYY-MM-DD"
                                            value={field.value ? dayjs(field.value) : null}
                                            onChange={(date) => field.onChange(date ? date.format('YYYY-MM-DD') : null)}
                                            slots={{
                                                textField: textFieldProps => <TextField {...textFieldProps}
                                                                                        variant="outlined"
                                                                                        required
                                                                                        fullWidth
                                                                                        size="small"
                                                                                        helperText={errors.dateTo ? errors.dateTo.message : ''}
                                                                                        error={!!errors.dateTo}/>
                                            }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Box>

                        <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Controller
                                    name="timeTo"
                                    control={control}
                                    rules={{
                                        required: {value: true, message: 'Este campo es requerido'},
                                    }}
                                    render={({field}) => (
                                        <MobileTimePicker
                                            ampm={false}
                                            minutesStep={1}
                                            label="Hora hasta"
                                            value={field.value ? dayjs(field.value, 'HH:mm') : null}
                                            onChange={(time) => field.onChange(time ? time.format('HH:mm') : null)}
                                            slots={{
                                                textField: textFieldProps => <TextField {...textFieldProps}
                                                                                        variant="outlined"
                                                                                        required
                                                                                        fullWidth
                                                                                        size="small"
                                                                                        helperText={errors.timeTo ? errors.timeTo.message : ''}
                                                                                        error={!!errors.timeTo}/>
                                            }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Box>


                        <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                            <Controller
                                name="totalPrice"
                                control={control}
                                rules={{
                                    required: {value: true, message: 'Este campo es requerido'},
                                    pattern: {
                                        value: /^[0-9]*[.,]?[0-9]+$/,
                                        message: 'El campo totalPrice debe ser un número válido'
                                    }
                                }}
                                render={({field}) => (
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="Precio Total"
                                        size={'small'}
                                        type="text"
                                        helperText={errors.totalPrice ? errors.totalPrice.message : ''}
                                        error={!!errors.totalPrice}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>


                        <Box display="flex" width="100%" justifyContent="flex-end" gap={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleClose}
                            >
                                Volver al listado
                            </Button>
                            <Button
                                variant="contained"
                                disabled={!isValid || loading}
                                color="primary"
                                onClick={() => handleSubmitForm(getValues())}
                            >
                                Guardar
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
        ;
};