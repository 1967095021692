import React, {Dispatch, SetStateAction, useState} from 'react';

import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Toolbar,
    Typography,
} from '@mui/material';
import {
    Add as AddIcon,
    Edit as EditIcon,
    FilterList as FilterListIcon,
    Search as SearchIcon
} from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {ISort} from "../../../../core/types";
import {IFilterListCorporateCard, IListCorporateCard} from "../../types";
import {defaultFilterListCorporateCards} from "../../constants";
import dayjs, {Dayjs} from "dayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers";
import EmptyTable from "../../../../core/components/EmptyTable/EmptyTable";

interface CorporateCardsListProps {
    title: string;
    search: string;
    setSearch: (search: string) => void;
    page: number;
    setPage: (page: number) => void;
    pageSize: number;
    setPageSize: (pageSize: number) => void;
    filters: IFilterListCorporateCard;
    setFilters: (filter: IFilterListCorporateCard) => void;
    order: ISort[],
    setOrder: Dispatch<SetStateAction<ISort[]>>;
    items: IListCorporateCard[];
    onAdd: () => void;
    onEdit: (item: IListCorporateCard) => void;
    onDetail: (item: IListCorporateCard) => void;
    totalElements: number;
}

const CorporateCardsList: React.FC<CorporateCardsListProps> = ({
                                                                   title,
                                                                   search,
                                                                   setSearch,
                                                                   page,
                                                                   setPage,
                                                                   pageSize,
                                                                   totalElements,
                                                                   setPageSize,
                                                                   filters,
                                                                   setFilters,
                                                                   order,
                                                                   setOrder,
                                                                   items,
                                                                   onAdd,
                                                                   onEdit,
                                                                   onDetail
                                                               }) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [internalFilters, setInternalFilters] = useState<IFilterListCorporateCard>(defaultFilterListCorporateCards);


    const [createdAt, setCreatedAt] = useState<Dayjs | null>(null);
    const [createdAtFrom, setCreatedAtFrom] = useState<Dayjs | null>(null);
    const [createdAtTo, setCreatedAtTo] = useState<Dayjs | null>(null);

    const [updatedAt, setUpdatedAt] = useState<Dayjs | null>(null);
    const [updatedAtFrom, setUpdatedAtFrom] = useState<Dayjs | null>(null);
    const [updatedAtTo, setUpdatedAtTo] = useState<Dayjs | null>(null);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleFilterChange = (field: keyof typeof filters) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setInternalFilters((prev) => ({...prev, [field]: event.target.value}));
    };

    const handleFilterChangeValue = (field: keyof typeof filters, value: string | number) => {
        setInternalFilters((prev) => ({...prev, [field]: value}));
    };

    const handleApplyFilter = () => {
        setFilters(internalFilters);
        handleCloseMenu();
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (property: keyof IListCorporateCard) => {
        const exist = order.find(o => o.field === property)
        const isAsc = order.find(o => o.field === property)?.order === 'asc';
        if (!exist) {
            setOrder(prevOrder => [
                ...prevOrder.filter(o => o.field !== property),
                {field: property, order: isAsc ? 'desc' : 'asc'},
            ]);
        } else {
            if (exist.order === 'asc') {
                setOrder(prevOrder => [
                    ...prevOrder.filter(o => o.field !== property),
                    {field: property, order: 'desc'},
                ]);
            } else {
                setOrder(prevOrder => [
                    ...prevOrder.filter(o => o.field !== property),
                ]);
            }
        }

    };

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleCancelFilters = () => {
        setInternalFilters(filters);
        handleCloseMenu();
    };

    return (
        <Box sx={{width: '100%', padding: 3}}>
            <Typography variant="h4" component="div" sx={{mb: 2}}>
                {title}
            </Typography>
            <Toolbar sx={{display: 'flex', justifyContent: 'space-between', mb: 2}} style={{paddingLeft: 0}}>
                <Button
                    variant="contained"
                    startIcon={<AddIcon/>}
                    onClick={() => onAdd()}
                >
                    Nueva tarjeta corporativa
                </Button>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <TextField
                        variant="outlined"
                        placeholder="Buscar"
                        size="small"
                        value={search}
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon/>
                                </InputAdornment>
                            ),
                        }}
                        sx={{mr: 2, width: 300}}
                    />
                    <Button variant="outlined" startIcon={<FilterListIcon/>} onClick={handleOpenMenu}>
                        Filtrar
                    </Button>
                    <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        disableRestoreFocus
                    >
                        <Box sx={{p: 2, display: 'flex', flexDirection: 'column', gap: 1}}>
                            <TextField
                                label="ID igual a"
                                size='small'
                                value={internalFilters.id}
                                onChange={handleFilterChange('id')}
                                fullWidth
                            />

                            <TextField
                                label="Titular contiene"
                                size='small'
                                value={internalFilters.cardHolder}
                                inputProps={{maxLength: 255}}
                                onChange={handleFilterChange('cardHolder')}
                                fullWidth
                            />


                            <TextField
                                label="Últimos 4 digitos igual a "
                                size='small'
                                type={"number"}
                                value={internalFilters.lastFourCardDigit}
                                inputProps={{maxLength: 4}}
                                onChange={handleFilterChange('lastFourCardDigit')}
                                fullWidth
                            />

                            <TextField
                                label="Sello contiene"
                                size='small'
                                value={internalFilters.cardStamp}
                                onChange={handleFilterChange('cardStamp')}
                                fullWidth
                            />

                            <TextField
                                label="Fecha de expiración contiene"
                                size='small'
                                value={internalFilters.expiry}
                                onChange={handleFilterChange('expiry')}
                                fullWidth
                                inputProps={{maxLength: 5}}
                            />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>


                                <Box sx={{display: 'flex', gap: 2, flexDirection: 'column'}}>
                                    <DatePicker
                                        label="Fecha de creación igual a"
                                        format="YYYY-MM-DD"
                                        value={createdAt ? dayjs(createdAt) : null}
                                        onChange={(newValue) => {
                                            setCreatedAt(newValue);
                                            handleFilterChangeValue('createdAt', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />
                                </Box>

                                <Box sx={{display: 'flex', gap: 2, flexDirection: 'column'}}>
                                    <DatePicker
                                        label="Fecha de creación desde"
                                        format="YYYY-MM-DD"
                                        value={createdAtFrom ? dayjs(createdAtFrom) : null}
                                        onChange={(newValue) => {
                                            setCreatedAtFrom(newValue);
                                            handleFilterChangeValue('createdAtFrom', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />
                                    <DatePicker
                                        label="Fecha de creación hasta"
                                        format="YYYY-MM-DD"
                                        value={createdAtTo ? dayjs(createdAtTo) : null}
                                        onChange={(newValue) => {
                                            setCreatedAtTo(newValue);
                                            handleFilterChangeValue('createdAtTo', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        minDate={createdAtFrom ? dayjs(createdAtFrom) : undefined}  // No permite seleccionar una fecha menor a la de inicio
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />
                                </Box>

                                <Box sx={{display: 'flex', gap: 2, flexDirection: 'column'}}>
                                    <DatePicker
                                        label="Fecha de actualización igual a"
                                        format="YYYY-MM-DD"
                                        value={updatedAt ? dayjs(updatedAt) : null}
                                        onChange={(newValue) => {
                                            setUpdatedAt(newValue);
                                            handleFilterChangeValue('updatedAt', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />
                                </Box>
                                <Box sx={{display: 'flex', gap: 2, flexDirection: 'column'}}>
                                    <DatePicker
                                        label="Fecha de actualización desde"
                                        format="YYYY-MM-DD"
                                        value={updatedAtFrom ? dayjs(updatedAtFrom) : null}
                                        onChange={(newValue) => {
                                            setUpdatedAtFrom(newValue);
                                            handleFilterChangeValue('updatedAtFrom', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />
                                    <DatePicker
                                        label="Fecha de actualización hasta"
                                        format="YYYY-MM-DD"
                                        value={updatedAtTo ? dayjs(updatedAtTo) : null}
                                        onChange={(newValue) => {
                                            setUpdatedAtTo(newValue);
                                            handleFilterChangeValue('updatedAtTo', newValue ? newValue.format('YYYY-MM-DD') : '')
                                        }}
                                        minDate={updatedAtFrom ? dayjs(updatedAtFrom) : undefined}  // No permite seleccionar una fecha menor a la de inicio
                                        slots={{
                                            textField: textFieldProps => <TextField {...textFieldProps}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    size="small"/>
                                        }}
                                    />
                                </Box>
                            </LocalizationProvider>

                            <TextField
                                label="Creada por contiene"
                                size='small'
                                value={internalFilters.createdBy}
                                inputProps={{maxLength: 255}}
                                onChange={handleFilterChange('createdBy')}
                                fullWidth
                            />

                            <TextField
                                label="Actualizada por contiene"
                                size='small'
                                value={internalFilters.updatedBy}
                                inputProps={{maxLength: 255}}
                                onChange={handleFilterChange('updatedBy')}
                                fullWidth
                            />

                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2}}>
                                <Button variant="contained" onClick={handleApplyFilter} sx={{mr: 2}}>
                                    Aplicar Filtros
                                </Button>
                                <Button variant="outlined" onClick={handleCancelFilters}>
                                    Cancelar
                                </Button>
                            </Box>
                        </Box>
                    </Popover>
                </Box>
            </Toolbar>
            <Typography variant="body2" sx={{mb: 1}}>
                Mostrando {items.length} resultados de {totalElements}
            </Typography>
            <TableContainer component="main" sx={{width: '100%'}}>
                <Table sx={{width: '100%'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                ID
                            </TableCell>

                            <TableCell sx={{width: '10%'}}>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'cardHolder')}
                                    direction={order.find(o => o.field === 'cardHolder')?.order || 'asc'}
                                    onClick={() => handleRequestSort('cardHolder')}
                                >
                                    Titular
                                </TableSortLabel>
                            </TableCell>

                            <TableCell sx={{width: '10%'}}>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'lastFourCardDigit')}
                                    direction={order.find(o => o.field === 'lastFourCardDigit')?.order || 'asc'}
                                    onClick={() => handleRequestSort('lastFourCardDigit')}
                                >
                                    Últimos 4 dígitos
                                </TableSortLabel>
                            </TableCell>

                            <TableCell sx={{width: '10%'}}>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'cardStamp')}
                                    direction={order.find(o => o.field === 'cardStamp')?.order || 'asc'}
                                    onClick={() => handleRequestSort('cardStamp')}
                                >
                                    Sello
                                </TableSortLabel>
                            </TableCell>

                            <TableCell sx={{width: '10%'}}>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'expiry')}
                                    direction={order.find(o => o.field === 'expiry')?.order || 'asc'}
                                    onClick={() => handleRequestSort('expiry')}
                                >
                                    Fecha de expiración
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'createdAt')}
                                    direction={order.find(o => o.field === 'createdAt')?.order || 'asc'}
                                    onClick={() => handleRequestSort('createdAt')}
                                >
                                    Fecha de creación
                                </TableSortLabel>
                            </TableCell>


                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'createdBy')}
                                    direction={order.find(o => o.field === 'createdBy')?.order || 'asc'}
                                    onClick={() => handleRequestSort('createdBy')}
                                >
                                    Creada por
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'updatedAt')}
                                    direction={order.find(o => o.field === 'updatedAt')?.order || 'asc'}
                                    onClick={() => handleRequestSort('updatedAt')}
                                >
                                    Fecha de actualización
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={order.some(o => o.field === 'updatedBy')}
                                    direction={order.find(o => o.field === 'updatedBy')?.order || 'asc'}
                                    onClick={() => handleRequestSort('updatedBy')}
                                >
                                    Actualizada por
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>Acciones</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item) => (
                            <TableRow
                                key={item.id}
                            >
                                <TableCell>{item.id}</TableCell>

                                <TableCell>
                                    <Box display="flex" alignItems="center" whiteSpace="nowrap" flexGrow={1}
                                         minWidth={0}>
                                        {item.cardHolder}
                                    </Box>

                                </TableCell>
                                <TableCell>
                                    <Box display="flex" alignItems="center" whiteSpace="nowrap" flexGrow={1}
                                         minWidth={0}>
                                        {item.lastFourCardDigit}
                                    </Box>
                                </TableCell>
                                <TableCell>{item.cardStamp}</TableCell>
                                <TableCell>{item.expiry}</TableCell>
                                <TableCell>{item.createdAt}</TableCell>
                                <TableCell>{item.createdBy}</TableCell>
                                <TableCell>{item.updatedAt ? item.updatedAt : '-'}</TableCell>
                                <TableCell>{item.updatedBy ? item.updatedBy : '-'}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => onEdit(item)} title={'Actualizar tarjeta corporativa'}>
                                        <EditIcon/>
                                    </IconButton>

                                    <IconButton onClick={() => onDetail(item)} title={'Detalle y autorizaciones'}>
                                        <VisibilityIcon/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        {items.length == 0 && <EmptyTable colSpan={10} />}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                labelRowsPerPage={'Filas por página'}
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={totalElements}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
};

export default CorporateCardsList;
