import React, {useState} from 'react';
import {Box, Button, Card, CardContent, TextField, Typography} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {IDetailAirline, IUpdateAirline} from "../../types";
import {PhotoCamera} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

export interface AirlineUpdateFormProps {
    handleSubmitForm: (value: IUpdateAirline) => void;
    loading: boolean;
    value: IDetailAirline
}

export const AirlineUpdateForm: React.FC<AirlineUpdateFormProps> = ({
                                                                        handleSubmitForm,
                                                                        loading,
                                                                        value
                                                                    }) => {
    const methods = useForm<IDetailAirline>({
        mode: 'all',
        defaultValues: value
    });

    const [preview, setPreview] = useState<string | null>(null);
    const [imageSize, setImageSize] = useState<{ width: number, height: number } | null>(null);
    const navigate = useNavigate();
    const {
        control,
        formState: {errors, isValid},
        setValue,
        getValues
    } = methods;

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result as string;
                setValue('image', base64String);  // Set base64 string to form
                setPreview(base64String);  // Set preview

                // Create an image to get the original dimensions
                const img = new Image();
                img.onload = () => {
                    setImageSize({width: img.width, height: img.height});
                };
                img.src = base64String;
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSave = () => {
        if (preview === null) {
            handleSubmitForm({...getValues(), changeImage: false, image: undefined});
        } else {
            const values = getValues()
            handleSubmitForm({...values, changeImage: true, image: values.image.split(',')[1]});
        }
    }

    return (
        <>
            <Card>
                <CardContent>
                    <Typography variant="h5" style={{fontWeight: 'bold'}} p={1}>Actualizar aerolínea</Typography>
                    <br/>
                    <Box component="form" display="flex" flexDirection="column" gap={1}>
                        <Box display="flex" flexDirection="row" justifyContent="space-around">
                            <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                                <Controller
                                    name="code"
                                    control={control}
                                    rules={{
                                        required: {value: true, message: 'Este campo es requerido'},
                                        minLength: {
                                            value: 2,
                                            message: 'El campo debe tener mínimo 2 caracteres'
                                        },
                                        maxLength: {
                                            value: 2,
                                            message: 'El campo excede el largo máximo permitido de: 2 caracteres'
                                        }
                                    }}
                                    render={({field}) => (
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            label="Código"
                                            size={'small'}
                                            type="text"
                                            helperText={errors.code ? errors.code.message : ''}
                                            error={!!errors.code}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>

                            <Box display="flex" alignItems="center" gap={2} flex={5} p={1}>
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{
                                        required: {value: true, message: 'Este campo es requerido'},
                                        maxLength: {
                                            value: 255,
                                            message: 'El campo excede el largo máximo permitido de: 255 caracteres'
                                        }
                                    }}
                                    render={({field}) => (
                                        <TextField
                                            variant="outlined"
                                            size={'small'}
                                            required
                                            fullWidth
                                            label="Nombre"
                                            type="text"
                                            helperText={errors.name ? errors.name.message : ''}
                                            error={!!errors.name}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>

                        </Box>

                        <Box display="flex" flexDirection={'column'} mb={1} p={1}>
                            <input
                                accept="image/*"
                                type="file"
                                onChange={handleFileChange}
                                style={{display: 'none'}}
                                id="raised-button-file"
                            />
                            <label htmlFor="raised-button-file">
                                <Button variant="contained" component="span" startIcon={<PhotoCamera/>}>
                                    Subir logo de aerolínea *
                                </Button>
                            </label>
                            {preview && (
                                <img
                                    src={preview}
                                    alt="Vista previa"
                                    style={{
                                        marginTop: 20,
                                        maxWidth: '100%',
                                        height: 'auto',
                                        width: imageSize ? `${imageSize.width}px` : 'auto'
                                    }}
                                />
                            )}{!preview && (
                            <div style={{width: '100%', display: 'flex', justifyContent: 'flex-start'}}>
                                <img
                                    src={value.image}
                                    alt="Vista previa del logo"
                                    style={{
                                        marginTop: 20,
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        width: 'auto',
                                        height: 'auto',
                                        objectFit: 'contain',
                                    }}
                                />
                            </div>
                        )}
                        </Box>

                        <Box display="flex" width="100%" justifyContent="flex-end" gap={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => navigate('/airlines')}
                            >
                                Volver al listado
                            </Button>
                            <Button
                                variant="contained"
                                disabled={!isValid || loading}
                                color="primary"
                                onClick={handleSave}
                            >
                                Guardar
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
        ;
};