import {ApiService} from "../../core/services";
import {AxiosResponse} from "axios";
import {IPaginatedResult, ISort} from "../../core/types";
import {formatSortingFields} from "../../core/helper";
import {IDetailProvider, IListProvider, IProvider} from "./types";

const endpoints = {
    list: 'api/providers',
    listAll: 'api/providers/list/all',
    get: 'api/providers/:id',
    create: 'api/providers',
    update: 'api/providers/:id',
    delete: 'api/providers/:id',
}

export const getProviders = (page = 1, pageSize = 10, sortingFields: ISort[], search: string): Promise<AxiosResponse<IPaginatedResult<IListProvider>>> => {
    const sorts = formatSortingFields(sortingFields);
    return ApiService.get(endpoints.list, {
        params: {
            page,
            pageSize,
            ...(sorts.length > 0 && {sort: sorts}), ...(search && search !== '' && {search: search})
        }
    });
};
export const getAllProviders = (): Promise<AxiosResponse<IDetailProvider[]>> => ApiService.get(endpoints.listAll);
export const getProvider = (id: number): Promise<AxiosResponse<IDetailProvider>> => ApiService.get(endpoints.get.replace(':id', id + ''));
export const createProvider = (data: IProvider): Promise<AxiosResponse<void>> => ApiService.post(endpoints.create, data);
export const updateProvider = (id: number, data: IProvider): Promise<AxiosResponse<void>> => ApiService.put(endpoints.update.replace(':id', id + ''), data);
export const deleteProvider = (id: number): Promise<AxiosResponse<void>> => ApiService.delete(endpoints.delete.replace(':id', id + ''));
