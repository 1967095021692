import {ISort} from "./types";
import {DocumentTypeEnum} from "../modules/payments/types";

export const formatSortingFields = (sortingFields: ISort[]):string => sortingFields.map(x => x.order === 'asc' ? '+'+x.field : `-${x.field}`).join(',');

export const extractErrorMessage = (error: any, defaultMessage: string): string => {
    console.log(error)
    if(error && error.response && error.response?.data && error.response.data['error']){

        if (typeof error.response.data['error'] === 'string') {
            return error.response.data['error'];
        }
        else if(Array.isArray(error.response.data['error']) && error.response.data['error'].length >0){
            if( typeof error.response.data['error'][0] === 'object' && error.response.data['error'][0] !== null) {
                return Object.values(error.response.data['error'][0])[0] + '';
            } else if (typeof error.response.data['error'][0] === 'string' )
                return error.response.data['error'][0]+'';
            else return defaultMessage;
        }	else return defaultMessage;
    } else if (error && error.response && error.response?.data && error.response.data['status'] === 403 ) {
        return 'Acción no permitida, contacte con el administrador del sistema.'
    } else if(error && error.response && error.response?.data && error.response?.data?.title ==='Validation Failed' && Array.isArray(error.response?.data?.violations) && error.response?.data?.violations.length >0){
        return error.response?.data?.violations[0]?.title || defaultMessage;
    }
    else return defaultMessage;
};

export const is404Error = (error:any):boolean => error && error.response && error.response?.status ===404;


export const cleanFilters = (filters:any) => {
    const result = Object.fromEntries(
        Object.entries(filters).filter(([key, value]) => value !== '' && value !== null && value !== undefined)
    );

    for (const key in result) {
        if (result.hasOwnProperty(key)) {
            const value = result[key];
            if (typeof value === "boolean") {
                result[key] = value?'1':'0';
            }
        }
    }
    return result;

}

export const cleanStringEmpty = (value:any) => {
    return  Object.fromEntries(
        Object.entries(value).filter(([key, value]) => value !== '' && value !== null && value !== undefined)
    );

}

export const formatCardNumber = (cardNumber: string): string => {
    if (cardNumber.replace(/\s/g, '').length === 16) {
        // Agrupa de 4 en 4
        return cardNumber.match(/.{1,4}/g)?.join(' ') || cardNumber;
    } else {
        // Formato alternativo: 9999 999999 99999
        return cardNumber.replace(/(\d{4})(\d{6})(\d{5})/, '$1 $2 $3');
    }
};

export const formatDocumentNumber = (documentType:DocumentTypeEnum,documentPlain: string): string=> {
    const expectedLength = 8; // Longitud esperada para aplicar el formato '9.999.999-9'

    // Verificar si el string tiene la longitud correcta
    if (documentType == DocumentTypeEnum.CEDULAUY && documentPlain.length === expectedLength) {
        // Aplicar el formato
        return `${documentPlain.slice(0, 1)}.${documentPlain.slice(1, 4)}.${documentPlain.slice(4, 7)}-${documentPlain.slice(7)}`;
    }

    // Si no tiene la longitud esperada, devolver el string original
    return documentPlain;
}