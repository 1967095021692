import React from 'react';
import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent, Checkbox,
    FormControl, FormControlLabel,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {IDetailTransferService, IUpsertTransferService, TransferTypeEnum} from "../../types";
import {useNavigate} from "react-router-dom";
import {transferTypeToViewList} from "../../constants";
import {IDestinationList} from "../../../destinations/types";

export interface TransferServiceUpsertFormProps {
    handleSubmitForm: (value: IUpsertTransferService) => void;
    loading: boolean;
    destinations: IDestinationList[]
    value?: IDetailTransferService,
}

const defaultValue: IUpsertTransferService = {
    name: '',
    departurePoint: '',
    destinationPoint: '',
    transferType: TransferTypeEnum.InOut,
    destinationCode: '',
    isPrivate: false,
    internalNotes:''
};
export const TransferServiceUpsertForm: React.FC<TransferServiceUpsertFormProps> = ({
                                                                                        handleSubmitForm,
                                                                                        loading,
                                                                                        value,
                                                                                        destinations
                                                                                    }) => {

    const methods = useForm<IUpsertTransferService>({
        mode: 'all',
        defaultValues: value || defaultValue
    });

    const navigate = useNavigate();
    const {
        control,
        formState: {errors, isValid},
        getValues
    } = methods;


    return (
        <>
            <Card sx={{maxWidth: 800, width: '100%'}}>
                <CardContent>
                    <Typography variant="h5" style={{fontWeight: 'bold'}}
                                p={1}>{value ? 'Actualizar' : 'Nuevo'} Servicio de traslado</Typography>
                    <br/>
                    <Box component="form" display="flex" flexDirection="column" gap={1}>

                        <Box display="flex" flexDirection="row" justifyContent="space-around">
                            <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{
                                        required: {value: true, message: 'Este campo es requerido'},
                                        maxLength: {
                                            value: 500,
                                            message: 'El campo excede el largo máximo permitido de: 500 caracteres'
                                        }
                                    }}
                                    render={({field}) => (
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            label="Nombre"
                                            size={'small'}
                                            type="text"
                                            helperText={errors.name ? errors.name.message : ''}
                                            error={!!errors.name}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>
                        </Box>

                        <Box display="flex" flexDirection="row" justifyContent="space-around">
                            <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                                <Controller
                                    name="isPrivate"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControl error={!!errors.isPrivate} component="fieldset">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        {...field}
                                                        checked={field.value}
                                                        color="primary"
                                                    />
                                                }
                                                label="Es privado"
                                            />
                                        </FormControl>
                                    )}
                                />
                            </Box>
                        </Box>


                        <Box display="flex" flexDirection="row" justifyContent="space-around">
                            <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                                <Controller
                                    name="departurePoint"
                                    control={control}
                                    rules={{
                                        required: {value: true, message: 'Este campo es requerido'},
                                        maxLength: {
                                            value: 255,
                                            message: 'El campo excede el largo máximo permitido de: 255 caracteres'
                                        }
                                    }}
                                    render={({field}) => (
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            label="Punto de origen"
                                            size={'small'}
                                            type="text"
                                            helperText={errors.departurePoint ? errors.departurePoint.message : ''}
                                            error={!!errors.departurePoint}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>
                        </Box>

                        <Box display="flex" flexDirection="row" justifyContent="space-around">
                            <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                                <Controller
                                    name="destinationPoint"
                                    control={control}
                                    rules={{
                                        required: {value: true, message: 'Este campo es requerido'},
                                        maxLength: {
                                            value: 255,
                                            message: 'El campo excede el largo máximo permitido de: 255 caracteres'
                                        }
                                    }}
                                    render={({field}) => (
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            label="Punto de destino"
                                            size={'small'}
                                            type="text"
                                            helperText={errors.destinationPoint ? errors.destinationPoint.message : ''}
                                            error={!!errors.destinationPoint}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>
                        </Box>


                        <Box display="flex" flexDirection="row" justifyContent="space-around">
                            <Box display="flex" alignItems="center" gap={2} flex={3} p={1}>
                                <Controller
                                    name="transferType"
                                    control={control}
                                    rules={{
                                        required: {value: true, message: 'Este campo es requerido'},
                                    }}
                                    render={({field}) => (
                                        <FormControl sx={{width: '100%'}} error={!!errors.transferType}>
                                            <InputLabel id="service-type-select-label">Tipo de servicio*</InputLabel>
                                            <Select
                                                size={'small'}
                                                labelId="service-type-select-label"
                                                id="service-type"
                                                required={true}
                                                value={field.value || []}
                                                onChange={(event) => field.onChange(event.target.value)}
                                                renderValue={(selected) => selected}
                                                label="Tipo de servicio*"
                                            >
                                                {transferTypeToViewList.map((transferType) => (
                                                    <MenuItem key={transferType.id} value={transferType.id}>
                                                        {transferType.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.transferType &&
                                                <FormHelperText>{errors.transferType.message}</FormHelperText>}
                                        </FormControl>
                                    )}
                                />
                            </Box>
                        </Box>

                        <Box display="flex" alignItems="center" gap={2} flex={3} p={1}>
                            <Controller
                                name="destinationCode"
                                control={control}
                                rules={{
                                    required: {value: true, message: 'Este campo es requerido'}
                                }}
                                render={({field}) => (
                                    <FormControl sx={{width: '100%'}}>
                                        <Autocomplete
                                            options={destinations}
                                            getOptionLabel={(option: IDestinationList) => `${option.code}-${option.name}, ${option.countryName}`}
                                            value={
                                                destinations.find((option) => option.code === field.value) || null
                                            }
                                            onChange={(event, value) => {
                                                const selectedValues = (value as IDestinationList)?.code || '';
                                                field.onChange(selectedValues);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size={'small'}
                                                    variant="outlined"
                                                    required
                                                    label="Seleccione la ciudad de destino"
                                                    fullWidth
                                                    helperText={errors.destinationCode ? errors.destinationCode.message : ''}
                                                    error={!!errors.destinationCode}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                )}
                            />
                        </Box>

                        <Box display="flex" flexDirection="row" justifyContent="space-around">
                            <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                                <Controller
                                    name="internalNotes"
                                    control={control}
                                    render={({field}) => (
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Notas internas"
                                            multiline
                                            rows={7}
                                            size={'small'}
                                            type="text"
                                            helperText={errors.internalNotes ? errors.internalNotes.message : ''}
                                            error={!!errors.internalNotes}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>
                        </Box>

                        <Box display="flex" width="100%" justifyContent="flex-end" gap={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => navigate('/services/transfer-services')}
                            >
                                Volver al listado
                            </Button>
                            <Button
                                variant="contained"
                                disabled={!isValid || loading}
                                color="primary"
                                onClick={() => handleSubmitForm(getValues())}
                            >
                                Guardar
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
        ;
};