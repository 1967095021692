import React, {useState} from 'react';
import {Autocomplete, Box, Button, Card, CardContent, FormControl, TextField, Typography} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {IDestinationDetail, IDestinationUpdate} from "../../types";
import {PhotoCamera} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {ICountry} from "../../../countries/types";

export interface DestinationCreateFormProps {
    handleSubmitForm: (value: IDestinationUpdate) => void;
    loading: boolean;
    value: IDestinationDetail,
    countries: ICountry[]
}

export const DestinationUpdateForm: React.FC<DestinationCreateFormProps> = ({
                                                                                handleSubmitForm,
                                                                                loading,
                                                                                countries,
                                                                                value
                                                                            }) => {
    const methods = useForm<IDestinationDetail>({
        mode: 'all',
        defaultValues: value
    });

    const [preview, setPreview] = useState<string | null>(null);
    const [imageSize, setImageSize] = useState<{ width: number, height: number } | null>(null);
    const navigate = useNavigate() ;
    const {
        control,
        formState: {errors, isValid},
        setValue,
        getValues
    } = methods;

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result as string;
                setValue('image', base64String);  // Set base64 string to form
                setPreview(base64String);  // Set preview

                // Create an image to get the original dimensions
                const img = new Image();
                img.onload = () => {
                    setImageSize({width: img.width, height: img.height});
                };
                img.src = base64String;
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSave = () => {
        if (preview === null) {
            handleSubmitForm({...getValues(), changeImage: false,image:undefined});
        } else {
            const values = getValues()
            handleSubmitForm({...values, changeImage: true, image: values.image.split(',')[1]});
        }
    }

    return (
        <>
            <Card>
                <CardContent>
                    <Typography variant="h5" style={{fontWeight: 'bold'}} p={1}>Actualizar destino</Typography>
                    <br/>
                    <Box component="form" display="flex" flexDirection="column" gap={1}>
                        <Box display="flex" flexDirection="row" justifyContent="space-around">
                            <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                                <Controller
                                    name="code"
                                    control={control}
                                    rules={{
                                        required: {value: true, message: 'Este campo es requerido'},
                                        maxLength: {
                                            value: 5,
                                            message: 'El campo excede el largo máximo permitido de: 5 caracteres'
                                        }
                                    }}
                                    render={({field}) => (
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            label="Código"
                                            size={'small'}
                                            type="text"
                                            helperText={errors.code ? errors.code.message : ''}
                                            error={!!errors.code}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>

                            <Box display="flex" alignItems="center" gap={2} flex={5} p={1}>
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{
                                        required: {value: true, message: 'Este campo es requerido'},
                                        maxLength: {
                                            value: 255,
                                            message: 'El campo excede el largo máximo permitido de: 255 caracteres'
                                        }
                                    }}
                                    render={({field}) => (
                                        <TextField
                                            variant="outlined"
                                            size={'small'}
                                            required
                                            fullWidth
                                            label="Nombre"
                                            type="text"
                                            helperText={errors.name ? errors.name.message : ''}
                                            error={!!errors.name}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>

                            <Box display="flex" alignItems="center" gap={2} flex={2} p={1}>
                                <Controller
                                    name="countryCode"
                                    control={control}
                                    rules={{
                                        required: {value: true, message: 'Este campo es requerido'}
                                    }}
                                    render={({field}) => (
                                        <FormControl sx={{width: '100%'}}>
                                            <Autocomplete
                                                options={countries}
                                                getOptionLabel={(option: ICountry) => option.name}
                                                value={
                                                    countries.find((option) => option.code === field.value) || null
                                                }
                                                onChange={(event, value) => {
                                                    const selectedValues = (value as ICountry)?.code || '';
                                                    field.onChange(selectedValues);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        size={'small'}
                                                        variant="outlined"
                                                        required
                                                        label="Seleccione el país"
                                                        fullWidth
                                                        helperText={errors.countryCode ? errors.countryCode.message : ''}
                                                        error={!!errors.countryCode}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    )}
                                />
                            </Box>
                        </Box>

                        <Box display="flex" flexDirection={'column'} mb={1} p={1}>
                            <input
                                accept="image/*"
                                type="file"
                                onChange={handleFileChange}
                                style={{display: 'none'}}
                                id="raised-button-file"
                            />
                            <label htmlFor="raised-button-file">
                                <Button variant="contained" component="span" startIcon={<PhotoCamera/>}>
                                    Subir imagen *
                                </Button>
                            </label>
                            {preview && (
                                <img
                                    src={preview}
                                    alt="Vista previa"
                                    style={{
                                        marginTop: 20,
                                        maxWidth: '100%',
                                        height: 'auto',
                                        width: imageSize ? `${imageSize.width}px` : 'auto'
                                    }}
                                />
                            )}{!preview && (
                            <div style={{width: '100%', display: 'flex', justifyContent: 'flex-start'}}>
                                <img
                                    src={value.image}
                                    alt="Vista previa"
                                    style={{
                                        marginTop: 20,
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        width: 'auto',
                                        height: 'auto',
                                        objectFit: 'contain',
                                    }}
                                />
                            </div>
                        )}
                        </Box>

                        <Box display="flex" width="100%" justifyContent="flex-end" gap={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => navigate('/destinations')}
                            >
                                Volver al listado
                            </Button>
                            <Button
                                variant="contained"
                                disabled={!isValid || loading}
                                color="primary"
                                onClick={handleSave}
                            >
                                Guardar
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
        ;
};