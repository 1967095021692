import React, {useCallback, useEffect, useState} from 'react';
import {IDetailAirport, IUpsertAirport} from "../../types";
import {ShowAlertState} from "../../../../core/types";
import {Alert, Backdrop, Box, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {createAirport, getAirport, updateAirport} from "../../services";
import {extractErrorMessage, is404Error} from "../../../../core/helper";
import {useNavigate, useParams} from "react-router-dom";
import {AirportUpsertForm} from "../../components/AirportUpsertForm/AirportUpsertForm";
import {IDestinationList} from "../../../destinations/types";
import {getAllDestinations} from "../../../destinations/services";

const AirportUpsertContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [airportDetail, setAirportDetail] = useState<IDetailAirport | null>(null);
    const [destinations, setDestinations] = useState<IDestinationList[]>([]);
    const {code} = useParams<{ code: string }>();
    const navigate = useNavigate();
    const isCreate = code === 'create'

    const loadAirportDetail = useCallback(async () => {
        try {
            if (code && code !== 'create') {
                setLoading(true);
                const result = (await getAirport(code)).data;
                setAirportDetail(result);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el aeropuerto')
            });
            setLoading(false);
            if (is404Error(error)) {
                setTimeout(() => navigate('/airports'), 2500);
            }
        }
    }, [code, setLoading, setAirportDetail, setAlert]);


    const loadDestinations =  async () => {
        try {

        setLoading(true);
        const result = (await getAllDestinations()).data;
        setDestinations(result);
        setLoading(false);
} catch (error) {
        console.log(error);
        setAlert({
            show: true,
            severity: 'error',
            message: extractErrorMessage(error, 'Ocurrió un error al cargar los destinos')
        });
        setLoading(false);
    }
}


     useEffect(()=>{
         loadDestinations()
    },[])


    useEffect(() => {
        loadAirportDetail();
    }, [loadAirportDetail]);

    const handleUpsertAirport = async (value: IUpsertAirport) => {
        const messageSuccess = isCreate?'creado':'actualizado';
        const messageError = isCreate?'crear':'actualizar';
        if(code){
            try {
                setLoading(true);
                if(isCreate){
                    await createAirport(value);
                } else {
                    await updateAirport(code,value);
                }

                setLoading(false);
                setAlert({
                    show: true,
                    severity: 'success',
                    message: 'Aeropuerto '+messageSuccess+' satisfactoriamente'
                });
                setTimeout(() => navigate('/airports'), 1500)
            } catch (error) {
                setAlert({
                    show: true,
                    severity: 'error',
                    message: extractErrorMessage(error, 'Ocurrió un error al '+messageError+' el aeropuerto')
                });
                setLoading(false);
            }
        }

    }


    return (<Box sx={{width: '80%', padding: 3}}><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            {code && code === 'create' &&
                <AirportUpsertForm loading={loading} handleSubmitForm={handleUpsertAirport} destinations={destinations}/> }
            {code && code !== 'create' && airportDetail &&
                <AirportUpsertForm value={airportDetail} loading={loading} destinations={destinations}
                                           handleSubmitForm={handleUpsertAirport}/>}
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </Box>
    );
}

export default AirportUpsertContainer;
