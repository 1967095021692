import {AxiosResponse} from "axios";
import {
    ICreateCardPortfolioRejectionResolved,
    IListCardPortfolioRejection, IUpsertCardPortfolioRejection,
} from "./types";
import {ApiService} from "../../../../core/services";

const endpoints = {
    list: 'api/cards-portfolio/:cardPortfolioId/rejections',
    create: 'api/cards-portfolio/:cardPortfolioId/rejections',
    update: 'api/cards-portfolio/:cardPortfolioId/rejections/:id',
    resolve: 'api/cards-portfolio/:cardPortfolioId/rejections/:id/resolve',
}

export const getRejections = (cardPortfolioId: number): Promise<AxiosResponse<IListCardPortfolioRejection[]>> => {
    return ApiService.get(endpoints.list.replace(':cardPortfolioId', cardPortfolioId + ''));
};
export const createRejection = (cardPortfolioId: number, data: IUpsertCardPortfolioRejection): Promise<AxiosResponse<void>> => ApiService.post(endpoints.create.replace(':cardPortfolioId', cardPortfolioId + ''), data);
export const updateRejection = (cardPortfolioId: number, id: number, data: IUpsertCardPortfolioRejection): Promise<AxiosResponse<void>> => ApiService.put(endpoints.update.replace(':cardPortfolioId', cardPortfolioId + '').replace(':id', id + ''), data);
export const resolveRejection = (cardPortfolioId: number, id: number, data: ICreateCardPortfolioRejectionResolved): Promise<AxiosResponse<void>> => ApiService.put(endpoints.resolve.replace(':cardPortfolioId', cardPortfolioId + '').replace(':id', id + ''), data);
