import React, {useCallback, useEffect, useState} from 'react';
import {ISort, ShowAlertState} from "../../../../core/types";
import {Alert, Backdrop, Box, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {extractErrorMessage, is404Error} from "../../../../core/helper";
import {useNavigate, useParams} from "react-router-dom";
import {IDetailCorporateCard} from "../../types";
import {getCorporateCardDetail} from "../../services";
import {appRoutes} from "../../../../core/const";
import CorporateCardDetail from "../../components/CorporateCardDetail/CorporateCardDetail";
import {
    IFilterListCorporateCardAuthorization,
    IListCorporateCardAuthorization
} from "../../modules/corporate-card-authorization/types";
import {getCorporateCardAuthorizations} from "../../modules/corporate-card-authorization/services";
import {getAllAuthorizationOperatorsToCorporateCard} from "../../../authorization-operators/services";
import {IListAuthorizationOperator} from "../../../authorization-operators/types";
import {getAllCorporateCardAuthorizationConcepts} from "../../../corporate-card-authorization-concept/services";
import {IListCorporateCardAuthorizationConcept} from "../../../corporate-card-authorization-concept/types";
import {defaultFilterListCorporateCardAuthorizations} from "../../modules/corporate-card-authorization/constants";

const CorporateCardDetailContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [corporateCardDetail, setCorporateCardDetail] = useState<IDetailCorporateCard | null>(null);
    const {id} = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [order, setOrder] = useState<ISort[]>([{'field': 'createdAt', order: 'desc'}]);
    const [filters, setFilters] = useState<IFilterListCorporateCardAuthorization>(defaultFilterListCorporateCardAuthorizations);
    const [items, setItems] = useState<IListCorporateCardAuthorization[]>([]);
    const [operators, setOperators] = useState<IListAuthorizationOperator[]>([]);
    const [concepts, setConcepts] = useState<IListCorporateCardAuthorizationConcept[]>([]);
    const validNumberId = !isNaN(Number(id))

    const loadCorporateCardDetail = useCallback(async () => {
        try {
            if (id && validNumberId) {
                setLoading(true);
                const result = (await getCorporateCardDetail(+id)).data;
                setCorporateCardDetail(result);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el detalle de la tarjeta corporativa')
            });
            setLoading(false);
            if (is404Error(error)) {
                setTimeout(() => navigate(appRoutes.corporateCard.list), 2500);
            }
        }
    }, [id, setLoading, setCorporateCardDetail, setAlert]);


    useEffect(() => {
        loadCorporateCardDetail();
    }, [loadCorporateCardDetail]);


    const loadAuthorizations = useCallback(async () => {
        if (corporateCardDetail) {
            try {
                setLoading(true);
                const paginatedResult = (await getCorporateCardAuthorizations(corporateCardDetail.id, page + 1, pageSize, order, search, filters)).data;
                setTotalElements(paginatedResult.total);
                setItems(paginatedResult.items);
                setLoading(false);
            } catch (error) {
                setAlert({
                    show: true,
                    severity: 'error',
                    message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado de autorizaciones')
                });
                setLoading(false);
            }
        }

    }, [corporateCardDetail, page, pageSize, order, search, filters]);

    const loadOperators = async () => {
        try {
            setLoading(true);
            const result = (await getAllAuthorizationOperatorsToCorporateCard()).data;
            setOperators(result);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar los operadores')
            });
        } finally {
            setLoading(false);
        }
    }

    const loadConcepts = async () => {
        try {
            setLoading(true);
            const result = (await getAllCorporateCardAuthorizationConcepts()).data;
            setConcepts(result);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar los operadores')
            });
        }
    }

    useEffect(() => {
        loadAuthorizations();
    }, [loadAuthorizations]);

    useEffect(() => {
        loadOperators();
    }, []);

    useEffect(() => {
        loadConcepts();
    }, []);


    return (<Box sx={{width: '100%', padding: 3}}><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            {corporateCardDetail && <CorporateCardDetail
                setAlert={setAlert}
                item={corporateCardDetail}
                loading={loading}
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                filters={filters}
                setFilters={setFilters}
                order={order}
                setOrder={setOrder}
                search={search}
                setSearch={setSearch}
                onChangeAuthorizations={loadAuthorizations}
                items={items}
                onLoading={setLoading}
                totalElements={totalElements}
                operators={operators}
                concepts={concepts}


            ></CorporateCardDetail>}
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </Box>
    );
}

export default CorporateCardDetailContainer;
