import * as React from 'react';
import {useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {ThemeProvider} from '@mui/material/styles';
import {useSelector} from "react-redux";
import {FormControl, FormHelperText, IconButton, InputAdornment, useTheme} from '@mui/material';
import DoneIcon from "@mui/icons-material/Done";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import logocolor from "../../../../assets/images/logo_on_white.png";
import {doLogin} from "../../slice";
import store, {RootState} from "../../../../store";
import {ILogin} from "../../types";
import {Controller, useForm} from "react-hook-form";
import {checkShowErrorMessage, emailValidation} from "../../../../core/const";
import loginBg from "../../../../assets/images/login_bg.png";

const Login = () => {
    const theme = useTheme();
    const { status, errorMessage } = useSelector(
        (state: RootState) => state.auth
    );
    const [showPassword, setShowPassword] = useState(false);
    const loginDefaultValues = {
        username: '',
        password: '',
    }
    const { control, formState: { errors, isValid } } = useForm({
        mode: 'all',
        defaultValues: { ...loginDefaultValues, }
    });

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        store.dispatch(doLogin({ password: data.get('password'), username: data.get('username') } as ILogin));
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth={false} sx={{
                backgroundImage: `url(${loginBg})`, // Usar la imagen importada
                 backgroundPosition: "center",
                height: "100vh",
                overflow: "hidden"
            }}>
            <Container component="main" maxWidth="xs" >
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',

                    }}
                >
                    <img src={logocolor} style={{ paddingRight: 30, width: '80%' }} alt="logo" />
                    <Typography component="h2" variant="h4" sx={{ color: theme.palette.primary.main, fontSize: 25 }} style={{ 'marginTop': 10, }}>Sys-Admin</Typography>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >

                    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h6">
                        Iniciar sesión
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <Controller
                            name="username"
                            control={control}
                            rules={{
                                required: { value: true, message: 'Este campo es requerido' },
                                maxLength: {
                                    value: 255,
                                    message: 'El campo excede el largo máximo permitido de: ' + 255 + ' caracteres'

                                },
                                pattern: {
                                    value: emailValidation.value,
                                    message: 'Ingrese un email valido',
                                }
                            }}
                            render={({ field }) => (
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    label="Correo electrónico"
                                    type={"text"}
                                    margin="normal"
                                    helperText={checkShowErrorMessage(Boolean(errors.username), errors.username?.message)}
                                    error={Boolean(errors.username)}
                                    {...field}
                                    id="username"
                                    name="username"
                                    size={"small"}
                                />
                            )}
                        />
                        <Controller
                            name="password"
                            control={control}
                            rules={{
                                required: { value: true, message: 'Este campo es requerido' },
                                minLength: {
                                    value: 5,
                                    message: 'El campo debe tener un mínimo de: ' + 5 + ' caracteres'
                                },
                                maxLength: {
                                    value: 255,
                                    message: 'El campo excede el largo máximo permitido de: ' + 255 + ' caracteres'
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    label="Contraseña"
                                    type={showPassword ? 'text' : 'password'}
                                    margin="normal"
                                    helperText={checkShowErrorMessage(Boolean(errors.password), errors.password?.message)}
                                    error={Boolean(errors.password)}
                                    {...field}
                                    name="password"
                                    size={"small"}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <Button
                            type={"submit"}
                            fullWidth
                            disabled={!isValid}
                            variant="contained"
                            startIcon={<DoneIcon />}
                            sx={{ mt: 1, mb: 2 }}
                        >
                            Acceder
                        </Button>

                        {status === 'failed' && (
                            <Grid item>
                                <FormControl error>
                                    <FormHelperText>{errorMessage}</FormHelperText>
                                </FormControl>
                            </Grid>
                        )}
                    </Box>
                </Box>
            </Container>
            </Container>
        </ThemeProvider>
    );
}

export default Login;
