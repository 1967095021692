import React, {useCallback, useEffect, useState} from 'react';
import {ISort, ShowAlertState} from "../../../../core/types";
import {Alert, Backdrop, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {extractErrorMessage} from "../../../../core/helper";
import {IFilterActionLog, IListActionLog} from "../../types";
import {getActionLogs} from "../../services";
import ActionLogsList from "../../components/ActionLogsList/ActionLogsList";

const ActionLogsListContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [order, setOrder] = useState<ISort[]>([{'field': 'executedAt', order: 'desc'}]);
    const [filters, setFilters] = useState<IFilterActionLog>({
        module: '',
        action: '',
        description: '',
        executedAtFrom: '',
        executedAtTo: '',
        executedByName: '',
        executedAt: ''
    });
    const [items, setItems] = useState<IListActionLog[]>([])

    const loadLogs = useCallback(async () => {
        try {
            setLoading(true);
            const paginatedResult = (await getActionLogs(page + 1, pageSize, order, search, filters)).data;
            setTotalElements(paginatedResult.total);
            setItems(paginatedResult.items);
            setLoading(false);
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado de log de acciones')
            });
            setLoading(false);
        }
    }, [page, pageSize, order, search, filters]);

    useEffect(() => {
        loadLogs();
    }, [loadLogs]);

    return (<><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            <ActionLogsList title={'Logs de acciones del sistema'}
                            items={items}
                            filters={filters}
                            setFilters={setFilters}
                            order={order}
                            setOrder={setOrder}
                            search={search}
                            setSearch={setSearch}
                            page={page}
                            setPage={setPage}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            totalElements={totalElements}
            />
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </>
    );
}

export default ActionLogsListContainer;
