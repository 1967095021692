import {ApiService} from "../../core/services";
import {AxiosResponse} from "axios";
import {IListPaymentInformedConsent} from "./types";

const endpoints = {
    list: 'api/payment-informed-consent',
    create: 'api/payment-informed-consent/upload',
}

export const getPaymentInformedConsent = (): Promise<AxiosResponse<IListPaymentInformedConsent[]>> => {
    return ApiService.get(endpoints.list);
};
export const upsertPaymentInformedConsent = (data: FormData): Promise<AxiosResponse<void>> => ApiService.post(endpoints.create, data, {
    headers: {
        'Content-Type': 'multipart/form-data',
    }

});