// CustomFilterList.tsx
import React from 'react';
import { Chip, Stack, Typography, Box } from '@mui/material';
import { IAppFilterList } from "../../types";

interface CustomFilterListProps<T> {
    filters: IAppFilterList<T>[];
    activeFilterId: number | null; // Controlado externamente
    onApplyCustomFilter: (filter: IAppFilterList<T> | null) => void;
    onDeleteCustomFilter: (id: number) => void;
}

const CustomFilterList = <T,>({
                                  filters,
                                  activeFilterId,
                                  onApplyCustomFilter,
                                  onDeleteCustomFilter
                              }: CustomFilterListProps<T>) => {

    const handleFilterClick = (filter: IAppFilterList<T>) => {
        if (activeFilterId === filter.id) {
            // Desactivar el filtro si ya está activo
            onApplyCustomFilter(null);
        } else {
            // Activar el nuevo filtro
            onApplyCustomFilter(filter);
        }
    };

    const handleDelete = (event: React.MouseEvent, id: number) => {
        event.stopPropagation(); // Evita que se active el filtro al hacer clic en eliminar
        onDeleteCustomFilter(id);
        if (activeFilterId === id) {
            onApplyCustomFilter(null);
        }
    };

    return (
        <Box>
            {/* Título encima del listado de filtros */}
            {filters.length >0 && <Typography variant="h6" gutterBottom>
                Mis filtros guardados
            </Typography>}

            {/* Listado de filtros */}
            <Stack direction="row" spacing={1} flexWrap="wrap">
                {filters.map((filter) => {
                    const isActive = filter.id === activeFilterId;
                    return (
                        <Chip
                            key={filter.id}
                            label={filter.title}
                            onClick={() => handleFilterClick(filter)}
                            onDelete={(event) => handleDelete(event, filter.id)}
                            sx={{
                                backgroundColor: isActive ? filter.backgroundColor : 'default',
                                color: isActive ? filter.textColor : 'inherit',
                                cursor: 'pointer',
                                margin: '4px',
                            }}
                            variant={isActive ? 'filled' : 'outlined'}
                        />
                    );
                })}
            </Stack>
        </Box>
    );
};

export default CustomFilterList;
