import React from 'react';
import {Box, Button, Card, CardContent, TextField, Typography} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {ICountry} from "../../types";
import {useNavigate} from "react-router-dom";

export interface CountryUpsertFormProps {
    handleSubmitForm: (value: ICountry) => void;
    loading: boolean;
    value?: ICountry,
}

const defaultValue: ICountry = {
    code: '',
    name: '',
};
export const CountryUpsertForm: React.FC<CountryUpsertFormProps> = ({
                                                                        handleSubmitForm,
                                                                        loading,
                                                                        value,
                                                                    }) => {

    const methods = useForm<ICountry>({
        mode: 'all',
        defaultValues: value || defaultValue
    });

    const navigate = useNavigate();
    const {
        control,
        formState: {errors, isValid},
        getValues
    } = methods;


    return (
        <>
            <Card sx={{maxWidth: 800, width: '100%'}}>
                <CardContent>
                    <Typography variant="h5" style={{fontWeight: 'bold'}}
                                p={1}>{value ? 'Actualizar' : 'Nuevo'} País</Typography>
                    <br/>
                    <Box component="form" display="flex" flexDirection="column" gap={1}>

                        <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                            <Controller
                                name="code"
                                control={control}
                                rules={{
                                    required: {value: true, message: 'Este campo es requerido'},
                                    minLength: {
                                        value: 2,
                                        message: 'El campo excede el largo mínimo permitido de: 2 caracteres'
                                    },
                                    maxLength: {
                                        value: 2,
                                        message: 'El campo excede el largo máximo permitido de: 2 caracteres'
                                    }
                                }}
                                render={({field}) => (
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="Código"
                                        size={'small'}
                                        type="text"
                                        helperText={errors.code ? errors.code.message : ''}
                                        error={!!errors.code}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>

                        <Box display="flex" flexDirection="row" justifyContent="space-around">
                            <Box display="flex" alignItems="center" gap={2} flex={1} p={1}>
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{
                                        required: {value: true, message: 'Este campo es requerido'},
                                        maxLength: {
                                            value: 500,
                                            message: 'El campo excede el largo máximo permitido de: 500 caracteres'
                                        }
                                    }}
                                    render={({field}) => (
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            label="Nombre"
                                            size={'small'}
                                            type="text"
                                            helperText={errors.name ? errors.name.message : ''}
                                            error={!!errors.name}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>
                        </Box>

                        <Box display="flex" width="100%" justifyContent="flex-end" gap={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => navigate('/countries')}
                            >
                                Volver al listado
                            </Button>
                            <Button
                                variant="contained"
                                disabled={!isValid || loading}
                                color="primary"
                                onClick={() => handleSubmitForm(getValues())}
                            >
                                Guardar
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
        ;
};