import {AxiosResponse} from "axios";
import {ApiService} from "../../core/services";
import {IDetailCorporateCard, IFilterListCorporateCard, IListCorporateCard, IUpsertCorporateCard} from "./types";
import {IPaginatedResult, ISort} from "../../core/types";
import {cleanFilters, formatSortingFields} from "../../core/helper";

const endpoints = {
    create: 'api/corporate-cards',
    update: 'api/corporate-cards/:id',
    list: 'api/corporate-cards',
    detail: 'api/corporate-cards/:id/detail-to-update',
}
export const getCorporateCards = (page = 1, pageSize = 10, sortingFields: ISort[], search: string, filters?: IFilterListCorporateCard): Promise<AxiosResponse<IPaginatedResult<IListCorporateCard>>> => {
    const sorts = formatSortingFields(sortingFields);
    return ApiService.get(endpoints.list, {
        params: {
            page,
            pageSize,
            ...(sorts.length > 0 && {sort: sorts}), ...(filters && cleanFilters(filters)), ...(search && search !== '' && {search: search})
        }
    });
};
export const createCorporateCard = (data: IUpsertCorporateCard): Promise<AxiosResponse<void>> => ApiService.post(endpoints.create, data);
export const updateCorporateCard = (id: number, data: IUpsertCorporateCard): Promise<AxiosResponse<void>> => ApiService.put(endpoints.update.replace(':id', id + ''), data);
export const getCorporateCardDetail = (id: number): Promise<AxiosResponse<IDetailCorporateCard>> => ApiService.get(endpoints.detail.replace(':id', id + ''));
