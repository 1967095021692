import React from 'react';
import {Container, Grid, Typography} from '@mui/material';
import {ICardPortfolioSummaryContainer} from "../../types";
import TopSellersList from "../TopSellersList/TopSellersList";
import CardPortfolioSummary from "../CardPortfolioSummary/CardPortfolioSummary";

interface CardPortfolioSummaryDashBoardProps {
    data: ICardPortfolioSummaryContainer;
}

const CardPortfolioSummaryDashBoard: React.FC<CardPortfolioSummaryDashBoardProps> = ({ data }) => {

    return (
        <Container sx={{mt: 4, mb: 4}}>
            <Typography variant="h4" gutterBottom>
                Reporte de tarjetas en cartera
            </Typography>
            <Grid container spacing={4}>
                {/* Resumen de Ventas */}
                <Grid item xs={12}>
                    <CardPortfolioSummary summary={data.saleSummary}/>
                </Grid>

                {/* Top Vendedores del Mes Anterior */}
                <Grid item xs={12} md={6}>
                    <TopSellersList
                        title={`Top Vendedores - ${data.previousMonthName[0].charAt(0).toUpperCase() + data.previousMonthName.slice(1)}`}
                        sellers={data.topSellerPreviousMonth}
                    />
                </Grid>

                {/* Top Vendedores del Mes Actual */}
                <Grid item xs={12} md={6}>
                    <TopSellersList
                        title={`Top Vendedores - ${data.currentMonthName[0].charAt(0).toUpperCase() + data.currentMonthName.slice(1)}`}
                        sellers={data.topSellerCurrentMonth}
                    />
                </Grid>


            </Grid>
        </Container>
    );
};

export default CardPortfolioSummaryDashBoard;
