import {ApiService} from "../../core/services";
import {AxiosResponse} from "axios";
import {IPaginatedResult, ISort} from "../../core/types";
import {cleanFilters, formatSortingFields} from "../../core/helper";
import {
    IDetailInsuranceService,
    IFilterInsuranceService,
    IUpsertInsuranceService
} from "./types";

const endpoints = {
    list: 'api/insurance-services',
    get: 'api/insurance-services/:id',
    create: 'api/insurance-services',
    update: 'api/insurance-services/:id',
    delete: 'api/insurance-services/:id',
}

export const getInsuranceServices = (page = 1, pageSize = 10, sortingFields: ISort[], search:string, filters?: IFilterInsuranceService): Promise<AxiosResponse<IPaginatedResult<IDetailInsuranceService>>> => {
    const sorts = formatSortingFields(sortingFields);
    return ApiService.get(endpoints.list, {
        params: {
            page,
            pageSize,
            ...(sorts.length > 0 && {sort: sorts}), ...(filters && cleanFilters(filters)), ...(search && search!=='' && {search:search})
        }
    });
};
export const getInsuranceService = (id:number): Promise<AxiosResponse<IDetailInsuranceService>> => ApiService.get(endpoints.get.replace(':id',id+''));
export const createInsuranceService = (data: IUpsertInsuranceService): Promise<AxiosResponse<void>> => ApiService.post(endpoints.create, data);
export const updateInsuranceService = (id: number, data: IUpsertInsuranceService): Promise<AxiosResponse<void>> => ApiService.put(endpoints.update.replace(':id', id+''), data);

export const deleteInsuranceService = (id: number): Promise<AxiosResponse<void>> => ApiService.delete(endpoints.delete.replace(':id', id+''));