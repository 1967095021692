export interface IDocumentTypeToView {
    id: string;
    name: string
}

export enum DocumentTypeEnum {
    CEDULAUY = 'CEDULAUY',
    DNI = 'DNI'
}

export const documentTypeSToViewList: IDocumentTypeToView[] = [{id: DocumentTypeEnum.CEDULAUY, name: 'Cédula Uruguaya'},
    {id: DocumentTypeEnum.DNI, name: 'DNI'}]

export interface ICreatePaymentLinkPay {
    saleOrderId:string;
    cardNumber:string;
    cardHolder:string;
    expiry:string;
    cvc:string;
    cardStamp:string;
    documentType:string;
    documentNumber:string;
}

export interface IOrderInfoToPay {
    id:string;
    amount:number;
    fee:number;
    allowPay:boolean;
    urlInformConsent:string
}