
export enum TransferTypeEnum {
    InOut ='InOut',
    OneWay = 'OneWay'
}
export interface IListTransferService{
    id:number;
    name:string;
    departurePoint:string;
    destinationPoint:string;
    transferType:TransferTypeEnum;
    isPrivate:boolean;
    destinationName:string;
}

export interface IUpsertTransferService{
    name:string;
    departurePoint:string;
    destinationPoint:string;
    transferType:TransferTypeEnum;
    isPrivate:boolean;
    destinationCode:string;
    internalNotes?:string
}

export interface IDetailTransferService{
    id:number;
    name:string;
    departurePoint:string;
    destinationPoint:string;
    transferType:TransferTypeEnum;
    isPrivate:boolean;
    destinationName:string;
    internalNotes?:string
}

export interface IFilterTransferService{
    name?:string;
    departurePoint?:string;
    destinationPoint?:string;
    transferType?:string;
    isPrivate?:boolean;
    destinationName?:string;
}