import React, {forwardRef, useEffect, useImperativeHandle} from 'react';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {Box, Button, IconButton, TextField, Typography} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {IContactInfoToEmail} from "../../types";
import {emailValidation} from "../../../../core/const";

interface IFormInput {
    Contacts: IContactInfoToEmail[];
}

interface ContactsFormProps {
    initialData: IContactInfoToEmail[];
    title:string
}

const ContactsForm = forwardRef(({initialData,title}: ContactsFormProps, ref) => {
    const {control, setValue, getValues, trigger, formState: {errors, isValid}} = useForm<IFormInput>({
        defaultValues: {
            Contacts: initialData,
        },
        mode: 'onChange',
    });

    const {fields, append, remove} = useFieldArray({
        control,
        name: 'Contacts',
    });

    useImperativeHandle(ref, () => ({
        getFormValues: () => getValues(),
        isValidForm: () => isValid,
        triggerValidation: () => trigger(),
    }));

    useEffect(() => {
        if (initialData) {
            setValue('Contacts', initialData);
        }
    }, [initialData, setValue]);

    return (
        <Box component="form" display="flex" flexDirection="column" gap={2}>
            <Typography variant="h6" style={{fontWeight: 'bold'}}>
                {title}
            </Typography>
            {fields.map((item, index) => (
                <Box key={item.id} display="flex" alignItems="flex-start" gap={2}>
                    <Controller
                        name={`Contacts.${index}.personName`}
                        control={control}
                        rules={{required: {value: true, message: 'Este campo es requerido'},
                            maxLength: {
                                value: 255,
                                message: 'El campo excede el largo máximo permitido de: ' + 255 + ' caracteres'

                            }}}
                        render={({field}) => (
                            <TextField
                                error={!!errors?.Contacts?.[index]?.personName}
                                helperText={errors?.Contacts?.[index]?.personName?.message}
                                {...field}
                                label="Nombre del contacto"
                                variant="outlined"
                                size="small"
                                required
                                fullWidth
                            />
                        )}
                    />
                    <Controller
                        name={`Contacts.${index}.email`}
                        control={control}
                        rules={{required: {value: true, message: 'Este campo es requerido'},
                            maxLength: {
                                value: 255,
                                message: 'El campo excede el largo máximo permitido de: ' + 255 + ' caracteres'

                            },
                            pattern: {
                                value: emailValidation.value,
                                message: 'Ingrese un email válido',
                            }}}
                        render={({field, fieldState}) => (
                            <TextField
                                error={!!errors?.Contacts?.[index]?.email}
                                helperText={errors?.Contacts?.[index]?.email?.message}
                                {...field}
                                label="Correo electrónico"
                                variant="outlined"
                                size="small"
                                required
                                fullWidth

                            />
                        )}
                    />

                    {index > 0 && <IconButton color="secondary" onClick={() => remove(index)}>
                        <DeleteIcon/>
                    </IconButton>}
                </Box>
            ))}
            <Box display="flex" justifyContent="flex-start" gap={2}>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon/>}
                    onClick={() => append({personName: '', email: ''})}
                >
                    Agregar nuevo contacto
                </Button>
            </Box>
        </Box>
    );
});

export default ContactsForm;
