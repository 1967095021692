import React, {useCallback, useEffect, useState} from 'react';
import {IListAuthorizationOperator, IUpsertAuthorizationOperator} from "../../types";
import {ShowAlertState} from "../../../../core/types";
import {Alert, Backdrop, Box, CircularProgress, Snackbar, useTheme} from "@mui/material";
import {createAuthorizationOperator, getAuthorizationOperator, updateAuthorizationOperator} from "../../services";
import {extractErrorMessage, is404Error} from "../../../../core/helper";
import {useNavigate, useParams} from "react-router-dom";
import {appRoutes} from "../../../../core/const";
import {
    AuthorizationOperatorUpsertForm
} from "../../components/AuthorizationOperatorUpsertForm/AuthorizationOperatorUpsertForm";

const AuthorizationOperatorUpsertContainer: React.FC = () => {
    const theme = useTheme();
    const defaultAlertState: ShowAlertState = {show: false, severity: 'success', message: ''};
    const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
    const [loading, setLoading] = useState<boolean>(false);
    const [authorizationOperatorDetail, setAuthorizationOperatorDetail] = useState<IListAuthorizationOperator | null>(null);
    const {id} = useParams<{ id: string }>();
    const navigate = useNavigate();

    const loadAuthorizationOperatorDetail = useCallback(async () => {
        try {
            if (id && id !== 'create') {
                setLoading(true);
                const result = (await getAuthorizationOperator(id)).data;
                setAuthorizationOperatorDetail(result);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al cargar el operador')
            });
            setLoading(false);
            if (is404Error(error)) {
                setTimeout(() => navigate(appRoutes.authorizationOperator.list), 2500);
            }
        }
    }, [id, setLoading, setAuthorizationOperatorDetail, setAlert]);


    useEffect(() => {
        loadAuthorizationOperatorDetail();
    }, [loadAuthorizationOperatorDetail]);

    const handleCreateAuthorizationOperator = async (value: IUpsertAuthorizationOperator) => {
        try {
            setLoading(true);
            await createAuthorizationOperator(value);
            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Operador creado satisfactoriamente'
            });
            setTimeout(() => navigate(appRoutes.authorizationOperator.list), 1500)
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al crear el operador')
            });
            setLoading(false);
        }
    }

    const handleUpdateAuthorizationOperator = async (value: IUpsertAuthorizationOperator) => {
        try {
            setLoading(true);
            await updateAuthorizationOperator(id||'', value);
            setLoading(false);
            setAlert({
                show: true,
                severity: 'success',
                message: 'Operador actualizado satisfactoriamente'
            });
            setTimeout(() => navigate(appRoutes.authorizationOperator.list), 1500)
        } catch (error) {
            setAlert({
                show: true,
                severity: 'error',
                message: extractErrorMessage(error, 'Ocurrió un error al actualizar el operador')
            });
            setLoading(false);
        }
    }


    return (<Box sx={{width: '80%', padding: 3}}><Backdrop sx={{
            zIndex: theme.zIndex.modal + 1,
            color: '#fff',
        }} open={loading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
            {id && id === 'create' &&
                <AuthorizationOperatorUpsertForm loading={loading}
                                                 handleSubmitForm={handleCreateAuthorizationOperator}/>}
            {id && id !== 'create' && authorizationOperatorDetail &&
                <AuthorizationOperatorUpsertForm value={authorizationOperatorDetail} loading={loading}
                                                 handleSubmitForm={handleUpdateAuthorizationOperator}/>}
            <Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
                <Alert variant="filled" severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        </Box>
    );
}

export default AuthorizationOperatorUpsertContainer;
