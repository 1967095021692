import React from 'react';
import { ListItem, ListItemIcon, ListItemText, useTheme, ListItemSecondaryAction } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { SxProps, Theme } from '@mui/material/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface NavListItemProps {
    to: string;
    icon: React.ReactElement;
    primary: string;
    exact?: boolean;
    sx?: SxProps<Theme>;
}

const NavListItem: React.FC<NavListItemProps> = ({ to, icon, primary, exact = false, sx }) => {
    const location = useLocation();
    const theme = useTheme();

    // Determinar si la ruta actual coincide con la ruta del item
    const isActive = exact
        ? location.pathname === to
        : location.pathname.startsWith(to);

    return (
        <ListItem
            button
            component={Link}
            to={to}
            sx={{
                backgroundColor: isActive ? theme.palette.primary.main : 'inherit',
                color: isActive ? 'white' : 'inherit',
                '&:hover': {
                    backgroundColor: isActive ? theme.palette.primary.main : theme.palette.primary.light,
                    color: 'white',
                },
                ...sx, // Permitir estilos adicionales a través de props
            }}
        >
            <ListItemIcon sx={{ color: isActive ? 'white' : 'inherit' }}>
                {icon}
            </ListItemIcon>
            <ListItemText primary={primary} />

            {/* Agregar el icono de flecha más pequeño al final */}
            <ListItemSecondaryAction>
                <ArrowForwardIosIcon
                    sx={{
                        color: isActive ? 'white' : 'inherit',
                        fontSize: 'small' // Ajusta el tamaño del icono
                    }}
                />
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export default NavListItem;
